@import "../ToolsModule/general";

.home_page_outer_container {
  width: inherit;
  height: auto;
  line-height: normal !important;
  overflow-x: hidden !important;
  font-family: "EXO2.0-REGULAR" !important;

  a {
    text-decoration: none;
    color: var(--bs-heading-color);
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 0;
    // line-height: 1.2rem;
    font-weight: 500;
  }

  * {
    font-family: "EXO2.0-REGULAR";
  }

  p {
    margin-bottom: 0;
  }
}

@import "../../common";

.capabilities_outer_container {
  @include centerFlex();
  background-color: rgba(207, 212, 219, 0.4);
}

.capabilities_container_wrapper {
  @include verticalFlex($justify: center);
  height: 100%;
  background-color: var(--white);
  padding-top: 3rem;
  gap: 2rem;
  clip-path: polygon(0 0, 100% 0, 100% 75%, 90% 95%, 5% 88%, 0 68%);
  padding-bottom: 33%;

  @media screen and (min-width: 320px) {
    padding-bottom: 35%;
  }
  @media screen and (min-width: 355px) {
    padding-bottom: 28%;
    clip-path: polygon(0 0, 100% 0, 100% 75%, 90% 95%, 12% 88%, 0 68%);
  }

  @include media-mid-device() {
    padding-bottom: 18%;
    gap: 3rem;
  }
  @include media-large-device() {
    padding: 8% 15% 12% 15%;
  }
  @media screen and (min-width: 1100px) {
    padding: 4% 16% 7% 16%;
  }
  @media screen and (min-width: 1200px) {
    flex-direction: row;
    clip-path: polygon(0 0, 100% 0, 94% 90%, 5% 78%);
    padding: 8% 5%;
    gap: 0rem;
  }

  @media screen and (min-width: 1650px) {
    clip-path: polygon(0 0, 100% 0, 88% 88%, 11% 69%);
    padding: 5% 10% 8% 10%;
  }

  @include media-xxl() {
    padding: 10%;
    // padding: 8% 5%;
    // clip-path: polygon(0 0, 100% 0, 94% 88%, 5% 74%);
  }

  @include media-mobile-landscape() {
    padding-bottom: 13%;
  }
}

.capabilities_container_brand_title {
  @include verticalFlex($gap: 0.5rem, $align: center);

  @include media-large-device() {
    width: 85%;
  }
  @media screen and (min-width: 1100px) {
  }
  @media screen and (min-width: 1200px) {
    align-items: flex-start;
    width: 75%;
  }
}
.brand_title_heading_wrapper {
  @include horizontalFlex($justify: center, $gap: 1rem);

  @media screen and (min-width: 1200px) {
    justify-content: flex-start;
  }
}

.capabilities_ez_logo_image {
  width: 4rem;
  @include media-large-device() {
    width: 6rem;
  }

  @include media-xl() {
    width: 8rem;
  }
  @include media-xxl() {
    width: 10rem;
  }
  @include media-xxxl() {
    width: 12rem;
  }
}

.brand_title_heading {
  color: var(--dark-blue);
  font-size: var(--rem-2xl-font);

  @media screen and (min-width: 300px) {
    font-size: 28px;
  }

  @media screen and (min-width: 350px) {
    font-size: 32px;
  }
  @include media-large-device() {
    font-size: var(--rem-4xl-font);
  }

  @include media-xl() {
    font-size: var(--rem-5xl-font);
  }
  @include media-xxl() {
    font-size: var(--rem-7xl-font);
  }
  @include media-xxxl() {
    font-size: var(--rem-8xl-font);
  }
}

.brand_title_desc {
  color: var(--dark-blue);
  font-size: var(--rem-base-font);
  text-align: center;

  @include media-large-device() {
    font-size: var(--rem-xl-font);
  }

  @include media-xl() {
    font-size: var(--rem-2xl-font);
  }
  @include media-xxl() {
    font-size: var(--rem-3xl-font);
  }
  @include media-xxxl() {
    font-size: var(--rem-4xl-font);
  }
}

.capabilities_container_brand_desc {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(1fr, 4);
  grid-gap: 3rem;
  width: 100%;

  @include media-mid-device() {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    width: 100%;
    grid-gap: 1rem;
  }
  @media screen and (min-width: 1200px) {
    width: 120%;
  }
  @include media-large-device() {
    width: 125%;
    grid-gap: 1.5rem;
  }
  @include media-small-desktop() {
    grid-gap: 1rem;
  }
  @include media-xl() {
    grid-gap: 1.5rem;
  }
  @include media-mobile-landscape() {
    grid-gap: 2.5rem;
  }
}

.desc_text_wrapper {
  @include verticalFlex($justify: flex-start);
  text-align: center;

  @include media-mobile-landscape() {
    justify-content: center;
  }

  p {
    padding-top: 2px;
    padding-bottom: 2px;
  }
}
.dark_blue {
  background-color: var(--dark-blue);
  color: white;
  padding: 3px 5px;
}

.first_desc {
  font-size: var(--rem-2xl-font);
}
.desc_text {
  font-size: var(--rem-base-font);

  @media screen and (min-width: 320px) {
    font-size: var(--rem-lg-font);
  }

  @include media-large-device() {
    font-size: var(--rem-xl-font);
  }

  @include media-xl() {
    font-size: var(--rem-2xl-font);
  }
  @include media-xxl() {
    font-size: var(--rem-4xl-font);
  }
  @include media-xxxl() {
    font-size: var(--rem-5xl-font);
  }
}

.primary_size_desc {
  font-size: var(--rem-xl-font);
  padding: 3px 5px;

  @media screen and (min-width: 320px) {
    font-size: var(--rem-2xl-font);
  }

  @include media-large-device() {
    font-size: var(--rem-2xl-font);
  }

  @include media-xl() {
    font-size: var(--rem-3xl-font);
  }
  @include media-xxl() {
    font-size: var(--rem-5xl-font);
  }
  @include media-xxxl() {
    font-size: var(--rem-6xl-font);
  }
}

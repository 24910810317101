@import "../../../common";

.card {
  @include verticalFlex($width: 13rem, $justify: flex-start, $gap: 2rem);
  padding: 3rem 1rem;
  background: radial-gradient(at center top, #4c4b4b, #000000);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  color: var(--white);
  height: 90%;
  cursor: pointer;
  height: 100%;

  @media screen and (min-width: 300px) {
    width: 17rem;
  }

  @media screen and (min-width: 360px) {
    padding: 1rem;
  }

  @media screen and (min-width: 360px) {
    width: 19rem;
  }

  @include media-mid-device() {
    gap: 3rem;
    padding: 1rem;
  }

  @include media-small-device() {
    width: 26rem;
  }

  @include media-mid-device() {
    width: 32rem;
  }

  @include media-xxl() {
    width: 42rem;
  }
}

.serivce_icons_container_wrapper {
  @include centerFlex();
  height: 100%;
}

.card_heading_text {
  position: relative;
  color: var(--white);
  width: 100%;
  text-align: center;
  font-size: var(--rem-lg-font);

  @include media-mid-device() {
    font-size: var(--rem-2xl-font);
  }

  @include media-xxxl() {
    font-size: var(--rem-5xl-font);
  }
}

.card_heading_wrapper {
  @include centerFlex();
}

.heading_bar {
  position: absolute;
  height: 0.2rem;
  bottom: -1rem;
  width: 6rem;
  left: 22%;
  background-color: rgba(255, 255, 255, 0.5);

  @media screen and (min-width: 300px) {
    width: 8rem;
    left: 26%;
  }

  @include media-small-device() {
    width: 12rem;
    left: 29%;
  }

  @include media-xxxl() {
    width: 12rem;
    left: 37%;
    height: 0.4rem;
    bottom: -2rem;
  }
}

.serivce_icons_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
}
.serivce_icons_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
  text-align: center;
  width: 4rem;
  height: 5.5rem;
  font-size: 12px;

  @media screen and (min-width: 360px) {
    width: 4rem;
  }

  @include media-small-device() {
    width: 7rem;
    height: 6.5rem;
    font-size: var(--rem-base-font);
  }

  @include media-xxxl() {
    width: 10rem;
    height: 9rem;
    font-size: var(--rem-2xl-font);
  }

  img {
    width: 2.5rem;
    object-fit: contain !important;

    @include media-small-device() {
      width: 3.5rem;
    }
    @include media-xxxl() {
      width: 5rem;
    }
  }
}

.service_icons_wrapper {
  position: absolute;
  right: 0rem;
  top: -2.5rem;

  @media screen and (min-width: 300px) {
    right: 0rem;
    top: -2rem;
  }

  @media screen and (min-width: 360px) {
    right: 0rem;
    top: 0rem;
  }

  img {
    width: 2.5rem;

    @include media-xxxl() {
      width: 4rem;
    }
  }
}

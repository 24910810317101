@import "../general";

.tools_layout {
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: grid;
  grid-template-rows: 60px auto;
  grid-template-columns: 60px 25% auto;
  /* background-image: url("./background/dashboard-bg.png"); */
  /* background-size: cover; */
  // background: var(--vertical-blue-black-gradient);
  background-color: var(--tool-bg-blue);
  grid-template-areas:
    "stcsidenav stsidebar stctopnav"
    "stcsidenav stsidebar stdashboard";

  @include media-potrait() {
    grid-template-rows: 50px 1fr 50px;
    grid-template-columns: 100vw;
    justify-content: stretch;
    align-content: stretch;
    grid-template-areas:
      "stctopnav"
      "stdashboard"
      "stcsidenav";
  }

  @include media-tab-potrait-device() {
    grid-template-rows: 6vh 1fr 6vh;
  }

  @include media-xxl() {
    grid-template-columns: 100px 25% auto;
  }
}

.tools_sidebar {
  grid-area: stsidebar;
  @include stc-normal-font();
  height: 100vh;
  width: 100%;
  background: rgb(6, 14, 24);

  @include media-potrait() {
    grid-area: stdashboard;
    position: absolute;
    z-index: 3;
    // height: calc(100vh - 100px);
    // transform: translateY(-50%);
    // top: 50%;
    grid-area: none;
    top: 50px;
    height: calc(100% - 100px);
    left: -100vw;
    width: 100vw;
    display: inline-block;
    overflow-y: auto;
    transition: 300ms;
  }

  @include media-tab-potrait-device() {
    top: 6vh;
    height: calc(100% - 12vh);
  }

  @include media-extrasmall-device() {
    height: calc(100% - 100px);
  }
}
.tools_sidebar-show {
  @include media-potrait() {
    left: 0px;
  }
}

@import "../../common";

.commercial_modals_outer_container {
  position: relative;
  //   height: 50rem;
  background-color: var(--darkest-blue);
  overflow: hidden;
  @include verticalFlex();

  @include media-1100p() {
    height: 50rem;
  }

  @include media-xxl() {
    height: 60rem;
  }
  @include media-xxxl() {
    height: 70rem;
  }
}

.top_gradient {
  position: absolute;
  top: 0;
  right: -50rem;

  @include media-mid-device() {
    right: -40rem;
  }
  svg {
    @include media-large-device() {
      width: 100%;
    }
  }
}

.commercial_modals_heading_wrapper {
  @include verticalFlex($gap: 0.5rem);
  text-align: center;
  padding: 2rem 1rem;

  @include media-small-device() {
    padding: 3rem 1rem;
  }

  @include media-large-device() {
    width: 60%;
    padding: 2rem 1rem;
  }
  @include media-xxl() {
    padding: 3rem 1rem;
  }
  @include media-xxxl() {
    padding: 4rem 1rem;
  }
}

.commercial_modals_heading_text {
  color: var(--white);
  font-size: 20px;

  @media screen and (min-width: 300px) {
    font-size: var(--rem-2xl-font);
  }
  // @include media-350p() {
  //   font-size: var(--rem-2xl-font);
  // }

  // @include media-large-device() {
  //   font-size: var(--rem-2xl-font);
  // }

  @include media-xl() {
    font-size: 32px;
  }
  @include media-xxl() {
    font-size: var(--rem-5xl-font);
  }

  @include media-xxxl() {
    font-size: var(--rem-6xl-font);
  }
}

.commercial_modals_sub_heading_text {
  color: var(--white);
  font-size: var(--rem-lg-font);

  @media screen and (min-width: 300px) {
    font-size: var(--rem-xl-font);
  }

  @include media-large-device() {
    font-size: var(--rem-xl-font);
  }

  @include media-xl() {
    font-size: var(--rem-2xl-font);
  }
  @include media-xxxl() {
    font-size: var(--rem-3xl-font);
  }
}

.commercial_modals_expansion_carousel {
  @include verticalFlex();
  height: 100%;

  @include media-1100p() {
    flex-direction: row;
  }
}
.entity_slider {
  @include verticalFlex($justify: center, $gap: 2rem);
  height: 100%;

  @include media-mid-device() {
    width: 100%;
    gap: 4rem;
  }
  @include media-large-device() {
    width: 70%;
  }

  @include media-1100p() {
    justify-content: flex-start;
    width: 100%;
  }
}
.entity_slider_wrapper {
  @include centerFlex();
  height: 100%;
  padding: 2rem 1rem;

  cursor: pointer;

  &:first-child {
    background-color: #001024;
  }
  &:nth-child(2) {
    background-color: #0d2039;
  }

  &:last-child {
    background-color: var(--dark-blue);
  }

  @include media-mid-device() {
    padding: 2rem;
  }

  @include media-small-desktop() {
    padding: 2rem 1rem;
  }

  @media screen and (min-width: 1500px) {
    padding: 3rem;
  }

  @include media-xl() {
    padding: 2rem 1.5rem;
  }
  @media screen and (min-width: 1800px) {
    padding: 4rem;
  }
}

.active_slider {
  width: 100%;
  transition: 0.5s all ease-out;
  height: 35rem;

  @include media-mid-device() {
    height: 30rem;
  }

  @include media-1100p() {
    width: 70%;
    height: 100%;
  }
}

.unactive_slider {
  width: 100%;
  transition: 0.5s all ease-out;
  height: 13rem;

  @include media-1100p() {
    width: 30%;
    height: 100%;
  }

  &:hover {
    .animated_under_line {
      &::after {
        transform: scaleX(1);
        transform-origin: bottom left;
      }
    }
  }
}

.entity_slider_header {
  @include verticalFlex($gap: 2rem, $align: flex-start);

  @include media-small-device() {
    flex-direction: row;
    align-items: center;
  }
}

.unactive_slider_header {
  flex-direction: column;
  gap: 1.5rem;
  text-align: center;

  @include media-mid-device() {
    flex-direction: row;
  }

  @include media-1100p() {
    flex-direction: column;
  }
}

.active_entity_slider_heading_wrapper {
  @include verticalFlex($justify: flex-start, $align: flex-start);

  @include media-mid-device() {
    width: 65%;
  }
  @include media-large-device() {
    width: 70%;
  }
  @include media-xxl() {
    gap: 0.5rem;
  }
}

.unactive_entity_slider_heading_wrapper {
  @include verticalFlex($align: center);

  @include media-mid-device() {
    align-items: flex-start;
    width: 65%;
  }
  @include media-large-device() {
    width: 70%;
  }

  @include media-1100p() {
    align-items: center;
    width: 100%;
  }
}

.entity_slider_heading_text {
  color: var(--white);
}

.active_slider_heading_text {
  @extend .entity_slider_heading_text;
  font-size: var(--rem-2xl-font);

  @include media-350p() {
    font-size: 32px;
  }

  @include media-large-device() {
    font-size: var(--rem-4xl-font);
  }

  @include media-xl() {
    font-size: var(--rem-5xl-font);
  }

  @include media-xxl() {
    font-size: var(--rem-6xl-font);
  }
  @include media-xxxl() {
    font-size: var(--rem-7xl-font);
  }
}

.unactive_slider_heading_text {
  @extend .entity_slider_heading_text;
  font-size: var(--rem-xl-font);
  position: relative;

  @include media-350p() {
    font-size: var(--rem-2xl-font);
  }

  @include media-large-device() {
    font-size: var(--rem-3xl-font);
  }

  @include media-xl() {
    font-size: var(--rem-4xl-font);
  }
  @include media-xxl() {
    font-size: var(--rem-5xl-font);
  }
}

.animated_under_line {
  position: relative;
  color: var(--dark-blue);
  width: 5rem;
  display: none;
  //   visibility: hidden;
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    border-radius: 5px;
    height: 0.5rem;
    bottom: 0;
    left: 0;
    background: var(--light-blue);
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  @include media-1100p() {
    display: inline-block;
  }
}

.model_desc {
  color: var(--white);
  font-size: var(--rem-sm-font);

  @include media-350p() {
    font-size: var(--rem-base-font);
  }

  @include media-xl() {
    font-size: var(--rem-lg-font);
  }
  @include media-xxl() {
    font-size: var(--rem-2xl-font);
  }
  @include media-xxxl() {
    font-size: var(--rem-3xl-font);
  }
}

.active_model_desc {
  display: block;
  opacity: 0;
  animation: fade-in 0.2s ease-in-out forwards 0.3s;
}

.unactive_model_desc {
  display: none;
}

.entity_slider_service_banner {
  @include verticalFlex($width: auto, $justify: flex-start, $align: flex-start);
  background-color: rgba(255, 255, 255, 0.75);
  padding: 0.8rem 1rem;
  border-radius: 0.8rem;
  color: var(--dark-blue);
  width: 12rem;
  font-size: var(--rem-base-font);

  @include media-350p() {
    font-size: var(--rem-lg-font);
  }

  @include media-mid-device() {
    width: 11rem;
  }

  @include media-large-device() {
    width: 12rem;
    font-size: var(--rem-xl-font);
    // width: auto;
  }
  @include media-xl() {
    width: 14rem;
    font-size: var(--rem-2xl-font);
  }
  @include media-xxl() {
    font-size: var(--rem-3xl-font);
    width: 18rem;
  }

  @include media-mobile-landscape() {
    width: 11rem;
  }

  @include media-xxxl() {
    width: 21rem;
    padding: 1.2rem 1rem;
  }
}

.entity_slider_service_banner_head {
  font-size: var(--rem-sm-font);

  @include media-350p() {
    font-size: var(--rem-base-font);
  }

  @include media-large-device() {
    font-size: var(--rem-lg-font);
  }
  @include media-xl() {
    font-size: var(--rem-xl-font);
  }
  @include media-xxl() {
    font-size: var(--rem-2xl-font);
  }

  @include media-xxxl() {
    font-size: var(--rem-3xl-font);
  }
}

.entity_slider_service_banner_wrapper {
  @include centerFlex($justify: center, $align: center);
  width: auto;
  @include media-mid-device() {
    // width: auto;
    align-items: flex-end;
  }

  // @include media-large-device() {
  //   width: 35%;
  // }

  // @include media-mobile-landscape() {
  //   width: auto;
  // }

  // @include media-tablet-1000p() {
  //   width: auto;
  // }
}

.active_banner_wrapper {
  justify-content: flex-start;

  @include media-mid-device() {
    justify-content: center;
  }
}
.unactive_banner_wrapper {
  width: 100%;

  @include media-small-device() {
    width: 100%;
  }

  @include media-mid-device() {
    width: auto;
  }
  @include media-small-desktop() {
    width: 85%;
  }

  @include media-mobile-landscape() {
    width: auto;
  }
}

.banner_highlight {
  font-family: "Exo2-Bold";
  text-wrap: nowrap;

  @include media-xxxl() {
    font-size: var(--rem-4xl-font);
  }
}

.entity_modal_body_display {
  @include verticalFlex($justify: space-between, $align: flex-start, $gap: 1rem);
  height: 100%;
  opacity: 0;
  animation: fade-in 0.2s ease-in-out forwards 0.3s;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.entity_modal_body_hidden {
  display: none;
}

.entity_service_desc_wrapper {
  @include verticalFlex($justify: flex-start, $align: flex-start, $gap: 0.5rem);
  z-index: 5;

  @include media-mid-device() {
    gap: 1rem;
  }
}

.service_desc_text {
  color: var(--white);
  font-size: var(--rem-base-font);
  width: 80%;

  @include media-350p() {
    font-size: var(--rem-lg-font);
  }
  @include media-small-device() {
    width: auto;
  }

  @include media-large-device() {
    font-size: var(--rem-xl-font);
  }

  @include media-xl() {
    font-size: var(--rem-2xl-font);
  }
  @include media-xxl() {
    font-size: var(--rem-3xl-font);
  }
  @include media-xxxl() {
    font-size: var(--rem-4xl-font);
  }
}
.entity_service_desc_list {
  @include horizontalFlex($gap: 1rem, $justify: flex-start);

  svg {
    width: 1.25rem;
    @include media-mid-device() {
      width: 1.75rem;
    }

    @include media-xxl() {
      width: 2.75rem;
      height: 2.5rem;
      // background-color: red;
    }
  }
}

.entity_slider_image {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 70%;

  @include media-small-device() {
    height: 100%;
    width: auto;
  }
}

.entity_cta_btn_wrapper {
  @include verticalFlex($justify: flex-start, $gap: 0.5rem, $align: flex-start);
}

.entity_credits_claim {
  font-size: var(--rem-lg-font);
  color: var(--white);

  @include media-large-device() {
    font-size: var(--rem-xl-font);
  }

  @include media-xl() {
    font-size: var(--rem-2xl-font);
  }
}

.entity_cta_btn {
  border: none;
  background-color: var(--orange);
  color: var(--white);
  font-family: "Exo2-Bold";
  padding: 0.7rem 1rem;
  border-radius: 0.5rem;
  font-size: var(--rem-sm-font);

  @include media-350p() {
    font-size: var(--rem-base-font);
  }

  @include media-mid-device() {
    padding: 1rem 3.9rem;
  }

  @include media-large-device() {
    font-size: 18px;
  }

  @include media-xl() {
    font-size: 22px;
  }
  @include media-xxl() {
    padding: 1.5rem 3.9rem;
    font-size: 28px;
  }
  @include media-xxxl() {
    padding: 2rem 3.9rem;
    font-size: 32px;
    border-radius: 0.8rem;
  }
}

@font-face {
  font-family: "Louis-Geroge-Cafe";
  src: local("Louis-Geroge-Cafe"),
    url("./fonts/Louis-George-Cafe.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "EXO2.0-REGULAR";
  src: local("EXO2.0-REGULAR"),
    url("./fonts/EXO2.0-REGULAR.OTF") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Exo2-Bold";
  font-weight: bolder;
  src: local("Exo2-Bold"), url("./fonts/Exo2-Bold.otf") format("truetype");
  /* font-display: swap; */
  font-display: block;
}

@font-face {
  font-family: "Exo2.0-Medium";
  font-weight: bold;
  src: local("Exo2.0-Medium"),
    url("./fonts/Exo2.0-Medium.otf") format("truetype");
  /* font-display: swap; */
  font-display: block;
}

@font-face {
  font-family: "Exo-BlackItalic";
  font-weight: bold;
  src: local("Exo-BlackItalic"),
    url("./fonts/Exo-BlackItalic.ttf") format("truetype");
  /* font-display: swap; */
  font-display: block;
}

@font-face {
  font-family: "Exo2-BoldItalic";
  font-weight: bold;
  src: local("Exo2-BoldItalic"),
    url("./fonts/Exo2-BoldItalic.otf") format("truetype");
  /* font-display: swap; */
  font-display: block;
}

@font-face {
  font-family: "Exo2.0-SemiBold";
  font-weight: bold;
  src: local("Exo2.0-SemiBold"),
    url("./fonts/Exo2.0-SemiBold.otf") format("truetype");
  /* font-display: swap; */
  font-display: block;
}
:root {
  --prime-blue: #3cc3f2;
  --darkest-blue: #091627;
  --dark-blue: #112949;
  --dark-blue-v2: #091525;
  --dark-blue-v3: #052144;
  --light-blue: #3cc3f2;
  --label-blue: #9cbdeb;
  --input-grey: #bbbbbb;
  --black: #03080f;
  --light-black: #3d3d3d;
  --white: #ffffff;
  --orange: #ea7b2c;
  --light-orange: #e98742;
  --light-grey: #bdbdbd;
  --dark-grey: #8a8888;
  --background-blue: #ebf2f5;
  --light-green: rgb(0, 255, 0);
  --green-crayola: #1fad5f;
  --android-green: #a7bc2a;
  --border-color: #707070;
  --yellow: #f1b715;
  --red: #c92b2b;
  --tool-bg-blue: #060e18;
  --tool-dark-blue: #08162a;
  --tool-sb-color: #0e1721;
  --vertical-blue-black-gradient: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(17, 41, 73, 1) 100%
  );
  --horizontal-blue-black-gradient: linear-gradient(
    270deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(17, 41, 73, 1) 100%
  );
  --shadow-color: rgba(17, 41, 73, 0.5); /*rgba(117, 116, 116, 0.34)*/
  --dark-shadow-color: rgba(0, 0, 0, 0.1);
  --modal-color: rgba(0, 0, 0, 0.5);
  /* --xxs-font: 12px;
  --xs-font: 14px;
  --s-font: 16px;
  --sm-font: 18px;
  --m-font: 20px;
  --ml-font: 22px;
  --l-font: 24px;
  --xl-font: 28px;
  --xxl-font: 30px;
  --xxxl-font: 36px; */
  --xxs-font: 0.4vw;
  --xs-font: 0.6vw;
  --s-font: 0.85vw;
  --sm-font: 0.95vw;
  --m-font: 1vw;
  --ml-font: 1.2vw;
  --l-font: 1.4vw;
  --xl-font: 1.6vw;
  --xxl-font: 2vw;
  --xxxl-font: 2.2vw;

  --landscape-sm-font: 12px;
  --landscape-m-font: 14px;
  --landscape-ml-font: 16px;
  --landscape-l-font: 20px;
  --landscape-xl-font: 24px;
  --landscape-xxl-font: 28px;
  --landscape-xxxl-font: 32px;

  /* ///////////////test fonts//////////////////// */

  --rem-xs-font: 0.75rem;
  --rem-sm-font: 0.875rem;
  --rem-base-font: 1rem;
  --rem-lg-font: 1.125rem;
  --rem-xl-font: 1.25rem;
  --rem-2xl-font: 1.5rem;
  --rem-3xl-font: 1.875rem;
  --rem-4xl-font: 2.25rem;
  --rem-5xl-font: 3rem;
  --rem-6xl-font: 3.75rem;
  --rem-7xl-font: 4.5rem;
  --rem-8xl-font: 6rem;
  --rem-9xl-font: 8rem;

  /* Border-Radius roots */

  --m-border-radius: 0.6rem;
}
/* @media only screen and (max-width: 1300px) {
  :root {
    --xxs-font: 0.8vw;
    --xs-font: 0.9vw;
    --s-font: 1vw;
    --m-font: 1.2vw;
    --l-font: 1.3vw;
    --xl-font: 1.4vw;
    --xxl-font: 1.5vw;
  }
} */
@media only screen and (max-width: 500px) {
  :root {
    /* --xxs-font: 10px;
    --xs-font: 12px;
    --s-font: 14px;
    --sm-font: 16px;
    --m-font: 18px;
    --ml-font: 20px;
    --l-font: 22px;
    --xl-font: 24px;
    --xxl-font: 28px;
    --xxxl-font: 32px */
    --xxs-font: 2vw;
    --xs-font: 3.5vw;
    --s-font: 4vw;
    --sm-font: 5vw;
    --m-font: 6vw;
    --ml-font: 7vw;
    --l-font: 8vw;
    --xl-font: 9vw;
    --xxl-font: 10vw;
    --xxxl-font: 11vw;
  }
}
/* Font sizes for IPAD and IPAD Pro*/
@media only screen and (min-width: 700px) and (max-width: 780px) and (min-height: 900px) and (max-height: 1100px),
  (min-width: 700px) and (max-width: 1000px) and (min-height: 700px) and (max-height: 1000px),
  (min-width: 1020px) and (max-width: 1040px) and (min-height: 1300px) and (max-height: 1390px),
  (min-width: 500px) and (max-width: 550px) and (min-height: 700px) and (max-height: 730px),
  (min-width: 750px) and (max-width: 880px) and (min-height: 900px) and (max-height: 1200px),
  (min-width: 800px) and (max-width: 1000px) and (min-height: 1300px) and (max-height: 1400px),
  (min-width: 500px) and (max-width: 580px) and (min-height: 700px) and (max-height: 750px) {
  :root {
    /* --xxs-font: 1vw;
    --xs-font: 1.5vw;
    --s-font: 2.5vw;
    --sm-font: 3vw;
    --m-font: 3.5vw;
    --ml-font: 4vw;
    --l-font: 4.5vw;
    --xl-font: 5vw;
    --xxl-font: 6vw;
    --xxxl-font: 7vw; */

    --xxs-font: 0.75vh;
    --xs-font: 0.9vh;
    --s-font: 1.2vh;
    --sm-font: 1.4vh;
    --m-font: 1.6vh;
    --ml-font: 1.8vh;
    --l-font: 2vh;
    --xl-font: 2.2vh;
    --xxl-font: 2.4vh;
    --xxxl-font: 2.6vh;
  }
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Louis-Geroge-Cafe";
  line-height: normal;
}

/* @media only screen and (min-width: 1150px) { */

@media only screen and (min-width: 1024px) {
  ::-webkit-scrollbar {
    position: absolute;
    width: 0.5vh;
    border-radius: 5px;
    background: transparent;
  }

  ::-webkit-scrollbar-track {
    border-radius: 5px;
    border: 0.1vh solid rgba(117, 116, 116, 0.34);
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(241, 247, 249, 0.6);
  }
}
@media only screen and (min-width: 700px) and (max-width: 1000px) and (min-height: 800px) and (max-height: 1000px),
  only screen and (min-width: 300px) and (max-width: 950px) and (orientation: landscape) {
  ::-webkit-scrollbar {
    position: absolute;
    width: 0.5vh;
    border-radius: 5px;
    background: transparent;
  }

  ::-webkit-scrollbar-track {
    border-radius: 5px;
    border: 0.1vh solid rgba(117, 116, 116, 0.34);
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(241, 247, 249, 0.6);
  }
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--light-grey);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--light-grey);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--light-grey);
}

#root {
  position: relative;
  display: block;
  height: 100vh;
  width: 100vw;
}

body::-webkit-scrollbar {
  position: absolute;
  display: none;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border: 1px solid var(--dark-blue, #112949);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--dark-blue, #112949);
}

button {
  cursor: pointer;
}

ol {
  list-style-position: inside;
}

ul {
  list-style-position: inside;
}

/* #### For removing arrow spinners in number fields ### */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* input[type="password"]{
  font: small-caption;
  font-size: var(--s-font);
} */

/* ======== CLient General CSS ============ */

.client-btns {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 7px 25px;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  font-size: var(--s-font);
  color: white;
  min-width: 150px;
}
.client-btns:hover {
  opacity: 0.8;
}
@media only screen and (max-width: 1300px) {
  .client-btns {
    padding: 5px 14px;
    min-width: 120px;
  }
}
@media only screen and (max-width: 500px) {
  .client-btns {
    padding: 5px 20px;
    min-width: 120px;
  }
}

.client-input {
  position: relative;
  display: flex;
  align-items: center;
  font-size: var(--s-font);
  width: 100%;
  padding: 7px 10px;
  border-radius: 6px;
  border: none;
  outline: none;
}
@media only screen and (max-width: 1300px) {
  .client-input {
    padding: 7px;
    border-radius: 5px;
  }
}
@media only screen and (max-width: 500px) {
  .client-input {
    padding: 5px;
    border-radius: 5px;
  }
}
.client-input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--input-grey, #e98742);
  font-style: italic;
}
.client-input::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--input-grey, #e98742);
  font-style: italic;
}
.client-input:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--input-grey, #e98742);
  font-style: italic;
}
.client-input:-moz-placeholder {
  /* Firefox 18- */
  color: var(--input-grey, #e98742);
  font-style: italic;
}

.client-dashboard-input {
  position: relative;
  display: flex;
  align-items: center;
  font-size: var(--s-font);
  width: 100%;
  padding: 7px 10px;
  border-radius: 6px;
  border: none;
  outline: none;
  background: rgba(17, 41, 73, 0.4);
  color: var(--white, #ffffff);
  border: 0.5px solid rgba(255, 255, 255, 0.4);
}
@media only screen and (max-width: 1300px) {
  .client-dashboard-input {
    padding: 5px;
    border-radius: 5px;
  }
}
@media only screen and (max-width: 500px) {
  .client-dashboard-input {
    padding: 5px;
    border-radius: 5px;
  }
}
.client-dashboard-input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--input-grey, #e98742);
  font-style: italic;
}
.client-dashboard-input::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--input-grey, #e98742);
  font-style: italic;
}
.client-dashboard-input:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--input-grey, #e98742);
  font-style: italic;
}
.client-dashboard-input:-moz-placeholder {
  /* Firefox 18- */
  color: var(--input-grey, #e98742);
  font-style: italic;
}

/* Lazy load image  */
.lazy-img {
  filter: blur(3px);
  transition: 300ms;
}

.show-img {
  filter: none;
}

/* ================ Login Notification toast custom styling ================== */
.toast-container {
  display: flex;
  width: fit-content;
  border-radius: 8px;
  background: var(--light-blue);
  position: relative;
  box-shadow: 2px 2px 3px #00000029;
}

.toast-error-container {
  display: flex;
  width: fit-content;
  border-radius: 8px;
  background: var(--light-orange);
  position: relative;
  box-shadow: 2px 2px 3px #00000029;
}

.toast-content {
  color: white;
  padding: 1vh 2vh;
  text-align: center;
}

/* Hoverable tooltip  */
/* .hover-tooltip {
  text-decoration:none;
  position:relative;
}
.hover-tooltip .tooltip-container {
  display:none;
  position:fixed;
  background-color: var(--black,#03080F);
  color: var(--white,#ffffff);
  border-radius: 2px;
  padding: 4px;
  overflow:hidden;
  font-size: var(--s-font);
  z-index: 2;
} */
.tooltip-container {
  display: none;
  position: fixed;
  background-color: var(--black, #03080f);
  color: var(--white, #ffffff);
  border-radius: 2px;
  padding: 4px;
  overflow: hidden;
  font-size: var(--s-font);
  z-index: 2;
  max-width: 20vw;
}
/* .tooltip-container-hover {
  display:flex;
  flex-direction: column;
} */
@media only screen and (min-width: 1000px) {
  /* .hover-tooltip:hover .tooltip-container {
    display:block;
  } */
  .tooltip-container-hover {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (max-width: 500px) {
  .tooltip-container {
    max-width: 90vw;
  }
}

.modal-content {
  border-radius: 16px; /* Majburi me likhna pda.. sb Bootstrap ki glti h*/
  border: none;
}

.btn-close {
  display: none; /* Majburi me likhna pda.. sb Bootstrap ki glti h*/
}

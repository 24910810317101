@import "./../../general";

.st_offering_main {
  grid-area: stc-capability;
  padding: 1vh 1vh 1vh calc(1vh + 5px);

  @include media-potrait() {
    @include verticalFlex();
    padding: 1vh;
    padding-right: 0vh;
  }

  @include media-tab-potrait-device() {
    max-width: 100vw;
  }
  @include media-extrasmall-device() {
    @include verticalFlex();

    max-width: 100vw;
  }
  .st_offering_heading_main {
    @include horizontalFlex();
    padding: 1vh;
    padding-top: 0;

    @include media-large-device() {
      padding: 1vh;
      padding-top: 1vh;
    }
    .st_offering_heading {
      @include stc-mega-heading-font($fontColor: var(--white));

      @include media-extrasmall-device() {
        // padding: 1vh 1vh 0vh 1vh;
        @include stc-heading-font($fontColor: var(--white));
      }
      @include media-potrait() {
        // padding-left: 3vh;
      }
    }
    @include media-tab-potrait-device() {
      padding: 1vh 0vh;
      padding-bottom: 0vh;
      margin-left: 0.5vh;
      white-space: nowrap;
      @include stc-mega-heading-font($fontColor: var(--white));
    }
    @include media-tab-landscape-device() {
      padding: 0.5vh;
    }

    @include media-extrasmall-device() {
      padding: 1vh 0vh;
      padding-bottom: 0vh;
      // margin-left: 0.5vh;
    }
  }
  .st_offerings {
    // height: 83vh;
    height: calc(100vh - 60px - var(--xl-font) - 3vh);
    overflow-y: auto;
    border-radius: 1vh;
    @include scrollbars($size: 2px, $thumb-color: rgba(255, 255, 255, 0.1), $track-color: rgba(6, 14, 24, 1));
    @include stc-glass-card();
    @include verticalFlex();
    margin: 0vh;

    @include media-large-device() {
      // border-radius: 8px;
      padding: 1vh;
      border-radius: 1vh 1vh 0 0;
    }
    @include media-small-desktop() {
      padding: 1vh;
      // border-radius: 8px;
    }
    @include media-xl() {
      padding: 1.5vh 1vh;
      // border-radius: 12px;
    }
    @include media-tab-potrait-device() {
      @include horizontalFlex($align: flex-start);
      height: auto;
      padding: 1vh 1vh 0vh 1vh;
      overflow-x: scroll;
      overflow-y: hidden;
      // border-right-style: none;
      border-radius: 1vh 0 0 1vh;
      border-right: none;
    }
    @include media-extrasmall-device() {
      @include horizontalFlex($align: flex-start);
      // max-height: 24vh;
      overflow-x: scroll;
      height: auto;
      padding: 1vh 1vh 0vh 1vh;
      // border-right-style: none;
      max-height: none;
      overflow-y: hidden;
      border-radius: 1vh 0 0 1vh;
      border-right: none;
    }
    @include media-xxl() {
      height: auto;
    }

    .offering_single {
      width: 100%;
    }
    .offering_wrapper {
      @include scrollbars($size: 2px, $thumb-color: rgba(255, 255, 255, 0.1), $track-color: rgba(6, 14, 24, 1));
      padding-right: 1vh;
      height: 100%;
      overflow-y: auto;
      // margin: 1vh auto;

      @include media-extrasmall-device() {
        @include horizontalFlex($align: flex-start);
      }
      @include media-tab-potrait-device() {
        @include horizontalFlex($align: flex-start);
      }
    }
  }
}
//single Offering styles
.st_single_offering_main {
  @include horizontalFlex($gap-size: 0vh, $align: flex-start);
  padding: 1vh;

  @include media-extrasmall-device() {
    padding: 0vh 1vh 1vh 1vh;
  }
  @include media-tab-potrait-device() {
    padding: 0vh 1vh 1vh 1vh;
  }
  @media screen and (min-width: 0px) and (max-width: 330px) {
    padding: 0vh;
  }
  .st_single_offering_icons {
    @include verticalFlex($width: 85%, $gap-size: 0vh);
    overflow: hidden;
    @include media-tab-potrait-device() {
      width: 100%;
    }
    @include media-extrasmall-device() {
      width: 100%;
    }

    .st_offering_heading_main {
      @include horizontalFlex($justify: space-between);
      .offering_heading {
        @include stc-heading-font($fontColor: var(--white));
        @include media-large-device() {
          @include stc-mega-heading-font($fontColor: var(--white));
        }
        @include media-small-desktop() {
          @include stc-heading-font($fontColor: var(--white));
        }
        @include media-tab-potrait-device() {
          white-space: nowrap;
          @include stc-heading-font($fontColor: var(--white));
        }
        @include media-extrasmall-device() {
          white-space: nowrap;
        }
        @include media-extrasmall-device() {
          width: 70vw;
        }
      }
    }
    .offering_subservices {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      place-items: center;
      width: 100%;
      gap: 2vh;
      padding: 0vh;
      @include media-extrasmall-device() {
        padding: 1vh 0vh 0vh 0vh;
      }
      @include media-tab-potrait-device() {
        padding: 1vh 0vh 0vh 0vh;
      }
      // @media screen and (min-width: 0px) and (max-width: 330px) {
      //   padding: 1vh;
      // }
      @include media-large-device() {
        gap: 1vh;
        padding: 0.5vh;
      }
      @include media-small-desktop() {
        gap: 2vh;
        padding: 1vh;
      }
      @include media-tab-landscape-device() {
        padding: 0.5vh;
      }

      .offering_icons {
        @include horizontalFlex($align: center, $justify: flex-start);
        @include media-large-device() {
          gap: 0.8vh;
        }
        @include media-small-desktop() {
          gap: 1vh;
        }
        .offering_icons_img {
          // width: 55px;
          aspect-ratio: 1/1;
          width: 4vh;
          @include media-large-device() {
            width: 2.7vh;
          }
          @include media-small-desktop() {
            width: 3.2vh;
          }

          @include media-xl() {
            width: 4vh;
          }
          @media screen and (min-width: 1500px) and (max-width: 1750px) {
            width: 3.5vh;
          }
          @media screen and (min-width: 0px) and (max-width: 330px) {
            width: 35px;
          }
        }
        .offering_single_icon_heading_main {
          @include horizontalFlex($justify: flex-start, $align: center);
          .offering_single_icon_heading {
            @include stc-normal-font($fontColor: var(--white));

            @include media-large-device() {
              font-size: 10px;
            }
            @include media-tab-potrait-device() {
              @include stc-sub-heading-font($fontColor: var(--white));
            }
            @include media-small-desktop() {
              @include stc-normal-font($fontColor: var(--white));
            }
          }
        }
      }
    }
  }

  .offering_add_icon_main {
    @include horizontalFlex($justify: flex-end);
    padding-top: 2vh;
    width: 15%;
    .offering_add_icon {
      cursor: pointer;
    }
    @include media-large-device() {
      padding-top: 1vh;
    }
    @include media-small-desktop() {
      padding-top: 0.8vh;
    }
    @include media-xl() {
      padding-top: 1vh;
    }
    @include media-tab-potrait-device() {
      display: none;
    }
    @include media-extrasmall-device() {
      display: none;
    }
  }
}

.offering_ad_icon_mobile {
  display: none;
  @include media-tab-potrait-device() {
    display: block;
    margin-bottom: -1vh;
  }
  @include media-extrasmall-device() {
    display: block;
  }
}
.plus_btn_icon {
  width: 30px;
  height: 30px;
  aspect-ratio: 1/1;

  @include media-tab-potrait-device() {
    height: 40px;
    width: 40px;
    margin-right: 1vh;
  }
  @include surface-duo() {
    height: 27px;
    width: 27px;
  }
  @include media-extrasmall-device() {
    margin-right: 1vh;
  }
}

// .plus_square{
//   @include media-large-device(){
//     width: 20px;
//     aspect-ratio: 1/1;
//   }
// }

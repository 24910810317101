@import "../../../general";

/* ============= Logs Component ========================== */
.client-logs-container {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 10px;
  height: 100%;
}

.client-log-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 10px;
  margin: 0px;
  height: fit-content;
  max-height: 50%;
  min-height: fit-content;
  /* min-height: fit-content; */
  /* max-height: 100%; */
}

.client-log-card-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 10px;
}

.client-log-items-container {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  column-gap: 10px;
  width: 100%;
  justify-content: space-between;
  padding: 0px 10px;
  margin: 10px 0px;
  overflow-y: auto;
  @include scrollbars(
    $size: 2px,
    $thumb-color: rgba(255, 255, 255, 0.1),
    $track-color: rgba(6, 14, 24, 1)
  );
}

.client-log-item-container {
  position: relative;
  display: grid;
  row-gap: 10px;
  column-gap: 10px;
  border-radius: 5px;
  width: 100%;
  justify-content: space-between;
}

.client-log-item-card {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  column-gap: 10px;
  align-items: flex-start;
  padding: 10px;
  width: 100%;
  font-size: var(--s-font);
  word-break: break-word;
}

.client-log-msg {
  text-align: center;
  padding: 10px;
  font-style: italic;
  color: var(--dark-grey);
}

.client-log-item-head {
  display: none;
  color: var(--label-blue, #9cbdeb);
}

.client-log-item-value {
  display: flex;
  align-items: center;
}

.client-access-log-type-logo {
  width: fit-content;
}

.client-access-log-type-logo img {
  height: 1.2vw;
  width: 1.2vw;
}

/* ==================== Share Component Style ================== */

.client-share-open-modal-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
  outline: none;
  border: none;
  border-radius: 5px;
  background-color: var(--light-blue, #3cc3f2);
  color: var(--white, #ffffff);
  font-size: var(--ml-font);
}

.tool-client-shared-users-container-head {
  position: relative;
  display: flex;
  column-gap: 10px;
  width: 100%;
  justify-content: space-between;
  padding: 0px 120px 0px 20px;
  margin: 5px 0px;
}
.tool-shared-main-head {
  position: relative;
  display: flex;
  column-gap: 10px;
  width: 100%;
  justify-content: space-between;
  padding: 0px 0px 0px 20px;
  margin: 5px 0px;
}
.client-share-users-container-head {
  position: relative;
  display: flex;
  column-gap: 10px;
  width: 100%;
  justify-content: space-between;
  padding: 0px 20px;
  margin: 5px 0px;
}

.tool-client-shared-users-container-head-value {
  position: relative;
  color: var(--label-blue, #9cbdeb);
  width: 100%;
  font-size: var(--s-font);
}
.tool-client-small-desktop {
  @include media-large-device() {
    width: 92%;
  }
  @include media-xl() {
    width: 100%;
  }
}

.tool-client-small-desktop-access {
  @include media-large-device() {
    padding-left: 3vh;
  }
  @include media-xl() {
    padding: 0vh;
  }
}
.tool-client-shared-users-container-head-value-center {
  text-align: center;
  @include media-large-device() {
    text-align: left;
  }
  @include media-xl() {
    text-align: center;
  }
}
/*
  .client-shared-users-container{
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  } */

.tool-client-shared-user-card {
  position: relative;
  display: grid;
  row-gap: 10px;
  column-gap: 10px;
  border-radius: 5px;
  width: 100%;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr 1fr 1fr 0.2fr 0.2fr;
  grid-template-areas: "user shared-by time-accessed last-accessed-on accept-icon decline-icon";
}

.tool-client-share-user-card {
  position: relative;
  display: grid;
  row-gap: 10px;
  column-gap: 10px;
  border-radius: 5px;
  width: 100%;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas: "user shared-by time-accessed last-accessed-on";
}

.tool-client-shared-user-card-info {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  column-gap: 10px;
  align-items: flex-start;
  padding: 10px;
  width: 100%;
  font-size: var(--s-font);
  word-break: break-word;
}

.tool-request-client-shared-user-card-info-value {
  color: var(--orange, #ea7b2c);
}

.client-share-user {
  grid-area: user;
}

.client-share-shared-by {
  grid-area: shared-by;
}

.client-share-time-accessed {
  grid-area: time-accessed;
}

.client-share-last-downloaded-version {
  grid-area: last-downloaded-version;
}

.client-share-last-accessed-on {
  grid-area: last-accessed-on;
}

.client-share-last-accessed-on-head-mobile {
  display: none;
}

.client-share-last-accessed-on-value-mobile {
  display: none;
}

.client-share-decline-icon {
  grid-area: decline-icon;
}

.client-share-accept-icon {
  grid-area: accept-icon;
}

.tool-client-shared-user-card-info-head {
  white-space: nowrap;
  display: none;
  color: var(--label-blue, #9cbdeb);
}

.tool-client-shared-user-card-info-center {
  align-items: center;
}

.revoke-icon {
  cursor: pointer;
  color: #ea7b2c;
}

.approved-access-icon {
  color: #3cc3f2;
}

.approve-request-icon {
  cursor: pointer;
  color: #3cc3f2;
}

.decline-request-icon {
  cursor: pointer;
  color: #ea7b2c;
}

/* ===================== ACCESS LOG ======================= */
.client-access-log-item-container {
  align-items: center;
  grid-template-columns: max-content 1fr 0.5fr 0.4fr;
  grid-template-areas: "type-logo string platform date";
}

.client-access-head {
  display: flex;
  column-gap: 10px;
  align-items: center;
}

.client-access-log-search-container {
  display: flex;
  align-items: center;
  width: 45%;
}

.client-access-log-search-form {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}

.client-access-log-search-input {
  background: transparent;
}

.client-access-log-search-btn {
  position: absolute;
  display: inline-flex;
  align-items: center;
  right: 10px;
  font-size: var(--m-font);
  background: transparent;
  border: none;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
  color: var(--label-blue, #9cbdeb);
  transition: 150ms;
}

.client-access-log-search-btn:hover {
  transform: scale(1.05);
}

.client-access-log-type-logo {
  grid-area: type-logo;
}

.client-access-log-string {
  grid-area: string;
}

.client-access-log-platform {
  grid-area: platform;
}

.client-access-log-date {
  grid-area: date;
}

.client-blue-access-log {
  color: var(--label-blue, #9cbdeb);
}

/* ###################### 720p ############################*/

@media only screen and (max-width: 1460px) {
  /* =============== LOGS ============= */
  .client-logs-container {
    row-gap: 5px;
  }

  .client-log-container {
    margin: 0px 5px;
    padding: 5px;
    min-height: fit-content;
  }

  .client-log-card-head {
    margin: 5px;
  }

  .client-log-items-container {
    margin: 5px 0px;
    padding: 0px 5px;
    row-gap: 5px;
  }

  .client-log-item-container {
    column-gap: 5px;
  }

  .client-log-item-card {
    padding: 7px;
  }

  /* ============= Shared Log ========== */
  .tool-client-shared-users-container-head {
    padding: 0px 5px;
  }
  .tool-shared-main-head {
    padding: 0px 5px;
  }
  .client-share-users-container-head {
    padding: 0px 5px;
  }

  .tool-client-shared-user-card {
    column-gap: 5px;
  }

  .tool-client-shared-user-card-info {
    padding: 7px;
  }
}

/* ###################### Tablet ############################*/

@include media-tab-potrait-device() {
  /* ==================== LOGS ====================== */

  .client-log-container {
    background-color: rgba(241, 247, 249, 0.05);
  }

  .client-log-item-card {
    padding: 5px;
  }

  .client-log-container {
    margin: 0px;
  }

  .client-log-items-container {
    padding: 0px;
    margin: 0px;
  }

  /* ==================== Share Component Style ================== */

  .tool-client-shared-user-card {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    padding: 7px;
    backdrop-filter: none;
    row-gap: 5px;
    column-gap: 5px;
    grid-template-areas:
      "user last-accessed-on decline-icon"
      "shared-by shared-by accept-icon";
  }

  .tool-client-shared-user-card-info {
    font-size: var(--s-font);
    align-items: flex-start;
    row-gap: 5px;
    padding: 0px;
  }

  .tool-client-shared-user-card-info-head {
    display: block;
  }

  .client-share-time-accessed {
    display: none;
  }

  .tool-client-shared-users-container-head {
    display: none;
  }
  .tool-shared-main-head {
    display: none;
  }
  .client-share-users-container-head {
    display: none;
  }

  .client-share-open-modal-btn {
    padding: 5px;
    border-radius: 4px;
    font-size: var(--sm-font);
  }

  /* ============= ACCESS LOG =============== */
  .client-access-log-search-container {
    width: 55%;
  }

  .client-access-log-item-container {
    row-gap: 2px;
    column-gap: 2px;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 0.1fr 0.8fr 1.2fr;
    grid-template-areas:
      "type-logo string string"
      "platform platform date";
  }

  .client-access-log-search-btn {
    right: 5px;
    height: 15px;
    width: 15px;
  }
}

/* ################## Mobile #########################*/

@media only screen and (max-width: 500px) {
  /* ==================== LOGS ====================== */

  .client-log-container {
    background-color: rgba(241, 247, 249, 0.05);
  }

  .client-log-item-card {
    padding: 5px;
  }

  .client-log-container {
    margin: 0px;
  }

  .client-log-items-container {
    padding: 0px;
    margin: 0px;
  }

  .client-access-log-type-logo img {
    height: 2.5vh;
    width: 2.5vh;
  }

  /* ==================== Share Component Style ================== */

  .tool-client-shared-user-card {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    padding: 7px;
    backdrop-filter: none;
    row-gap: 5px;
    column-gap: 5px;
    grid-template-areas:
      "user last-accessed-on decline-icon"
      "shared-by shared-by accept-icon";
  }

  .tool-client-share-user-card {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    padding: 7px;
    backdrop-filter: none;
    row-gap: 5px;
    column-gap: 5px;
    grid-template-areas:
      "user last-accessed-on"
      "shared-by shared-by";
  }

  .tool-client-shared-user-card-info {
    font-size: var(--xs-font);
    align-items: flex-start;
    row-gap: 5px;
    padding: 0px;
  }

  .tool-client-shared-user-card-info-head {
    display: block;
  }

  .client-share-time-accessed {
    display: none;
  }

  .tool-client-shared-users-container-head {
    display: none;
  }
  .tool-shared-main-head {
    display: none;
  }

  .client-share-users-container-head {
    display: none;
  }

  .client-share-open-modal-btn {
    padding: 5px;
    border-radius: 4px;
    font-size: var(--s-font);
  }

  .client-share-last-accessed-on-head-mobile {
    color: var(--orange);
    display: flex;
  }

  .client-share-last-accessed-on-value-mobile {
    color: var(--white);
    display: flex;
  }

  .client-share-last-accessed-on-head {
    display: none;
  }

  .client-share-last-accessed-on-value {
    display: none;
  }

  /* ============= ACCESS LOG =============== */
  .client-access-log-sort-btn {
    font-size: var(--s-font) !important;
  }

  .client-access-log-search-container {
    width: 55%;
  }

  .client-access-log-search-input {
    font-size: var(--xs-font);
  }

  .client-access-log-item-container {
    row-gap: 2px;
    column-gap: 2px;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 0.1fr 0.8fr 1.2fr;
    grid-template-areas:
      "type-logo string string"
      "platform platform date";
  }

  .client-access-log-search-btn {
    right: 5px;
    height: 15px;
    width: 15px;
  }

  .client-log-item-card {
    font-size: var(--xs-font);
  }
}

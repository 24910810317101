.client-revoke-share-modal-container {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 1vh;
  width: 30vw;
  font-size: var(--s-font);
  border-radius: 10px;
  top: 50%;
  transform: translate(-10%, -50%);
  background: var(--black, #03080f);
}

#client-revoke-share-heading {
  color: var(--label-blue);
}

#client-revoke-share-heading-close-btn {
  color: var(--light-blue, #3cc3f2);
}

.client-revoke-share-heading-para-white {
  color: #ffffff;
}

.client-revoke-share-names-list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
}

.client-revoke-share-names-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  row-gap: 5px;
  column-gap: 5px;
  flex-direction: column;
}

.client-revoke-share-name-item {
  position: relative;
  padding: 5px;
  background-color: var(--light-blue, #3cc3f2);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 5px;
  font-size: var(--s-font);
  width: fit-content;
  flex-wrap: wrap;
}

.client-revoke-share-requester-name-item {
  position: relative;
  padding: 5px;
  background-color: var(--label-blue, #9cbdeb);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 5px;
  font-size: var(--s-font);
  width: fit-content;
  flex-wrap: wrap;
}

.client-revoke-share-remove-btn {
  background: none;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
}

.client-revoke-share-heading {
  color: var(--label-blue, #9cbdeb);
}

.client-revoke-share-requester-names-list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
}
.client-revoke-share-heading-para {
  padding-top: 10px;
  color: var(--label-blue, #9cbdeb);
}

.client-revoke-share-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 1vh;
}

.client-revoke-share-btn-container button {
  background-color: var(--light-blue, #3cc3f2);
}

#client-revoke-share-modal-cancel-btn {
  background-color: transparent;
  color: var(--light-blue, #3cc3f2);
  padding: 0px;
  margin: 0px;
  min-width: fit-content;
}

.client-revoke-share-modal-err-msg {
  position: relative;
  top: 50%;
  font-size: var(--xs-font);
  width: -moz-fit-content;
  width: fit-content;
  color: var(--orange, #ea7b2c);
}

/* ==============IPAD - TABLET=================== */

@media only screen and (min-width: 700px) and (max-width: 780px) and (min-height: 1000px) and (max-height: 1050px),
  (min-width: 1020px) and (max-width: 1040px) and (min-height: 1350px) and (max-height: 1370px),
  (min-width: 500px) and (max-width: 550px) and (min-height: 700px) and (max-height: 730px) {
  .client-revoke-share-modal-container {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: calc(100% - 20px);
    top: 50%;
    left: 0%;
    margin: 10px;
    transform: translate(0%, -50%);
  }
}

@media only screen and (max-width: 500px) {
  /* ============ REVOKE SHARING MODAL ================= */
  .client-revoke-share-modal-container {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: calc(100% - 20px);
    top: 50%;
    left: 0%;
    margin: 10px;
    font-size: var(--s-font);
    transform: translate(0%, -50%);
  }
}

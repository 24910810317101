@import "../../../common";

.testimonial_card_wrapper {
  @include verticalFlex($width: auto, $gap: 4rem);
  height: 100%;
  background: transparent linear-gradient(157deg, rgba(17, 41, 73, 1) 0%, rgba(3, 8, 15, 1) 100%) 0% 0% no-repeat
    padding-box;

  color: var(--white);
  width: 100%;
  padding: 1rem;
  border-radius: 1rem;
  margin-right: 0.8vw;

  @include media-350p() {
    padding: 1.5rem;
  }

  @include media-large-device() {
    width: 25rem;
    padding: 2rem;
    gap: 1rem;
    box-shadow: none;
  }

  @include media-xxl() {
    width: 30rem;
  }

  @include media-tablet-1000p() {
    width: 100%;
  }

  @include media-xxxl() {
    width: 38rem;
  }
}

.testimonial_card_icon {
  @include centerFlex($position: absolute, $justify: flex-start, $align: flex-start);
  left: 2rem;
  top: 2rem;
}

.testimonial_card_message_text {
  font-size: var(--rem-base-font);

  @include media-350p() {
    font-size: var(--rem-lg-font);
  }
  @include media-xxl() {
    font-size: var(--rem-2xl-font);
  }

  @include media-xxxl() {
    font-size: var(--rem-3xl-font);
  }
}

.testimonial_card_message_wrapper {
  @include centerFlex();
  z-index: 5;
  padding-top: 4rem;

  @include media-large-device() {
    padding-top: 3rem;
  }
  @include media-small-desktop() {
    padding-top: 4rem;
  }
}

.client_details_wrapper {
  @include verticalFlex($align: flex-start, $gap: 0.5rem);

  @include media-xxxl() {
    gap: 1rem;
  }
}

.clients_info {
  @include verticalFlex($gap: 0.5rem, $justify: flex-start, $align: flex-start);
}
.testimonial_client_name {
  color: var(--white);
  font-size: var(--rem-xl-font);

  @include media-350p() {
    font-size: var(--rem-2xl-font);
  }
  @include media-xxl() {
    font-size: var(--rem-4xl-font);
  }
  @include media-xxxl() {
    font-size: var(--rem-5xl-font);
  }
}

.testimonial_client_profile {
  color: var(--white);
  font-size: var(--rem-base-font);

  @include media-350p() {
    font-size: var(--rem-lg-font);
  }
  @include media-xxl() {
    font-size: var(--rem-2xl-font);
  }
  @include media-xxxl() {
    font-size: var(--rem-3xl-font);
  }
}

.testimonial_star_wrapper {
  @include horizontalFlex($gap: 0.5rem);

  svg {
    width: 1rem;
    height: 1rem;

    @include media-350p() {
      width: 1.2rem;
      height: 1.2rem;
    }

    @include media-small-desktop() {
      width: 1.5rem;
      height: 1.5rem;
    }
    @include media-xxl() {
      width: 2rem;
      height: 2rem;
    }
    @include media-xxxl() {
      width: 2.5rem;
      height: 2.5rem;
    }
  }
}

.country_flag_img {
  width: 2rem;
  height: auto;

  @include media-350p() {
    width: 2rem;
  }

  @include media-xxxl() {
    width: 3rem;
  }
}

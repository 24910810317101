@import "../../general";

.tools_files_dashboard {
  grid-area: stdashboard;
  @include verticalFlex();
  padding: 1vh;
  padding-bottom: 0;
  height: calc(100vh - 60px);

  @include media-potrait() {
    height: calc(100vh - 100px);
    overflow: auto;
  }
  @include media-tab-potrait-device() {
    @include scrollbars(
      $size: 5px,
      $thumb-color: rgba(255, 255, 255, 0.1),
      $track-color: rgba(6, 14, 24, 1)
    );
  }
}

.tools_consult_dashboard {
  overflow: hidden;
  padding: 0vh;
  height: 100%;

  @include media-large-device() {
    overflow: auto;
    // height: auto;
  }
}

.tools_file_dashboard_wrapper {
  height: 100%;
  @include verticalFlex();
  width: 100%;
  @include media-potrait() {
    display: block;
  }
}

.tools_file_share_revoke_container {
  @include verticalFlex($justify: center);
  @include stc-glass-card();
  @include stc-normal-font();
  overflow-x: hidden;
  margin: 0;
  height: calc(100% - 25vh - 1vh);
  padding-bottom: 0;
  border-bottom: 0px;
  border-radius: $genBorderRadius $genBorderRadius 0 0;

  @include media-potrait() {
    overflow-y: auto;
  }
}

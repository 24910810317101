@import "../../../../../general";

.tools_consult_pdf_wrapper {
  height: 100%;
  overflow: auto;
  width: 100%;

  @include media-large-device() {
    height: 94%;
  }

  .rpv-core__spinner {
    border-bottom: 2px solid transparent;
    border-left: 2px solid transparent;
    border-right: 2px solid var(--white);
    border-top: 2px solid var(--white);
  }
}

.tools_consult_pdf_container {
  @include verticalFlex();
  border: 1px solid rgba(0, 0, 0, 0.3);
  height: 100%;
}

.consult_pdf_page_overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 5;

  &:hover {
    .pdf_page_pop_up_reference_page_wrapper {
      @include media-large-device() {
        display: flex;
        background: rgba(0, 0, 0, 0.3);
      }
    }
  }
}

.consult_pdf_page_number_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  .consult_pdf_page_number {
    display: flex;
    justify-content: center;
    align-items: center;
    @include stc-normal-font($fontColor: var(--white));
    padding: 0.3rem;
    background-color: var(--dark-blue);
    border-radius: 4px;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    min-width: 1.8rem;
    color: var(--white);

    @include media-large-device() {
      padding: 0.3rem 0px;
    }
  }

  .consult_pdf_page_number_highlighted {
    background-color: #004292;
  }
}

.pdf_page_pop_up_reference_page_wrapper {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  display: none;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 3;

  .pdf_page_pop_up_btn {
    border: none;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;

    .pdf_page_pop_up_btn_icon {
      fill: var(--white);
      color: var(--white);
      font-size: var(--xl-font);
      margin: 1vh;
    }
  }
}

.tools_consult_pdf_wrapper_container {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.tools_consult_pdf_reference_carousel_container {
  @include horizontalFlex($justify: center);
  position: absolute;
  bottom: 3%;
  @include stc-normal-font($fontColor: var(--white));

  @include media-large-device() {
    bottom: 10%;
  }
}

.tools_consult_pdf_reference_carousel_inner_container {
  @include horizontalFlex($justify: center, $width: auto, $gap-size: 2vh);
  background: #070f1a;
  border-top: 1px solid #394d67;
  box-shadow: 0px 0px 10px 0px #00000080;
  backdrop-filter: blur(4px);
  color: var(--white);
  padding: 0.5rem 0.8rem;
  border-radius: 8px;
}

.tools_consult_pdf_reference_carousel_wrapper {
  @include horizontalFlex(
    $width: auto,
    $justify: center,
    $align: center,
    $gap-size: 0vh
  );
  height: 30px;
  border-radius: 4px;
  border: 1px solid #394d67;
  overflow: hidden;
}

.tools_consult_pdf_reference_carousel_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  outline: none;
  border: none;
  padding: 0px 0.5rem;
  height: 100%;

  &:first-child {
    border-right: 1px solid #394d67;
  }
  &:last-child {
    border-left: 1px solid #394d67;
  }

  &:disabled {
    cursor: not-allowed;
  }

  svg {
    color: var(--white);
  }
}

.tools_consult_pdf_reference_carousel_counter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0px 5px;
  border-left: 1px solid #394d67;
  border-right: 1px solid #394d67;
  padding: 0px 0.5rem;
}

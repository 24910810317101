@import "../../../general";

.tools_consult_chat_upload_wrapper {
  display: grid;
  grid-template-rows: 1fr auto 1fr;
  width: 85%;
  gap: 1vh;
  margin: 0 auto;

  position: relative;
  @include media-small-device() {
    gap: 2vh;
  }
  @include media-large-device() {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr auto 1fr;
    gap: 6vh;
    height: 95%;
  }

  @include media-tablet-1000p() {
    grid-template-rows: 1fr auto 1fr;
    grid-template-columns: auto;
    gap: 1vh;
  }

  .consult_info_button {
    color: var(--white);
    cursor: pointer;

    position: absolute;
    top: -10px;
    right: -9%;
    z-index: 2;

    .consult_i_button_img {
      width: 1.3rem;
      @include media-350p() {
        width: auto;
      }
    }
  }
}

.tool_consult_chat_start_wrapper {
  @include verticalFlex($align: center, $gap-size: 4vh);
  border: 1px solid #394d67;
  background: #0e223d;
  border-radius: 0.8vh;
  // @include stc-glass-card($inputBgColor: #0e223d, $borderColor: #394d67);
  color: var(--white);
  // height: 100%;
  padding: 3vh 2vh;
  margin: 0vh;
  overflow: hidden;

  @include media-large-device() {
    padding: 7vh 3vh;
  }
}

.tools_consult_chat_start_desc_wrapper {
  @include verticalFlex($align: center, $justify: center, $width: 100%);
  @include stc-heading-font($fontColor: var(--white));
  text-align: center;
  height: auto;

  //   line-height: 1.8rem;
  // @include media-large-device() {
  //   width: 82%;
  // }
}

.tool_consult_file_upload {
  @include verticalFlex();
}

.tools_consult_btn_wrapper {
  @include verticalFlex();
}

.tools_consult_start_chat_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 2vh;
  @include stc-glass-card($inputBgColor: #00429280);
  border: none;
  @include stc-heading-font();
  margin: 0;
  @include media-large-device() {
    padding: 4vh 2vh;
  }
}

.tool_consult_upload_divider {
  @include horizontalFlex($width: auto, $justify: center, $gap-size: 1.5vh);
  // height: 100%;
  @include media-large-device() {
    flex-direction: column;
    height: 100%;
  }

  @include media-tablet-1000p() {
    flex-direction: row;
  }
}

.divider_text {
  @include stc-heading-font($fontColor: var(--white));
  text-transform: uppercase;
}

.vertical_line {
  height: 2px;
  width: 100%;
  background-color: var(--white);
  color: var(--white);

  @include media-small-device() {
    width: 80px;
    margin: 0 10px;
  }
  @include media-large-device() {
    height: 120px;
    width: 1px;
  }

  @include media-xl() {
    width: 1.2px;
  }

  @include media-tablet-1000p() {
    width: 100%;
    height: 2px;
  }
}

.consult_tooltip_info {
  position: absolute;
  right: 0;
  background: #0a182a;

  padding: 1vh;
  width: 45vh;
  max-height: 45vh;
  overflow-y: auto;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #394d67;
  z-index: 999;
  color: var(--white);

  @include media-small-desktop() {
    width: 60vh;
  }

  @include media-xl() {
    width: 70vh;
  }

  @include media-tab-potrait-device() {
    width: 30vh;
    height: 40vh;
    overflow-y: auto;
  }

  @include media-extrasmall-device() {
    width: 30vh;
    height: 40vh;
    overflow-y: auto;
  }

  .consult_tooltip_ordered_container {
    padding: 0;
    margin: 0;

    .consult_tooltip_list {
      padding-left: 0.9rem;
      text-indent: -0.9rem;
    }

    .consult_tooltip_non_ordered_item {
      margin: 0;
    }
  }
  animation: fadeIn 0.3s ease-in;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

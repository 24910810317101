@import "../../../general";

.ez_consult_modal_container {
  @include centerFlex();
  justify-content: center;
  width: 100%;
  height: 100%;
}
.ez_consult_modal_wrapper {
  width: 100%;
  height: 50%;
  background-color: #0a182a;
  color: var(--white);
  border-radius: 8px;
  z-index: 10;
}
.ez_consult_modal_section {
  @include verticalFlex($align: flex-start, $gap-size: 8vh);
  height: 100%;
  width: 100%;
  padding: 2vh;

  @include media-mid-device() {
    padding: 4vh;
  }
}
.ez_consult_modal_heading_text {
  @include verticalFlex($align: flex-start, $gap-size: 1vh);
}
.ez_consult_modal_button_section {
  @include centerFlex($gap-size: 1vh);
  justify-content: space-between;
  width: 100%;
}
.ez_consult_modal_left_button,
.ez_consult_modal_right_button {
  width: 100%;
  padding: 1vh 0;
  border: 2px solid white;
  @include stc-normal-font($fontColor: var(--white));
  border-radius: 5px;
}
.ez_consult_modal_left_button {
  background-color: var(--white);
  color: black;
}
.ez_consult_modal_right_button {
  background: transparent;
  color: var(--white);
}
.ez_consult_modal_main_text {
  @include stc-heading-font();
}
.ez_consult_modal_para_text {
  @include stc-normal-font($fontColor: rgb(184, 184, 184));
}

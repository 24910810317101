@import "../../../general";

/* ================== REVOKED BOTTOM CLIENT DASHBOARD ======= */

.tool-revoked-bottom-client-dashboard {
  position: relative;
  /* width: 100%; */
  height: 100%;
  /* margin-top: 5px; */
  overflow-y: auto;
  margin: 10px;
  border-radius: 10px;
  /* background-color: #091524; */
}

.tool-revoked-bottom-client-dashboard-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  min-height: 50vh;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 1vh;
  /* background-color: #091524; */
}

.tool-revoked-bottom-client-dashboard-container-top {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tool-revoked-bottom-client-dashboard-container-top-heading {
  color: #ea7b2c;
  font-weight: bold;
  // font-size: var(--ml-font);

  @include media-large-device() {
    @include stc-heading-font();
    color: #ea7b2c;
    font-weight: bold;
  }
  @include media-extrasmall-device() {
    @include stc-heading-font();
    color: #ea7b2c;
    font-weight: bold;
  }
  @include media-tab-potrait-device() {
    @include stc-heading-font();
    color: #ea7b2c;
    font-weight: bold;
  }
}

.tool-revoked-bottom-client-dashboard-container-top-img {
  height: 20vh;
  padding: 20px 0;
}

.tool-revoked-bottom-client-dashboard-container-bottom {
  display: flex;
  flex-direction: column;
}
.tool-revoked-bottom-client-dashboard-para {
  text-align: center;
  color: #ffffff;
  font-size: var(--m-font);
  @include media-large-device() {
    @include stc-heading-font();
    color: #ffffff;
  }
  @include media-extrasmall-device() {
    @include stc-heading-font();
    color: #ffffff;
  }
  @include media-tab-potrait-device() {
    @include stc-heading-font();
    color: #ffffff;
  }
}
.tool-client-revoked-tab-names-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  row-gap: 5px;
  column-gap: 5px;
}

.tool-client-revoke-share-warning-name-item {
  position: relative;
  padding: 5px;
  background-color: var(--label-blue, #9cbdeb);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 5px;
  font-size: var(--s-font);
  width: fit-content;
  flex-wrap: wrap;
  margin-top: 5px;
}

@import "../../../common";

.search_input_dropdown_wrapper {
  @include verticalFlex();
  line-height: normal !important;
}
.search_input_wrapper {
  @include horizontalFlex($gap: 1rem);
  border: 1px solid #ababab;
  border-radius: 8px;
  height: 40px;
  padding: 0px 12px;
  background-color: var(--white);

  @include media-large-device() {
    height: 50px;
  }
}
.search_input_input_field {
  border: none;
  background: none;
  outline: none;
  font-size: var(--rem-base-font);
  width: 100%;

  @include media-large-device() {
    font-size: var(--rem-lg-font);
  }
}

.search_dropdown_wrapper {
  @include verticalFlex($position: absolute, $justify: flex-start);
  background-color: var(--white);
  top: 48px;
  transition: all 0.3s ease;
  border-radius: 8px;
  // height: 0rem;
  overflow-y: auto;

  // border: 1px solid #ababab;
  z-index: 2;
  padding-right: 1px;

  &:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  @include media-large-device() {
    top: 58px;
  }
}

.search_dropdown_active {
  // display: flex;
  height: 10rem;
  border: 1px solid #ababab;
  box-shadow: 0px 3px 6px #00000029;
  transition: all 0.3s ease;
}

.search_dropdown_unactive {
  height: 0rem;
  transition: all 0.3s ease;
  // display: none;
}

.search_input_dropdown_icon {
  cursor: pointer;
  font-size: 1.2rem;
}

.service_wrapper {
  @include centerFlex($justify: flex-start);
  font-size: var(--rem-base-font);
  color: var(--dark-blue);
  cursor: pointer;

  @include media-large-device() {
    font-size: var(--rem-lg-font);
  }
}

.service_outer_wrapper_container {
  @include centerFlex();
  padding: 2px 2px 0px 2px;
}

.service_wrapper_container {
  @include horizontalFlex(
    $justify: flex-start,
    $align: flex-start,
    $gap: 0.5rem
  );
  padding: 7px 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  @include media-350p() {
    padding: 7px 12px;
  }

  @include media-large-device() {
    &:hover {
      background-color: #1129491a;
    }
  }
}
.servicedata_mainwrappers {
  @include centerFlex($justify: space-between);
}
.subservice_container_wrapper {
  @include verticalFlex($justify: flex-start, $align: flex-start);
  padding: 0px 5px;
  background-color: #1129491a;
}

.subservice_container_outer_wrapper {
  @include centerFlex();
  padding: 0px 2px;
}

.subservice_wrapper {
  @include horizontalFlex($align: center);
  // height: 2rem;
  // padding: 7px 12px;
  padding: 7px 0px 7px 2px;
  cursor: pointer;

  @include media-350p() {
    padding: 7px 7px 7px 28px;
  }

  @include media-small-device() {
    padding: 7px 6px 7px 34px;
  }
}
.subservice_name_wrapper {
  color: var(--dark-blue);
  font-size: var(--rem-base-font);

  @include media-large-device() {
    font-size: var(--rem-lg-font);
  }
}

.selected_services_wrapper {
  @include horizontalFlex($justify: flex-start, $width: auto, $gap: 0.5rem);
  flex-wrap: wrap;
}

.selected_services_wrapper_container {
  @include horizontalFlex($justify: flex-start, $gap: 0.5rem);
  max-height: 4.5rem;
  overflow-y: auto;
  flex-wrap: wrap;
}

.top_spacing {
  padding-top: 10px;

  @include media-large-device() {
    padding-top: 1rem;
  }
}
.selected_services_individual_wrapper {
  @include horizontalFlex($gap: 0.5rem, $width: auto);
  border: 1px solid var(--dark-blue);
  border-radius: var(--rem-lg-font);
  padding: 0.2rem 0.5rem;
}

.selected_service_name {
  color: var(--dark-blue);
  font-size: var(--rem-base-font);

  @include media-large-device() {
    font-size: var(--rem-lg-font);
  }
}

.selected_service_icon {
  @include centerFlex($width: auto, $justify: flex-end, $align: flex-end);
  height: 100%;
  svg {
    font-size: 0.8rem;
    cursor: pointer;
  }
}

.tick_icon_wrapper {
  @include centerFlex($width: auto);
  height: 100%;
  svg {
    font-size: 1rem;
  }
}

.tick_icon_wrapper_checked {
  @extend .tick_icon_wrapper;
  fill: var(--dark-blue);
}

.tick_icon_wrapper_unchecked {
  @extend .tick_icon_wrapper;

  svg {
    fill: #bcbcbc;
  }
}

.dropdown_arrow {
  @include centerFlex($width: auto);
  height: 100%;

  svg {
    font-size: 1.2rem;
  }
}

.no_results_found {
  @include centerFlex();
  height: 100%;
}

.no_results_found_text {
  font-size: var(--rem-lg-font);
  color: var(--dark-blue);
}

@import "../../general";

.st_new_request_main {
  position: absolute;
  left: 0%;
  @include scrollbars(
    $size: 2px,
    $thumb-color: rgba(255, 255, 255, 0.1),
    $track-color: rgba(6, 14, 24, 1)
  );
  @include verticalFlex($gap-size: 3vh);
  padding: 3vh;
  border-radius: 16px;
  @include stc-glass-card($inputBgColor: rgba(10, 24, 42, 1));
  width: 50%;
  z-index: 5;

  @include media-large-device() {
    border-radius: 12px;
    width: calc(100% - 50% - 5vh);
    gap: 1vh;
    left: 4.1vh;
    height: 90vh;
  }
  @media screen and (min-width: 1350px) and (max-width: 1380px) and (min-height: 1020px) and (max-height: 1040px) {
    //ipad pro landscape issue
    left: 3.6vh !important;
  }
  @include media-tab-potrait-device() {
    width: 90%;
    gap: 2vh;
    margin: 0 auto;
    left: 0vh;
    max-height: 85vh;
  }

  @include media-small-desktop() {
    border-radius: 14px;
    width: calc(100% - 50% - 5vh);
    gap: 1.5vh;
    left: 4vh;
    height: 90vh;
  }

  @include media-tab-landscape-device() {
    width: calc(100vw - 50vw - 5vh);
  }

  @media screen and (min-width: 1500px) and (max-width: 1800px) {
    height: 90vh;
    width: calc(100% - 50% - 5vh);
  }
  @include media-xl() {
    border-radius: 16px;
    width: calc(100% - 50% - 5vh);
    height: 95dvh;
    gap: 2vh;
    left: 3.5vh;
  }
  @media screen and (min-width: 1200px) and (max-width: 1599px) and (max-height: 730px) {
    //for short heighted viewports
    height: 90vh;
  }
  @include media-xxl() {
    left: 3vh;
  }

  @include media-extrasmall-device() {
    gap: 2vh;
    width: 90%;
    margin: 0 auto;
    max-height: 85vh;
  }

  .st_new_heading_container {
    @include horizontalFlex();
    // padding: 0.5vh 0vh;
    .st_new_heading_name_main {
      .st_new_heading_name {
        @include stc-heading-font($fontColor: var(--white));
        @include media-large-device() {
          @include stc-mega-heading-font($fontColor: var(--white));
        }
        @include media-small-desktop() {
          @include stc-heading-font($fontColor: var(--white));
        }
      }
    }
    .st_new_heading_cross {
      cursor: pointer;
    }
  }
  .st_files_section {
    @include verticalFlex($gap-size: 1vh);
    .st_new_files {
      @include verticalFlex($gap-size: 1vh);
      .st_new_files_heading_main {
        @include horizontalFlex($justify: flex-start);
        .st_new_files_heading {
          @include stc-normal-font($fontColor: rgba(255, 255, 255, 0.5));
          @include media-large-device() {
            @include stc-heading-font($fontColor: rgba(255, 255, 255, 0.5));
          }
          @include media-small-desktop() {
            @include stc-normal-font($fontColor: rgba(255, 255, 255, 0.5));
          }
        }
      }
      .st_input_files_container_main {
        @include verticalFlex($gap-size: 0.5vh);
        .st_input_files_container {
          @include horizontalFlex($align: center, $justify: space-between);
          .st_input_files_heading {
            @include stc-normal-font($fontColor: var(--white));
            @include media-large-device() {
              @include stc-heading-font($fontColor: var(--white));
            }
            @include media-small-desktop() {
              @include stc-normal-font($fontColor: var(--white));
            }
            @include media-tab-potrait-device() {
              @include stc-normal-font($fontColor: var(--white));
            }
            @include media-extrasmall-device() {
              @include stc-normal-font($fontColor: var(--white));
            }
          }
          .st_input_reset_icon {
            cursor: pointer;
            @include stc-heading-font($fontColor: var(--white));
            @include media-large-device() {
              @include stc-mega-heading-font($fontColor: var(--white));
            }
            @include media-small-desktop() {
              // margin-bottom: -1vh;
              @include stc-heading-font();
            }
          }
        }
      }
    }
  }
}
.st_request_inputs {
  width: 100%;
}
.st_request_deliver_input_main {
  @include verticalFlex($gap-size: 0.5vh);
  .st_request_deliver_input {
    width: 100%;
  }
}
.st_request_text_input_container {
  @include verticalFlex($gap-size: 0.5vh);
  .st_input_text_container_main {
    @include verticalFlex();
    .st_input_text_container {
      @include horizontalFlex($align: center, $justify: space-between);
      .st_input_text_heading {
        @include stc-normal-font($fontColor: var(--white));
        @include media-large-device() {
          @include stc-heading-font($fontColor: var(--white));
        }
        @include media-small-desktop() {
          @include stc-normal-font($fontColor: var(--white));
        }
      }
      .st_input_reset_icon {
        cursor: pointer;
        @include stc-heading-font($fontColor: var(--white));
      }
    }
  }
}
.st_entity_container_main {
  @include verticalFlex();
}
.st_charge_and_delivery_container {
  @include horizontalFlex();
  @include media-tab-potrait-device() {
    @include verticalFlex();
  }
  @include media-extrasmall-device() {
    @include verticalFlex();
  }
  .st_input_charge_container {
    @include verticalFlex($width: 50%, $align: flex-start, $gap-size: 0.5vh);
    @include media-tab-potrait-device() {
      width: 100%;
    }
    @include media-extrasmall-device() {
      width: 100%;
    }
    .st_input_charge_heading {
      @include stc-normal-font($fontColor: var(--white));
      @include media-large-device() {
        @include stc-heading-font($fontColor: var(--white));
      }
      @include media-small-desktop() {
        @include stc-normal-font($fontColor: var(--white));
      }
    }
    .st_input_charge_component {
      width: 100%;
    }
  }
  .st_delivery_container {
    @include verticalFlex($width: 50%, $align: flex-start, $gap-size: 0.5vh);
    @include media-tab-potrait-device() {
      width: 100%;
    }
    @include media-extrasmall-device() {
      width: 100%;
    }
    .st_input_delivery_heading {
      @include stc-normal-font($fontColor: var(--white));
      @include media-large-device() {
        @include stc-heading-font($fontColor: var(--white));
      }
      @include media-small-desktop() {
        @include stc-normal-font($fontColor: var(--white));
      }
    }
    .st_input_delivery_calendar {
      width: 100%;
    }
  }
}
.new_request_submit_main {
  @include horizontalFlex();
  .new_request_submit_btn {
    @include stc-btn($btnBgColor: var(--white), $btnTextColor: #112949);
    width: 100%;
    @include media-large-device() {
      @include stc-heading-font($fontColor: var(--white));
      @include stc-btn(
        $btnBgColor: var(--white),
        $btnTextColor: #112949,
        $width: 100%
      );
    }
    @include media-small-desktop() {
      @include stc-btn(
        $btnBgColor: var(--white),
        $btnTextColor: #112949,
        $width: 100%
      );
    }
  }
}

.stc_calendar_details {
  background: rgba(10, 24, 42, 1);
  border: 1px solid #394d67;
  min-height: 36px;
  border-radius: 4px;
  width: 100%;
  z-index: 100;
}
.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker__month-container {
  z-index: 1000;
}

.delivery_calendar {
  position: relative;
  background: rgba(10, 24, 42, 1);
  border: 1px solid #394d67;
  min-height: 35px;
  width: 100%;
  min-width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  color: var(--white);
  border-radius: 4px;
  font-style: normal;

  @include media-tab-potrait-device() {
    min-width: 98%;
    height: 34px;
    width: 100%;
  }
  @include media-extrasmall-device() {
    min-width: 96%;
    height: 36px;
    width: 100%;
  }
  @include media-large-device() {
    min-width: none;
    height: auto;
  }
}

.delivery_calendar::placeholder {
  font-style: italic;
  color: grey;
}
.delivery_calendar:focus {
  outline: none;
}
::-webkit-calendar-picker-indicator {
  filter: invert(1);
  width: 20px;
  height: 20px;
}
.disable_btn {
  cursor: wait;
  @include stc-btn($btnBgColor: grey, $btnTextColor: var(--white));
  width: 100%;
  @include media-large-device() {
    @include stc-heading-font();
  }
  @include media-small-desktop() {
    @include stc-btn(
      $btnBgColor: grey,
      $btnTextColor: var(--white),
      $width: 100%
    );
  }
}
.disable_btn :hover {
  cursor: wait;
}
.loader_button_files {
  width: auto !important;
  white-space: nowrap;
  @include stc-normal-font($fontColor: #112949);
  @include horizontalFlex();
}
.discount_code_input {
  width: 100% !important;
}
.st_new_req_close_btn {
  margin-right: 0.5vh;
}

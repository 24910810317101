@import "../../../common";

.client_testimonial_section_outermost_section {
  @include centerFlex();
  display: none;
  height: 50rem;

  @include media-large-device() {
    display: flex;
  }

  @include media-small-desktop() {
    height: 60rem;
  }

  @include media-tablet-1000p() {
    display: none;
  }

  @include media-xxl() {
    height: 85rem;
  }

  @include media-xxxl() {
    height: 100rem;
  }
}

.client_testimonial_section_overlay {
  background-color: rgba(207, 212, 219, 0.4);
  clip-path: polygon(0 9%, 18% 14%, 100% 9%, 100% 94%, 80% 88%, 0 94%);
  height: 100%;
  width: 100%;

  @include media-large-device() {
    clip-path: polygon(0 6%, 33% 13%, 100% 6%, 100% 94%, 67% 87%, 0 94%);
  }

  @include media-small-desktop() {
    clip-path: polygon(0 9%, 32% 19%, 100% 9%, 100% 94%, 68% 81%, 0 94%);
  }
}

.client_testimonial_content_wrapper {
  @include verticalFlex();
  // padding: 31% 1rem 27% 1rem;

  @include media-large-device() {
    display: grid;
    grid-template-columns: 0.7fr 1.3fr;
    grid-template-rows: 1fr;
    height: 100%;
    width: 100%;
    padding: 9rem 0px 9rem 4rem;
  }

  @include media-small-desktop() {
    padding: 13rem 0px 13rem 5rem;
  }

  @include media-xxl() {
    padding: 19rem 0px 19rem 5rem;
  }
  @include media-xxxl() {
    padding: 21rem 0px 21rem 5rem;
  }
}

.header_wrapper {
  @include centerFlex($justify: flex-start, $align: flex-start);

  @include media-large-device() {
    height: 100%;
    border-right: 2px solid var(--dark-blue);
    padding-right: 2rem;
  }
}

.heading_text {
  font-family: "Exo2.0-SemiBold";
  color: var(--dark-blue);
  font-size: 32px;
  z-index: 5;
  text-align: center;

  @include media-large-device() {
    font-size: var(--rem-4xl-font);
    text-align: left;
  }

  @include media-small-desktop() {
    font-size: var(--rem-5xl-font);
  }
  @include media-xxl() {
    font-size: var(--rem-7xl-font);
  }
  @include media-xxxl() {
    font-size: var(--rem-8xl-font);
  }
}

.quote_icon_wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  left: 0;
  right: 2rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-right: 2rem;

  svg {
    @media screen and (min-width: 1800px) {
      height: 100%;
    }
  }
}

.testimonial_scroll_carosuel_wrapper {
  display: none;
  width: 100%;
  height: 100%;
  padding-left: 2rem;

  @include media-large-device() {
    display: flex;
  }
}

.testimonial_btn_carosuel_wrapper {
  display: flex;

  @include media-large-device() {
    display: none;
  }
}

// .carousel_outer_wrapper {
//   overflow: hidden;
//   position: relative;
//   height: 100%;
//   border-color: #4b5563;
//   width: 100%;
//   // margin-left: 2rem;
// }

// .carosuel_track {
//   display: flex;
//   position: absolute;
//   left: 0;
//   justify-content: center;
//   align-items: center;
//   gap: 1rem;
//   // width: 550%;
//   height: 100%;
//   animation: slide 10s linear infinite;
//   &:hover {
//     animation-play-state: paused;
//   }

//   @include media-small-desktop() {
//     // width: 500%;
//     gap: 1rem;
//   }

//   @media screen and (min-width: 1800px) {
//     // width: 350%;
//   }
// }

// @keyframes slide {
//   0% {
//     left: 0;
//   }
//   100% {
//     left: -100%;
//   }
// }

.testimonial_slider_scroll_track {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 100%;
}

.testimonial_slider_scroll_wrapper {
  position: absolute;
  display: flex;
  height: 100%;
  gap: 1.5rem;
}

.testimonial_scroll_animation_container {
  display: flex;
  gap: 1.5rem;
}

.testimonial_scroll_animation {
  animation: swipe var(--speed) linear infinite backwards;
  &:hover {
    animation-play-state: paused;
  }
}

.testimonial_slider_scroll_track:hover .testimonial_scroll_animation {
  animation-play-state: paused;
}
@keyframes swipe {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(-100%);
  }
}

@import "../../../common";

.testimonial_outer_container {
  // @include centerFlex();
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  height: 100%;
  @include media-large-device() {
    display: none;
  }
  @include media-tablet-1000p() {
    display: flex;
  }
}

.featured_article_outermost_container {
  // display: flex;
  // justify-content: flex-start;
  // align-items: flex-start;
  height: 100%;
}

.testimonial_content_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 2rem;
  background-color: rgba(207, 212, 219, 0.4);
  clip-path: polygon(0 4%, 20% 9%, 100% 4%, 100% 96%, 80% 90%, 0 96%);
  height: 100%;
  padding: 30% 0.5rem 31% 0.5rem;
  width: 100%;

  @include media-350p() {
    padding: 25% 0.5rem 25% 0.5rem;
  }

  @include media-small-device() {
    gap: 3rem;
  }
  @include media-mid-device() {
    padding: 14% 0rem 14% 1rem;
    gap: 3rem;
  }

  @include media-mobile-landscape() {
    padding: 15% 0.5rem 12% 0.5rem;
  }
}

.quote_icon_wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  // z-index: 2;
  left: 0rem;
  right: 2rem;
  top: 2rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-right: 2rem;

  svg {
    width: 20rem;
  }

  @include media-350p() {
    left: -1rem;
  }

  @include media-mid-device() {
    left: 7rem;
    right: 2rem;
    top: 2rem;
  }
}

.testimonial_card_carousel_wrapper {
  margin: 0px 0.5rem;
  height: 100%;
}

.testimonial_button_container {
  @include centerFlex();
}

.testimonial_button_wrapper {
  @include horizontalFlex($width: auto, $gap: 1rem);
}

.testimonial_button {
  @include centerFlex($width: auto);
  background: none;
  border: none;
  background: var(--dark-blue);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 50%;
  border: thin solid rgba(255, 255, 255, 0.2);
  padding: 1rem;

  @include media-sm {
    padding: 0.5rem;
  }
}

.button_icon {
  color: var(--white);
  font-size: var(--rem-4xl-font);
}

.testimonial_section_heading_wrapper {
  @include centerFlex();
}

.testimonial_section_heading_text {
  font-family: "Exo2.0-SemiBold";
  color: var(--dark-blue);
  // font-size: var(--rem-2xl-font);
  font-size: 24px;
  z-index: 5;
  text-align: center;

  @media screen and (min-width: 300px) {
    font-size: 24px;
  }

  @media screen and (min-width: 350px) {
    font-size: 32px;
  }

  @include media-large-device() {
    font-size: var(--rem-4xl-font);
  }
}

.testimonial_carousel_movable_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  width: 100%;
}

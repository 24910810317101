@import "../../general";

.st_dashboard_cards_main {
  grid-area: tools-tab-home-nav;
  display: block;
  position: relative;
  overflow-y: auto;
  padding: 6vh 14vh;
  @include verticalFlex($gap-size: 3vh);
  @include media-large-device() {
    padding: 6vh 9vh;
  }
  @include media-small-desktop() {
    padding: 6vh 14vh;
  }
  @include media-xl() {
    padding: 6vh 17vh;
  }
  @include media-tab-potrait-device() {
    padding: 3vh 1vh;
    max-height: calc(100vh - 100px);
  }
  @include media-extrasmall-device() {
    padding: 3vh 1vh;
    max-height: calc(100vh - 100px);
  }

  .st_dashboard_heading_main {
    @include horizontalFlex($align: center, $justify: center);
    .st_dashboard_heading,
    .st_dashboard_heading_sub {
      @include stc-mega-heading-font($fontColor: var(--white));
    }
  }
  .st_dashboard_sub_heading_container {
    @include verticalFlex($gap-size: 0vh);
    @include stc-normal-font($fontColor: var(--white));
    .subheading_one,
    .subheading_two,
    .subheading_three {
      @include media-tab-potrait-device() {
        display: none;
      }
      @include media-extrasmall-device() {
        display: none;
      }
    }

    @include media-large-device() {
      text-align: center;
      @include stc-heading-font($fontColor: var(--white));
    }
    @include media-small-desktop() {
      @include stc-normal-font($fontColor: var(--white));
    }
    @include media-tab-potrait-device() {
      text-align: center;
    }
    @include media-extrasmall-device() {
      text-align: center;
    }
  }
  .tools_single_card {
    display: grid;
    width: 94%;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    width: auto;
    gap: 4vh;

    @include media-large-device() {
      gap: 3vh;
    }
    @include media-small-desktop() {
      gap: 4vh;
    }
    @include media-tab-potrait-device() {
      grid-template-rows: repeat(2, 1fr);
      grid-template-columns: 1fr;
      row-gap: 1.5vh;
      width: 100%;
    }
    @include media-extrasmall-device() {
      grid-template-rows: repeat(2, 1fr);
      grid-template-columns: 1fr;
      row-gap: 1.5vh;
    }
    .st_dashboard_tools_card {
      cursor: pointer;
      border-radius: 12px;
      @include stc-glass-card();
      @include verticalFlex($justify: flex-start);
      gap: 3vh;
      margin: 0vh;
      padding: 3vh;

      @include media-large-device() {
        border-radius: 8px;
        padding: 3vh;
        min-height: 27vh;
      }
      @include media-small-desktop() {
        gap: 5vh;
      }
      @include media-xl() {
        border-radius: 12px;
        gap: 5vh;
        padding: 4vh;
      }
      @include media-tab-potrait-device() {
        gap: 1vh;
        min-height: 22vh;
        padding: 3.5vh 2.5vh;
      }
      @include media-extrasmall-device() {
        gap: 1vh;
        padding: 3.5vh 2.5vh;
      }
      .st_dashboard_tool_name_main {
        @include horizontalFlex();
        .st_dashboard_tool_name {
          @include horizontalFlex($gap-size: 0.5vh, $align: flex-start);
          .st_dashboard_tool_logo {
          }
          .st_dashboard_tool_name {
            @include stc-mega-heading-font();
            color: var(--white);
          }
        }
        .st_dashboard_tool_arrow {
          cursor: pointer;

          @include media-sm-landscape-500p() {
            height: 3vh;
          }
          @include media-sm-landscape-500p() {
          }
          @include media-sm-landscape-500p() {
          }
          .arrow-st-client {
            height: 3vh;
          }
        }
      }

      .st_dashboard_tool_content_main {
        @include horizontalFlex($justify: center, $align: center);
        margin: 2.5vh auto;
        text-align: center;
        @include media-large-device() {
          margin: 1.5vh auto;
        }
        @include media-small-desktop() {
          margin: 1.5vh auto;
        }
        @include media-xl() {
          margin: 1.5vh auto;
        }
        .st_dashboard_tool_content {
          @include verticalFlex($gap-size: 1vh, $justify: flex-start);
          min-height: 6vh;
          width: 95%;
          color: var(--white);
          @include stc-normal-font($fontColor: var(--white));

          @include media-large-device() {
            @include stc-heading-font($fontColor: var(--white));
          }
          @include media-small-desktop() {
            @include stc-normal-font($fontColor: var(--white));
          }
          @include media-tab-potrait-device() {
            @include stc-heading-font($fontColor: var(--white));
          }
        }
      }
    }
  }
  .st_dashboard_tool_img_new,
  .st_dashboard_tool_img {
    height: 3vh;
    @include media-sm-landscape-500p() {
      height: 3vh;
    }
    @include media-sm-landscape-700p() {
      height: 3vh;
    }
    @include media-sm-landscape-900p() {
      height: 3vh;
    }

    @include media-large-device() {
      height: 26px;
    }
    @include media-small-desktop() {
      height: 30px;
    }

    @include media-xl() {
      height: 40px;
    }
    @media screen and (min-width: 1500px) and (max-width: 1750px) {
      height: 30px;
    }
    @include media-xxl() {
      height: 65px;
    }
    @include media-tab-potrait-device() {
      height: 38px;
    }
    @include surface-duo() {
      height: 32px;
    }
    @include media-extrasmall-device() {
      height: 38px;
    }
  }
}
.mobile_heading_ez_tools {
  @include media-extrasmall-device() {
    @include stc-normal-font($fontColor: var(--white));
  }
  @media screen and (min-width: 1000px) and (max-width: 1024px) and (min-height: 1300px) and (max-height: 1380px) and (orientation: portrait) {
    display: block !important;
    @include stc-heading-font($fontColor: var(--white));
  }
  @include media-tab-potrait-device() {
    @include stc-heading-font($fontColor: var(--white));
  }

  @include media-large-device() {
    display: none;
  }

  @media screen and (min-width: 0px) and (max-width: 980px) and (orientation: landscape) {
    display: none;
  }
}

@import "../../common";

.uspmodels_outerWrapper {
  @include centerFlex();
  background-color: var(--white);
}
.uspmodels_mainSection {
  @include verticalFlex($justify: center, $gap: 1rem);
  height: auto;

  @include media-small-device() {
    gap: 2rem;
  }
  @include media-small-desktop() {
    padding: var(--rem-9xl-font);
    gap: 4rem;
    background-color: rgba(207, 212, 219, 0.4);
    clip-path: polygon(35% 5%, 100% 10%, 100% 0%, 100% 85%, 100% 75%, 75% 97%, 0% 90%, 0 25%);
  }
  @include media-small-desktop() {
    padding: 7rem 1rem 9rem 1rem;
    gap: 4rem;
    clip-path: polygon(35% 5%, 100% 10%, 100% 0%, 100% 88%, 100% 85%, 75% 95%, 0% 87%, 0 17%);
  }
  @include media-xl() {
    padding: 6rem 6rem 13rem 6rem;
    clip-path: polygon(35% 5%, 100% 10%, 100% 0%, 100% 85%, 100% 77%, 70% 95%, 0% 84%, 0 25%);
  }
  @include media-xxl() {
    padding: 15rem 10rem;
  }
  @include media-xxxl() {
    padding: 10rem 50rem;
  }
}
.uspmodels_mainContainer {
  @include horizontalFlex($justify: center, $align: flex-start, $gap: 0);
  flex-direction: column-reverse;

  @include media-large-device() {
    flex-direction: column-reverse;
  }

  @include media-small-desktop() {
    flex-direction: row;
    gap: 2rem;
  }
  @include media-xl() {
    gap: 3rem;
  }
}

.uspmodels_Headingtext {
  font-size: 35px;
  text-align: center;
  color: #112949;
  font-family: "Exo2.0-SemiBold";
  display: none;
  @include media-small-desktop() {
    display: block;
    text-align: left;
    font-size: var(--rem-5xl-font);
  }
  @include media-xxxl() {
    font-size: var(--rem-6xl-font);
  }
}
.uspmodels_CardsHeadingText {
  font-size: 24px;
  text-align: center;
  color: #112949;
  font-family: "Exo2.0-SemiBold";
  display: block;

  @media screen and (min-width: 300px) {
    font-size: 28px;
  }

  @media screen and (min-width: 350px) {
    font-size: 32px;
  }

  @include media-large-device() {
    font-size: var(--rem-4xl-font);
  }
  @include media-small-desktop() {
    display: none;
    font-size: var(--rem-5xl-font);
  }
}
.uspmodels_leftHeadingPara {
  font-size: 20px;
  width: 100%;
  color: #112949;
  font-family: "Exo2.0-SemiBold";
  text-align: center;

  @media screen and (min-width: 300px) {
    font-size: 24px;
  }

  @media screen and (min-width: 350px) {
    font-size: 28px;
  }
  @include media-large-device() {
    font-size: 32px;
  }
  @include media-xxxl() {
    font-size: var(--rem-5xl-font);
  }
}
.uspmodels_leftmainContainer {
  @include verticalFlex($gap: 4rem, $align: center, $justify: center, $width: 100%);
  padding: 0 1rem 2rem 1rem;
  @include media-large-device() {
    gap: 4rem;
  }
  @include media-small-desktop() {
    width: 60%;
    align-items: center;
  }

  @include media-xl() {
    width: 36%;
  }
  @media screen and (min-width: 1800px) {
    width: 100%;
  }
}
.uspmodels_centerMainContainer {
  height: 35rem;
  width: 1px;
  background: #03080f;
  display: none;
  @include media-small-desktop() {
    display: block;
  }
}
.uspmodels_clocktimemainWrapper {
  display: flex;
  gap: 1.3rem;
  @include media-mid-device() {
    gap: 2.3rem;
  }
}
.uspmodels_verticalLineContainer {
  width: 5px;
  height: 10rem;
  border-radius: 5px;
  background: #112949;
  opacity: 0.6;
}
.uspmodels_BulletPointsConatiner {
  width: var(--rem-base-font);
  height: var(--rem-base-font);
  border-radius: var(--rem-base-font);
  margin: 5px 0;
  background: #112949;
}
.uspmodels_roundclockMainSection {
  @include centerFlex($align: normal, $justify: center, $width: auto);
  gap: 1rem;
  padding-bottom: 5rem;
  @include media-mid-device() {
    gap: 2rem;
  }
  @include media-small-desktop() {
    gap: 3rem;
    justify-content: center;
    padding-bottom: 0;
  }
  @include media-xl() {
    justify-content: space-between;
  }
}
.uspmodels_clockIndiviContainer {
  @include verticalFlex($align: center, $width: auto);
}
.uspmodels_clocktimecontainer {
  color: #112949;
  font-size: 18px;
  text-align: center;
  @include media-1100p() {
    font-size: 28px;
  }
  @include media-xxxl() {
    font-size: var(--rem-5xl-font);
  }
  h1 {
    font-family: "Exo2-Bold";
    font-size: 60px;
    line-height: 1;
    @include media-1100p() {
      font-size: 80px;
    }
    @include media-xxxl() {
      font-size: 90px;
    }
  }
}

.uspmodels_clockIndiviParagraph {
  @include centerFlex($width: 8rem);
  text-align: center;
  background: #d1d4d9;
  color: #112949;
  height: 5rem;
  border-radius: 10px;
  padding: 0 1rem;
  font-size: var(--rem-base-font);
  @include media-350p() {
    font-size: var(--rem-lg-font);
    width: 12rem;
    height: 5rem;
  }
  @include media-mid-device() {
    width: 15rem;
  }
  @include media-1100p() {
    font-size: 24px;
    width: 18rem;
    height: 7rem;
  }
  @include media-xl() {
    font-size: 28px;
  }
  @include media-xxl() {
    font-size: var(--rem-4xl-font);
  }
}
.uspmodels_rightmainwrapperContainer {
  display: flex;
  flex-direction: column;
  padding: 2rem 10px;
  width: 100%;
  background-color: rgba(207, 212, 219, 0.4);
  align-items: center;
  clip-path: polygon(26% 0%, 101% 3%, 100% 19%, 100% 100%, 100% 96%, 70% 98%, 0% 96%, 0 3%);

  @include media-350p() {
    padding: 4rem 1rem;
    clip-path: polygon(26% 1%, 101% 5%, 100% 19%, 100% 84%, 100% 96%, 80% 99%, 0% 97%, 0 5%);
  }
  @include media-mid-device() {
    padding: 4rem 7rem;
  }
  @include media-1100p() {
    padding: 4rem 13rem;
  }
  @include media-small-desktop() {
    display: flex;
    flex-direction: column;
    clip-path: none;
    background: none;
    padding: 0;
  }
}
#uspmodels_specialStraightLine {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  @include media-1100p() {
    margin-bottom: 2rem;
  }
}
.uspmodels_rightmainContainer {
  display: grid;
  grid-template-columns: 1fr;
  padding: 2rem 0;
  gap: 4rem;
  width: 100%;
  @include media-small-device() {
    grid-template-columns: 1fr;
    gap: 3rem;
    padding: 4rem 0;
    width: 55%;
  }
  @include media-large-device() {
    grid-template-columns: 1fr 1fr;
    padding: 4rem 0;
    width: 90%;
    gap: 4rem;
  }
  @include media-small-desktop() {
    padding: 5rem 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    width: 100%;
    column-gap: 3rem;
    row-gap: 5rem;
  }
  @include media-small-desktop() {
    clip-path: none;
    background: none;
    padding: 0;
  }
}

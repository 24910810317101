@import "../../../../general";

.tools_consult_chat_footer_wrapper {
  @include verticalFlex($align: flex-end, $gap-size: 1vh);
}

.tools_consult_chat_input_form {
  @include verticalFlex($align: flex-start);
  @include stc-glass-card($inputBgColor: transparent);
  padding: 0px;
  overflow: hidden;
  margin: 0vh;
  border: none;
  box-shadow: none;

  @include media-large-device() {
    border: 1px solid #394d67;
    background-color: var(--dark-blue-v2);
    box-shadow: 0px 0px 4px 2px var(--shadow-color);
    border-radius: 10px;
    padding: 2.5vh;
  }

  @include media-small-desktop() {
    border-radius: 16px;
  }
}

.tools_consult_message_form {
  @include horizontalFlex($gap-size: 1.5vh);
  background-color: #394d67;
  padding: 1.8vh;
  border-radius: 8px;
}

.tools_consult_form_label_text {
  @include stc-normal-font($fontColor: var(--white));
  display: none;
  @include media-large-device() {
    display: block;
  }
}

.tools_consult_message_input {
  @include verticalFlex();
  background: none;
  border: none;
  height: 100%;
  outline: none;
  @include stc-sub-heading-font($fontColor: var(--white));

  &::placeholder {
    @include stc-sub-heading-font($fontColor: rgba(255, 255, 255, 0.5));
  }
}

.tools_consult_message_input_btn_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: none;
  position: relative;

  img {
    width: var(--xl-font);
  }

  &:disabled {
    cursor: not-allowed;
  }

  .tools_consult_message_input_listening_dots {
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 5px;
    resize: none;
  }

  .tools_consult_message_input_listening_dots span {
    width: 4px;
    height: 4px;
    background-color: var(--white);
    border-radius: 50%;
    animation: pulse 1.2s infinite;
    opacity: 0;
  }

  /* Dots Animation */
  @keyframes pulse {
    0%,
    100% {
      opacity: 0;
      transform: scale(1);
    }
    50% {
      opacity: 1;
      transform: scale(1.5);
    }
  }

  .tools_consult_message_input_listening_dots span:nth-child(1) {
    animation-delay: 0s;
  }

  .tools_consult_message_input_listening_dots span:nth-child(2) {
    animation-delay: 0.2s;
  }

  .tools_consult_message_input_listening_dots span:nth-child(3) {
    animation-delay: 0.4s;
  }
}

.tools_consult_message_input_btn_icon {
  @include stc-mega-heading-font($fontColor: var(--white));
}

.tools_consult_gpt_desc_wrapper {
  @include verticalFlex();
}

.tools_consult_gpt_desc_text {
  text-align: center;
  @include stc-normal-font($fontColor: #5b779c);
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

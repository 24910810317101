@import "../../ToolsModule/general";
/* ========================= HELP =============================== */

/* ================= HELP SIDEBAR ============================ */

.client-help-sidebar {
  -webkit-box-shadow: 0px 4px 4px 0px var(--shadow-color);
  -moz-box-shadow: 0px 4px 4px 0px var(--shadow-color);
  box-shadow: 0px 4px 4px 0px var(--shadow-color);
  z-index: 2;
}

.client-help-sidebar-items {
  position: relative;
  float: right;
  width: 100%;
}

.client-help-sidebar-head {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  height: fit-content;
  -webkit-box-shadow: 0px 0px 4px 1px var(--shadow-color);
  -moz-box-shadow: 0px 0px 4px 1px var(--shadow-color);
  box-shadow: 0px 0px 4px 1px var(--shadow-color);
  z-index: 1;
  padding: 10px;
  background: var(--dark-blue, #112949);
}

.client-help-sidebar-head-top {
  position: relative;
  display: flex;
  align-items: center;
  height: 40px;
  /* height: 100%; */
  color: var(--white, #ffffff);
}

.client-help-sidebar-text {
  position: relative;
  height: fit-content;
  display: inline-block;
  margin: 0px;
  font-size: var(--xl-font);
}

.client-sidebar-account-item {
  position: relative;
  display: flex;
  flex-direction: row;
  color: var(--dark-grey);
  flex-wrap: wrap;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  /* padding-left: 10px; */
  border-bottom: 1px solid var(--light-grey);
  border-top: 1px solid var(--light-grey);
  margin: 10px 10px;
  border: 1px solid transparent;
  color: white;
  transition: 100ms;
  box-sizing: border-box;
  /* -webkit-box-shadow: 0px 0px 4px 1px var(--shadow-color);
    -moz-box-shadow: 0px 0px 4px 1px var(--shadow-color);
    box-shadow: 0px 0px 4px 1px var(--shadow-color); */
  /* background: rgba( 255, 255, 255, 0.20 ); */
  /* box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 ); */
  /* backdrop-filter: blur( 10.0px ); */
  /* -webkit-backdrop-filter: blur( 10.0px ); */
  border-radius: 5px;
}

/* .client-sidebar-account-item:hover{
    background: rgba( 255, 255, 255, 0.35 );
  }

  .client-sidebar-account-item-active{
    background: rgba( 255, 255, 255, 0.35 );
    border-bottom: 1px solid var(--dark-blue,#112949);
    border-top: 1px solid var(--dark-blue,#112949);
  } */

.client-sidebar-help-item-head {
  position: relative;
  display: block;
  font-size: var(--s-font);
  margin: 10px;
}

.client-sidebar-help-item {
  position: relative;
  display: flex;
  flex-direction: row;
  color: var(--dark-grey);
  flex-wrap: wrap;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  /* padding-left: 10px; */
  border-bottom: 1px solid var(--light-grey);
  border-top: 1px solid var(--light-grey);
  margin: 10px 10px;
  border: 1px solid transparent;
  color: white;
  transition: 100ms;
  box-sizing: border-box;
  /* -webkit-box-shadow: 0px 0px 4px 1px var(--shadow-color);
    -moz-box-shadow: 0px 0px 4px 1px var(--shadow-color);
    box-shadow: 0px 0px 4px 1px var(--shadow-color); */
  /* background: rgba( 255, 255, 255, 0.20 ); */
  /* box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 ); */
  /* backdrop-filter: blur( 10.0px ); */
  /* -webkit-backdrop-filter: blur( 10.0px ); */
  border-radius: 5px;
}

/* .client-sidebar-account-item:hover{
    background: rgba( 255, 255, 255, 0.35 );
  }*/

.client-sidebar-help-item-logo {
  position: relative;
  height: var(--m-font);
  width: var(--m-font);
  font-size: var(--l-font);
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
}

/* ===================== HELP dashboard ================== */

.client-help-section {
  position: relative;
  padding: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  height: 100vh;
  background: transparent;
  overflow-y: auto;
}

.client-faqs-search-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.client-faqs-search-form {
  @include horizontalFlex($width: 40%);
  border: 0.5px solid var(--light-grey);

  @include media-potrait($tablet: false) {
    width: 100%;
  }

  @include media-potrait($mobile: false) {
    width: 60%;
  }

  .client-faqs-search-input {
    @include stc-input($borderColor: transparent);
    background: transparent;
    border: none;
    padding: 8px;

    @include stc-sub-heading-font(
      $fontColor: (
        var(--white),
      )
    );
  }

  .client-faqs-search-input::placeholder {
    color: #ffffff80;
  }

  .client-faqs-search-btn {
    @include stc-btn($btnBgColor: transparent, $minWidth: fit-content);
    // position: absolute;
    right: 1vh;
    padding: 1vh 0px;
  }
}

.client-faqs-no-results {
  @include verticalFlex();
  height: 100%;
  @include stc-heading-font($fontColor: var(--white));
  padding-top: 10vh;
  font-style: italic;
}

.client-help-head {
  position: relative;
  color: var(--white, #ffffff);
  // font-size: var(--m-font);

  @include stc-heading-font(
    $fontColor: (
      var(--white),
    ),
    $bold: bold
  );
}

.client-help-head-mobile {
  display: none;
}

.client-help-form-head {
  position: relative;
  font-size: 1.8rem;
}

.client-faqs-wrapper {
  @include verticalFlex($align: flex-start);
}

.client-helps-category-text {
  @include stc-sub-heading-font(
    $fontColor: (
      var(--white),
    ),
    $bold: bold
  );
}

.client-helps-container {
  position: relative;
  display: flex;
  flex-direction: column;
  color: var(--white, #ffffff);
  row-gap: 15px;
  width: 100%;
}

.client-faq-item {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  font-size: var(--s-font);
}

.client-faq-item-head {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  column-gap: 40px;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.client-faq-item-question {
  position: relative;
}

.client-faq-item-head-arrow {
  font-size: 1.6rem;
  color: var(--light-grey);
  transition: 300ms;
  cursor: pointer;
}
.client-faq-item-head-arrow-rotate {
  transform: rotateZ(90deg);
}

.client-faq-item-content {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  height: 0px;
  overflow: hidden;
  padding: 0px 10px;
  transition: 300ms;
}
.client-faq-item-content-show {
  height: fit-content;
  padding: 10px;
}

/* ####################### 720p ################################## */

@media only screen and (max-width: 1025px) and (min-width: 850px) {
  /* ================= HELP ===================== */

  .client-help-sidebar-head-top {
    height: 30px;
  }
}

/* ####################### Tablet ################################## */

@media only screen and (min-width: 700px) and (max-width: 780px) and (min-height: 1000px) and (max-height: 1050px),
  (min-width: 1020px) and (max-width: 1040px) and (min-height: 1350px) and (max-height: 1370px),
  (min-width: 500px) and (max-width: 550px) and (min-height: 700px) and (max-height: 730px) {
  /* ====================== FAQs ===================== */

  .client-help-section {
    row-gap: 20px;
    padding: 10px;
    height: calc(100vh - 50px - 50px);
  }

  .client-helps-container {
    row-gap: 10px;
  }

  .client-help-head {
    display: none;
  }

  .client-help-head-mobile {
    display: flex;
    flex-direction: row;
    font-size: var(--sm-font);
    align-items: center;
  }

  .client-faq-item-head {
    column-gap: 10px;
  }

  .client-faq-item-head-arrow {
    display: none;
  }
}

/* ####################### Mobile ################################## */

@media only screen and (max-width: 500px) {
  /* ====================== FAQs ===================== */

  .client-help-section {
    row-gap: 20px;
    padding: 10px;
    height: calc(100vh - 50px - 50px);
  }

  .client-helps-container {
    row-gap: 10px;
  }

  .client-help-head {
    display: none;
  }

  .client-help-head-mobile {
    display: flex;
    flex-direction: row;
    font-size: var(--sm-font);
    align-items: center;
  }

  .client-faq-item-head {
    column-gap: 10px;
  }

  .client-faq-item-head-arrow {
    display: none;
  }
}

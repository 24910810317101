@import "../../../../../general";

.tools_consult_file_processing_wrapper {
  @include verticalFlex();
  height: 100%;
  overflow-y: auto;
  // padding-bottom: 2vh;

  @include media-small-device() {
    width: 80%;
  }

  @include media-large-device() {
    width: 100%;
  }
}

.tools_consult_file_processing_header {
  @include horizontalFlex();
}

.tools_consult_file_processing_upload {
  @include stc-normal-font($fontColor: var(--white), $bold: 700);
}

.tools_consult_file_processing_include {
  @include stc-normal-font($fontColor: var(--light-blue));
  text-decoration: underline;
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
}

.tools_consult_file_include_active {
  color: var(--white);
}

.consult_include_file_wrapper_container {
  @include verticalFlex($justify: flex-start, $align: flex-start);
  height: 100%;
  overflow: auto;
}

.consult_include_file_name {
  @include horizontalFlex($width: auto, $justify: flex-start);
  @include stc-normal-font($fontColor: var(--white));
}
.consult_include_file_wrapper {
  @include horizontalFlex();
  @include stc-glass-card($inputBgColor: #344155);
  margin: 0;
  padding: 3vh 0.5rem;
  overflow: hidden;

  @include media-350p() {
    padding: 1.8rem 1.5vh;
  }
  @include media-small-device() {
    padding: 2.5vh 1vh;
  }
  @include media-large-device() {
    padding: 3vh 1.5vh;
  }
}

.file_details_section {
  @include horizontalFlex();
  height: 100%;
  flex-grow: 1;
  overflow: hidden;
}
.file_include_section {
  @include verticalFlex();
  height: 100%;
}

.consult_include_file_btn_wrapper {
  @include horizontalFlex();
}

.consult_include_file_btn {
  @include horizontalFlex($justify: center);
  padding: 1vh;
  border-radius: 0.8vh;
}

.consult_include_file_apply_btn {
  @extend .consult_include_file_btn;
  background-color: var(--white);
  @include stc-normal-font($fontColor: var(--dark-blue));
  border: 1px solid transparent;
}
.consult_include_file_cancel_btn {
  @extend .consult_include_file_btn;
  @include stc-normal-font($fontColor: var(--white));
  background-color: transparent;
  border: 1px solid var(--white);
}

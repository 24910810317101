/* ============ SHARING MODAL ================= */
.client-shared-modal-container {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 30vw;
  border-radius: 10px;
  top: 50%;
  transform: translate(-10%, -50%);
  background: var(--black, #03080f);
}

.client-shared-modal-head {
  display: flex;
  justify-content: space-between;
  color: var(--label-blue, #9cbdeb);
}

#client-share-heading {
  color: var(--label-blue, #9cbdeb);
}

#client-share-heading-close-btn {
  color: var(--light-blue, #3cc3f2);
}

.client-share-modal-input-container {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  row-gap: 10px;
  flex-wrap: wrap;
  height: fit-content;
  max-height: 50vh;
  max-width: 100%;
  overflow: auto;
}

.client-share-names-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  row-gap: 5px;
  column-gap: 5px;
}

.client-share-name-item {
  position: relative;
  padding: 5px;
  background: var(--light-blue, #3cc3f2);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 5px;
  font-size: var(--s-font);
}

.client-share-name-item-err {
  background: var(--orange, #ea7b2c);
}

.client-share-remove-btn {
  background: none;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
}

.client-share-modal-form {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  row-gap: 5px;
}

.client-share-modal-input {
  position: relative;
  background: transparent;
  color: var(--white, #ffffff);
  font-size: var(--s-font);
}

.client-share-suggestions-container {
  position: absolute;
  padding: 5px 0px;
  /* margin: 5px; */
  background: var(--white, #ffffff);
  border-radius: 5px;
  color: var(--dark-blue, #112949);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 5px;
  max-height: 100px;
  max-width: 90%;
  overflow: auto;
  z-index: 1;
  top: 110%;
  left: 10px;
  /* transform: translate(50%,50%); */
}

.client-share-error {
  position: relative;
  margin-top: 0px;
  margin-left: 10px;
  text-align: start;
}

.client-share-suggestion-item {
  width: 100%;
  text-align: left;
  padding: 5px;
  cursor: pointer;
}

.client-share-suggestion-item:hover {
  background: rgba(60, 195, 242, 0.5);
}

.client-share-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.client-share-btn-container button {
  background-color: var(--light-blue, #3cc3f2);
}

#client-share-modal-cancel-btn {
  background-color: transparent;
  color: var(--light-blue, #3cc3f2);
  padding: 0px;
  margin: 0px;
  min-width: fit-content;
}

/* ###################### Tablet ############################*/

@media only screen and (min-width: 700px) and (max-width: 780px) and (min-height: 1000px) and (max-height: 1050px),
  (min-width: 1020px) and (max-width: 1040px) and (min-height: 1350px) and (max-height: 1370px),
  (min-width: 500px) and (max-width: 550px) and (min-height: 700px) and (max-height: 730px) {
  /* ============ SHARING MODAL ================= */
  .client-shared-modal-container {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: calc(100% - 20px);
    top: 50%;
    left: 0%;
    margin: 10px;
    transform: translate(0%, -50%);
  }

  .client-shared-modal-head {
    display: flex;
    justify-content: space-between;
  }

  .client-share-modal-form {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
  }

  .client-share-suggestions-container {
    top: 110%;
    left: 0px;
  }

  /* .client-share-modal-input{

        } */
}

@media only screen and (max-width: 500px) {
  /* ============ SHARING MODAL ================= */
  .client-shared-modal-container {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: calc(100% - 20px);
    top: 50%;
    left: 0%;
    margin: 10px;
    transform: translate(0%, -50%);
  }

  .client-shared-modal-head {
    display: flex;
    justify-content: space-between;
  }

  .client-share-modal-form {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
  }

  .client-share-suggestions-container {
    top: 110%;
    left: 0px;
  }

  /* .client-share-modal-input{

        } */
}

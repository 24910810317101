$genBorderRadius: 0.8vh;
$genSmBorderRadius: 0.4vh;

// ++++++++++++++++++ MEDIA QUERIES +++++++++++++++++++++++

@mixin media-potrait($mobile: true, $tablet: true) {
  @if $mobile {
    @media screen and (min-width: 0px) and (max-width: 500px) {
      @content;
    }
  }

  @if $tablet {
    @media only screen and (min-width: 700px) and (max-width: 780px) and (min-height: 900px) and (max-height: 1100px),
      (min-width: 700px) and (max-width: 1000px) and (min-height: 700px) and (max-height: 1000px),
      (min-width: 1000px) and (max-width: 1100px) and (min-height: 1300px) and (max-height: 1400px),
      (min-width: 500px) and (max-width: 550px) and (min-height: 700px) and (max-height: 730px),
      (min-width: 750px) and (max-width: 880px) and (min-height: 900px) and (max-height: 1200px),
      (min-width: 800px) and (max-width: 1000px) and (min-height: 1300px) and (max-height: 1400px),
      (min-width: 500px) and (max-width: 580px) and (min-height: 700px) and (max-height: 750px) {
      @content;
    }
  }
}

@mixin media-extrasmall-device {
  @media screen and (min-width: 0px) and (max-width: 500px) {
    @content;
  }
}

@mixin media-half-screen-desktop-device {
  @media only screen and (min-width: 700px) and (max-width: 1000px) and (min-height: 800px) and (max-height: 1000px) {
    @content;
  }
}

@mixin media-tab-potrait-device {
  @media only screen and (min-width: 700px) and (max-width: 780px) and (min-height: 900px) and (max-height: 1100px),
    (min-width: 700px) and (max-width: 1000px) and (min-height: 700px) and (max-height: 1000px),
    (min-width: 1000px) and (max-width: 1100px) and (min-height: 1300px) and (max-height: 1400px),
    (min-width: 500px) and (max-width: 550px) and (min-height: 700px) and (max-height: 730px),
    (min-width: 750px) and (max-width: 880px) and (min-height: 900px) and (max-height: 1200px),
    (min-width: 800px) and (max-width: 1000px) and (min-height: 1100px) and (max-height: 1400px),
    (min-width: 500px) and (max-width: 580px) and (min-height: 700px) and (max-height: 750px) {
    @content;
  }
}

@mixin media-tab-landscape-device {
  @media only screen and (min-height: 700px) and (max-height: 780px) and (min-width: 900px) and (max-width: 1100px),
    (min-height: 1000px) and (max-height: 1100px) and (min-width: 1300px) and (max-width: 1400px),
    (min-height: 500px) and (max-height: 550px) and (min-width: 700px) and (max-width: 730px),
    (min-height: 750px) and (max-height: 880px) and (min-width: 900px) and (max-width: 1200px),
    (min-height: 800px) and (max-height: 1000px) and (min-width: 1100px) and (max-width: 1400px),
    (min-height: 500px) and (max-height: 580px) and (min-width: 700px) and (max-width: 750px) {
    @content;
  }
}

@mixin media-350p {
  @media screen and (min-width: 350px) {
    @content;
  }
}

@mixin media-surface-duo-landscape-device {
  @media only screen and (min-height: 500px) and (max-height: 580px) and (min-width: 700px) and (max-width: 750px) {
    @content;
  }
}

@mixin media-surface-duo-potrait-device {
  @media only screen and (min-width: 500px) and (max-width: 580px) and (min-height: 700px) and (max-height: 750px) {
    @content;
  }
}
@mixin media-surface-pro-landscape-device {
  @media only screen and (min-height: 800px) and (max-height: 1000px) and (min-width: 1100px) and (max-width: 1400px) {
    @content;
  }
}

@mixin media-surface-pro-potrait-device {
  @media only screen and (min-width: 800px) and (max-width: 1000px) and (min-height: 1100px) and (max-height: 1400px) {
    @content;
  }
}

@mixin media-small-device {
  @media screen and (min-width: 501px) {
    @content;
  }
}

@mixin media-mid-device {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin max-width-800px {
  @media screen and (max-width: 800px) {
    @content;
  }
}

@mixin media-large-device {
  @media screen and (min-width: 1023px) and (max-height: 1365px) {
    @content;
  }
}

@mixin media-small-desktop {
  @media screen and (min-width: 1200px) {
    @content;
  }
}

@mixin media-xl {
  @media screen and (min-width: 1500px) {
    @content;
  }
}

@mixin media-1720p {
  @media screen and (min-width: 1501px) and (max-width: 1720px) {
    @content;
  }
}
@mixin media-xxl {
  @media screen and (min-width: 2000px) {
    @content;
  }
}
@mixin surface-duo {
  @media screen and (min-width: 500px) and (max-width: 580px) and (min-height: 700px) and (max-height: 750px) {
    @content;
  }
}

///mobile landscape and zoom 150%
@mixin media-sm-landscape-500p {
  @media screen and (min-width: 500px) and (max-width: 700px) and (orientation: landscape) {
    @content;
  }
}

@mixin media-sm-landscape-700p {
  @media screen and (min-width: 701px) and (max-width: 932px) and (orientation: landscape) {
    @content;
  }
}

@mixin media-sm-landscape-900p {
  @media screen and (min-width: 900px) and (max-width: 932px) and (orientation: landscape) {
    @content;
  }
}

@mixin media-tablet-1000p {
  @media (min-width: 1000px) and (max-width: 1024px) and (min-height: 1300px) and (max-height: 1380px) and (orientation: portrait) {
    @content;
  }
}

@mixin verticalFlex(
  $gap-size: 1vh,
  $justify: space-between,
  $align: center,
  $width: 100%
) {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: $align;
  justify-content: $justify;
  gap: $gap-size;
  width: $width;
}

@mixin horizontalFlex(
  $gap-size: 1vh,
  $justify: space-between,
  $align: center,
  $width: 100%
) {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: $align;
  justify-content: $justify;
  gap: $gap-size;
  width: $width;
}

@mixin centerFlex($gap-size: 1vh) {
  @include stc-normal-font();
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: center;
  gap: $gap-size;
}

@mixin boxShadow($dir: down) {
  @if $dir == up {
    box-shadow: 0vh -0.3vh 0.3vh 0.2vh var(--shadow-color);
  } @else if $dir == right {
    box-shadow: 0.3vh 0vh 0.3vh 0.1vh var(--shadow-color);
  } @else if $dir == down {
    box-shadow: 0vh 0.3vh 0.3vh 0.1vh var(--shadow-color);
  } @else if $dir == left {
    box-shadow: -0.3vh 0vh 0.3vh 0.2vh var(--shadow-color);
  } @else {
    @error "Unknown direction #{$direction}.";
  }
}

@mixin stc-Border(
  $dir: all,
  $borderSize: 0.2vh,
  $borderStyle: solid,
  $borderRadius: false,
  $borderColor: var(--card-color)
) {
  @if $dir == up {
    border-top: $borderSize $borderStyle $borderColor;
  } @else if $dir == right {
    border-right: $borderSize $borderStyle $borderColor;
  } @else if $dir == down {
    border-bottom: $borderSize $borderStyle $borderColor;
  } @else if $dir == left {
    border-left: $borderSize $borderStyle $borderColor;
  } @else if $dir == all {
    border: $borderSize $borderStyle $borderColor;
  } @else {
    @error "Unknown direction #{$direction}.";
  }
  @if $borderRadius==true {
    border-radius: $genBorderRadius;
  }
}

// ============ BADGES =================================
@mixin stc-badge($color) {
  @include horizontalFlex($width: auto, $justify: center);
  color: $color;
  background: rgba($color, 0.3);
  padding: 0.5vh 1vh;
  border-radius: $genBorderRadius;
  overflow: hidden;
}

// ============ SCROLBAR (CSS TRICKS) ==================
@mixin scrollbars(
  $size: 0.2vh,
  $thumb-color: var(--dark-blue),
  $track-color: var(--white-color)
) {
  // For Webkit (Chrome, Safari)
  &::-webkit-scrollbar {
    position: absolute;
    width: $size;
    height: $size; // Add height for the horizontal scrollbar
    border-radius: 5px;
    background: $track-color;
  }

  &::-webkit-scrollbar-thumb {
    width: $size;
    height: $size; // Add height for the horizontal scrollbar
    border-radius: 5px;
    background: $thumb-color;
    // border: 1px solid black;
  }

  &::-webkit-scrollbar-track {
    width: $size;
    height: $size; // Add height for the horizontal scrollbar
    background: $track-color;
    border-radius: 5px;
    border: none;
  }
  @-moz-document url-prefix() {
    & {
      scrollbar-color: $thumb-color $track-color;
      // scrollbar-width: thin;
    }
  }
  // For Firefox

  // For Internet Explorer
  & {
    scrollbar-face-color: $thumb-color;
    scrollbar-track-color: $track-color;
  }
}

@mixin regScrollbars(
  $size: 0.5vh,
  $thumb-color: rgba(241, 247, 249, 0.6),
  $track-color: rgba(117, 116, 116, 0.34)
) {
  &::-webkit-scrollbar {
    position: absolute;
    width: $size;
    border-radius: 5px;
    background: transparent;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
    border: 0.1vh solid $track-color;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: $thumb-color;
  }
}

/* ================ New Font styles mixins ======================= */
@mixin stc-mega-heading-font(
  $bold: normal,
  $fontColor: var(--dark-blue),
  $hoverColor: false
) {
  font-size: var(--xl-font);
  font-weight: $bold;
  color: $fontColor;

  @media screen and (min-width: 0px) and (max-width: 500px) {
    font-size: var(--m-font);
  }

  // @media screen and (min-width: 501px) and (max-width: 1280px) {
  //   font-size: var(--xl-font);
  // }

  @if $hoverColor {
    &:hover {
      color: $hoverColor;
    }
  }
}

@mixin stc-heading-font(
  $bold: normal,
  $fontColor: var(--black-new-color),
  $hoverColor: false
) {
  font-size: var(--ml-font);
  font-weight: $bold;
  color: $fontColor;

  @include media-extrasmall-device() {
    font-size: var(--sm-font);
  }

  @include media-tab-potrait-device() {
    font-size: var(--m-font);
  }

  @if $hoverColor {
    &:hover {
      color: $hoverColor;
    }
  }
}

@mixin stc-sub-heading-font(
  $bold: normal,
  $fontColor: var(--black-new-color),
  $hoverColor: false
) {
  font-size: var(--m-font);
  font-weight: $bold;
  color: $fontColor;

  @include media-extrasmall-device() {
    font-size: var(--s-font);
  }

  @include media-tab-potrait-device() {
    font-size: var(--sm-font);
  }

  @if $hoverColor {
    &:hover {
      color: $hoverColor;
    }
  }
}

@mixin stc-normal-font(
  $bold: normal,
  $fontColor: var(--white),
  $hoverColor: false
) {
  font-size: var(--s-font);
  font-weight: $bold;
  color: $fontColor;

  // @media screen and (min-width: 0px) and (max-width: 800px) {
  //   font-size: var(--s-font);
  // }

  // @media screen and (min-width: 801px) and (max-width: 1280px) {
  //   font-size: var(--s-font);
  // }

  @if $hoverColor {
    &:hover {
      color: $hoverColor;
    }
  }
}

@mixin stc-small-font(
  $bold: normal,
  $fontColor: var(--black-new-color),
  $hoverColor: false
) {
  font-size: var(--xs-font);
  font-weight: $bold;
  color: $fontColor;

  // @media screen and (min-width: 0px) and (max-width: 500px) {
  //   font-size: var(--xs-font);
  // }

  // @media screen and (min-width: 501px) and (max-width: 1280px) {
  //   font-size: var(--xs-font);
  // }

  @if $hoverColor {
    &:hover {
      color: $hoverColor;
    }
  }
}

/* ============ Card Output =============== */
.card-layout {
  position: relative;
  display: block;
  overflow: auto;
  background-color: var(--white);
  height: auto;
  padding: 1vh;
  margin: 1vh;
  outline: none;
  border-radius: $genBorderRadius;
  box-shadow: 0px 0px 4px 2px var(--shadow-color);
}

.neon-highlight {
  border: 1px solid rgba(60, 195, 242, 0.5019607843);
  box-shadow: 0px 0px 3px rgba(60, 195, 242, 0.5019607843);
}

/* ================ Overall input styles ======================= */
@mixin placeholder {
  &::placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

@mixin stc-input(
  $inputBgColor: transparent,
  $inputTextColor: var(--white),
  $borderColor: var(--light-grey, #03080f),
  $width: 100%
) {
  @include stc-normal-font($fontColor: var(--text-color));
  @include horizontalFlex();
  padding: 7px;
  // padding: 1vh;
  border-radius: $genSmBorderRadius;
  border: none;
  outline: none;
  background: $inputBgColor;
  color: $inputTextColor;
  border: 0.5px solid $borderColor;
  width: $width;
  @include placeholder {
    font-style: italic;
    color: var(--border-color);
    font-weight: 100;
  }
}

/* ================ Overall button styles ======================= */
@mixin stc-btn(
  $btnBgColor: var(--light-blue),
  $btnTextColor: var(--white),
  $width: auto,
  $padding: 0.8vh 1.5vh,
  $flexGap: 1vh,
  $minWidth: 5vw
) {
  @include stc-normal-font($fontColor: var(--white));
  @include horizontalFlex($gap-size: $flexGap);
  text-align: center;
  justify-content: center;
  padding: $padding;
  border-radius: $genSmBorderRadius;
  border: none;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  min-width: $minWidth;
  background-color: $btnBgColor;
  color: $btnTextColor;
  width: $width;

  &:hover {
    opacity: 0.8;
  }
}

// +++++++++++++++++++++ glass card mixin +++++++++++++++++++++
@mixin stc-glass-card(
  $inputBgColor: #060e18,
  $inputTextColor: inherit,
  $borderColor: #394d67,
  $width: 100%
) {
  @extend .card-layout;
  //   box-shadow: 0px 0px 20px 0px rgba(17, 41, 73, 0.25);
  border: 1px solid $borderColor;
  background: $inputBgColor;
  color: $inputTextColor;
  width: $width;
}

.glass-card {
  @extend .card-layout;
  padding: 1vh;
  width: 100%;
  color: var(--white, #ffffff);
  background: rgba(241, 247, 249, 0.15);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);

  &:hover {
    color: var(--white, #ffffff);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    background: rgba(241, 247, 249, 0.2);
  }

  &:active {
    color: var(--white, #ffffff);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    /* background: rgba( 241, 247, 249, 0.25 ); */
    background: rgba(9, 21, 38, 0.4);
    /* opacity: 0.8; */
  }
}

// GENERAL INPUT LIB
@mixin lib-input-group($width: 100%, $gap-size: 0.5vh) {
  @include verticalFlex(
    $align: flex-start,
    $gap-size: $gap-size,
    $width: $width
  );
}

@mixin lib-input-label($labelColor: var(--white-color)) {
  @include verticalFlex($align: flex-start);
  color: $labelColor;
}

@mixin lib-input-input(
  $inputBgColor: var(--white-color),
  $inputTextColor: inherit,
  $borderColor: #394d67,
  $width: 100%
) {
  @include stc-normal-font($fontColor: var(--white-color));
  @include horizontalFlex();
  padding: 7px;
  // padding: 1vh;
  border-radius: $genSmBorderRadius;
  border: none;
  outline: none;
  background: $inputBgColor;
  color: $inputTextColor;
  border: 0.5px solid $borderColor;
  width: $width;
  @include placeholder {
    font-style: italic;
    color: white;
    font-weight: 100;
  }
}

@mixin lib-btn(
  $btnBgColor: rgba(10, 24, 42, 1),
  $btnTextColor: var(--white-color),
  $width: auto,
  $padding: 0.8vh 1.5vh,
  $flexGap: 1vh
) {
  @include stc-normal-font($fontColor: var(--white-color));
  @include horizontalFlex($gapSize: $flexGap);
  text-align: center;
  justify-content: center;
  padding: $padding;
  border-radius: $genSmBorderRadius;
  border: none;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  min-width: 5vw;
  background-color: $btnBgColor;
  color: $btnTextColor;
  width: $width;

  // &:hover {
  //   opacity: 0.8;
  // }
}

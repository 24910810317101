.client-dashboard-container {
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: grid;
  grid-template-rows: 60px auto;
  grid-template-columns: 60px 25% auto;
  /* background-image: url("./background/dashboard-bg.png"); */
  /* background-size: cover; */
  background-color: var(--vertical-blue-black-gradient);
  grid-template-areas:
    "sidenav sidebar topnav"
    "sidenav sidebar dashboard";
}

.glass-container {
  color: var(--white, #ffffff);
  background: rgba(241, 247, 249, 0.15);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.glass-container-hover:hover {
  color: var(--white, #ffffff);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  background: rgba(241, 247, 249, 0.2);
}

.glass-container-active {
  color: var(--white, #ffffff);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  /* background: rgba( 241, 247, 249, 0.25 ); */
  background: rgba(9, 21, 38, 0.4);
  /* opacity: 0.8; */
}

.client-input-error {
  font-size: var(--xs-font);
  margin-top: 2px;
  margin-left: 25px;
  width: 90%;
  height: 100%;
  vertical-align: top;
  height: fit-content;
  word-wrap: break-word;
  color: var(--orange, #ea7b2c);
}

.client-dashboard-background-container {
  position: absolute;
  display: block;
  height: 100vh;
  width: 100vw;
  background: var(--vertical-blue-black-gradient);
}

.client-dashboard-background-image {
  position: relative;
  display: block;
  height: 100%;
  width: 100%;
  background-image: url("../../assets/dashboard-bg.png");
  background-size: cover;
  background-color: var(--vertical-blue-black-gradient);
  /* background: rgba(0,0,0,0.5); */
}

.client-dashboard-background-gradient {
  position: relative;
  display: block;
  height: 100%;
  width: 100%;
  /* background: var(--vertical-blue-black-gradient); */
  background: linear-gradient(
    0deg,
    rgb(15 18 25) 0%,
    rgb(25 40 67) 62%,
    rgba(17, 41, 73, 1) 100%
  );
}

/* ========== CLIENT DASHBOARD MODAL WINDOWS =============*/
.client-modal {
  position: absolute;
  display: block;
  width: 100vw;
  height: 100vh;
  z-index: 5;
  background: transparent;
  /* background: rgba(17, 41, 73, 0.7); */
}

/* ============== MODAL ================== */
.client-modal-container {
  position: absolute;
  height: fit-content;
  width: fit-content;
  background: var(--vertical-blue-black-gradient);
  text-align: left;
  padding: 10px;
  left: 50%;
  transform: translateX(-50%);
  font-size: var(--s-font);
}

.client-modal-head {
  display: flex;
  justify-content: space-between;
}

.client-modal-container-head {
  position: relative;
  display: inline-block;
  text-align: center;
  font-size: var(--l-font);
  color: var(--light-blue, #3cc3f2);
}

/* .client-encrypted-btn-container{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  } */

.client-modal-container-close-btn {
  position: relative;
  float: right;
  outline: none;
  font-size: var(--l-font);
  z-index: 1;
  cursor: pointer;
  color: var(--white, #ffffff);
  background: transparent;
  border: none;
  outline: none;
  transition: 100ms;
  color: var(--light-blue, #3cc3f2);
}

.client-modal-container-close-btn:hover {
  transform: scale(1.2);
}

.client-modal-btn {
  position: relative;
  min-width: 150px;
  padding: 4px 10px;
  font-size: var(--s-font);
  color: white;
  background: var(--orange, #ea7b2c);
  border-radius: 5px;
  border: 1px solid var(--orange, #ea7b2c);
  outline: none;
  transition: 0.1s;
  cursor: pointer;
}

.client-modal-btn:hover {
  transform: scale(1.05);
}

/* ================ DASHBOARD ==============*/
.client-file-icon {
  position: relative;
  display: inline-block;
  height: fit-content;
}

.client-file-icon img {
  position: relative;
  width: 100%;
  height: 100%;
}

.client-file-type {
  position: absolute;
  font-size: var(--xs-font);
  right: -5px;
  top: -7px;
  font-weight: bold;
  color: white;
  padding: 4px;
  border-radius: 5px;
  background: var(--orange, #ea7b2c);
}

.client-empty-dashboard {
  position: relative;
  grid-area: dashboard;
  height: calc(100vh-60px);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: auto;
  color: var(--white, #ffffff);
  /* background: var(--vertical-blue-black-gradient); */
  background: transparent;
}

.client-empty-dashbaord-text {
  font-size: var(--m-font);
  font-style: italic;
}

.client-dashboard {
  position: relative;
  grid-area: dashboard;
  display: flex;
  flex-direction: column;
  /* height: calc(100vh-60px); */
  overflow: hidden;
  width: auto;
  /* background: var(--vertical-blue-black-gradient); */
  background: transparent;
}

.client-dashboard-selector {
  position: relative;
  grid-area: top-dashboard-tab-buttons;
  display: flex;
  flex-direction: row;
  column-gap: 30px;
  /* margin-top: 20px; */
  /* padding: 0px 10px; */
  /* -webkit-box-shadow: 0px 1px 4px 1px var(--shadow-color);
    -moz-box-shadow: 0px 1px 4px 1px var(--shadow-color);
    box-shadow: 0px 1px 4px 0px var(--shadow-color); */
}

.client-dashboard-selector-btn {
  position: relative;
  display: flex;
  gap: 0.5vh;
  background: transparent;
  align-items: center;
  padding: 3px 0px;
  font-size: var(--s-font);
  border: none;
  color: var(--light-blue, #9cbdeb);
  outline: none;
}

.client-dashboard-selector-btn:hover {
  color: var(--white, #ffffff);
}

.client-dashboard-selector-btn-active {
  color: var(--white, #ffffff);
}

.client-dashboard-selector-btn-active::after {
  position: absolute;
  bottom: -2px;
  left: 0px;
  content: "";
  height: 5px;
  width: 100%;
  background: var(--white, #ffffff);
}

#client-share-selector-btn {
  /* display: none; */
  margin-left: auto;
  justify-self: flex-end;
  font-size: var(--s-font);
  padding: 0px 3px;
}
#client-share-selector-btn svg {
  font-size: var(--s-font);
}

/* ================== BOTTOM CLIENT DASHBOARD ======= */

.bottom-client-dashboard {
  position: relative;
  width: 100%;
  height: 100%;
  margin-top: 5px;
  overflow-y: auto;
}

/* ================== REVOKED BOTTOM CLIENT DASHBOARD ======= */

.revoked-bottom-client-dashboard {
  position: relative;
  /* width: 100%; */
  height: 100%;
  /* margin-top: 5px; */
  overflow-y: auto;
  margin: 10px;
  border-radius: 10px;
  /* background-color: #091524; */
}

.revoked-bottom-client-dashboard-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  min-height: 50vh;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 1vh;
  /* background-color: #091524; */
}

.revoked-bottom-client-dashboard-container-top {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.revoked-bottom-client-dashboard-container-top-heading {
  color: #ea7b2c;
  font-weight: bold;
  font-size: var(--ml-font);
}

.revoked-bottom-client-dashboard-container-top-img {
  height: 20vh;
  padding: 20px 0;
}

.revoked-bottom-client-dashboard-container-bottom {
  display: flex;
  flex-direction: column;
}
.revoked-bottom-client-dashboard-para {
  color: #ffffff;
  font-size: var(--m-font);
}
.client-revoked-tab-names-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  row-gap: 5px;
  column-gap: 5px;
}

.client-revoke-share-warning-name-item {
  position: relative;
  padding: 5px;
  background-color: var(--label-blue, #9cbdeb);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 5px;
  font-size: var(--s-font);
  width: fit-content;
  flex-wrap: wrap;
  margin-top: 5px;
}

/*=================== DASHBOARD CARDS ================ */
.client-dashboard-card {
  position: relative;
  display: block;
  margin: 10px;
  padding: 10px;
  min-height: 235px;
  color: var(--white, #ffffff);
  /* box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 ); */
  border-radius: 10px;
  /* -webkit-box-shadow: 0px 2px 4px 0px var(--shadow-color);
    -moz-box-shadow: 0px 2px 4px 0px var(--shadow-color);
    box-shadow: 0px 0px 4px 2px var(--shadow-color); */
}

.client-dashboard-card-head {
  font-size: var(--m-font);
  margin: 0px 0px 20px 0px;
}

/* ================== MIDDLE DAHBOARD ================ */

.client-dashboard-block-head {
  color: var(--label-blue, #9cbdeb);
  font-size: var(--s-font);
  margin-bottom: 10px;
}

.client-delivery-notes-content {
  position: relative;
  display: inline-block;
  width: 70%;
  text-align: left;
  font-size: var(--s-font);
  margin: 0px 0px 10px 0px;
}

.client-dashboard-file-block {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: top;
  justify-content: center;
  width: 100%;
  margin: 2px auto 5px auto;
}

.client-dashboard-file-content {
  position: relative;
  display: flex;
  flex-direction: column;
  vertical-align: top;
  width: 50%;
  font-size: 1.2rem;
}

/* .client-dashboard-file-content > div{
    position: relative;
    display: block;
    width: 100%;
  } */

.client-target-files {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  vertical-align: middle;
  margin: 2px 0px 5px 0px;
  column-gap: 20px;
}

.client-target-download {
  position: relative;
  display: inline-block;
  color: var(--orange, #ea7b2c);
  font-size: var(--m-font);
  height: fit-content;
}

.client-file-download-link {
  text-decoration: none;
  color: var(--light-blue, #3cc3f2);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: var(--sm-font);
}

.client-file-download-link:hover {
  color: var(--orange, #ea7b2c);
}

.client-target-file-name {
  position: relative;
  display: inline-block;
  font-size: var(--s-font);
  word-wrap: break-word;
  height: auto;
}

.client-target-file {
  position: relative;
  display: flex;
  flex-direction: row;
  width: max-content;
  column-gap: 10px;
  align-items: center;
}

.client-target-file-logo {
  position: relative;
  float: left;
  width: 28px;
  height: 28px;
}

.client-file-icon {
  position: relative;
  display: inline-block;
}

.client-dashboard-file-right {
  position: relative;
  width: 100%;
  display: block;
}

/* ================== Feedback From ================= */

.client-delivery-details-block {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin: 20px 0px;
}

.client-delivery-details {
  position: relative;
  width: 100%;
  line-break: anywhere;
  font-size: var(--s-font);
}

.client-dashboard-delivery-details {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.client-dashboard-delivery-details p {
  position: relative;
  width: 70%;
  justify-self: start;
  text-align: justify;
  line-break: normal;
  justify-content: right;
  text-align: left;
}

.client-dashboard-delivery-details ul {
  position: relative;
  width: 100%;
  margin-bottom: 10px;
}
.client-dashboard-delivery-details ul li {
  margin: 2px 20px;
  line-break: normal;
}

/* ================== Bottom Dashboard =============== */

.bottom-dashboard {
  position: relative;
  display: block;
  font-size: 1.2rem;
}

.bottom-dashboard-tab {
  border-top: 1px solid var(--light-grey);
  overflow-y: auto;
  height: 590px;
}

.bottom-dashboard-tab-btn {
  border: none;
  outline: none;
  color: var(--dark-grey);
  padding: 5px 20px;
  font-size: 1.2rem;
  background: white;
  cursor: pointer;
  margin-right: 2px;
}

.assignment-id {
  float: right;
  font-size: 1.3rem;
  margin-right: 20px;
}

/* ======================== Archive ========================= */
.client-archive-file-btn {
  font-size: var(--xl-font);
  color: var(--light-grey);
  margin: 5px;
}

@media only screen and (max-width: 1025px) and (min-width: 850px) {
  .client-btns {
    min-width: 80px;
  }

  .client-dashboard-input {
    padding: 5px 4px;
  }

  .client-new-file-btn {
    min-width: auto;
  }

  .client-maintenance-error-msg-big {
    font-size: 36px;
  }

  .client-maintenance-error-msg-small {
    font-size: 18px;
  }

  .client-maintenance-error-img {
    position: absolute;
    height: 40%;
    width: auto;
    right: 30px;
    bottom: 30px;
  }

  /* ========== Dashboard =========== */
  /* .client-label-head{
        min-width: 80px;
    } */

  .bottom-client-dashboard {
    margin-top: 5px;
  }

  .revoked-bottom-client-dashboard {
    margin-top: 5px;
  }

  .revoked-bottom-client-dashboard-container-top-heading {
    font-size: var(--sm-font);
  }

  .revoked-bottom-client-dashboard-para {
    font-size: var(--sm-font);
  }
}

@media only screen and (max-width: 850px) and (min-width: 500px) {
  .client-dashboard-container {
    grid-template-rows: 30px auto;
    grid-template-columns: 30px 25% auto;
  }

  .client-maintenance-error-section {
    width: calc(100% - 30px);
  }

  .client-btns {
    min-width: 80px;
    border-radius: 2px;
  }

  .client-dashboard-input {
    padding: 5px 4px;
  }

  .client-add-btn {
    height: 15px;
    width: 15px;
  }

  .client-new-file-btn {
    min-width: auto;
    border-radius: 2px;
  }

  .client-file-history-head-top {
    height: 30px;
  }

  .client-maintenance-error-msg-big {
    font-size: 36px;
  }

  .client-maintenance-error-msg-small {
    font-size: 18px;
  }

  .client-maintenance-error-img {
    position: absolute;
    height: 40%;
    width: auto;
    right: 30px;
    bottom: 30px;
  }

  /* =========== Sidebar =============== */
  .client-file-stage {
    width: 20px;
    height: 20px;
  }

  /* ========== Dashboard =========== */
  /* .client-label-head{
        min-width: 80px;
    } */

  .client-dashboard-card {
    margin: 5px;
    padding: 5px;
  }

  .client-dashboard-selector {
    column-gap: 10px;
  }

  /* ========== OUTPUT CARD ========= */
  .bottom-client-dashboard {
    margin-top: 5px;
    min-height: 50vh;
  }

  /* ============== REVOKED DASHBOARD ========== */
  .revoked-bottom-client-dashboard {
    margin-top: 5px;
    min-height: 50vh;
  }

  .revoked-bottom-client-dashboard-container-top-heading {
    font-size: var(--sm-font);
  }

  .revoked-bottom-client-dashboard-para {
    font-size: var(--sm-font);
  }

  .client-dashboard-file-decrypt-form {
    min-width: 300px;
  }
}

@media only screen and (max-width: 1460px) {
  /* .client-dashboard-container {
    grid-template-rows: 50px auto;
    grid-template-columns: 50px 25% auto;
  } */

  /* =========== Dashboard ===================== */
  .client-file-logo img {
    height: 38px;
    width: 38px;
  }

  .client-file-logo {
    margin-bottom: 15px;
  }

  .client-dashboard-selector {
    margin-top: 15px;
    column-gap: 20px;
  }

  /* =========== Dashboard Cards =============== */
  .client-dashboard-card {
    margin: 15px;
    min-height: 200px;
  }

  .client-dashboard-card-head {
    margin: 5px 0px 10px 0px;
  }

  .client-dashboard-file-decrypt-btn {
    min-height: 60px;
  }

  /*Key input*/
  .client-target-file-logo {
    height: 25px;
    width: 25px;
  }

  .client-secure-key-btn {
    min-width: 120px;
  }
  /* .decrypt-key-input{
        padding: 4px 6px;
    } */

  /* .client-decrypt-check-mail{
        transform: translateY(50%);
    } */

  .client-decrypt-btn-info {
    left: 5px;
  }

  .decrypt-resend-btn {
    min-width: 120px;
    padding: 0px 5px;
  }

  /* .decrypt-key-error{
        transform: translateY(80%);
    } */
  .client-dashboard-file-decrypt-form {
    min-width: 100%;
  }
}

@media only screen and (max-width: 500px) {
  .client-dashboard-container {
    grid-template-rows: 50px 1fr 50px;
    grid-template-columns: 100vw;
    justify-content: stretch;
    align-content: stretch;
    background: var(--vertical-blue-black-gradient);
    grid-template-areas:
      "topnav"
      "dashboard"
      "sidenav";
  }

  .glass-container-active {
    background: rgba(241, 247, 249, 0.25);
  }

  .client-dashboard-background-image {
    background: var(--vertical-blue-black-gradient);
  }

  /* ========== MODAL ==================== */
  .client-modal-container {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: calc(100% - 20px);
    top: 50%;
    left: 0%;
    margin: 10px;
    transform: translate(0%, -50%);
    font-size: var(--s-font);
  }

  .client-modal-container-head {
    font-size: var(--m-font);
  }

  /* ================== Dashboard ========================== */

  .client-dashboard {
    overflow-y: auto;
  }

  /* .client-top-dashboard-content{
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .client-top-dashboard-part{
        width: 100%;
        row-gap: 10px;
    }

    .client-dashboard-head{
        margin: 10px 0px 0px 0px;
    }

    .client-label-head{
        min-width: auto;
    }

    .client-delivery-details{
        column-gap: 5px;
        justify-content: space-between;
    }

    .client-delivery-details-head{
        margin: 0px;
    } */

  /* ================= BOTTOM Dashbaord ================*/
  .bottom-client-dashboard {
    margin-top: 5px;
    min-height: 50vh;
  }

  /* ================= REVOKED BOTTOM Dashbaord ================*/
  .revoked-bottom-client-dashboard {
    margin-top: 5px;
    min-height: 50vh;
  }

  .revoked-bottom-client-dashboard-container-top-heading {
    font-size: var(--sm-font);
  }

  .revoked-bottom-client-dashboard-para {
    font-size: var(--sm-font);
  }

  /* ================== SELECTOR TABS ================== */
  .client-dashboard-selector {
    column-gap: 20px;
    margin-top: 10px;
  }

  #client-share-selector-btn {
    font-size: var(--s-font);
  }

  /*=================== DASHBOARD CARDS ================ */
  .client-dashboard-card {
    padding: 10px;
    margin: 10px;
  }

  .client-dashboard-card-head {
    font-size: var(--s-font);
    margin: 0px 0px 10px 0px;
  }

  .client-delivery-notes-content {
    width: auto;
    word-wrap: break-word;
    line-break: normal;
    margin: 0px 0px 10px 0px;
  }

  .client-dashboard-file-block {
    flex-direction: column;
    margin: 0px auto;
    row-gap: 10px;
  }

  /* ================== Output Card ====================== */

  .client-dashboard-file-content {
    width: 100%;
    row-gap: 5px;
  }

  .client-target-files {
    justify-content: space-between;
    margin: 0px;
  }

  .client-target-file-name {
    max-width: 60vw;
  }

  .client-delivery-details-block {
    margin: 10px 0px;
    row-gap: 5px;
  }

  /* ================= MODAL ========================== */
  /* .client-encrypted-modal-container{
            width: 80vw;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
        .client-encrypted-modal-container p{
            margin: 10px auto;
        }
        .client-encrypted-btn-container{
            row-gap: 10px;
            flex-direction: column;
        } */
}

/* IPAD media queries stays below evry other query since its very specific */
@media only screen and (min-width: 700px) and (max-width: 780px) and (min-height: 900px) and (max-height: 1100px),
  (min-width: 700px) and (max-width: 1000px) and (min-height: 700px) and (max-height: 1000px),
  (min-width: 1000px) and (max-width: 1100px) and (min-height: 1300px) and (max-height: 1400px),
  (min-width: 500px) and (max-width: 550px) and (min-height: 700px) and (max-height: 730px),
  (min-width: 750px) and (max-width: 880px) and (min-height: 900px) and (max-height: 1200px),
  (min-width: 800px) and (max-width: 1000px) and (min-height: 1300px) and (max-height: 1400px) {
  .client-dashboard-container {
    grid-template-rows: 6vh 1fr 6vh;
    grid-template-columns: 100vw;
    justify-content: stretch;
    align-content: stretch;
    background: var(--vertical-blue-black-gradient);
    grid-template-areas:
      "topnav"
      "dashboard"
      "sidenav";
  }

  .glass-container-active {
    background: rgba(241, 247, 249, 0.25);
  }

  .client-dashboard-input {
    padding: 10px;
    border-radius: 7px;
  }

  .client-btns {
    min-width: 180px;
    padding: 10px 20px;
    border-radius: 7px;
  }

  .client-dashboard-background-image {
    background: var(--vertical-blue-black-gradient);
  }

  /* ========== MODAL ==================== */
  .client-modal-container {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: calc(100% - 20px);
    top: 50%;
    left: 0%;
    margin: 10px;
    transform: translate(0%, -50%);
    font-size: var(--s-font);
  }

  .client-modal-container-head {
    font-size: var(--m-font);
  }

  /* ================== Dashboard ========================== */

  .client-dashboard {
    overflow-y: auto;
  }

  /* ================== SELECTOR TABS ================== */
  .client-dashboard-selector {
    column-gap: 20px;
    margin-top: 15px;
  }

  /*=================== DASHBOARD CARDS ================ */
  .client-dashboard-card {
    padding: 15px;
    margin: 15px;
  }

  .client-dashboard-card-head {
    margin: 0px 0px 15px 0px;
  }

  .client-dashboard-block-head {
    margin-bottom: 15px;
  }

  .client-delivery-notes-content {
    width: auto;
    word-wrap: break-word;
    line-break: normal;
    margin: 0px 0px 15px 0px;
  }

  .client-dashboard-file-block {
    flex-direction: column;
    margin: 0px auto;
    row-gap: 15px;
  }

  /* ================== Output Card ====================== */

  .client-dashboard-file-content {
    width: 100%;
    row-gap: 10px;
  }

  .client-target-files {
    justify-content: space-between;
    margin: 0px;
  }

  .client-target-download svg {
    height: 25px;
    width: 25px;
  }

  .client-delivery-details-block {
    margin: 10px 0px;
  }
}

@media screen and (min-width: 2000px) {
  .client-dashboard-container {
    grid-template-columns: 100px 25% auto;
  }
}

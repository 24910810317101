@import "./../common";

.container_wrapper {
  @include centerFlex();
}

.service_drop_list_main {
  position: absolute;
  top: 3.5rem;
  width: 100vw;
  // left: 0;
  right: 0;
  height: 0px;
  transition: 0.5s all ease;
  z-index: 1;
  padding: 1rem;

  // box-shadow: 0 -5px 5px -5px #00000085;
  @include media-small-device() {
    width: 60vw;
    right: 0;
  }
  @include media-large-device() {
    top: 4rem;
    width: 100vw;
    padding: 0.5rem 2rem;
  }
  @include media-xl() {
    top: 5rem;
    padding: 1rem 2rem;
  }
  @include media-xxl() {
    top: 8rem;
  }
  @media screen and (min-width: 719px) and (max-width: 721px) and (min-height: 539px) and (max-height: 541px) {
    top: -1.5rem;
    right: 1rem;
  }
}

.service_drop_list_main_open {
  height: 91vh;
  transition: 0.5s all ease;
  padding: 1rem;
  @include media-mid-device() {
    height: 77vh;
  }
  @include media-large-device() {
    padding: 0.5rem 2rem;
    height: 89vh;
  }
  @include media-xl() {
    height: 87vh;
    padding: 1rem 2rem;
  }
}

.services_dropdown_container {
  position: relative;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  box-shadow: 0px 0px 30px 0px #000000;
  overflow-y: scroll;
  z-index: -1;
  height: 0;
  transition: 0.5s all ease-in-out;
  &::-webkit-scrollbar {
    display: none;
  }
  @media (min-width: 1020px) and (min-height: 1170px) and (max-height: 1380px) and (orientation: portrait) {
    //ipadpro-portrait
    display: block !important;
  }

  @media screen and (min-width: 710px) and (max-width: 730px) and (orientation: landscape) {
    top: 13vh !important;
    left: 1.3vh;
  }

  @include media-large-device() {
    display: none;
  }
  @media screen and (min-width: 1023px) and (max-width: 1025px) and (min-height: 1365px) and (max-height: 1367px) and (orientation: portrait) {
    display: none !important;
  }
}

.serviceDropdown_RemoveContainer {
  color: var(--white);
  position: fixed;
  opacity: 0.6;
  right: 2.3rem;
  top: 6.5rem;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 5;
  display: none;
  @include media-large-device() {
    display: block;
    top: 5rem;
  }
  @include media-xl() {
    top: 6.4rem;
    right: 2.5rem;
  }
}

.services_dropdown_container_open {
  height: 100%;
  margin: auto;
  margin-top: 0.5rem;
  box-shadow: 0px 0px 30px 0px #000000;
  border: 1px solid #ffffff99;
  border-radius: 12px;
  width: 100%;
  padding-bottom: 1rem;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(12.5px);
  @include media-small-device() {
    // width: 60%;
    // height: 92%;
  }
  @media screen and (min-width: 360px) {
    // padding-bottom: 18vh;
  }
  @media screen and (min-width: 400px) {
    // padding-bottom: 15vh;
  }

  @include media-mid-device() {
    // padding-bottom: 2rem;
  }
  @include media-mobile-landscape() {
    // padding-bottom: 5rem;
  }
}

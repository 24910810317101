@import "../../common";

.uspcard_outerContainer {
  position: relative;
  .uspcard_outerWrapper,
  .hoveruspcard_Container {
    @include verticalFlex($align: flex-start, $width: 100%);
    margin: 0 auto;
    flex-wrap: wrap;
    height: 15rem;
    cursor: pointer;
    padding: 1rem;
    box-shadow: 0px 0px 15px #00000099;
    border-radius: var(--rem-base-font);
    background: transparent linear-gradient(143deg, #112949 0%, #03080f 100%);
    color: var(--white);
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition-duration: 2s;
    transition-property: transform, opacity;
    @include media-small-device() {
    }
    @include media-large-device() {
    }
    @include media-1100p() {
      width: 100%;
    }
  }
  .uspcard_outerWrapper {
    transform: rotateY(0deg);
    // @include media-small-desktop() {
    //   width: 19rem;
    // }
    // @include media-xl() {
    //   width: 21rem;
    // }
  }
  .uspcard_mainContainer {
    @include centerFlex($align: flex-start, $justify: space-between);
  }
  .hoveruspcard_Container {
    position: absolute;
    opacity: 0;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    transform: rotateY(-180deg);
    z-index: -1;
    margin: 0 auto;
    @include media-small-device() {
      z-index: -1;
    }
    @include media-mid-device() {
      z-index: 1;
    }
  }

  &:hover,
  &.flip-vertical {
    transform: none;
  }

  @include media-large-device() {
    &:hover {
      .uspcard_outerWrapper {
        transform: rotateY(180deg);
      }
      .hoveruspcard_Container {
        opacity: 1;
        transform: rotateY(0deg);
      }
    }
    &.flip-vertical {
      .hoveruspcard_Container {
        transform: rotateX(-180deg);
      }
      &:hover {
        .uspcard_outerWrapper {
          transform: rotateX(180deg);
        }
        .hoveruspcard_Container {
          transform: rotateX(0deg);
        }
      }
    }
  }
}

.hoveruspcard_TextWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}
.uspcard_mainTextPara {
  text-align: center;
  font-size: var(--rem-lg-font);
  width: 100%;
  padding: 0 3rem;
  padding-bottom: 1rem;
  @include media-350p() {
    font-size: var(--rem-2xl-font);
  }
  @include media-small-device() {
    font-size: 24px;
  }
  @include media-xl() {
    padding: 0 3.7rem;
    font-size: 28px;
  }

  @media screen and (min-width: 1800px) {
    padding: 0 3.8rem;
    font-size: 28px;
  }
  @include media-xxxl() {
    font-size: var(--rem-4xl-font);
  }
}

.hoverReadMoreText {
  text-decoration: underline;
  font-size: var(--rem-lg-font);
}

.uspcard_backgroundImageWrappr {
  @include centerFlex($position: absolute, $align: flex-start);
  top: -35%;
  z-index: -1;
  img {
    height: var(--rem-9xl-font);
  }
}
.uspcard_PiArrowCircleIcons {
  svg {
    width: 2rem;
    height: 2rem;
  }
}
.hoveruspcard_HeadermainText {
  font-size: var(--rem-2xl-font);
}
.hoveruspcard_mainTextParagraph {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
  font-size: var(--rem-lg-font);
  color: #b4b4b4;
  @include media-xxxl() {
    font-size: var(--rem-xl-font);
  }
}

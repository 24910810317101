@import "../../ToolsModule/general";

.custom_checkbox_wrapper {
  @include horizontalFlex($gap-size: 0.5vh, $width: auto);
  @include stc-normal-font();

  .custom_checkbox_label {
    @include horizontalFlex($gap-size: 0.5vh);

    .custom_checkbox_input {
      display: none;
    }

    .custom_checkbox_box {
      @include verticalFlex($justify: center);
      border: 1px solid white;
      height: 2vh;
      width: 2vh;
      aspect-ratio: 1;
      border-radius: 0.2vh;
      cursor: pointer;

      @include media-350p() {
        width: 1.5vh;
        height: 1.5vh;
      }

      @include media-small-device() {
        height: 1.2vh;
        width: 1.2vh;
      }
    }

    .custom_checkbox_svg {
      @include stc-normal-font($fontColor: var(--white));
      height: fit-content;
    }

    .custom_checkbox_label_text {
      @include horizontalFlex();
    }
  }
}

.custom_checkbox_input:disabled + .custom_checkbox_box {
  border: 1px solid gray;
  cursor: not-allowed;
}

.custom_checkbox_input:disabled + .custom_checkbox_box .custom_checkbox_svg {
  color: gray;
}

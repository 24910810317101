@import "../../../../../general";

.tools_consult_message_feedback_form_wrapper {
  @include verticalFlex();
  background-color: var(--dark-blue-v2);
  padding: 1.5vh 1vh 1vh 1vh;
  border-radius: 8px;

  .tools_consult_message_feedback_form_header_wrapper {
    @include horizontalFlex();

    .tools_consult_message_feedback_form_close_btn {
      border: none;
      background: none;
      @include centerFlex();
    }
    .tools_consult_message_feedback_form_heading_text {
      @include stc-normal-font();
    }
  }
  .tools_consult_message_feedback_form_btn_wrapper {
    @include horizontalFlex($justify: flex-start, $gap-size: 0.5vh);
    flex-wrap: wrap;

    .tools_consult_message_feedback_form_btn {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.5vh;
      border: 1px solid #394d67;
      @include stc-normal-font($fontColor: #a6bedd);
      border-radius: 4px;
      background: none;
    }

    .tools_consult_message_feedback_form_active_btn {
      background-color: #004292;
      color: var(--white);
      border: 1px solid transparent;
    }
  }
  .tools_consult_message_feedback_form_input_container {
    @include verticalFlex($gap-size: 0.5vh);

    .tools_consult_message_feedback_form_input_wrapper {
      @include horizontalFlex();
      border: 1px solid #0d2a4e;
      background: #041933;
      padding: 1.5vh 1vh;
      border-radius: 4px;

      .tools_consult_message_feedback_form_input {
        width: 100%;
        background: none;
        border: none;
        @include stc-normal-font($fontColor: var(--white));
        outline: none;
      }

      .tools_consult_message_feedback_form_btn {
        @include centerFlex();
        background: none;
        border: none;
        font-size: var(--l-font);
      }
    }
  }
}

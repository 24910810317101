@mixin verticalFlex(
  $position: relative,
  $gap: 0vh,
  $justify: space-between,
  $align: center,
  $width: 100%,
  $fontSize: var(--m-font)
) {
  position: $position;
  display: flex;
  flex-direction: column;
  gap: $gap;
  align-items: $align;
  justify-content: $justify;
  width: $width;
  font-size: $fontSize;
}
@mixin centerFlex($position: relative, $justify: center, $align: center, $width: 100%) {
  position: $position;
  display: flex;
  align-items: $align;
  justify-content: $justify;
  width: $width;
}

@mixin horizontalFlex(
  $position: relative,
  $gap: 0vh,
  $justify: space-between,
  $align: center,
  $width: 100%,
  $fontSize: 2vh
) {
  position: $position;
  display: flex;
  flex-direction: row;
  gap: $gap;
  align-items: $align;
  justify-content: $justify;
  width: $width;
  font-size: $fontSize;
}

/////////////////TEST MEDIA QUERIES////////////////////////////

@mixin media-extrasmall-device {
  @media screen and (min-width: 0px) and (max-width: 639px) {
    @content;
  }
}

@mixin media-350p {
  @media screen and (min-width: 350px) {
    @content;
  }
}

@mixin media-small-device {
  @media screen and (min-width: 501px) {
    @content;
  }
}

@mixin media-mid-device {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin media-large-device {
  @media screen and (min-width: 1023px) {
    @content;
  }
}

@mixin media-1100p {
  @media screen and (min-width: 1100px) {
    @content;
  }
}

@mixin media-small-desktop {
  @media screen and (min-width: 1200px) {
    @content;
  }
}

@mixin media-xl {
  @media screen and (min-width: 1500px) {
    @content;
  }
}

@mixin media-xxl {
  @media screen and (min-width: 2200px) {
    @content;
  }
}

@mixin media-xxxl {
  @media screen and (min-width: 3000px) {
    @content;
  }
}

//////////////////////////// PRIMARY MEDIA QUERIES //////////////////////////

// mobile device
@mixin media-sm {
  @media screen and (min-width: 200px) and (max-width: 500px) and (orientation: portrait) {
    @content;
  }
}

// media mobile landscape

@mixin media-mobile-landscape {
  @media screen and (min-width: 500px) and (max-width: 932px) and (orientation: landscape) {
    @content;
  }
}
//Tablet collective
@mixin media-tablet {
  @media (min-width: 701px) and (max-width: 1024px) and (orientation: portrait) {
    @content;
  }
}
@mixin media-700p {
  @media (min-width: 701px) and (max-width: 800px) and (orientation: portrait) {
    @content;
  }
}
@mixin media-800p {
  @media (min-width: 801px) and (max-width: 900px) and (orientation: portrait) {
    @content;
  }
}

@mixin media-900p {
  @media (min-width: 901px) and (max-width: 1024px) and (orientation: portrait) {
    @content;
  }
}
@mixin media-tablet-1000p {
  @media (min-width: 1000px) and (max-width: 1024px) and (min-height: 1300px) and (max-height: 1380px) and (orientation: portrait) {
    @content;
  }
}

// MID DEVICE
@mixin media-tab-potrait-device {
  @media only screen and (min-width: 700px) and (max-width: 780px) and (min-height: 900px) and (max-height: 1100px),
    (min-width: 700px) and (max-width: 1000px) and (min-height: 700px) and (max-height: 1000px),
    (min-width: 1000px) and (max-width: 1100px) and (min-height: 1300px) and (max-height: 1400px),
    (min-width: 500px) and (max-width: 550px) and (min-height: 700px) and (max-height: 730px),
    (min-width: 750px) and (max-width: 880px) and (min-height: 900px) and (max-height: 1200px),
    (min-width: 800px) and (max-width: 1000px) and (min-height: 1100px) and (max-height: 1400px),
    (min-width: 500px) and (max-width: 580px) and (min-height: 700px) and (max-height: 750px) {
    @content;
  }
}

@mixin media-tab-landscape-device {
  @media only screen and (min-height: 700px) and (max-height: 780px) and (min-width: 900px) and (max-width: 1100px),
    (min-height: 1000px) and (max-height: 1100px) and (min-width: 1300px) and (max-width: 1400px),
    (min-height: 500px) and (max-height: 550px) and (min-width: 700px) and (max-width: 730px),
    (min-height: 750px) and (max-height: 880px) and (min-width: 900px) and (max-width: 1200px),
    (min-height: 800px) and (max-height: 1000px) and (min-width: 1100px) and (max-width: 1400px),
    (min-height: 500px) and (max-height: 580px) and (min-width: 700px) and (max-width: 750px) {
    @content;
  }
}

@mixin media-surface-duo-landscape-device {
  @media only screen and (min-height: 500px) and (max-height: 580px) and (min-width: 700px) and (max-width: 750px) {
    @content;
  }
}

@mixin media-surface-duo-potrait-device {
  @media only screen and (min-width: 500px) and (max-width: 580px) and (min-height: 700px) and (max-height: 750px) {
    @content;
  }
}
@mixin media-surface-pro-landscape-device {
  @media only screen and (min-height: 800px) and (max-height: 1000px) and (min-width: 1100px) and (max-width: 1400px) {
    @content;
  }
}

@mixin media-surface-pro-potrait-device {
  @media only screen and (min-width: 800px) and (max-width: 1000px) and (min-height: 1100px) and (max-height: 1400px) {
    @content;
  }
}

@mixin media-md {
  @media screen and (min-width: 501px) and (max-width: 699px) and (orientation: portrait) {
    @content;
  }
}

@mixin media-touch-device {
  @media screen and (min-width: 0px) and (max-width: 1024px) and (orientation: portrait) {
    @content;
  }
}

@mixin media-sm-landscape-500p {
  @media screen and (min-width: 500px) and (max-width: 700px) and (orientation: landscape) {
    @content;
  }
}

@mixin media-sm-landscape-700p {
  @media screen and (min-width: 701px) and (max-width: 932px) and (orientation: landscape) {
    @content;
  }
}

@mixin media-sm-landscape-900p {
  @media screen and (min-width: 900px) and (max-width: 932px) and (orientation: landscape) {
    @content;
  }
}
@mixin media-ipad-pro-landscape {
  @media screen and (min-width: 1360px) and (max-width: 1370px) and (min-height: 1020px) and (max-height: 1030px) {
    @content;
  }
}
@mixin media-special-tab-device {
  @media screen and (min-width: 1025px) and (max-width: 1060px) and (min-height: 1420px) and (max-height: 1460px) {
    @content;
  }
}

@mixin media-md {
  @media screen and (min-width: 501px) and (max-width: 700px) and (orientation: portrait) {
    @content;
  }
}

@mixin media-lg {
  @media screen and (min-width: 1200px) {
    @content;
  }
}

@mixin media-vl {
  @media screen and (min-width: 1900px) {
    @content;
  }
}

@mixin media-sm-large {
  @media screen and (min-width: 280px) and (max-width: 769px) {
    @content;
  }
}

@mixin media-square-device {
  @media screen and (min-width: 650px) and (max-width: 800px) and (min-height: 650px) and (max-height: 800px) {
    @content;
  }
}

// media desktop queries
@mixin media-1000p {
  @media screen and (min-width: 1000px) and (max-width: 1199px) and (min-height: 600px) and (max-height: 900px) {
    @content;
  }
}

@mixin media-1200p {
  @media screen and (min-width: 1200px) and (max-width: 1400px) {
    @content;
  }
}

@mixin media-1200p-height {
  @media screen and (min-width: 1200px) and (max-width: 1400px) and (min-height: 900px) and (max-height: 950px) {
    @content;
  }
}

@mixin media-1400p {
  @media screen and (min-width: 1401px) and (max-width: 1800px) {
    @content;
  }
}

@mixin media-1600p {
  @media screen and (min-width: 1600px) and (max-width: 1800px) {
    @content;
  }
}

@mixin media-1900p {
  @media screen and (min-width: 1801px) and (max-width: 2000px) {
    @content;
  }
}

@mixin media-1800p {
  @media screen and (min-width: 1801px) and (orientation: portrait) {
    @content;
  }
}

@mixin media-1440kp {
  @media screen and (min-width: 2000px) and (max-width: 3000px) {
    @content;
  }
}

@mixin media-8kp {
  @media screen and (min-width: 3000px) and (max-width: 4000px) {
    @content;
  }
}

// media mobile category queries

@mixin media-smaller-device {
  @media (min-width: 200px) and (max-width: 300px) and (min-height: 500px) and (max-height: 700px) {
    @content;
  }
}
@mixin media-200p {
  @media (min-width: 200px) and (max-width: 375px) and (min-height: 500px) and (max-height: 700px) {
    @content;
  }
}

@mixin media-400p {
  @media (min-width: 360px) and (max-width: 600px) and (min-height: 701px) and (max-height: 935px) {
    @content;
  }
}

// mid devices media queries

// rarely used media tablet queries

@mixin media-md-720p {
  @media screen and (min-width: 501px) and (max-width: 700px) and (min-height: 721px) and (max-height: 1024px) and (orientation: portrait) {
    @content;
  }
}
@mixin media-1020p {
  @media (min-width: 1000px) and (max-width: 1024px) and (orientation: portrait) {
    @content;
  }
}

@mixin media-920p {
  @media (min-width: 901px) and (max-width: 950px) and (orientation: portrait) {
    @content;
  }
}

@mixin media-nest-hub {
  @media (width: 1024px) and (height: 600px) {
    @content;
  }
}

@mixin media-nest-hub-max {
  @media (width: 1280px) and (height: 800px) {
    @content;
  }
}

//tablet landscape rarely used
@mixin media-1000p-landscape {
  @media (min-width: 1000px) and (max-width: 1100px) and (orientation: landscape) {
    @content;
  }
}
@mixin media-1100p-landscape {
  @media (min-width: 1101px) and (max-width: 1200px) and (orientation: landscape) {
    @content;
  }
}

@mixin media-1200p-landscape {
  @media (min-width: 1201px) and (max-width: 1400px) and (orientation: landscape) {
    @content;
  }
}

// general styles
.website_btn {
  @include verticalFlex($width: auto);
  padding: 1.5vh 3.5vh;
  border-radius: 4px;
  background-color: var(--light-blue);
  border: none;
  outline: none;
  color: var(--white);
  font-size: var(--m-font);
  // transition: 500ms;
  @include media-sm() {
    padding: 0.5rem;
  }
  @include media-sm-landscape-500p() {
    font-size: 12px !important;
    padding: 0.5rem 1rem;
  }
  @include media-sm-landscape-700p() {
    font-size: 16px !important;
    padding: 0.5rem 1rem;
  }
  @include media-nest-hub() {
    font-size: var(--l-font);
  }
}

.website_input {
  @include verticalFlex($width: 100%);
  border: none;
  outline: none;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0px 4.37719px 17.5088px rgba(0, 0, 0, 0.12);
  color: var(--white);
  font-size: var(--m-font);
  padding: 1.5vh 1vh;
  height: auto;
  box-sizing: border-box;
  margin: 0;
  height: auto;
  border-radius: 4px;

  &::placeholder {
    color: var(--white);
  }
}

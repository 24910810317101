@import "../../common";

.service_dropdown {
  @include horizontalFlex(
    $justify: flex-start,
    $align: flex-start,
    $width: 100%
  );
  box-shadow: 0px 0px 30px 0px #000000;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(12.5px);
  right: 0;
  border-radius: 12px;
  margin: auto;
  height: 100%;
  transition: width 0.3s ease-out;
  z-index: 2;
  overflow-x: scroll;

  @media (max-width: 1023px) {
    display: none;
  }

  @media (min-width: 1020px) and (min-height: 1170px) and (max-height: 1380px) and (orientation: portrait) {
    display: none;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  @media (min-width: 1400px) and (max-width: 1500px) {
    margin-right: 4.5vw;
  }
  @media screen and (min-width: 1023px) and (max-width: 1025px) and (min-height: 1365px) and (max-height: 1367px) and (orientation: portrait) {
    display: block;
  }
}

.service_dropdown_specialborder {
  border: 1px solid rgba(255, 255, 255, 0.6);
}

.service_dropdown_inner_wrapper {
  @include horizontalFlex($justify: flex-start);
  height: 100%;
  border: none;
  @include media-xl() {
    justify-content: center;
  }
}

.width_and_height_toggle {
  right: 36vh;
  background: none;
  overflow: hidden;
}

.service_dropdown_toggle {
  @include horizontalFlex($justify: center, $width: auto);
  position: absolute;
  right: 0%;
  position: absolute;
  background: transparent linear-gradient(180deg, #112949 0%, #03080f 100%);
  box-shadow: 0px 0px 10px #3d3d3d;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
  height: 100%;
}

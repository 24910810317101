@import "../../../general";

.select-form-group {
  @include lib-input-group();

  .select-form-label {
    @include lib-input-label();
  }

  .select-form-select {
    width: 100%;
  }
  .select__control {
    width: 100%;
    background: rgba(10, 24, 42, 1);
  }
  .select-form-input {
    @include lib-input-input();
  }
  .css-1jqq78o-placeholder {
    font-style: italic !important;
  }
  .select-form-value {
    @include lib-input-input();
    background: transparent;
    border: none;
    align-items: flex-start;
  }
}
.css-h8li20-singleValue {
  background-color: rgba(10, 24, 42, 1) !important;
  border: 1px solid rgba(57, 77, 103, 1) !important; //react-select overwrite
}
.css-upfpuq-control {
  min-height: 36px !important;
  border: 1px solid rgba(57, 77, 103, 1) !important; //react-select overwrite
}
.css-15od0hi-control {
  min-height: 36px !important;
}

.css-le4ta2p-control:hover {
  border-color: none !important;
}

.css-xdo9mr-MultiValueRemove:hover {
  background-color: rgba(10, 24, 42, 1) !important;
}

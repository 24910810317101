@import "../../../../../general";

.tools_consult_regenerate_message {
  @include verticalFlex($gap-size: 2vh);
  padding-top: 5vh;
  padding-bottom: 2vh;
}

.tools_consult_regenerate_message_text {
  @include stc-normal-font($fontColor: var(--white));
}

.tools_consult_regenerate_message_btn {
  @include horizontalFlex($width: auto);
  background-color: #004292;
  @include stc-normal-font($fontColor: var(--white));
  border: none;
  padding: 1vh;
  border-radius: 4px;
}

.tools_consult_regenerate_message_btn_icon {
  font-size: 1.2rem;
}

@import "../../../common";

.carousel_wrapper {
  z-index: 5;
  height: 500px;
  width: 35%;

  @media screen and (min-width: 300px) {
    height: 400px;
  }

  @media screen and (min-width: 360px) {
    width: 25%;
    height: 400px;
  }

  @include media-small-device() {
    height: 500px;
  }
  @include media-xxxl() {
    height: 700px;
  }
}

@import "../../../../general";

.tools_consult_file_upload_wrapper {
  @include verticalFlex($gap-size: 2vh);
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 1vh;
  padding-top: 0vh;
  overflow: hidden;

  @include media-small-device() {
    width: 70%;
  }

  @include media-large-device() {
    width: 100%;
    padding: 1.5vh;
    padding-top: 0vh;
  }
  // margin-top: 3vh;
  // display: grid;
  // grid-template-columns: 1fr;
  // grid-template-rows: 1fr 1fr;
  // width: 100%;
  // height: 100%;
  // gap: 3vh;
}
.tools_consult_flip_upload_container_box {
  @include horizontalFlex();
  padding: 2vh 1vh;
  border-radius: 12px;
  box-shadow: 0px 0px 20px 0px #11294940;
  border: 2px dashed var(--white);
  stroke: var(--white);
  background: #344155;
  width: 100%;
  height: 100%;

  @include media-large-device() {
    padding: 5vh 1vh 2vh 1vh;
    height: auto;
  }
}

.disable_upload_filepane_section {
  opacity: 0.5;
  cursor: not-allowed;
}

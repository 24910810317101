@import "../common";
.navbar_main_Container {
  width: 100%;
  height: 100%;
}

.navbarMainContainerTransition {
  transition: all 0.5s ease 1.5s;
}

.show_nav {
  top: 0px;
  transition: all 400ms ease 0s;
}

.hidden_nav {
  top: -200px;
  transition: all 400ms ease 0s;
}

.navbar_hideContainer {
  display: none;
}
.navbar_showContainer {
  display: block;
}

.navbar_Container {
  @include horizontalFlex($position: fixed);
  z-index: 10;
  height: 4rem;
  background: radial-gradient(
    circle,
    rgba(17, 41, 73, 1) 19%,
    rgba(7, 20, 37, 1) 89%,
    rgba(3, 8, 15, 1) 100%
  );
  box-shadow: 0px 10px 40px #000000;
  padding: 8px 1rem;
  @include media-small-device() {
    padding: 8px 2rem;
  }
  @include media-large-device() {
    padding: 0 3rem;
    height: 55px;
  }
  @include media-xl() {
    height: 5rem;
  }
  @include media-xxl() {
    height: 8rem;
  }
  @include media-xxxl() {
    height: 10rem;
  }
}

.navbar_InputFieldDropDown_Container {
  @include centerFlex($justify: flex-end, $width: 100%);
  gap: 15px;
  padding-right: 5px;
  @include media-large-device() {
    justify-content: center;
    gap: 1rem;
    padding-right: 0;
  }
}
.navbar_inputFieldWrapper {
  @include centerFlex($width: auto);
  height: 40px;
  background: radial-gradient(at center top, #4c4b4b, #000000);
  border: 1px solid #a8a6a6;
  border-radius: 8px;
  padding: 0 5px;
  color: white;
  transition: all 0.3s ease;
  @include media-large-device() {
    width: 52%;
    height: 50px;
    padding: 0 1rem;
  }
  @include media-small-desktop() {
    width: 70%;
    height: 50px;
  }
  @include media-xl() {
    width: 85%;
    height: 50px;
  }
  @include media-xxl() {
    height: 5rem;
  }
}
.navbar_inputFieldMobileWrapper {
  @include centerFlex($width: auto);
  height: 40px;
  background: transparent;
  border-radius: 8px;
  padding: 6px 5px;
  color: white;
  transition: all 0.3s ease;

  @include media-large-device() {
    border: 1px solid #ffffff9d;
    width: 100%;
    height: 40px;
    padding: 0 10px;
    opacity: 0.8;
    background: radial-gradient(at center top, #3e3e3e, #222222);
  }
  @include media-xl() {
    width: 90%;
    height: 50px;
  }
  @include media-xxl() {
    width: 85%;
  }

  &:active {
    background: radial-gradient(at center top, #4c4b4b, #000000);
    opacity: 1;
  }
  &:hover {
    @include media-large-device() {
      background: radial-gradient(at center top, #4c4b4b, #000000);
    }
    opacity: 1;
  }
}
.navbar_activeInputFields {
  background: radial-gradient(at center top, #4c4b4b, #000000);
  opacity: 1;
}
.navbar_SearchinputField {
  opacity: 1;
}
.navbar_inputFieldMobileWrapper:hover {
  @include media-large-device() {
    .inputfield_searchIcon {
      color: var(--light-blue);
    }
  }
}
.navbar_inputFieldWrapper:hover {
  .inputfield_searchIcon {
    color: var(--light-blue);
  }
}
.nav_iconsearchactive {
  color: var(--light-blue);
}

.Navbar_searchSuggestion_TextHeadings {
  width: 200px;
  display: -webkit-box;
  cursor: pointer;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  font-size: var(--rem-sm-font);
  @include media-350p() {
    font-size: var(--rem-base-font);
  }
  @include media-small-device() {
    width: 350px;
  }
  @include media-small-desktop() {
    font-size: var(--rem-lg-font);
  }

  @include media-xxl() {
    font-size: var(--rem-2xl-font);
  }
}
.navbarDropdown_searchArticleList {
  position: fixed;
  top: 3.8rem;
  left: 0;
  right: 0;
  width: 95%;
  border-radius: 8px;
  background: white;
  padding: 5px 5px 10px 5px;
  line-height: normal;
  box-shadow: 0px 0px 30px #00000040;
  z-index: 21;
  margin: 0 auto;
  @include media-mid-device() {
    width: 100%;
    position: absolute;
    top: 3.5rem;
    padding: 10px 10px 1rem 10px;
  }
  @include media-xxl() {
    top: 5.5rem;
  }
}

.navbarDropdown_empty_searchArticleList {
  @extend .navbarDropdown_searchArticleList;
  padding: 5px 5px 5px 5px;

  @include media-mid-device() {
    padding: 10px;
  }
}
.navbarDropdown_SearchArticleBox {
  color: black;
}
.navbar_allSearchArticlesContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: auto;
  min-height: auto;
  max-height: 15rem;

  @include media-xxl() {
    max-height: 20rem;
  }
}
.Navbar_searchSuggestion_Headingwrapper {
  @include centerFlex($width: 100%, $justify: flex-start);
  gap: 10px;
  cursor: pointer;
  padding: 0 10px;
}
.navbar_SearchMoreArticles {
  text-align: center;
  width: 100%;
  padding-top: 1rem;
  cursor: pointer;
  color: var(--light-blue);
  border-top: 1px solid #bebebe;
  font-size: var(--rem-sm-font);
  @include media-350p() {
    font-size: var(--rem-base-font);
  }
  @include media-small-desktop() {
    font-size: var(--rem-lg-font);
  }

  @include media-xxl() {
    font-size: var(--rem-2xl-font);
  }
}

.navbar_empty_SearchMoreArticles {
  text-align: center;
  width: 100%;
  padding: 1rem;
  cursor: pointer;
  color: var(--light-blue);
  font-size: var(--m-font);
}

.navbar_empty_allSearchArticlesContainer {
  height: auto;
}
.search_suggestionItems_wrapper {
  @include centerFlex($justify: flex-start);
  padding-right: 10px;
}
.navbar_DropDownVideoImg_Container {
  @include centerFlex($align: flex-start, $justify: flex-start, $width: auto);
  height: 100%;
}
.search_suggestion_item_nav {
  @include centerFlex($align: flex-start, $justify: space-between);
  border-bottom: 1px solid #bebebe;
  padding: 1rem 5px;
  cursor: pointer;
}
.search_suggestion_item_nav:hover {
  background: #cfd4db80;
}
.search_suggestionImage_navbar {
  width: 5rem;
  height: var(--rem-5xl-font);
}

.navbar_logoContainer {
  // cursor: pointer;
  width: auto;
  padding-right: 10px;
  @include media-large-device() {
    padding-right: 0;
    width: 50%;
  }
  @include media-small-desktop() {
    width: 100%;
  }
  // img {
  //   aspect-ratio: 1/1;
  //   height: 5vh;
  //   width: 5vh;
  //   @include media-large-device() {
  //     height: 40px;
  //     width: 40px;
  //   }
  //   @include media-xl() {
  //     height: 50px;
  //     width: 50px;
  //   }
  // }
}
.inputField_Container {
  outline: none;
  border: none;
  background: transparent;
  height: 100%;
  color: white;
  opacity: 0.8;
  width: 0;
  padding-right: 0;
  font-size: var(--rem-lg-font);
  @include media-large-device() {
    padding-right: 1rem;
    width: 100%;
    display: block;
  }
  @include media-xxl() {
    font-size: var(--rem-3xl-font);
  }
}
.inputField_Container:hover {
  opacity: 1;
}

.inputFieldMobile_Container {
  display: block;
  width: 90%;
  height: 100%;
  background: transparent;
  outline: none;
  border: none;
  color: white;
  padding-right: 0.5rem;
  opacity: 0.9;
  transition: all 0.3s ease;
  font-size: var(--rem-sm-font);
  @include media-350p() {
    font-size: var(--rem-lg-font);
  }
  @include media-small-device() {
    width: 42vw;
    font-size: 1rem;
  }
  @include media-large-device() {
    width: 100%;
  }
}
.inputfield_searchIcon {
  font-size: 2rem;
  transition: color 0.3s ease;
  cursor: pointer;

  @include media-large-device() {
    font-size: 2rem;
    // pointer-events: none;
  }
}

.navbar_menuGrid_container {
  @include centerFlex($width: auto);
  border-radius: 5px;
  transition: outline 0.3s ease;
  padding: 3px 2px;
  @include media-small-desktop() {
    width: var(--rem-5xl-font);
    height: var(--rem-5xl-font);
    border-radius: 8px;
    outline: none;
    padding: 0;
  }

  @include media-xxl() {
    width: var(--rem-7xl-font);
    height: var(--rem-7xl-font);
  }
}
.navbar_menuGrid_container:hover {
  border-radius: 8px;
}
.menuGrid_icon {
  color: white;
  cursor: pointer;
  font-size: 2rem;
  @include media-large-device() {
    font-size: 2.5rem;
  }
  @include media-1100p() {
    font-size: 3.5rem;
  }

  @include media-xxl() {
    font-size: var(--rem-7xl-font);
  }
}
.navbar_logoSection {
  height: 40px;
  cursor: pointer;
  @include media-large-device() {
    height: 40px;
  }
  @include media-xl() {
    height: 50px;
  }
}
.menuGridIconBg {
  background: white;
  width: 2rem;
  height: 2rem;
  color: black;
  border-radius: 5px;
  font-size: 2rem;
  @include media-large-device() {
    font-size: 2rem;
    border-radius: 5px;
    width: 3rem;
    height: 3rem;
  }
}
.navbar_allIcons_Container {
  width: 100%;
  display: none;
  align-items: center;
  justify-content: flex-end;
  gap: 2rem;
  @include media-large-device() {
    display: flex;
    gap: 5px;
  }
  @include media-small-desktop() {
    gap: 1rem;
  }
}

.nav_menubaricons_container {
  display: block;
  @include media-large-device() {
    display: none;
  }
}
.navmenubar_dropdown_Container {
  position: absolute;
  top: 5rem;
  left: 0;
  right: 0;
  width: 95%;
  height: 12rem;
  background: white;
  transition: height 0.3s ease;
  border: 1px solid #ffffff99;
  margin: auto;
  border-radius: 12px;
  background: #000000e0;
  z-index: 20;
  box-shadow: 0px 0px 30px 0px #000000;
  backdrop-filter: blur(5px);
  padding: 10px 0;
  @include media-small-device() {
    right: 1rem;
    width: 55%;
    left: auto;
  }
  @include media-large-device() {
    display: none;
  }
}
.navmenubar_dropdown_hide_Container {
  height: 0;
  width: 95%;
  padding: 0;
  border: none;
  @include media-small-device() {
    width: 0%;
  }
}
.nav_mobiledeviceIcons_Container {
  @include verticalFlex($align: flex-start);
  height: 100%;
}
.navmenu_allIcons_wrapper {
  @include centerFlex($justify: space-between);
  padding: 0 10px;
  &:active {
    background-color: rgba(90, 90, 90, 0.411);
  }
}
.navbar_secureTranserIcon_Container {
  width: auto;
  padding: 0 5px;
  transition: outline 0.3s ease;
  border-radius: 5px;
  cursor: pointer;
  @include media-1100p() {
    font-size: 1rem;
    padding: 0 5px;
  }
}

.nav_menusicons_wrapper {
  color: var(--white);
  @include horizontalFlex($justify: center);
  font-size: 2rem;
  @include media-small-device() {
    font-size: var(--rem-4xl-font);
  }
}

.navbar_display_hover {
  &:hover {
    outline: 1px solid #ffffff;
  }
}
.navbar_display_nonhover {
  outline: 1px solid #ffffff;
}
.navmenu_iconsfontsize {
  width: 6rem;
  height: 2.5rem;
  @include media-large-device() {
    width: 5rem;
    height: 40px;
  }
  @include media-xl() {
    width: 8rem;
    height: 50px;
  }
}
.navmenu_icons70fontsize {
  width: 7.5rem;
  height: 2.5rem;
  @include media-large-device() {
    width: 7rem;
    height: 40px;
  }
  @include media-xl() {
    width: 10rem;
    height: 50px;
  }
}
.securetransfer_icon {
  font-size: 1.5rem;
  color: white;
  cursor: pointer;
  @include media-large-device() {
    font-size: 2rem;
  }

  @include media-xxl() {
    font-size: 3.5rem;
  }
}
.navbar_Scrolling_Wrapper {
  display: none;
  @include media-large-device() {
    display: flex;
    pointer-events: none;
    align-items: center;
    position: absolute;
    left: 14px;
    height: var(--rem-5xl-font);
    span {
      opacity: 0.8;
    }
  }
  span {
    font-size: var(--rem-lg-font);

    @include media-xxl() {
      font-size: var(--rem-3xl-font);
    }
  }
}
.ScrollPlaceholder_Container {
  height: 100%;
  margin: auto;
  overflow: hidden;
  @include media-large-device() {
    height: 40px;
  }
  @include media-xl() {
    height: 100%;
  }
  ul {
    margin: 0 0.5rem;
    padding: 0;
    animation: scrollUp 20s infinite;
    li {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 3.2rem;
      list-style: none;
      font-size: var(--rem-lg-font);
      opacity: 0.8;
      line-height: normal;
      @include media-large-device() {
        height: 2.5rem;
      }
      @include media-xl() {
        height: 48px;
      }

      @include media-xxl() {
        font-size: var(--rem-3xl-font);
        height: 48px;
      }
    }
  }
}

// $item-count: 11;

// @keyframes scrollUp {
//   @for $i from 0 through ($item-count - 1) {
//     $percentage: ($i * (100% / $item-count));
//     #{($percentage)},
//     #{($percentage + (100% / $item-count))} {
//       transform: translateY(-#{$i * (100% / $item-count)});
//     }
//   }
// }

$item-count: 11;

@keyframes scrollUp {
  @for $i from 1 through ($item-count - 1) {
    #{($i * 10) - 2%},
    #{$i * 10%} {
      transform: translateY((-100% / $item-count) * $i);
    }
  }
}

@import "../../general";

.tools_file_share_container {
  @include verticalFlex($justify: flex-start);
  @include stc-glass-card();
  @include stc-normal-font();
  overflow-x: hidden;
  margin: 0;
  height: calc(100% - 25vh - 1vh);
  padding-bottom: 0;
  border-bottom: 0px;
  border-radius: $genBorderRadius $genBorderRadius 0 0;

  @include media-potrait() {
    // @include verticalFlex();
    overflow-y: auto;
    // height: 58vh;
  }
}

/* ========== TOPNAV CSS ===============*/

.client-topnav {
  position: relative;
  grid-area: topnav;
  background: var(--horizontal-blue-black-gradient);
  display: flex;
  justify-content: center;
  vertical-align: middle;
  align-content: center;
  align-items: center;
  flex-direction: row;
  box-sizing: border-box;
  -webkit-box-shadow: 0px 2px 4px 0px var(--dark-shadow-color);
  -moz-box-shadow: 0px 2px 4px 0px var(--dark-shadow-color);
  box-shadow: 0px 2px 4px 0px var(--dark-shadow-color);
  z-index: 5;
}

.client-ez-logo-topnav {
  display: none;
}

.client-user {
  position: absolute;
  display: flex;
  justify-content: left;
  vertical-align: middle;
  align-content: center;
  flex-direction: row;
  color: white;
  height: 50px;
  margin: 5px 10px 10px auto;
  min-width: 150px;
  border-radius: 8px;
  right: 10px;
  padding: 5px 10px;
  cursor: pointer;
  border: 1px solid transparent;
  transition: 200ms;
}

/* .client-user:hover{
    background: rgba( 241, 247, 249, 0.20 );
    backdrop-filter: blur( 10.0px );
    -webkit-backdrop-filter: blur( 10.0px );
  } */

.client-user-img {
  position: relative;
  display: inline-block;
  height: 40px;
  width: 40px;
  top: 50%;
  transform: translateY(-50%);
  object-fit: cover;
  margin-left: 12px;
  border-radius: 5px;
}

.client-user-name {
  position: relative;
  display: inline-block;
  height: fit-content;
  color: var(--white, #ffffff);
  top: 50%;
  font-size: var(--m-font);
  transform: translateY(-50%);
  transition: 300ms;
}

#client-logout-box-user-name {
  color: var(--dark-blue, #112949);
}

.client-logout-box {
  position: absolute;
  display: none;
  flex-direction: column;
  max-height: calc(61px * 3);
  height: fit-content;
  min-width: 150px;
  width: fit-content;
  top: 5px;
  right: 20px;
  background: transparent;
  z-index: 1;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid var(--light-grey);
}

.client-logout-btns {
  position: relative;
  display: flex;
  justify-content: left;
  vertical-align: middle;
  align-content: center;
  flex-direction: row;
  width: 100%;
  background: white;
  border: none;
  outline: none;
  padding: 4px 10px;
  font-size: var(--m-font);
  z-index: 3;
  text-align: right;
  cursor: pointer;
  border-top: 1px solid var(--light-grey);
}
.client-logout-btns:hover {
  background: var(--background-blue);
}

#client-user-name-btn {
  height: 48px;
  border-top: none;
}
#client-user-name-btn:hover {
  background: white;
}

@media only screen and (max-width: 1460px) {
  .client-user {
    height: 40px;
    margin: 5px 10px 5px auto;
    min-width: 120px;
    border-radius: 6px;
    right: 5px;
    padding: 5px;
  }

  .client-user-img {
    height: 30px;
    width: 30px;
    margin-left: 10px;
    border-radius: 5px;
  }

  .client-logout-box {
    max-height: calc(51px * 3);
    min-width: 120px;
    top: 5px;
    right: 15px;
    border-radius: 5px;
  }

  .client-logout-btns {
    padding: 5px;
  }

  #client-user-name-btn {
    height: 38px;
  }
}

@media only screen and (max-width: 1025px) and (min-width: 850px) {
  .client-user {
    min-width: 80px;
  }

  .client-logout-box {
    min-width: 80px;
  }
}

@media only screen and (max-width: 850px) and (min-width: 500px) {
  .client-user {
    min-width: 80px;
    height: 20px;
  }

  .client-user-img {
    height: 20px;
    width: 20px;
  }

  #client-user-name-btn {
    height: 22px;
  }

  .client-logout-box {
    min-width: 80px;
  }
}

@media only screen and (min-width: 700px) and (max-width: 780px) and (min-height: 900px) and (max-height: 1100px),
  (min-width: 700px) and (max-width: 1000px) and (min-height: 700px) and (max-height: 1000px),
  (min-width: 1000px) and (max-width: 1100px) and (min-height: 1300px) and (max-height: 1400px),
  (min-width: 500px) and (max-width: 550px) and (min-height: 700px) and (max-height: 730px),
  (min-width: 750px) and (max-width: 880px) and (min-height: 900px) and (max-height: 1200px),
  (min-width: 800px) and (max-width: 1000px) and (min-height: 1300px) and (max-height: 1400px) {
  .client-topnav {
    position: relative;
    grid-area: topnav;
  }

  .client-user {
    height: 5vh;
    border-radius: 1vh;
    padding: 1vh;
  }

  .client-user-name {
    font-size: var(--sm-font);
  }

  #client-user-name-btn {
    height: 5vh;
  }

  .client-user-img {
    height: 4.5vh;
    width: 4.5vh;
  }

  .client-ez-logo-topnav {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    display: inline-block;
    height: 5vh;
    width: 5vh;
  }

  .client-logout-box {
    border-radius: 1vh;
    max-height: fit-content;
  }

  .client-logout-btns {
    padding: 1vh;
    font-size: var(--sm-font);
  }

  .client-ez-logo-topnav img {
    height: 100%;
    width: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .client-topnav {
    position: relative;
    grid-area: topnav;
  }

  .client-ez-logo-topnav {
    position: absolute;
    top: 50%;
    left: 7px;
    transform: translateY(-50%);
    display: inline-block;
    height: 35px;
    width: 35px;
  }

  .client-ez-logo-topnav img {
    height: 100%;
    width: 100%;
  }

  .client-user-name {
    font-size: var(--sm-font);
  }

  .client-logout-btns {
    font-size: var(--sm-font);
  }
}

@import "../../../general";

.tools_consult_loader {
  @include horizontalFlex($justify: center);
  height: 100%;
  .tools_consult_spin_loader {
    place-self: center;
    animation: rotation 1s infinite linear;
    font-size: 2rem;
  }
}

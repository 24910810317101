@import "../../../general";
.new_request_container {
  // background-color: blur;
  // height: 100vh;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.6);
}

@import "../../../general";
.revoke-request-error {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.6);
}
.tool-client-revoke-request-error-modal-container {
  //   position: relative;
  position: fixed;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 30vw;
  border-radius: 10px;
  color: var(--white);
  //   top: 50%;
  //   transform: translate(-10%, -50%);
  background: #0e223d;
}

#tool-client-revoke-reuqest-error-heading {
  color: var(--white);
}

#tool-client-revoke-request-error-heading-close-btn {
  color: var(--white);
}

.tool-client-revoke-request-error-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.tool-client-revoke-request-error-btn-container button {
  background-color: var(--light-blue, #3cc3f2);
}

#tool-client-revoke-request-error-modal-cancel-btn {
  background-color: transparent;
  color: var(--light-blue, #3cc3f2);
  padding: 0px;
  margin: 0px;
  min-width: fit-content;
}

/* ###################### Tablet ############################*/

@include media-tab-potrait-device() {
  .tool-client-revoke-request-error-modal-container {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: calc(100% - 20px);
    top: 50%;
    left: 0%;
    margin: 10px;
    transform: translate(0%, -50%);
  }
}

@media only screen and (max-width: 500px) {
  .tool-client-revoke-request-error-modal-container {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: calc(100% - 20px);
    top: 50%;
    left: 0%;
    margin: 10px;
    transform: translate(0%, -50%);
  }
}

/* ============ SECURE KEY MODAL ========================= */
.client-secure-key-modal {
  background: var(--dark-blue, #112949);
  color: white;
  border-radius: 10px;
}

.client-modal-container form {
  text-align: center;
}

.client-modal-input {
  position: relative;
  display: inline-block;
  width: 50%;
  padding: 5px;
  border: 2px solid var(--dark-blue, #112949);
  border-radius: 5px;
  font-size: var(--s-font);
  outline: none;
}

.client-secure-key-input {
  width: 65%;
}

.client-modal-input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: red;
}
.client-modal-input::-moz-placeholder {
  /* Firefox 19+ */
  color: red;
}
.client-modal-input:-ms-input-placeholder {
  /* IE 10+ */
  color: red;
}
.client-modal-input:-moz-placeholder {
  /* Firefox 18- */
  color: red;
}

.delivery-notes-input {
  height: 140px;
}

.delivery-notes-label {
  vertical-align: top;
  top: 60px;
}

.client-modal-submit-btn {
  position: relative;
  float: right;
  width: 30%;
  height: 35px;
  font-size: var(--s-font);
  outline: none;
  border-radius: 5px;
  border: none;
  color: white;
  background: var(--orange, #ea7b2c);
  margin-right: 15px;
  margin-bottom: 10px;
  cursor: pointer;
}

.client-secure-key-submit-btn {
  width: 30%;
}

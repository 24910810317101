@import "../../common";

.outersection {
  @include verticalFlex($justify: flex-start, $gap: 1rem);
  background-color: var(--darkest-blue);
  padding: 2rem 1rem;

  @include media-large-device() {
    padding: 2rem 1rem 4rem 1rem;
  }

  @include media-xxxl() {
    padding: 4rem 1rem 6rem 1rem;
  }
}

.serivce_section_inner_wrapper {
  @include verticalFlex($justify: flex-start, $gap: 2rem);

  @include media-small-device() {
    gap: 3rem;
  }
  @include media-large-device() {
    gap: 2rem;
  }
  @include media-xxxl() {
    gap: 4rem;
  }
}

.top_gradient {
  position: absolute;
  top: 0;
  left: -20rem;
  transform: rotateY(180deg);
  height: 100%;

  @include media-extrasmall-device() {
    left: -50rem;
  }
  @include media-mobile-landscape() {
    left: -40rem;
  }
}

.bottom_gradient {
  position: absolute;
  right: -20rem;
  bottom: 0;
  height: 100%;
  transform: rotateX(180deg);

  @include media-extrasmall-device() {
    right: -50rem;
  }
  @include media-mobile-landscape() {
    right: -40rem;
  }
}

.service_section_header_wrapper {
  @include centerFlex();
}

.service_section_header_text {
  color: var(--white);
  font-size: var(--rem-xl-font);
  text-align: center;

  // @media screen and (min-width: 300px) {
  //   font-size: 28px;
  // }

  // @media screen and (min-width: 350px) {
  //   font-size: 32px;
  // }

  @include media-350p() {
    font-size: var(--rem-2xl-font);
  }

  @include media-mid-device() {
    font-size: 28px;
  }

  @include media-small-desktop() {
    font-size: 32px;
  }

  @include media-xxl() {
    font-size: var(--rem-5xl-font);
  }

  @include media-xxxl() {
    font-size: var(--rem-6xl-font);
  }
}

.carousels_wrapper {
  @include verticalFlex($justify: flex-start, $gap: 1.5rem);
}

.indicator_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  height: 2rem;

  &:last-child() {
    width: 8px;
    height: 8px;
  }

  @include media-small-device() {
    padding-top: 1rem;
  }
  @include media-mid-device() {
    padding-top: 0;
  }
}

.indicator:last-child {
  width: 8px;
  height: 8px;
}

.indicator:first-child {
  width: 8px;
  height: 8px;
}

.indicator:nth-child(2) {
  width: 10px;
  height: 10px;
}

.indicator:nth-last-child(2) {
  width: 10px;
  height: 10px;
}

.indicator {
  width: 12px;
  height: 12px;
  background-color: #ffffff80;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color width height 0.3s ease;
}

.indicator.active {
  background-color: #ffffff;
  width: 16px;
  height: 16px;
  transition: 0.3s all ease;
}

.cta_section {
  @include verticalFlex($gap: 1rem);
  z-index: 2;
  margin-top: 1rem;

  @include media-xxxl() {
    gap: 1.5rem;
  }
}

.cta_title {
  color: var(--white);
  font-size: var(--rem-base-font);
  width: 100%;
  text-align: center;

  @include media-350p() {
    font-size: var(--rem-lg-font);
  }

  @include media-mid-device() {
    width: 55%;
    font-size: var(--rem-2xl-font);
  }

  @include media-small-desktop() {
    width: 35%;
  }
  @include media-xl() {
    width: 30%;
  }
  @include media-xxl() {
    font-size: var(--rem-4xl-font);
    width: 35%;
  }
  @include media-xxxl() {
    font-size: var(--rem-4xl-font);
    width: 30%;
  }
}

.cta_desc {
  color: var(--white);
  font-size: var(--rem-sm-font);
  text-align: center;

  @include media-350p() {
    font-size: var(--rem-base-font);
  }
  @include media-xxl() {
    font-size: 28px;
  }
}

.cta_button {
  color: var(--white);
  background-color: var(--orange);
  border: none;
  font-size: var(--rem-sm-font);
  padding: 0.5rem 1rem;
  border-radius: 4px;

  @media screen and (min-width: 350px) {
    padding: 0.7rem 1rem;
    font-size: var(--rem-base-font);
    border-radius: 8px;
  }

  @include media-small-device() {
    padding: 1rem 1.5rem;
  }

  @include media-xxl() {
    font-size: 28px;
    padding: 1.5rem 3.9rem;
  }
  @include media-xxxl() {
    font-size: 32px;
    padding: 2rem 3.9rem;
    border-radius: 0.8rem;
  }
}

@import "../../../general";

.tool_loader_container {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.6);

  .assignment_loader {
    @include horizontalFlex($justify: center, $align: center);
    color: white;
    text-align: center;

    .assignment_loader_text {
      @include stc-mega-heading-font($fontColor: var(--white));
    }

    .dots {
      display: inline-block;
      @include stc-mega-heading-font($fontColor: var(--white));
      span {
        animation: dots 1.5s infinite;
        opacity: 0;
        margin: 0 2px;

        &:nth-child(1) {
          animation-delay: 0s;
        }
        &:nth-child(2) {
          animation-delay: 0.3s;
        }
        &:nth-child(3) {
          animation-delay: 0.6s;
        }
      }
    }
  }

  @keyframes dots {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}

@import "../../general";
.st-client-sidenav {
  position: relative;
  grid-area: stcsidenav;
  height: 100vh;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  row-gap: 2vh;
  column-gap: 1vh;
  align-items: center;
  background: var(--vertical-blue-black-gradient);
  text-align: center;
  -webkit-box-shadow: 0px 2px 4px 0px var(--shadow-color);
  -moz-box-shadow: 0px 2px 4px 0px var(--shadow-color);
  box-shadow: 0px 2px 4px 0px var(--shadow-color);
  z-index: 2;
}

.st-client-sidenav-icon-faq {
  position: relative;
  // right: 15px;
  aspect-ratio: 1/1;
  color: white;
  /* width: 27px;
  height: 27px; */
  width: 3vh;
  font-size: var(--xxl-font);
  transition: 200ms;

  @include media-large-device() {
    width: 3.5vh;
  }
}
.st-client-ez-logo {
  position: relative;
  display: inline-block;
  aspect-ratio: 1/1;
  width: 4vh;
  // height: 50px;
  // width: 50px;
  margin: 0px auto 0px auto;
}

.st-client-ez-logo img {
  position: relative;
  height: 100%;
  width: 100%;
}

.st-client-sidenav-btn {
  position: relative;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 4.7vh;
  width: 4.7vh;
  margin: 0px auto;
  background: none;
  border: none;
  border-radius: 5px;
  color: white;
  outline: none;
  transition: 200ms;
  box-sizing: border-box;
}

.st-client-mobile-sidebar-show-btn {
  display: none;
}

// .st-client-dashboard-sidebar-show-btn {
//   transform: scale(1.15);
// }

.st-client-settings-btn {
  position: absolute;
  bottom: 2%;
  // width: 0%;
  // height: 50px;
  // bottom: 2%;
  // left: 1vh;
}

.st-client-archive-btn {
  font-size: 33px;
}

.st-client-sidenav-icon {
  aspect-ratio: 1/1;
  color: white;
  // width: 27px;
  // height: 27px;
  width: 3vh;
  font-size: var(--xxl-font);
  transition: 200ms;
}

.st-client-sidenav-btn-active {
  background: rgba(216, 213, 213, 0.35);
}

.st-client-sidenav-btn-hover {
  background: rgba(216, 213, 213, 0.35);
}

/* .st-client-sidenav-btn-hover .st-client-sidenav-icon{
    transform: scale(1.2);
  } */

@media only screen and (max-width: 1460px) {
  /* =========== SideNav ======================= */
  .st-client-ez-logo {
    // height: 40px;
    // width: 40px;
  }

  .st-client-sidenav-btn {
    padding: 2vh;
  }

  .st-client-sidenav-icon {
    /* bug fix for 1366*768 */
    // width: 22px;
    // height: 35px;
  }
}

@media only screen and (max-width: 850px) and (min-width: 500px) {
  .st-client-ez-logo {
    // width: 20px;
    // height: 20px;
  }

  .st-client-sidenav-btn {
    height: 30px;
    width: 30px;
  }
  .st-client-sidenav-icon {
    width: 100%;
    height: 100%;
  }
}

@include media-tab-potrait-device() {
  /* ========= SIDENAV CSS ========== */

  .st-client-sidenav {
    position: fixed;
    bottom: 0px;
    height: 6vh;
    padding: 0px 1vw;
    /* grid-area: stsidenav; */
    display: flex;
    flex-direction: row;
    width: 100vw;
    justify-content: space-between;
    align-items: center;
    text-align: end;
    padding-top: 0px;
    z-index: 4;
    background: var(--dark-blue, #112949);
  }

  .st-client-sidenav-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: fit-content;
    margin: 0px;
    padding: 1vh;
  }

  .st-client-sidenav-btn-hover {
    background: none;
  }

  .st-client-mobile-sidebar-show-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
  }

  .st-client-dashboard-sidebar-show-btn {
    display: none;
  }

  .st-client-ez-logo {
    display: none;
  }

  .st-client-sidenav-icon {
    // height: 4vh;
    width: 3vh;
  }

  .st-client-settings-btn {
    position: relative;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .st-client-archive-btn {
    position: relative;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3vh;
    margin-right: auto;
  }
}

@include media-potrait() {
  /* ========= SIDENAV CSS ========== */

  .st-client-sidenav {
    position: fixed;
    bottom: 0px;
    height: 50px;
    grid-area: stcsidenav;
    display: flex;
    flex-direction: row;
    row-gap: 5px;
    column-gap: 2vh;
    width: 100vw;
    justify-content: flex-start;
    align-items: center;
    vertical-align: middle;
    text-align: end;
    /* padding-top: 0px; */
    padding: 2px 10px;
    z-index: 4;
    background: #192234;

    @include media-tab-potrait-device() {
      padding: 0px 1vw;
    }
  }

  .st-client-sidenav-icon-faq {
    position: relative;
    // right: 15px;
    aspect-ratio: 1/1;
    color: white;
    /* width: 27px;
    height: 27px; */
    width: 3vh;
    font-size: var(--xxl-font);
    transition: 200ms;
  }
  .st-client-sidenav-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: fit-content;
    aspect-ratio: 1/1;
    margin: 0px;
    padding: 1vh;
  }
  .st-client-sidenav-btn-hover {
    background: none;
  }

  .st-client-sidenav-btn-active {
    background: rgba(216, 213, 213, 0.35);
  }

  .st-client-mobile-sidebar-show-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
  }

  .st-client-dashboard-sidebar-show-btn {
    // display: none;
  }

  .st-client-ez-logo {
    display: none;
  }

  .st-client-sidenav-icon {
    // height: 22px;
    // width: 35px;
    width: 3vh;
  }

  .st-client-settings-btn {
    position: relative;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
  }

  .st-client-archive-btn {
    position: relative;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5.7vh;
    margin-right: auto;
  }
}

@include media-tab-potrait-device() {
  .st-client-sidenav {
    column-gap: 1vh;
    height: 6vh;
  }

  .st-client-sidenav-btn {
    height: 4.7vh;
    width: 4.7vh;
  }
}

@include media-tab-landscape-device() {
  .st-client-sidenav-btn {
    padding: 0;
  }
}

@import "../../common";

.featured_articles_outer_container {
  @include centerFlex();
  background-color: var(--darkest-blue);
  -webkit-box-shadow: 0px 11px 38px 16px rgba(0, 0, 0, 0.39);
  -moz-box-shadow: 0px 11px 38px 16px rgba(0, 0, 0, 0.39);
  box-shadow: 0px 11px 38px 16px rgba(0, 0, 0, 0.39);
}

.featured_article_outermost_container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
}

.top_gradient {
  position: absolute;
  top: 0;
  left: -40rem;
  transform: rotateY(180deg);
  height: 50%;
  overflow: hidden;

  svg {
    height: 100%;
  }
  @include media-large-device() {
    height: 100%;
    left: -20rem;
  }
}

.bottom_gradient {
  position: absolute;
  right: -40rem;
  bottom: 0;
  transform: rotateX(180deg);
  height: 50%;
  overflow: hidden;

  svg {
    height: 100%;
  }

  @include media-large-device() {
    height: 100%;
    right: -20rem;
  }
}

.featured_articles_content_wrapper {
  padding: 2rem 2rem 4rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 2rem;

  @include media-small-device() {
    gap: 3rem;
  }

  @include media-large-device() {
    gap: 4rem;
    padding: 4rem 2rem;
  }

  @include media-xl() {
    max-width: 1460px;
  }

  @include media-xxl() {
    padding: 6rem 2rem;
  }

  @include media-tablet-1000p() {
    gap: 3rem;
  }
}

.featured_articles_heading_wrapper {
  @include centerFlex();
}

.featured_articles_heading_text {
  color: var(--white);
  font-size: var(--rem-2xl-font);
  text-align: center;

  @include media-large-device() {
    font-size: var(--rem-3xl-font);
  }

  @include media-small-desktop() {
    font-size: var(--rem-4xl-font);
  }

  @include media-xxl() {
    font-size: var(--rem-5xl-font);
  }

  @include media-xxxl() {
    font-size: var(--rem-6xl-font);
  }
}

.featured_article_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;

  @include media-small-desktop() {
    padding: 0px 10px;
  }
  @media (min-width: 1600px) {
    padding: 0px 20px;
  }
}

.hoverable_featured_article_container {
  @extend .featured_article_container;
  height: 100%;
  @include media-large-device() {
    height: 15rem;
  }

  @include media-xl() {
    height: 17rem;
  }
  @include media-xxl() {
    height: 24rem;
  }

  &:hover {
    @include media-large-device() {
      .featured_article_text_wrapper {
        height: 85%;
        transition: all 350ms ease-in-out 0s;

        @include media-small-desktop() {
          height: 75%;
        }
        @include media-xxl() {
          height: 90%;
        }

        .hovered_featured_article_desc_text {
          height: 90px;
          transition: all 350ms ease-in-out 0s;

          @include media-xxl() {
            height: 100px;
          }
        }
        .featured_article_desc_wrapper {
          height: auto;
        }
      }
    }
  }
}

.non_hovered_featured_article_container {
  @extend .featured_article_container;
  height: auto;
}

.display_card_image_wrapper {
  position: relative;
  @include media-large-device() {
    // position: absolute;
    overflow: hidden;
    border-radius: 0.5rem;
    height: 100%;
    // top: 1rem;
    // left: 1rem;
    // right: 1rem;
  }
}

.non_hovered_display_card_image_wrapper {
  position: relative;
  //   width: 1;
}
.display_card_image {
  width: 100%;
  border-radius: 0.5rem;
  z-index: 10;
}

.non_hover_display_card_image {
  position: relative;
  width: 100%;
}

.featured_article_text_wrapper,
.featured_article_desc_wrapper {
  @include centerFlex($justify: flex-start, $align: flex-start);
  z-index: 1;
}

.feature_article_text_ellipsis {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: hidden;
}

.featured_article_heading_wrapper {
  @include centerFlex($justify: flex-start, $align: flex-start);
}

.featured_article_heading_text {
  @extend .feature_article_text_ellipsis;
  font-size: var(--rem-lg-font);
  color: var(--white);

  @include media-large-device() {
    font-size: var(--rem-xl-font);
  }

  @include media-xl() {
    font-size: var(--rem-2xl-font);
  }
  @include media-xxl() {
    font-size: var(--rem-4xl-font);
  }
}

.non_hoverable_featured_article_desc_wrapper {
  @extend .feature_article_text_ellipsis;
  height: 100%;
}
.featured_article_desc_wrapper {
  @extend .feature_article_text_ellipsis;

  @include media-large-device() {
    height: 0;
  }
}

.hovered_featured_article_desc_text {
  @extend .feature_article_text_ellipsis;
  font-size: var(--rem-sm-font);
  color: #b4b4b4;
  //   height: 0;
  transition: all 350ms ease-in-out 0s;

  @include media-large-device() {
    font-size: var(--rem-lg-font);
  }

  @include media-xxl() {
    font-size: var(--rem-3xl-font);
  }
}

.non_hovered_featured_article_desc_text {
  font-size: var(--rem-lg-font);
  color: #b4b4b4;
  height: 100%;

  @include media-xxl() {
    font-size: var(--rem-3xl-font);
  }
}

.display_card_wrapper {
  @include verticalFlex($justify: flex-start);
  // background: transparent
  //   radial-gradient(closest-side at 52% 0%, #4c4b4b 0%, #000000 100%);

  background: rgba(76, 75, 75, 0.6);
  background: radial-gradient(at center top, rgba(76, 75, 75, 0.6), rgba(0, 0, 0, 0.6));
  padding: 1rem;
  border-radius: 1rem;
  height: 100%;
  overflow: hidden;
}

.display_card_hoverable {
  justify-content: flex-end;
}

.display_card_non_hoverable {
  justify-content: flex-start;
}

.featured_article_text_wrapper {
  @include verticalFlex($justify: space-between, $align: flex-start);
  overflow: hidden;
  transition: all 350ms ease-in-out 0s;
  cursor: pointer;

  @include media-large-device() {
    justify-content: flex-end;
  }
}

.hovered_featured_article_text_wrapper {
  @extend .featured_article_text_wrapper;
  top: 0%;
  // box-shadow: 0 -2px 12px rgba(#000, 0.3);
  height: 100%;

  @include media-large-device() {
    height: 35%;
  }

  @include media-small-desktop() {
    height: 35%;
  }

  @include media-xl() {
    height: 40%;
  }
}

.non_hovered_featured_article_text_wrapper {
  @extend .featured_article_text_wrapper;
  align-items: flex-end;
  height: 100%;
  justify-content: space-between;
  box-shadow: 0 -2px 12px rgba(#000, 0.3);
}

.carousel_btn {
  position: absolute;
  top: 50%;
  color: var(--white);
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.52);
  transition: all 350ms ease-in-out 0s;
  background-color: transparent;
  background: none;
  border: none;

  svg {
    font-size: var(--rem-2xl-font);

    @include media-xxl() {
      font-size: var(--rem-5xl-font);
    }
  }

  @include media-large-device() {
    top: 40%;
    background: transparent radial-gradient(closest-side at 52% 0%, rgba(76, 75, 75, 1) 0%, rgba(0, 0, 0, 1) 100%);
    border: 0.5px solid rgba(255, 255, 255, 0.2);
    padding: 1rem;
    border-radius: 100%;
  }
  @include media-large-device() {
    padding: 0.7rem;
  }
  @include media-xxl() {
    padding: 1.5rem;
  }
}
.featured_article_carousel_btn_left {
  @extend .carousel_btn;
  // left: -4rem;
  left: -1.5rem;

  @include media-mid-device() {
    left: -3rem;
  }
  @include media-large-device() {
    left: -3.5rem;
  }

  @include media-small-desktop() {
    left: -5rem;
  }
  @media (min-width: 1500px) and (max-width: 1600px) {
    left: -3rem;
  }
  @include media-xxl() {
    left: -7rem;
  }
  @include media-tablet-1000p() {
    left: -2rem;
  }

  @include media-mobile-landscape() {
    left: -2rem;
  }
}

.featured_article_carousel_btn_right {
  @extend .carousel_btn;
  right: -1.5rem;
  // right: 2px;

  @include media-mid-device() {
    right: -3rem;
  }
  @include media-large-device() {
    right: -3.5rem;
  }
  @include media-small-desktop() {
    right: -5rem;
  }

  @media (min-width: 1500px) and (max-width: 1600px) {
    right: -3rem;
  }
  @include media-xxl() {
    right: -7rem;
  }

  @include media-tablet-1000p() {
    right: -2rem;
  }
  @include media-mobile-landscape() {
    right: -2rem;
  }
}

// .carousel_wrapper {
//   @include media-large-device() {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//   }
// }
.carousel_movable_container {
  // width: 1430px;
  position: relative;

  // @media only screen and (min-width: 1024px) {
  //   position: relative;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   width: 930px;
  // }
  // @media only screen and (min-width: 1499px) {
  //   width: 1110px;
  // }

  // @media only screen and (min-width: 1800px) {
  //   width: 1410px;
  // }
  // @include media-xxl() {
  //   width: 1930px;
  // }
}

@import "../../general";
.client-dashboard-file-decrypt-btn {
  position: relative;
  display: flex;
  row-gap: 10px;
  /* min-height: 6vh; */
  font-size: var(--s-font);
  /* min-height: 60px; */
  flex-direction: column;
  align-items: flex-end;
  width: fit-content;
  float: right;
  /* padding-top: 1vh; */
  /* justify-content: center; */
}

.tool-client-dashboard-file-decrypt-form {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  min-width: 550px;

  @include media-large-device() {
    min-width: 100px;
  }
  @include media-small-desktop() {
    min-width: 150px;
  }

  @include media-xl() {
    min-width: 550px;
  }
  @media screen and (min-width: 1500px) and (max-width: 1750px) {
    min-width: 470px;
  }
}

.client-encrypt-now-btn {
  color: var(--orange);
  text-decoration: underline;
  cursor: pointer;
  font-size: var(--s-font);
}

.tool-client-secure-key-btn {
  position: relative;
  min-width: auto;
  background: var(--light-blue, #3cc3f2);
  color: var(--white);
  overflow: hidden;
  border: 1px solid var(--light-blue, #3cc3f2);

  @include media-large-device() {
    min-width: 80px;
  }

  @include media-tab-potrait-device() {
    min-width: 150px;
  }
  @include media-small-desktop() {
    min-width: 150px;
  }
}
.tool-client-secure-key-btn:hover {
  opacity: 0.9;
}
.client-decrypt-btn-tool {
  @include media-extrasmall-device() {
    padding: 5px 4vh;
  }
}

#tool-client-decrypt-key-btn {
  background: var(--orange, #ea7b2c);
  border: 1px solid var(--orange, #ea7b2c);
  @include media-extrasmall-device() {
    padding: 5px 4vh;
  }
}

#client-secure-encrypt-btn {
  background: var(--dark-blue, #112949);
  margin: 0px;
}

#client-timer-text {
  position: relative;
  z-index: 2;
}

#client-timer-span {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 50%;
  background: var(--light-blue, #3cc3f2);
  z-index: 1;
}

.tool-client-timer-container {
  width: fit-content;
  display: flex;
  flex-direction: row;
  gap: 1vh;
  height: 4.5vh;
  /* display: grid;
      grid-auto-flow: row;
      grid-auto-rows: fit-content;
      row-gap: 10px; */
  flex: row;
  column-gap: 10px;
  align-items: center;
  @include media-large-device() {
    height: 3vh;
  }
  @include media-small-desktop() {
    height: 3.3vh;
  }
  @include media-xl() {
    height: 4.5vh;
  }
  @media screen and (min-width: 1500px) and (max-width: 1700px) {
    height: 3.5vh;
  }
}

.client-secure-timer {
  /* color: var(--light-blue,#3cc3f2); */
  color: var(--white);
  font-size: var(--s-font);
}

.tool-decrypt-key-input-error-contianer {
  position: relative;
  display: flex;
  align-items: center;
  width: 70%;
  margin-right: 10px;
  outline: none;
  display: inline-block;
  @include media-large-device() {
    min-width: 220px;
  }
  @include media-small-desktop() {
    min-width: none;
  }
}

/* .decrypt-key-input{
      padding: 6px 10px;
      width: 100%;
      border: 1px solid var(--light-grey);
      border-radius: 6px;
      font-size: var(--s-font);
      outline: none;
    } */
@media only screen and (max-width: 1300px) {
  .tool-decrypt-key-input-error-contianer {
    width: 60%;
  }
}

.client-decrypt-btn-info-panel {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* align-items: top; */
  width: 100%;
}

.client-decrypt-btn-info {
  position: relative;
  left: 10px;
  width: fit-content;
  font-size: var(--s-font);
  height: fit-content;
  text-align: start;
  word-wrap: break-word;
}

.client-decrypt-check-mail {
  color: var(--white);
}

.decrypt-key-error {
  /* position: absolute;
      transform: translateY(60%); */
  /* left: 10px;
      width: fit-content;
      font-size: var(--xs-font);
      height: fit-content;
      word-wrap: break-word; */
  color: var(--orange, #ea7b2c);
}

.tool-decrypt-resend-btn {
  position: relative;
  right: 0px;
  background: transparent;
  max-width: 200px;
  min-width: 150px;
  padding: 2px 20px;
  height: fit-content;
  border: none;
  font-size: var(--s-font);
  outline: none;
  color: var(--orange, #ea7b2c);
  text-align: center;
  text-decoration: underline;
  @include media-large-device() {
    min-width: 100px;
    right: -15px;
  }
  @include media-small-desktop() {
    min-width: 130px;
    right: 0px;
  }
  @include media-xl() {
    min-width: 140px;
  }
  @media screen and (min-width: 0px) and (max-width: 370px) {
    min-width: 88px !important;
  }
}

.tools-decrypt-resend-btn-timer {
  color: var(--orange, #ea7b2c);
  text-decoration: underline;
  cursor: wait;
}

.encrypt-timer {
  position: relative;
  font-size: 1.3rem;
  color: var(--light-blue, #3cc3f2);
}

.encrypt-timer > span {
  font-weight: bold;
  font-size: 1.4rem;
}

#resend-secure-key {
  color: var(--orange, #ea7b2c);
  font-size: 1.2rem;
}

.timer-line {
  position: relative;
  bottom: 0px;
  width: 100%;
  height: 5px;
  background: var(--light-blue, #3cc3f2);
}

/* #################### TABLET #################################### */

@include media-tab-potrait-device() {
  .client-dashboard-file-decrypt-btn {
    width: 100%;
    min-height: 1px;
  }

  .tool-client-dashboard-file-decrypt-form {
    min-width: 100%;
    display: flex;
    column-gap: 10px;
    justify-content: space-between;
  }

  .tool-decrypt-key-input-error-contianer {
    width: 80%;
    margin: 0px;
  }

  .client-decrypt-btn-info {
    left: 0px;
  }

  .client-decrypt-check-mail {
    left: 0px;
    width: fit-content;
    transform: translateY(10%);
  }

  .tool-decrypt-resend-btn {
    min-width: 150px;
    padding: 0px;
  }

  .client-dashboard-file-decrypt-btn {
    row-gap: 5px;
  }

  .tool-client-timer-container {
    display: flex;
    /* width: 100%; */
    align-items: center;
    justify-content: space-between;
  }

  #client-target-download-all-btn {
    display: none;
  }
}

/* ######################### MOBILE ######################## */
@media only screen and (max-width: 500px) {
  .tool-client-dashboard-file-decrypt-form {
    justify-content: space-between;
    min-width: 100%;
  }

  .client-decrypt-btn-info {
    left: 0px;
  }

  .client-dashboard-file-decrypt-btn {
    width: 100%;
    min-height: fit-content;
  }

  .client-decrypt-check-mail {
    left: 0px;
    /* width: 120px; */
    width: 50vw;
    transform: translateY(10%);
  }

  .tool-decrypt-resend-btn {
    min-width: 120px;
    padding: 0px;
  }

  .tool-decrypt-key-input-error-contianer {
    width: 63%;
  }

  .client-dashboard-file-decrypt-btn {
    row-gap: 5px;
  }

  .tool-client-timer-container {
    display: flex;
    /* width: 100%; */
    align-items: center;
    justify-content: space-between;
  }

  #client-target-download-all-btn {
    display: none;
  }
}

@import "../../../../../../general";

.tools_consult_File_upload_dashboard_wrapper {
  @include verticalFlex($justify: flex-start, $gap-size: 0.8vh);
  height: 100%;
  overflow: auto;
  // padding-bottom: 4vh;
  // padding-right: 1vh;
}

.tools_consult_active_upload_wrapper {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr;
  width: 100%;
  padding: 1.5vh;
  background: #344155;
  border-radius: 0.8vh;
  gap: 1vh;
}

.tools_consult_File_upload_progress_bar_container {
  @include verticalFlex($gap-size: 5px);
}

.tools_consult_active_upload_file_details_wrapper {
  @include verticalFlex($gap-size: 0.5vh);
}
.tools_consult_active_upload_file_header {
  @include horizontalFlex();
}

.tools_consult_active_upload_file_name {
  @include stc-normal-font($fontColor: var(--white), $bold: 700);
}

.tools_consult_active_upload_file_confidential_wrapper {
  @include horizontalFlex($justify: flex-start);
  font-size: 14px;
  color: var(--white);
  // @include stc-normal-font($fontColor: var(--white));
}

.tools_consult_File_upload_progress_upload_percentage {
  @include horizontalFlex($align: flex-end, $justify: flex-end);
  font-size: 12px;
  font-weight: 700;
}

.tools_consult_active_upload_file_info {
  @include horizontalFlex($justify: flex-start, $gap-size: 0px);
  @include stc-normal-font($fontColor: var(--white));
}

.confidential_disbaled {
  @include stc-normal-font($fontColor: gray);
}

.tools_consult_File_upload_progress_bar {
  width: 100%;
  background-color: var(--white);
  height: 5px;
  border-radius: 10px;
  overflow: hidden;
}

.tools_consult_File_upload_progress_bar_progress {
  height: 100%;
  background-color: var(--light-blue);
  width: 0%;
  transition: width 1s;
}

.tools_consult_active_upload_failed_processing {
  @include stc-normal-font($fontColor: var(--orange));
}

.file_upload_type_icon_wrapper {
  @include horizontalFlex($justify: flex-end, $width: auto);
}

.consult_include_file_icon {
  width: 2rem;

  @include media-large-device() {
    width: 1.5rem;
  }

  @include media-small-desktop() {
    width: 2rem;
  }

  @include media-xl() {
    width: 2.5rem;
  }
}

.file_upload_type_icon {
  width: 1rem;
  @include media-mid-device() {
    width: 0.7rem;
  }

  @include media-small-desktop() {
    width: 0.8rem;
  }

  svg {
    width: 100%;
  }
}
.file_status_icon {
  cursor: pointer;
}

.no_data_found {
  @include verticalFlex($justify: center);
  height: 100%;
  @include stc-normal-font($fontColor: var(--white));
  font-style: italic;
}

.tools_consult_file_upload_checkbox_label {
  @include stc-normal-font($fontColor: var(--white));
}

.tools_consult_file_upload_processing_status_icon {
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}

@import "../../common";

.quoteModal_ParentContainers {
  width: 100%;
  height: 100vh;
  display: flex !important;
  align-items: center;
  line-height: normal !important;
  justify-content: center;
  margin: 0 auto;
  padding: 0 5px;
  @include media-mid-device() {
    padding: 0;
  }
  @include media-mobile-landscape() {
    align-items: flex-start;
  }
  @include media-nest-hub() {
    align-items: flex-start;
  }
}
.quoteModal_mobileclose_btn {
  position: absolute;
  background: transparent;
  border: none;
  top: 5px;
  right: 5px;
  width: 30px;
  height: 30px;
  color: white;
  font-size: 1rem;
  font-family: "Exo2-Bold";
  z-index: 10;
  @include media-small-desktop() {
    display: none;
    font-size: 1.5rem;
    right: 1rem;
  }
  &:hover {
    border: 1px solid #3d3d3d;
    border-radius: 16px;
    background: #112949;
    color: white;
    backdrop-filter: blur(10px);
  }
}
.quoteModal_ParentMainContainerWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  border-radius: 16px;
  @include media-small-desktop() {
    flex-direction: row !important;
  }
}
.quoteModal_desktopclose_btn {
  @include centerFlex($position: absolute);
  top: 5px;
  right: 5px;
  display: none;
  border: none;
  background: transparent;
  width: 30px;
  height: 30px;
  color: #3d3d3d;
  font-family: "Exo2-Bold";
  transition: background-color 0.3s ease;
  border-radius: 16px;

  @include media-small-desktop() {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &:hover {
    border: 1px solid #3d3d3d;
    background-color: #112949;
    color: white;
    // backdrop-filter: blur(10px);
  }
}
.quoteModal_MainHeaderContainers {
  width: 100%;
  height: 8rem;

  background: transparent linear-gradient(180deg, #112949 0%, #03080f 100%);
  border-radius: 16px 16px 0 0;
  @include media-small-desktop() {
    width: 46%;
    background: transparent linear-gradient(180deg, #112949 0%, #03080f 100%);
    height: auto;
    border-radius: 16px 0 0 16px;
    align-items: flex-start;
    border-bottom: none;
  }
}
.quoteModal_MainBodyContainers {
  height: 100%;
  width: 100%;
  padding: 1rem 15px;
  @include media-small-desktop() {
    width: 65%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-radius: 0 16px 16px 0;
    padding: 2.5rem 2rem;
  }
}
.quoteModal_MainBodyWrapperContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  @include media-small-desktop() {
    gap: 1rem;
  }
}
.quoteModal_FieldWrapper {
  width: 100%;
}

.quoteModal_InputFieldContainer {
  width: 100%;
  height: 100%;
  padding: 0 12px;
  border: 1px solid #aeaeae;
  border-radius: 8px;
  height: 40px;
  font-size: var(--rem-base-font);
  &:focus {
    border: 1px solid #3e516b;
  }
  &::placeholder {
    font-size: var(--rem-base-font);
  }
  @include media-small-desktop() {
    height: 50px;
    font-size: var(--rem-lg-font);

    &::placeholder {
      font-size: var(--rem-lg-font);
    }
  }
}
.quoteModal_textareaFieldWrapper {
  width: 100%;
}
.quoteModal_spanContainer {
  padding-left: 10px;
  // font-size: 11px;
  // @include media-350p() {
  //   font-size: 15px;
  // }
  font-size: var(--rem-base-font);
  @include media-xl() {
    font-size: var(--rem-lg-font);
  }
}
.quoteModal_checkboxMainWrapper {
  display: flex;
  align-items: center;
}
.quoteModal_textareasFieldWrappers {
  height: 6rem;
  width: 100%;
}
.quoteModal_textareaWrapper {
  border: 1px solid #aeaeae;
  border-radius: 8px;
  padding: 15px 12px;
  height: 100%;
  width: 100%;
  font-size: var(--rem-base-font);
  &:focus {
    border: 1px solid #3e516b;
  }
  &::placeholder {
    font-size: var(--rem-base-font);
  }

  @include media-small-desktop() {
    font-size: var(--rem-lg-font);

    &::placeholder {
      font-size: var(--rem-lg-font);
    }
  }
}

.quoteModal_textareaFieldWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}
.quoteModal_submitBtn {
  width: 100%;
  background: #ea7b2c;
  border-radius: 8px;
  border: none;
  outline: none;
  color: white;
  height: 50px;
  font-size: var(--rem-base-font);

  @include media-small-desktop() {
    font-size: var(--rem-lg-font);
  }
}
.quoteModal_titleMainContainers {
  position: relative;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 10px 5px;
  z-index: 5;
  margin-top: 16px;

  @include media-small-desktop() {
    gap: 1rem;
    padding: 1.2rem;
    margin-top: 0;
  }
}
.quoteModal_maintextHeading {
  font-family: "Exo2-Bold";
  font-size: 24px;
  @include media-small-desktop() {
    font-size: 32px;
  }
}
.quoteModal_subtextparagraph {
  font-size: 16px;
  @include media-small-desktop() {
    font-size: 24px;
  }
}
.quoteModal_backgroundImgWrapper {
  height: 20%;
  width: 90%;
  z-index: 1;
  position: absolute;
  background: url("../../../public_icons/CommonImages/contactmobilemodal.svg");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: top;

  @include media-small-desktop() {
    background: url("../../../public_icons/CommonImages/contactdesktopmodal.svg");
    width: 42%;
    height: 90%;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: bottom;
    margin-top: 10px;
  }
}

.phone_input {
  display: none;
}

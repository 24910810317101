@import "../../general";

.tools_consult_chat_section_wrapper {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 1fr;
  height: 100%;
  width: 100%;

  border-radius: 12px;
  overflow: hidden;

  @include media-large-device() {
    border-radius: 8px;
    height: 100%;
    grid-template-columns: auto 35%;
    border: 1px solid #394d67;
  }

  @include media-small-desktop() {
    grid-template-columns: auto 30%;
  }

  @include media-xl() {
    border-radius: 12px;
  }
}

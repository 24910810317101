@import "../../../common";
.service_list {
  @include verticalFlex($gap: 5px, $justify: center);
  cursor: pointer;
  padding: 0.5vh 0;
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 8px;
  }
}

.service {
  @include verticalFlex($justify: center, $fontSize: 1.6vh, $width: 10vh);
}

.service_icon {
  margin-top: 1.2vh;
  width: 50%;
  height: 50%;
}

.service_name {
  text-align: center;
  line-height: 1.8vh;
  height: 3vh;
  white-space: pre-wrap;
  width: 100%;
  font-family: "EXO2.0-REGULAR";
}

.sub_services {
  @include verticalFlex($justify: center);
}

.sub_service {
  @include verticalFlex(
    $gap: 2px,
    $justify: center,
    $fontSize: 1.4vh,
    $width: 10vh
  );
  @include media-xl() {
    font-size: 1.2vh;
  }
}

.sub_service_icon {
  margin-top: 1vh;
  width: 50%;
  height: 50%;
}

.sub_service_name {
  text-align: center;
  line-height: 1.7vh;
  height: 3vh;
  width: 100%;
  padding: 0 0.5vh;
  padding-bottom: 0.2vh;
  font-family: "EXO2.0-REGULAR";
}

/* ============ SHARING MODAL ================= */
@import "../../../general";
.tools_share_modal {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.6);
}
.tool-client-modal-container {
  height: fit-content;
  width: fit-content;
  background: var(--vertical-blue-black-gradient);
  text-align: left;
  padding: 10px;
  left: 50%;
  font-size: var(--s-font);
}
.tool-client-shared-modal-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 20px 0px #11294940;
  row-gap: 10px;
  width: 30vw;
  border-radius: 10px;
  background: #0e223d;
  color: white;
  top: 50%;
  left: 50%;
  height: fit-content;
  padding: 10px;
  transform: translate(-10%, -50%);
  font-size: var(--s-font);
}

.tool-client-modal-container-close-btn {
  position: relative;
  float: right;
  outline: none;
  font-size: var(--l-font);
  z-index: 1;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  transition: 100ms;
  color: var(--light-blue, #3cc3f2);
}

.tool-client-modal-container-close-btn:hover {
  transform: scale(1.2);
}

#tool-client-share-heading {
  color: var(--white);
}

#tool-client-share-heading-close-btn {
  color: var(--white);
}

.tool-client-share-names-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  row-gap: 5px;
  column-gap: 5px;
}

.tool-client-share-name-item {
  position: relative;
  padding: 5px;
  background: var(--light-blue, #3cc3f2);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 5px;
  font-size: var(--s-font);
}

.tool-client-share-name-item-err {
  background: var(--orange, #ea7b2c);
}

.tool-client-share-modal-form {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  row-gap: 5px;
}

.tool-client-share-modal-input {
  position: relative;
  background: transparent;
  color: var(--white, #ffffff);
  font-size: var(--s-font);
}

.tool-client-share-suggestions-container {
  position: absolute;
  padding: 5px 0px;
  /* margin: 5px; */
  background: var(--white, #ffffff);
  border-radius: 5px;
  color: var(--dark-blue, #112949);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 5px;
  max-height: 100px;
  max-width: 90%;
  overflow: auto;
  z-index: 1;
  top: 110%;
  left: 10px;
  /* transform: translate(50%,50%); */
}

.tool-client-share-error {
  position: relative;
  margin-top: 0px;
  margin-left: 10px;
  text-align: start;
}

.tool-client-share-suggestion-item {
  width: 100%;
  text-align: left;
  padding: 5px;
  cursor: pointer;
}

.tool-client-share-suggestion-item:hover {
  background: rgba(60, 195, 242, 0.5);
}

.tool-client-share-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.tool-client-share-btn-container button {
  background-color: var(--light-blue, #3cc3f2);
}

#tool-client-share-modal-cancel-btn {
  background-color: transparent;
  color: var(--light-blue, #3cc3f2);
  padding: 0px;
  margin: 0px;
  min-width: fit-content;
}

/* ###################### Tablet ############################*/

@include media-tab-potrait-device() {
  /* ============ SHARING MODAL ================= */
  .tool-client-modal-container {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: calc(100% - 20px);
    top: 50%;
    left: 0%;
    margin: 10px;
    transform: translate(0%, -50%);
    font-size: var(--s-font);
  }
  .tool-client-shared-modal-container {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: calc(100% - 20px);
    top: 50%;
    left: 0%;
    margin: 10px;
    transform: translate(0%, -50%);
  }

  .tool-client-share-modal-form {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
  }

  .tool-client-share-suggestions-container {
    top: 110%;
    left: 0px;
  }
}

@media only screen and (max-width: 500px) {
  /* ============ SHARING MODAL ================= */
  .tool-client-shared-modal-container {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: calc(100% - 20px);
    top: 50%;
    left: 0%;
    margin: 10px;
    transform: translate(0%, -50%);
  }
  .tool-client-modal-container {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: calc(100% - 20px);
    top: 50%;
    left: 0%;
    margin: 10px;
    transform: translate(0%, -50%);
    font-size: var(--s-font);
  }

  .tool-client-share-modal-form {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
  }

  .tool-client-share-suggestions-container {
    top: 110%;
    left: 0px;
  }

  /* .client-share-modal-input{

        } */
}

.tools-revoke-request-modal {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.6);
}

.tools-decline-request-modal {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.6);
}

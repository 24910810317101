@import "../../general";

.files_pane_section {
  @include verticalFlex($justify: flex-start);
  background-color: var(--tool-dark-blue);
  height: 100%;
  overflow: hidden;

  .files_pane_head_container {
    @include verticalFlex($gap-size: 0vh, $justify: flex-start);
    padding: 1vh;
    padding-bottom: 0;
    padding-top: 0;
    height: calc(60px + 5%);

    @include media-xl() {
      height: calc(60px + 3%);
    }

    .files_pane_head {
      padding-top: 1vh;
      @include horizontalFlex($justify: space-between);
      height: 60px;

      .files_pane_head_back {
        display: none;
        @include media-potrait() {
          @include verticalFlex($width: auto);
        }
      }

      .files_pane_head_text {
        @include stc-heading-font();

        .files_pane_heading {
          @include horizontalFlex();
          @include stc-mega-heading-font($fontColor: var(--white));
        }
      }

      .files_pane_head_add_new_btn {
        @include stc-btn(
          $btnBgColor: transparent,
          $btnTextColor: var(--white),
          $minWidth: fit-content
        );
        // position: absolute;
        padding: 0.5vh;
      }
    }

    .files_pane_search {
      @include horizontalFlex();
      border: 0.5px solid var(--light-grey);
      border-radius: $genBorderRadius;

      .file_pane_search_input {
        @include stc-input($borderColor: transparent);
        border-radius: 0.6vh;
        background: transparent;
        border: none;
      }
      .file_pane_search_input::placeholder {
        color: #ffffff80;
      }
      .file_pane_search_btn {
        @include stc-btn($btnBgColor: transparent, $minWidth: fit-content);
        // position: absolute;
        right: 1vh;
        padding: 0.5vh;
      }
    }
  }

  .files_pane_list_section {
    @include verticalFlex($justify: flex-start);
    padding-bottom: 1vh;
    height: calc(100% - 60px - 5%);

    @include media-xl() {
      height: calc(100% - 60px - 3%);
    }

    // border-right: 1px solid rgba( 255, 255, 255, 0.1 );

    // @include media-potrait() {
    //   height: 79vh;
    // }

    .file_pane_tools_tab {
      @include horizontalFlex($gap-size: 0vh);
      padding: 0 2vh;

      .file_pane_tool_tab_btn {
        position: relative;
        text-transform: capitalize;
        cursor: pointer;
        padding: 0 1vh;
      }

      .file_pane_tool_tab_btn-active {
        &::after {
          content: "";
          bottom: -0.7vh;
          left: 0;
          background: var(--white);
          width: 100%;
          height: 0.35vh;
          position: absolute;
        }
      }
    }

    .files_pane_list_container {
      position: relative;
      height: 100%;
      width: 100%;
      overflow-y: auto;
      background-color: var(--tool-sb-color);

      @include media-large-device() {
        height: 85vh;
        padding-right: 1vh;
      }

      .files_pane_list_wrapper {
        @include verticalFlex();

        .files_pane_month_wrapper {
          @include verticalFlex();

          .files_pane_month_head {
          }

          .files_pane_list {
            @include verticalFlex($gap-size: 0.5vh);
            padding: 1vh;
            padding-right: 0;
            @include media-potrait() {
              padding-right: 1vh;
            }

            .files_pane_item {
              @extend .glass-card;
              position: relative;
              height: 0px;
              margin: 0px;
              padding: 0px;
              box-sizing: border-box;
              color: var(--white, #ffffff);
              cursor: pointer;
              border: none;
              transition: 100ms;
              box-sizing: border-box;
              overflow: hidden;
              display: grid;
              grid-template-columns: 1fr 1.2fr;
              grid-template-rows: repeat(3, max-content);
              grid-template-areas:
                "sidebar-file-services sidebar-file-feedback"
                "sidebar-file-names sidebar-file-names"
                "sidebar-file-unit sidebar-file-date";
              justify-content: space-between;
              align-items: center;
              gap: 1vh;

              &:hover {
                border-left: none;
                border-right: none;
              }

              .file_service_tag {
                grid-area: sidebar-file-services;
                @include stc-badge($color: rgba(241, 247, 249, 0.1));
                color: var(--white);
                border-radius: 0px 0px 5px 5px;
                padding: 1vh;
                width: 12vh;

                img {
                  // aspect-ratio: 1/1;
                  // height: 2.5vh;
                  width: 100%;
                }

                .file_service_tag_name {
                  text-transform: capitalize;
                }
              }

              .file_feedback {
                grid-area: sidebar-file-feedback;
                justify-self: flex-end;
                svg {
                  aspect-ratio: 1/1;
                  height: 2.5vh;
                  width: 2.5vh;
                  color: var(--light-grey) !important;
                }
              }

              .file_name {
                @include horizontalFlex($justify: flex-start);
                grid-area: sidebar-file-names;
                width: 60%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                .file_name_namecontent {
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }

              .file_count {
                grid-area: sidebar-file-unit;
              }

              .file_date {
                grid-area: sidebar-file-date;
              }
            }

            .files_pane_item-show {
              height: fit-content;
              margin: 2px 10px;
              padding: 0px 10px 5px 10px;
            }

            .files_pane_item-active {
              background: linear-gradient(
                90deg,
                rgba(17, 41, 73, 0.25) 0%,
                rgba(0, 0, 0, 0.25) 100%
              );
              backdrop-filter: 0.8;
            }
          }
        }
      }
    }
  }
}
.files_pane_head_text {
  @include horizontalFlex($width: auto);
}

.empty_list_data_text {
  font-style: italic;
  padding: 1vh;
}

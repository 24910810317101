@import "../../../general";

.consult_tool_description_banner {
  @include verticalFlex($gap-size: 2vh);
  @include stc-glass-card($inputBgColor: #0e223d80);
  padding: 2vh;
  color: var(--white);
  height: 100%;
  margin: 0vh;
  @include media-mid-device() {
    // height: 28%;
    padding: 5vh 3vh;
  }
}

.consult_tool_header_wrapper {
  @include horizontalFlex($justify: center);
  @include stc-mega-heading-font($fontColor: var(--white));
}

.consult_description_text_wrapper {
  @include verticalFlex($width: 100%, $justify: center, $align: center);
  text-align: center;
  @include media-mid-device() {
    width: 33%;
  }
}

.consult_description_text {
  @include stc-normal-font();
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.consult_tool_header_mainImage {
  width: 80%;
  @include media-mid-device() {
    width: 30%;
  }
}

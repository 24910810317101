@import "../../../general";

.tools_share_revoke_main {
  // background-color: blur;
  // height: 100vh;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.6);
}
.tool-client-revoke-share-modal-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  row-gap: 1vh;
  width: 30vw;
  font-size: var(--s-font);
  border-radius: 10px;
  // top: 50%;
  // transform: translate(-10%, -50%);
  background: #0e223d;
}

#tool-client-revoke-share-heading {
  color: var(--white);
}

#tool-client-revoke-share-heading-close-btn {
  color: var(--white);
}

.tool-client-revoke-share-heading-para-white {
  color: #ffffff;
}

.tool-client-revoke-share-names-list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
}

.tool-client-revoke-share-names-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  row-gap: 5px;
  column-gap: 5px;
  flex-direction: column;
}

.tool-client-revoke-share-name-item {
  position: relative;
  padding: 5px;
  background-color: var(--light-blue, #3cc3f2);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 5px;
  font-size: var(--s-font);
  width: fit-content;
  flex-wrap: wrap;
}

.tool-client-revoke-share-requester-name-item {
  position: relative;
  padding: 5px;
  background-color: var(--label-blue, #9cbdeb);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 5px;
  font-size: var(--s-font);
  width: fit-content;
  flex-wrap: wrap;
}

.tool-client-revoke-share-remove-btn {
  background: none;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
}

.tool-client-revoke-share-heading {
  color: var(--label-blue, #9cbdeb);
}

.tool-client-revoke-share-requester-names-list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
}
.tool-client-revoke-share-heading-para {
  padding-top: 10px;
  color: var(--label-blue, #9cbdeb);
}

.tool-client-revoke-share-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 1vh;
}

.tool-client-revoke-share-btn-container button {
  background-color: var(--light-blue, #3cc3f2);
}

#tool-client-revoke-share-modal-cancel-btn {
  background-color: transparent;
  color: var(--light-blue, #3cc3f2);
  padding: 0px;
  margin: 0px;
  min-width: fit-content;
}

/* ==============IPAD - TABLET=================== */

@include media-tab-potrait-device() {
  .tool-client-revoke-share-modal-container {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: calc(100% - 20px);
    top: 50%;
    left: 0%;
    margin: 10px;
    transform: translate(0%, -50%);
  }
}

@media only screen and (max-width: 500px) {
  /* ============ REVOKE SHARING MODAL ================= */
  .tool-client-revoke-share-modal-container {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: calc(100% - 20px);
    top: 50%;
    left: 0%;
    margin: 10px;
    font-size: var(--s-font);
    transform: translate(0%, -50%);
  }
}

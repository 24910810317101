/* ============= CLIENT ERROR PAGES ===================== */
.client-maintenance-error-section {
  position: absolute;
  background: var(--vertical-blue-black-gradient);
  color: var(--white, #ffffff);
  padding: 20px;
  height: 100%;
  width: calc(100% - 60px);
  right: 0px;
}

.client-maintenance-error-msg {
  width: 50%;
  position: absolute;
  transform: translateY(50px);
}

.client-maintenance-error-msg-big {
  display: block;
  font-size: 64px;
}

.client-maintenance-error-msg-small {
  display: block;
  font-size: 22px;
}

.client-maintenance-error-img {
  position: absolute;
  height: 70%;
  right: 30px;
  bottom: 30px;
}

/* ============= LOADER =========================*/
.client-loader-modal {
  position: absolute;
  right: 0px;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100vw - 60px);
  height: calc(100vh - 59px);
  background: var(--modal-color);
  z-index: 5;
}

.client-loader {
  background: var(--white, #ffffff);
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  height: fit-content;
}

.spin-loader {
  display: flex;
  font-size: 2rem;
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@media only screen and (max-width: 1460px) {
  /* ============== Error ================ */
  .client-maintenance-error-section {
    width: calc(100% - 50px);
  }

  .client-maintenance-error-msg-big {
    font-size: 40px;
  }

  .client-maintenance-error-msg-small {
    font-size: 20px;
  }

  .client-maintenance-error-img {
    width: 70%;
    height: auto;
  }

  /* ============= LOADER =========================*/
  .client-loader-modal {
    width: calc(100vw - 50px);
    height: calc(100vh - 49px);
  }
}

@media only screen and (min-width: 700px) and (max-width: 780px) and (min-height: 1000px) and (max-height: 1050px),
  (min-width: 1020px) and (max-width: 1040px) and (min-height: 1350px) and (max-height: 1370px),
  (min-width: 500px) and (max-width: 550px) and (min-height: 700px) and (max-height: 730px) {
  /* ========= CLIENT ERROR ================================ */
  .client-maintenance-error-section {
    width: 100vw;
    top: 50%;
    transform: translateY(-50%);
    height: calc(100% - 100px);
  }

  .client-maintenance-error-msg {
    width: 90%;
    position: absolute;
    transform: translateY(10px);
  }

  .client-maintenance-error-msg-big {
    font-size: 28px;
  }

  .client-maintenance-error-msg-small {
    font-size: 16px;
  }

  .client-maintenance-error-img {
    position: absolute;
    width: 100%;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  /* ================== Loader ==================== */
  .client-loader-modal {
    width: 100vw;
    top: 50%;
    transform: translateY(-50%);
    height: calc(100% - 100px);
    z-index: 5;
  }

  .client-loader {
    font-size: var(-xxs-font);
  }

  .spin-loader {
    display: flex;
    font-size: var(--sm-font);
    animation: rotation 2s infinite linear;
  }
}

@media only screen and (max-width: 500px) {
  /* ========= CLIENT ERROR ================================ */
  .client-maintenance-error-section {
    width: 100vw;
    top: 50%;
    transform: translateY(-50%);
    height: calc(100% - 100px);
  }

  .client-maintenance-error-msg {
    width: 90%;
    position: absolute;
    transform: translateY(10px);
  }

  .client-maintenance-error-msg-big {
    font-size: 28px;
  }

  .client-maintenance-error-msg-small {
    font-size: 16px;
  }

  .client-maintenance-error-img {
    position: absolute;
    width: 100%;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  /* ================== Loader ==================== */
  .client-loader-modal {
    width: 100vw;
    top: 50%;
    transform: translateY(-50%);
    height: calc(100% - 100px);
    z-index: 5;
  }

  .client-loader {
    font-size: var(-xxs-font);
  }

  .spin-loader {
    display: flex;
    font-size: var(--sm-font);
    animation: rotation 2s infinite linear;
  }
}

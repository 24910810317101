@import "../../general";

.home_pie_chart_section {
    @include verticalFlex($gap-size: 2vh);
    @include stc-normal-font();
    max-width: 100%;
    height: fit-content;
    padding: 2vh 0 1vh 0;

    @include media-potrait() {
        padding: 2vh 1vh 1vh 1vh;
    }

    .home_pie_chart_head {
        @include horizontalFlex();

        .home_pie_chart_heading {
            @include horizontalFlex();
            @include stc-mega-heading-font($fontColor: var(--white));

            @include media-extrasmall-device() {
                @include stc-heading-font($fontColor: var(--white));
            }
        }

        .home_pie_chart_select {
            @include horizontalFlex();
            z-index: 1;
            right: 0vh;
            width: auto;

            @include media-large-device() {
                position: absolute;
                top: calc(var(--xl-font) + 5px + 1vh);
                // bottom: calc(-100% - 1vh);
            }

            // @include media-small-desktop() {
            //     top: calc(var(--xl-font) + 5px + 1.8vh);
            // }

            // @include media-tab-landscape-device() {
            //     top: calc(var(--xl-font) + 2px + 1vh);
            // }

            @include media-potrait() {
                position: relative;
                // top: 2vh;
                top: 0;
            }

            .home_pie_chart_select_wrapper {
                @include horizontalFlex();
                width: 14vh;

                @include media-extrasmall-device() {
                    width: 120px;
                }

                .home_pie_chart_select_input {
                    @include stc-input();
                    color: var(--white, #ffffff);
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                    outline: none;
                    border: 1px solid #394d67;
                }

                .home_pie_chart_select_input::-ms-expand {
                    display: none;
                }

                .home_pie_chart_select_input option {
                    background-color: #0e223d80;
                    color: var(--white, #ffffff);
                }

                .home_pie_chart_select_input option:hover {
                    background-color: #0e223d80;
                }

                .home_pie_chart_select_icon {
                    position: absolute;
                    right: 1vh;
                }
            }
        }
    }

    .chart_section_wrapper {
        @include horizontalFlex($justify: space-around);
        height: 25.5vh;
        width: 90%;

        @include media-large-device() {
            height: 18vh;
        }

        @mixin max-width-800px() {
            height: 19vh;
        }

        @include media-extrasmall-device() {
            @include verticalFlex();
            height: 100%;
        }

        @include media-tab-potrait-device() {
            height: 18vh;
            gap: 5vh;
        }

        @include media-xl() {
            height: 25.5vh;
        }

        .chart_section {
            @include verticalFlex($gap-size: 2vh);
            width: auto;
            height: 100%;
            @include media-potrait() {
                gap: 1vh;
                width: 100%;
            }

            @include media-tab-potrait-device() {
                align-items: self-end;
            }

            .chart_graph_head {
                @include verticalFlex($width: auto);
                @include stc-heading-font();
                margin-left: auto;
                width: 22vh;
                text-transform: capitalize;

                @include media-extrasmall-device() {
                    margin-left: 0;
                    margin-right: auto;
                    align-items: flex-start;
                }

                @include media-tab-potrait-device() {
                    width: 16vh;
                }

                @include media-mid-device() {
                    width: 14vh;
                }

                @include media-xl() {
                    width: 22vh;
                }
            }

            .chart_graph_wrapper {
                @include horizontalFlex($justify: flex-start, $gap-size: 2vh);
                height: 100%;
                width: auto;

                @include media-extrasmall-device() {
                    flex-direction: row-reverse;
                }

                .chart_legends {
                    @include verticalFlex($width: 13vh);

                    @include media-large-device() {
                        width: 13vh;
                    }

                    @include media-xl() {
                        width: 13vh;
                    }

                    @include media-tab-potrait-device() {
                        width: 10vh;
                    }

                    @include media-tab-landscape-device() {
                        width: 10vh;
                    }

                    @include media-extrasmall-device() {
                        width: 15vh;
                    }

                    .chart_legend_item {
                        @include horizontalFlex($justify: flex-start);

                        .chart_legend_item_color {
                            @include horizontalFlex();
                            width: 2vh;
                            aspect-ratio: 1/1;
                        }

                        .chart_legend_item_label {
                            position: relative;
                            display: inline-block;
                            text-transform: capitalize;

                            @include media-potrait() {
                                width: auto;
                            }
                            // &::first-letter {
                            //     text-transform: capitalize;
                            // }
                        }
                    }
                }

                .chart_graph {
                    @include verticalFlex($justify: center, $align: center);
                    width: 22vh;
                    height: 22vh;
                    .pie_chart {
                        aspect-ratio: 1/1;
                    }

                    @include media-tab-potrait-device() {
                        height: 16vh;
                        width: 16vh;
                    }

                    @include media-mid-device() {
                        width: 14vh;
                        height: 14vh;
                    }

                    @include media-xl() {
                        width: 22vh;
                        height: 22vh;
                    }

                    @include media-extrasmall-device() {
                        height: 20vh;
                        width: 20vh;
                    }
                }

                .chart_success {
                    @include verticalFlex();
                    position: absolute;

                    .chart_succes_text {
                        @include stc-normal-font();
                        color: var(--dark-grey);
                    }
                    .chart_succes_val {
                        @include stc-normal-font();
                        color: var(--white);
                    }
                }

                .chart_no_data {
                    position: absolute;
                    color: var(--dark-grey);
                }
            }
        }

        .chart_section-flip {
            @include media-tab-potrait-device() {
                align-items: self-start;
            }
            .chart_graph_head {
                margin-left: 0;
                margin-right: auto;
                // @include media-extrasmall-device() {
                //     margin-left: auto;
                //     margin-right: 0;
                // }
            }
            .chart_graph_wrapper {
                flex-direction: row-reverse;

                // @include media-extrasmall-device() {
                //     flex-direction: row;
                // }
            }
        }
    }
}

@import "../../general";

.tools_home_page {
  grid-area: stdashboard;
  background: #060e18;
  display: grid;
  // padding: 1vh;
  // gap: 1vh;
  grid-template-columns: auto calc(25vw - 1vh);
  grid-template-areas: "stc-home stc-capability";
  // "stc-recent-assgn stc-capability"
  // "stc-file-search stc-capability";

  // @include media-xl() {
  //   gap: 2.4vh;
  // }
  .mobile_sidebar_tools {
    display: none;
    @include media-tab-potrait-device() {
      display: block;
      grid-area: sidebar_mobile_home;
      width: 100vw;
    }
    @include media-extrasmall-device() {
      display: block;
      grid-area: sidebar_mobile_home;
      width: 100vw;
    }
  }

  .tools_home_wrapper {
    grid-area: stc-home;
    @include verticalFlex($justify: flex-start, $align: center);
    @include scrollbars($size: 2px, $thumb-color: rgba(255, 255, 255, 0.1), $track-color: rgba(6, 14, 24, 1));
    gap: 1vh;
    overflow-y: auto;
    max-height: calc(100vh - 60px - 1vh);
    width: 100%;
    margin: 0 auto;
    // padding: 0 1vh 0 2vh;

    @include media-potrait() {
      overflow: hidden;
      height: max-content;
      // max-height: calc(100vh - 120px - 1vh);
    }
    // @include media-large-device() {
    //   height: 88vh;
    // }
    // @include media-tab-potrait-device() {
    //   overflow: hidden;
    //   // height: max-content;
    //   height: auto;
    // }
    // @media screen and (min-width: 1500px) and (max-width: 1700px) {
    //   overflow: auto;
    //   max-height: 90vh;
    // }
    // @include media-xl() {
    //   height: auto;
    // }

    @include media-xxl() {
      overflow-x: hidden;
    }
  }
  @include media-tab-potrait-device() {
    padding: 0;
    overflow-x: hidden;
    display: block;
    display: block;
    grid-area: sidebar_mobile_home;
    width: 100vw;
    grid-area: sidebar_mobile_home;
    width: 100vw;
    // height: 8vh;
    grid-area: stdashboard;
    grid-template-areas:
      "stc-homechart"
      "stc-file-search"
      "sidebar_mobile_home"
      "stc-capability";
    max-height: calc(100vh - 12vh);
    overflow-y: auto;
    overflow-x: hidden;
    @include scrollbars($size: 2px, $thumb-color: rgba(255, 255, 255, 0.1), $track-color: rgba(6, 14, 24, 1));
  }
  @include media-extrasmall-device() {
    padding: 0;
    display: block;
    display: block;
    grid-area: sidebar_mobile_home;
    width: 100vw;
    grid-area: sidebar_mobile_home;
    width: 100vw;
    height: 89vh;
    grid-area: stdashboard;
    grid-template-areas:
      "stc-homechart"
      "stc-file-search"
      "sidebar_mobile_home"
      "stc-capability";
    max-height: calc(100vh - 100px);
    overflow-y: auto;
    overflow-x: hidden;
  }
}

// .tools_sidebar_column_wise {
//   @extend mobile_sidebar_tools;
//   flex-direction: column;
// }

.error-div {
  position: relative;
  display: inline-block;
  top: 50%;
  transform: translateY(-50%);
  width: 100vw;
  height: 400px;
  background: blue;
}

.error-head {
  position: relative;
  display: block;
  top: 50%;
  transform: translateY(-50%);
  color: azure;
  margin: 10px auto;
  font-size: 2.5rem;
  text-align: center;
}

.get-back {
  position: relative;
  display: inline-block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-decoration: none;
  padding: 5px 20px;
  margin: 10px auto;
  border-radius: 4px;
  color: azure;
  font-size: 1.2rem;
  background-color: black;
}

/* ============= CLIENT ERROR PAGES ===================== */
.client-lost-error-section {
  position: absolute;
  background: var(--vertical-blue-black-gradient);
  color: var(--white, #ffffff);
  padding: 20px;
  height: 100%;
  width: 100%;
  right: 0px;
}

.client-lost-ez-logo {
  position: relative;
  display: inline-block;
  height: 50px;
  width: 50px;
  margin: 0px auto 10px auto;
}

.client-lost-ez-logo img {
  position: relative;
  height: 100%;
  width: 100%;
}

.client-lost-error-msg {
  width: 30%;
  position: absolute;
  /* transform: translateY(50px); */
}

.client-lost-error-msg-big {
  display: block;
  font-size: 64px;
}

.client-lost-error-msg-small {
  display: block;
  font-size: 22px;
}

.client-lost-error-img {
  position: absolute;
  height: 70%;
  right: 30px;
  bottom: 30px;
}

@media only screen and (max-width: 500px) {
  /* ========= CLIENT ERROR ================================ */
  .client-lost-error-section {
    width: 100vw;
    top: 50%;
    transform: translateY(-50%);
    height: 100%;
  }

  .client-lost-ez-logo {
    height: 40px;
    width: 40px;
  }

  .client-lost-error-msg {
    width: 90%;
    position: absolute;
    transform: translateY(10px);
  }

  .client-lost-error-msg-big {
    font-size: 28px;
  }

  .client-lost-error-msg-small {
    font-size: 16px;
  }

  .client-lost-error-img {
    position: absolute;
    width: 100%;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@import "../../general";

.file_info_section {
  @include stc-glass-card($inputBgColor: #08162a00);
  @include stc-normal-font();
  position: relative;
  display: grid;
  width: 100%;
  gap: 3vh 1vh;
  padding: 2vh 3vh 1vh 3vh;
  // height: 30vh;
  margin: 0;
  overflow: hidden;
  background: var(--tool-dark-blue);
  justify-content: space-between;
  grid-template-rows: max-content max-content max-content;
  grid-template-columns: max-content max-content;
  // @include scrollbars(
  //   $size: 2px,
  //   $thumb-color: rgba(255, 255, 255, 0.1),
  //   $track-color: rgba(6, 14, 24, 1)
  // );
  grid-template-areas:
    "file-info-head tools-dashboard-key-section"
    "file-info-name tools-dashboard-key-section"
    "file-info-items file-info-items"
    "share-output-tabs share-output-tabs";

  @include media-tab-potrait-device() {
    padding: 1vh;
    padding-bottom: 1vh !important;
    height: fit-content;
    margin-bottom: 1vh;
    grid-template-rows: repeat(5, max-content);
    grid-template-columns: max-content max-content;
    grid-template-areas:
      "file-info-head lock-mobile"
      "file-info-name file-info-name"
      "file-info-items file-info-items"
      "tools-dashboard-key-section tools-dashboard-key-section"
      "share-output-tabs share-output-tabs";
    grid-gap: 2vh 1vh;
  }
  @include media-extrasmall-device() {
    padding: 1vh;
    padding-bottom: 1vh !important;
    height: fit-content;
    margin-bottom: 1vh;
    grid-template-rows: repeat(6, max-content);
    grid-template-columns: max-content max-content;
    grid-template-areas:
      "file-info-head lock-mobile"
      "file-info-name file-info-name"
      "file-info-items file-info-items"
      "info-iitems info_iitems"
      "tools-dashboard-key-section tools-dashboard-key-section"
      "share-output-tabs share-output-tabs";
    grid-gap: 2vh 1vh;
  }
  @include media-large-device() {
    padding-bottom: 0.8vh;
  }
  @include media-small-desktop() {
    padding-bottom: 0.8vh;
  }
  @include media-xl() {
    padding-bottom: 1vh;
  }
  @include media-tab-landscape-device() {
    padding-bottom: 0.5vh;
  }
  .file_info_back {
    display: none;
    @include media-potrait() {
      @include verticalFlex($width: auto);
    }
  }

  .file_head {
    grid-area: file-info-head;
    @include horizontalFlex($justify: flex-start);

    img {
      height: 4vh;
      width: auto;

      @include media-tab-potrait-device() {
        height: 3vh;
      }
    }

    .file_head_logo {
      width: 5vh;
      aspect-ratio: 1/1;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .file_head_tool_name {
      @include stc-heading-font($bold: bold);

      .file_head_tool_name-txt {
        text-transform: capitalize;
      }
    }
  }

  .file_info_name {
    grid-area: file-info-name;
    @include horizontalFlex();
    @include stc-sub-heading-font();
    text-wrap: wrap;
    @include media-extrasmall-device() {
      @include stc-heading-font();
      width: 90vw;
      white-space: pre-wrap;
    }
  }

  .file_info_coin_wrapper {
    grid-area: file-info-coin;
    @include verticalFlex($justify: flex-start, $align: flex-end);
    width: auto;
    display: none;

    .file_info_coin {
      @include horizontalFlex($width: auto);

      .coin_svg {
        aspect-ratio: 1/1;
        width: 2.5vh;
        background: var(--white);
        border-radius: 2vh;
      }

      .coin_val {
        @include horizontalFlex($width: auto);
      }
    }

    .file_info_error {
      @include horizontalFlex();
      @include stc-normal-font();
      @include stc-badge($color: var(--white));
      padding: 0.5vh 1.5vh;
      background-color: var(--orange);
      color: var(--white);
      border-radius: 0.3vh;
      height: 3.5vh;
      // aspect-ratio: 1/1;
    }

    .file_info_coin_warning {
      @include horizontalFlex($width: auto);
      text-align: right;
      height: 3.5vh;

      @include media-potrait() {
        @include stc-small-font();
        width: 20vh;
        word-wrap: break-word;
      }
    }
  }

  .file_info_error-new {
    @include horizontalFlex();
    @include stc-normal-font();
    @include stc-badge($color: var(--white));
    padding: 0.5vh 1.5vh;
    background-color: var(--orange);
    color: var(--white);
    border-radius: 0.3vh;
    height: 3.5vh;
    width: fit-content;
    justify-self: flex-end;

    @include media-potrait() {
      width: 100%;
    }
  }

  .file_info_items {
    grid-area: file-info-items;
    @include horizontalFlex($justify: flex-start, $gap-size: 2.5vh);

    .file_info_item {
      @include verticalFlex($align: flex-start, $gap-size: 0.5vh);
      min-width: 15vh;
      width: fit-content;
      @include media-potrait() {
        min-width: auto;
      }

      .file_info_item_label {
        color: var(--input-grey);
      }

      .file_info_item_value {
        @include horizontalFlex();
      }
    }
  }
  .file_info_iitems {
    @include media-large-device() {
      display: none;
    }
    @include media-tab-potrait-device() {
      display: none;
    }
    grid-area: file-info-iitems;
    @include horizontalFlex($justify: flex-start, $gap-size: 2.5vh);

    .file_info_iitem {
      @include media-tab-potrait-device() {
        display: none !important;
      }
      @include verticalFlex($align: flex-start, $gap-size: 0.5vh);
      min-width: 15vh;
      width: fit-content;
      @include media-potrait() {
        min-width: auto;
      }

      .file_info_iitem_label {
        color: var(--input-grey);
      }

      .file_info_item_value {
        @include horizontalFlex();
      }
    }
  }
  .tools_share_btn {
  }
}
.share_output_tabs {
  grid-area: share-output-tabs;
  @include horizontalFlex($justify: space-between);

  // @include media-potrait() {
  //   justify-content: flex-start;
  // }
}
.share-decrypt_btns {
  grid-area: share-output-tabs;
  @include horizontalFlex($justify: space-between);
  @include media-large-device() {
    // padding-bottom: 0.6vh;
    padding-bottom: 1vh;
  }
}
// tool decrypt css
.client-dashboard-back-btn {
  display: none;
  justify-content: center;
  vertical-align: middle;
  text-align: center;
  font-size: 20px;
  background: transparent;
  border: none;
  color: var(--white, #ffffff);
}

.client-filename {
  position: relative;
  display: inline-block;
  word-wrap: break-word;
  word-break: break-all;
  /* width: 85%; */
  width: fit-content;
  max-width: 30vw;
  font-size: var(--ml-font);
  font-weight: bold;
  vertical-align: top;
  color: var(--white, #ffffff);
}

.client-file-version-name {
  grid-area: top-dashboard-version;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  max-height: 50px;
  color: var(--label-blue);
  font-size: var(--s-font);
}

.tool-client-file-lock {
  grid-area: key-lock-mobile;
  color: var(--light-blue);
  font-size: var(--l-font);
  display: flex;
  padding-top: 0.5vh;
  justify-content: flex-end;
  @include media-tab-potrait-device() {
    grid-area: auto;
  }
}

#tools-client-file-lock-encrypted {
  color: var(--light-blue);
}

#tools-client-file-lock-decrypted {
  color: var(--white);
}

#tools-client-file-lock-download {
  color: var(--orange);
}
.tool-decrypt-comp {
  @include media-large-device() {
    grid-area: tools-dashboard-key-section;
  }

  @include media-small-desktop() {
  }
}
.st-client-key-button-section {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  justify-content: flex-end;
}

.client-top-dashboard-section {
  font-size: var(--s-font);
  color: var(--white);
  display: grid;
  grid-area: top-dashboard-info-section;
  column-gap: 10px;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fit, minmax(100px, max-content));
  grid-template-rows: 1fr;
}

.client-file-info-head {
  position: relative;
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: center;
  column-gap: 10px;
}

.client-file-info-head-services-count {
  display: none;
}

.client-file-info-head-logo {
  position: relative;
  border-radius: 0px 0px 5px 5px;
  font-size: var(--xs-font);
  display: grid;
  justify-content: center;
  align-items: center;
}

.client-file-info-head-logo img {
  height: 2.5vh;
  width: 2.5vh;
}

.client-file-info-value-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 0.5vh;
}

.client-file-info-value-item {
  display: grid;
  grid-template-columns: max-content max-content;
  justify-content: flex-start;
  column-gap: 3px;
  align-items: center;
  overflow-y: hidden;
  transition: 200ms;
}

.client-file-info-value-item-text {
  display: flex;
  align-items: center;
  word-wrap: break-word;
}

.client-file-info-value-item-tooltip {
  display: flex;
  align-items: center;
}

.client-file-info-head-text {
  font-weight: bold;
  display: flex;
  flex-direction: row;
  gap: 0.5vh;
}

.client-top-details {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-template-rows: 1fr;
  column-gap: 10px;
  font-size: var(--s-font);
}

@include media-tab-potrait-device() {
  /* ================== Top Dashboard Parts file_info_se=============== */

  .client-filename {
    max-width: fit-content;
    font-size: var(--sm-font);
  }

  .client-dashboard-back-btn {
    display: flex;
    justify-content: center;
    vertical-align: middle;
    text-align: center;
    font-size: 20px;
    background: transparent;
    border: none;
    width: fit-content;
    height: fit-content;
    margin-right: 5px;
  }

  /* .client-file-version-name{
        max-height: 45px;
    } */

  .client-file-info-head-services-count-show {
    display: flex;
  }

  .client-file-info-value-item {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    justify-content: flex-start;
    column-gap: 3px;
  }

  .client-file-info-value-item-collapse {
    height: 0px;
  }

  .tool-client-file-lock {
    font-size: var(--sm-font);
    padding: 0px;
  }

  .st-client-key-button-section {
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }

  .client-top-dashboard-section {
    font-size: var(--s-font);
    color: var(--white);
    display: grid;
    column-gap: 10px;
    row-gap: 10px;
    justify-content: start;
    grid-template-columns: 1fr;
  }

  .client-file-info {
    row-gap: 4px;
  }

  .client-file-info-head-logo img {
    height: 3.5vh;
    width: 3.5vh;
  }
}

@media only screen and (max-width: 500px) {
  /* ================== Top Dashboard Parts =============== */

  .client-filename {
    max-width: fit-content;
    font-size: var(--sm-font);
  }

  .client-dashboard-back-btn {
    display: flex;
    justify-content: center;
    vertical-align: middle;
    text-align: center;
    font-size: 20px;
    background: transparent;
    border: none;
    width: fit-content;
    height: fit-content;
    margin-right: 5px;
  }

  /* .client-file-version-name{
        max-height: 45px;
    } */

  .client-file-info-head-services-count-show {
    display: flex;
  }

  .client-file-info-value-item {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    justify-content: flex-start;
    column-gap: 3px;
  }

  .client-file-info-value-item-collapse {
    height: 0px;
  }

  .tool-client-file-lock {
    font-size: var(--sm-font);
    padding: 0px;
  }
  .tool-client-lock-mobile {
    @include media-potrait() {
      display: flex;
      grid-area: lock-mobile;
    }
  }
  .st-client-key-button-section {
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }

  .client-top-dashboard-section {
    font-size: var(--s-font);
    color: var(--white);
    display: grid;
    column-gap: 10px;
    row-gap: 10px;
    justify-content: start;
    grid-template-columns: 1fr;
  }

  .client-file-info {
    row-gap: 4px;
  }

  .client-file-info-head-logo img {
    height: 3.5vh;
    width: 3.5vh;
  }
}
.file_info_mobile {
  @include media-extrasmall-device() {
    display: none !important;
  }
}
.file_info_iitem {
  @include media-large-device() {
    display: none;
  }
  @include media-tab-potrait-device() {
    display: none;
  }
  grid-area: info-iitems;
  @include verticalFlex($align: flex-start, $gap-size: 0.5vh, $justify: center);
  min-width: auto;
  width: fit-content;
  @include media-potrait() {
    min-width: auto;
  }
  .file_info_iitem_label {
    color: var(--input-grey);
  }

  .file_info_iitem_value {
    @include horizontalFlex();
  }
}
.tool-client-dashboard-selector-btn-active {
  color: var(--white);
}
.tool-client-dashboard-selector-btn-active::after {
  position: absolute;
  bottom: -6px;
  left: 0px;
  content: "";
  height: 4px;
  width: 100%;
  background: var(--white, #ffffff);

  @include media-large-device() {
    height: 3px;
    bottom: -4px;
  }

  @include media-small-desktop() {
  }
  @include media-tab-potrait-device() {
    height: 4px;
    bottom: -6px;
  }
  @include media-xl() {
    bottom: -6px;
    height: 4px;
  }
}
.tool-client-share-selector-btn {
  margin-left: auto;
  justify-self: flex-end;
  font-size: var(--s-font);
  padding: 0px 3px;
  @include media-extrasmall-device() {
    font-size: var(--s-font);
  }
}

.search-suggestion-article-type-image-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  width: fit-content;
  color: #000 !important;
}

@media screen and (min-width: 2200px) {
  .search-suggestion-article-type-image-wrapper {
    width: 2rem;
  }
}
.search-suggestion-article-type-image {
  width: 25px;
  height: 15px;
  margin-top: 5px;
}

.search-suggestion-article-type-image-wrapper {
  // filter: invert(75%) sepia(46%) saturate(3116%) hue-rotate(163deg) brightness(98%) contrast(93%);
}

@media only screen and (max-width: 767px) {
  .search-suggestion-article-type-image-wrapper {
    filter: none;
  }
}

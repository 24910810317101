@import "../../../../general";

.tools_consult_messages {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.tools_consult_messages_wrapper {
  @include verticalFlex($justify: flex-start, $gap-size: 2vh);
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  @include media-large-device() {
    // padding-right: 1vh;
    gap: 2vh;
  }
}

.tools_consult_helpful_texts {
  width: 100%;
  @include centerFlex($gap-size: 1vh);
  justify-content: flex-start;
  @include stc-normal-font($fontColor: #5b779c);
}

.tools_consult_feedback_btn_wrapper {
  @include centerFlex($gap-size: 1vh);
  justify-content: center;
  font-size: var(--m-font);
}

.tools_consult_sender_message_wrapper {
  @include horizontalFlex(
    $justify: flex-start,
    $gap-size: 1.5vh,
    $align: flex-start
  );
  align-self: start;
  width: 100%;
  padding-right: 0.5vh;

  @include media-mid-device() {
    max-width: 65%;
    gap: 1.5vh;
  }

  @include media-large-device() {
    align-items: center;
    gap: 2vh;
    max-width: 80%;
  }
}

.tools_consult_reciever_message_wrapper {
  @extend .tools_consult_sender_message_wrapper;
  justify-content: flex-end;
  align-self: end;

  &:hover {
    .tools_consult_message_edit_icon {
      visibility: visible;
    }
  }
}

.tools_consult_reciever_edit_message_wrapper {
  @include horizontalFlex(
    $justify: flex-start,
    $gap-size: 0.5vh,
    $align: flex-start
  );
  align-self: end;
  width: 100%;
  max-width: 80%;

  @include media-large-device() {
    // align-items: center;
    gap: 2vh;
  }
}

.tools_consult_message_container {
  @include verticalFlex($justify: flex-start, $width: 100%, $align: flex-start);
}

.tools_consult_reciever_edit_message_container {
  @include verticalFlex($justify: flex-start, $width: auto, $align: flex-start);
  flex: 1;
}

.tools_consult_sender_text_message_wrapper {
  @include horizontalFlex($width: auto, $align: flex-start);
}

.tools_consult_sender_message_outer_container {
  @include verticalFlex($width: 100%, $align: flex-start);
}

.tools_consult_sender_message_translate_wrapper_ {
  @include horizontalFlex($width: auto, $justify: flex-start);
}

.tools_consult_sender_message_container {
  @extend .tools_consult_message_container;
  align-items: flex-start;
}

.tools_consult_reciever_message_container {
  @extend .tools_consult_message_container;
  align-items: flex-end;
}

.tools_consult_image_wrapper {
  @include verticalFlex($justify: flex-start, $width: auto);
  height: 100%;
  padding-right: 1vh;

  @include media-mid-device() {
    padding-right: 0;
  }
}

.tools_consult_image {
  width: 3rem;

  @include media-large-device() {
    width: 2.5rem;
  }

  @include media-xl() {
    width: 4rem;
  }
}

.tools_consult_user_name_text_wrapper {
  @include verticalFlex($align: flex-start);
  @include stc-normal-font($fontColor: var(--white));
}

.tools_consult_sender_name_text_wrapper {
  @extend .tools_consult_user_name_text_wrapper;
}

.tools_consult_reciever_name_text_wrapper {
  @extend .tools_consult_user_name_text_wrapper;
  align-items: flex-end;

  span {
    text-transform: capitalize;
  }
}

.tools_consult_text_message {
  @include verticalFlex($align: flex-start);
  padding: 1vh;
  border-radius: 12px;
  @include stc-normal-font($fontColor: var(--white));
  overflow-wrap: anywhere;

  @include media-small-device() {
    border-radius: 8px;
  }

  @include media-large-device() {
    padding: 1vh;
    border-radius: 8px;
  }

  @include media-xl() {
    border-radius: 12px;
  }
}

.tools_consult_sender_text_message {
  @extend .tools_consult_text_message;
  background-color: rgba(0, 66, 146, 1);
  margin-left: 0;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: -10px;
    border: 15px solid transparent;
    border-top: 15px solid rgba(0, 66, 146, 1);

    @include media-small-device() {
      left: -8px;
      border: 8px solid transparent;
      border-top: 8px solid rgba(0, 66, 146, 1);
    }

    @include media-large-device() {
      left: -8px;
      border: 8px solid transparent;
      border-top: 8px solid rgba(0, 66, 146, 1);
    }

    @include media-xl() {
      left: -10px;
      border: 15px solid transparent;
      border-top: 15px solid rgba(0, 66, 146, 1);
    }
  }

  p,
  ol,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  ul,
  ol,
  th,
  td {
    margin: 0;
    // padding: 0;
  }

  ol,
  ul {
    padding-left: 1rem;
  }
}

.tools_consult_reciever_text_message {
  @extend .tools_consult_text_message;
  background-color: rgba(38, 51, 69, 1);
  cursor: pointer;
  transition: all 0.3s ease-out;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: -10px;
    border: 15px solid transparent;
    border-top: 15px solid rgba(38, 51, 69, 1);

    @include media-small-device() {
      right: -8px;
      border: 8px solid transparent;
      border-top: 8px solid rgb(38, 51, 69);
    }

    @include media-large-device() {
      right: -8px;
      border: 8px solid transparent;
      border-top: 8px solid rgb(38, 51, 69);
    }

    @include media-xl() {
      right: -10px;
      border: 15px solid transparent;
      border-top: 15px solid rgba(38, 51, 69, 1);
    }
  }
}

.tools_consult_reciever_text_message_edit_mode {
  @extend .tools_consult_text_message;
  background-color: #394d67;
  transition: all 0.3s ease-out;
}

.tools_consult_reference {
  // display: grid;
  // grid-template-columns: 1fr;
  // grid-template-rows: repeat(3, 1fr);
  // width: auto;
  // gap: 1vh;

  // @include media-large-device() {
  //   grid-template-rows: 1fr;
  //   grid-template-columns: repeat(3, 1fr);
  //   height: auto;
  // }
  @include horizontalFlex($justify: flex-start);
}

.tools_consult_sender_reference {
  @extend .tools_consult_reference;
  width: 90%;

  @include media-small-device() {
    width: 100%;
  }
}

.tools_consult_reciever_image_container {
  @include horizontalFlex($justify: flex-start, $width: auto);
  height: 100%;
  padding-left: 1vh;

  @include media-mid-device() {
    padding-left: 0;
  }
}

.tools_consult_reciever_image_wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  width: 3rem;
  height: 3rem;
  border-radius: 50%;

  @include media-mid-device() {
    width: 3rem;
    height: 3rem;
  }

  @include media-large-device() {
    width: 2.5rem;
    height: 2.5rem;
  }

  @include media-xl() {
    width: 4rem;
    height: 4rem;
  }
}

.tools_consult_reciever_name_initials {
  @include stc-heading-font($fontColor: var(--dark-blue));
  text-transform: uppercase;
}

.tools_consult_notification_msg_wrapper {
  @include horizontalFlex($justify: center);
}

.tools_consult_notification_msg_container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(38, 51, 69, 1);
  padding: 0.5rem 1rem;
  border-radius: 4px;
  @include stc-normal-font($fontColor: var(--white));
}

.tools_consult_sender_reference_content_wrapper {
  // padding: 5px 0px;
  // width: 100%;
  // background-color: rgba(0, 66, 146, 0.35);

  // @include media-large-device() {
  //   width: auto;
  // }
  @include verticalFlex($width: 5.7rem);
  padding: 1vh;
  background-color: rgba(0, 66, 146, 0.35);
  border-radius: 8px;
  cursor: pointer;
  word-break: break-all;
  height: 100%;
  height: 10rem;

  img {
    position: relative;
    width: 100%;
    height: 5rem;
    width: 4.7rem;
  }
}

.scrollable {
  overflow-x: auto;
  white-space: nowrap;
}

.tools_consult_sender_active_reference_content_wrapper {
  background-color: rgba(0, 66, 146, 1);
}

.tools_consult_sender_reference_content {
  @include verticalFlex($align: flex-start);
  border-left: 2px solid var(--white);
  padding: 0px 1vh;
  flex-direction: row;
  height: 100%;

  @include media-large-device() {
    flex-direction: column;
  }
}

.tools_consult_file_reference_text {
  @include stc-normal-font($fontColor: var(--white));
  position: relative;
  margin: 0;
  word-wrap: break-word;
  line-break: anywhere;
  word-wrap: nowrap;
  text-overflow: ellipsis;
}

.tools_consult_file_reference_btn_wrapper {
  @include verticalFlex($justify: flex-end, $align: flex-end, $width: auto);

  @include media-large-device() {
    width: 100%;
  }
}

.tools_consult_file_reference_btn {
  background: none;
  border: none;
  text-align: end;

  svg {
    @include stc-heading-font($fontColor: var(--white));
    transform: rotate(-90deg);
  }
}

.tools_consult_text_translate {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  // padding-top: 3.5vh;
}

.tools_consult_user_message_tools {
  @include verticalFlex($width: auto, $gap-size: 0.5vh);
  height: 100%;
}

.tools_consult_user_message_tools_wrapper {
  @include horizontalFlex($width: auto, $align: flex-start);
}

.tools_consult_user_message_edit_wrapper {
  @include verticalFlex($align: flex-start);
}

.tools_message_and_thread_switch_wrapper {
  @include verticalFlex($align: flex-end);
}

.tools_consult_chat_flow_switch_btn_wrapper {
  @include horizontalFlex($width: auto, $gap-size: 0vh);
}

.tools_consult_chat_switch_btn {
  border: none;
  background: none;

  svg {
    @include stc-heading-font($fontColor: #5b779c);
  }

  &:disabled {
    svg {
      color: gray;
    }
  }
}

.tools_consult_user_message_thread_info {
  @include stc-normal-font($fontColor: #5b779c);
}

.tools_consult_message_edit_wrapper {
  @include verticalFlex();
}

.tools_consult_message_edit_input {
  background-color: transparent;
  border: none;
  outline: none;
  resize: none;
  width: 100%;
  @include stc-normal-font($fontColor: var(--white));
}

.tools_consult_message_edit_btn_wrapper {
  @include horizontalFlex($justify: flex-end);
}

.tools_consult_message_btn {
  padding: 0.5rem 1.5rem;
  @include stc-normal-font($fontColor: var(--white));
  border: none;
  border-radius: 4px;
}

.tools_consult_message_edit_submit_btn {
  @extend .tools_consult_message_btn;
  background-color: #004292;
}

.tools_consult_message_cancel_submit_btn {
  @extend .tools_consult_message_btn;
  background-color: var(--dark-blue-v2);
  border-radius: 1px solid #394d67;
}

.tools_consult_message_edit_icon,
.tool_consult_feedback_thumb,
.tools_consult_translate_image {
  cursor: pointer;
}

.tools_consult_message_edit_icon {
  visibility: hidden;
  transition: all 0.3s ease-out;
  width: 1.2rem;

  @include media-small-device() {
    width: 1rem;
  }

  @include media-large-device() {
    width: 1.2rem;
  }

  @include media-xl() {
    width: 1.5rem;
  }
}

.tool_consult_feedback_thumb_btn {
  border: none;
  background: none;
  outline: none;
  display: flex;

  svg {
    fill: #5b779c;
    width: 1rem;

    @include media-small-device() {
      width: 1rem;
    }

    @include media-large-device() {
      width: 0.9rem;
    }

    @include media-xl() {
      width: 1.2rem;
    }
  }
}

.tool_consult_feedback_thumb_selected {
  border: none;
  background: none;
  outline: none;
  display: flex;

  svg {
    fill: rgba(0, 66, 146, 1);
    width: 1rem;

    @include media-small-device() {
      width: 1rem;
    }

    @include media-large-device() {
      width: 0.9rem;
    }

    @include media-xl() {
      width: 1.2rem;
    }
  }
}

.tools_consult_translate_image {
  width: 1.5rem;

  @include media-small-device() {
    width: 1.2rem;
  }

  @include media-mid-device() {
    width: 1.5rem;
  }

  @include media-xl() {
    width: 2rem;
  }
}

.tools_consult_sender_reference_source_file_previews_wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  width: 100%;
  gap: 1vh;

  @include media-large-device() {
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
  }

  @include media-tablet-1000p() {
    grid-template-columns: 1fr;
    grid-auto-rows: 1fr;
  }

  .tools_consult_sender_reference_file_preview_see_more_btn_wrapper {
    @include horizontalFlex();
    display: none;

    @include media-large-device() {
      display: flex;
    }
  }
}

.tools_consult_sender_reference_file_preview_see_more_btn_wrapper_mobile {
  @include horizontalFlex();
  flex-direction: row-reverse;
  background: var(--dark-blue-v3);
  padding: 0.5rem;
  border-radius: 8px;

  @include media-large-device() {
    display: none;
  }
}

.tools_consult_sender_reference_file_preview_see_more_btn {
  @include horizontalFlex();
  // flex-direction: row-reverse;
  background: var(--dark-blue-v3);
  border: none;
  outline: none;
  @include stc-normal-font($fontColor: var(--white));
  height: 100%;
  padding: 0.5rem;
  border-radius: 8px;

  @include media-large-device() {
    @include verticalFlex($justify: center);
    padding: 1rem;
  }

  svg {
    font-size: var(--m-font);

    @include media-large-device() {
      font-size: var(--xl-font);
    }
  }
}

.tools_consult_sender_reference_file_preview {
  @include verticalFlex($align: flex-start, $justify: flex-start);
  padding: 0.5rem;
  border-radius: 8px;
  height: 100%;
  background: var(--dark-blue-v3);
  cursor: pointer;

  .tools_consult_bot_message_reference_header_wrapper {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: 1fr;
    width: 100%;
    gap: 1vh;
    place-items: center;

    .tools_consult_bot_message_reference_header_text_wrapper {
      @include horizontalFlex($align: flex-start);
      background-color: #ffffff1a;
      padding: 0.5vh;
      border-radius: 4px;
      height: 100%;

      .tools_consult_bot_message_reference_quote_icon {
        position: absolute;
        top: 2px;
        left: 2px;
      }

      .tools_consult_bot_message_reference_text {
        @include stc-normal-font();

        position: relative;
        display: -webkit-box;
        line-clamp: 2;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        word-break: break-word;

        @include media-large-device() {
          line-clamp: 3;
          -webkit-line-clamp: 3;
        }
      }
    }
  }

  .tools_consult_bot_message_reference_file_data {
    @include horizontalFlex();
    // overflow: hidden;
    @include stc-normal-font();

    .tools_consult_bot_message_reference_file_name_wrapper {
      @include horizontalFlex($width: auto, $gap-size: 0vh);
      word-break: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;

      // .tools_consult_bot_message_reference_file_name {
      //   overflow: hidden;
      //   white-space: nowrap;
      //   text-overflow: ellipsis;
      //   max-width: 17ch;
      // }

      // .tools_consult_bot_message_reference_file_extension {
      //   flex-shrink: 0;
      // }
    }
  }

  .tools_consult_bot_message_reference_weblink {
    display: flex;
    white-space: nowrap;
    overflow: hidden;
  }

  .first_part {
    flex-shrink: 0;
  }

  .masked_middle {
    flex-grow: 1;
    overflow: hidden;
    white-space: nowrap;
    max-width: 20ch;
  }

  .last_part {
    flex-shrink: 0;
  }

  .file_dropdown_option_wrapper {
    @include horizontalFlex($justify: flex-end);
  }

  .tools_consult_bot_message_reference_type_image {
    position: relative;
    width: 7vw;

    @include media-small-device() {
      width: 4vw;
    }

    @include media-large-device() {
      width: 1.4vw;
    }
  }

  .file_name {
    position: relative;
    margin: 0;
    word-wrap: break-word;
    line-break: anywhere;
  }

  .file_dropdown {
    @include verticalFlex($gap-size: 0.5rem, $align: flex-start);
    width: fit-content;
    position: absolute;
    top: 0;
    left: 6rem;
    z-index: 10;
    background-color: var(--white);
    padding: 0.5rem;
    width: 9.5rem;
    border-radius: 4px;
    color: var(--dark-blue);
    list-style: none;
    font-size: var(--rem-base-font);
    animation: expand 0.3s forwards;

    .file_dropdown_option {
      font-family: "Louis-George-Cafe";
      white-space: nowrap;
      word-wrap: nowrap;
      animation: fadeIn 0.4s linear;
    }
  }
}

.tools_consult_sender_active_reference_content_wrapper {
  background-color: rgba(0, 66, 146, 1);
}

.tool_consult_output_reference_dropdown_wrapper {
  @include verticalFlex($width: 11rem, $align: flex-start);
  position: absolute;
  top: 1rem;
  left: 5.8rem;
  z-index: 5;
  padding: 1vh;
  background-color: var(--white);
  border-radius: 8px;
  @include stc-normal-font($fontColor: var(--dark-blue));
  box-shadow: 0px 0px 20px 0px #11294940;
}

.cursor_not_allowed {
  cursor: not-allowed;
}

.tools_consult_bot_message_reference_file_page_tooltip_container {
  @include horizontalFlex($width: auto, $gap-size: 0vh);
  position: absolute;
  visibility: hidden;
  top: 40px;
  left: 0;
  cursor: pointer;
  // display: inline-block;
  text-align: left;
  padding: 5px;
  border-radius: 5px;
  z-index: 1;
  background-color: var(--white);
  @include stc-normal-font($fontColor: var(--black));
  border: 2px solid #052144;
  text-wrap: nowrap;

  &::after {
    content: "";
    position: absolute;
    top: -14px;
    left: 1rem;
    transform: translateX(-50%);
    border-width: 0 7px 15px;
    border-style: solid;
    border-color: transparent transparent var(--white) transparent;
  }
}

.tools_consult_bot_message_reference_file_page_count {
  position: relative;
  background: #ffffff1a;
  padding: 5px;
  border-radius: 4px;
  text-wrap: nowrap;

  &:hover {
    .tools_consult_bot_message_reference_file_page_tooltip_container {
      visibility: visible;
    }
  }
}

.tools_consult_bot_message_reference_file_page_tooltip_content {
  @include horizontalFlex($gap-size: 0vh);
}

.tools_consult_bot_message_reference_view_more_wrapper {
  @include horizontalFlex($width: auto);
  @include stc-normal-font($fontColor: var(--white));
}
.tools_consult_bot_message_reference_view_more_type_image {
  width: 1.2rem;
  position: relative;
  animation: moveImage 1s infinite alternate ease-in-out;

  @keyframes moveImage {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(10px);
    }
  }
}

.tools_consult_similar_results_heading_wrapper {
  @include horizontalFlex($align: center, $justify: flex-start);
}

.tools_consult_similar_results_section {
  @include verticalFlex($align: flex-start);
}

.tools_consult_similar_results_heading_text {
  @include stc-normal-font($fontColor: #5b779c);
}

.tools_consult_similar_results_heading_bar {
  flex: 1;
  width: 100%;
  height: 1px;
  background-color: #5b779c;
}

@import "../../general";

.files_pane_item {
  background: #0e223d80;
  border: 1px solid #394d67;
  position: relative;
  height: 0px;
  margin: 0px;
  padding: 0px;
  box-shadow: 0px 0px 20px 0px #11294940;
  box-sizing: border-box;
  color: var(--white, #ffffff);
  cursor: pointer;
  border-radius: 12px;
  box-sizing: border-box;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-template-rows: repeat(3, max-content);
  grid-template-areas:
    "sidebar-file-services sidebar-file-feedback"
    "sidebar-file-names sidebar-file-names"
    "sidebar-file-subservices sidebar-file-date";
  justify-content: space-between;
  align-items: center;
  row-gap: 10px;
  column-gap: 80px;

  @include media-large-device() {
    border-radius: 7px;
    column-gap: 40px;
    font-size: 8px;
    color: var(--white);
    // @include stc-small-font($fontColor: var(--white));
  }
  @include media-small-desktop() {
    @include stc-normal-font();
    border-radius: 10px;
    column-gap: 75px;
    row-gap: 9px;
  }
  @include media-xl() {
    border-radius: 12px;
    column-gap: 80px;
    row-gap: 10px;
  }
  .file_service_tag {
    @include horizontalFlex();
    grid-area: sidebar-file-services;
    // @include stc-badge($color: rgba(241, 247, 249, 0.1));
    color: var(--white);
    border-radius: 0px 0px 5px 5px;
    // padding: 0vh 1vh 1vh 1vh;

    @include media-large-device() {
      width: min-content;
      // padding: 0vh 0.7vh 0.7vh 0.7vh;
      border-radius: 0px 0px 3px 3px;
    }

    @include media-small-desktop() {
      width: min-content;
      // padding: 0vh 0.8vh 0.8vh 0.8vh;
      border-radius: 0px 0px 4px 4px;
    }
    @include media-xl() {
      // padding: 0vh 1vh 1vh 1vh;
      border-radius: 0px 0px 5px 5px;
    }
    img {
      aspect-ratio: 1/1;
      height: 2.5vh;

      @include media-large-device() {
        height: 2vh;
      }
      @include media-small-desktop() {
        height: 2.2vh;
      }
      @include media-xl() {
        height: 2.5vh;
      }
    }
  }

  .file_feedback {
    grid-area: sidebar-file-feedback;
    justify-self: flex-end;
    svg {
      aspect-ratio: 1/1;
      height: 2.5vh;
      width: 2.5vh;
    }
  }

  .file_name {
    grid-area: sidebar-file-names;
  }

  .file_count {
  }

  .file_date {
    text-align: end;
  }
}

.files_pane_item-show {
  height: fit-content;
  padding: 0px 10px 5px 10px;

  @include media-large-device() {
    padding: 0px 5px 3px 5px;
  }
  @include media-small-desktop() {
    padding: 0px 8px 4px 8px;
  }
  @include media-xl() {
    padding: 0px 10px 5px 10px;
  }
}

@import "../../../general";

.tools_share_warning_modal {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.6);
}
.tool-client-share-warning-modal-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  row-gap: 1vh;
  width: 30vw;
  font-size: var(--s-font);
  border-radius: 10px;
  color: var(--white);
  // top: 50%;
  // transform: translate(-10%, -50%);
  background: #0e223d;
}

#tool-client-share-warning-heading {
  color: var(--orange);
}

#tool-client-share-warning-heading-close-btn {
  color: var(--light-blue, #3cc3f2);
}

.tool-client-share-warning-modal-text-container {
  display: flex;
  flex-direction: column;
  gap: 0.9vh;
}

.tool-client-share-warning-modal-para-span-orange {
  display: block;
  color: var(--orange);
}

.tool-client-share-warning-names-list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
}

.tool-client-share-warning-names-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  row-gap: 5px;
  column-gap: 5px;
  flex-direction: column;
}

.tool-client-share-warning-name-org-heading {
  display: flex;
  padding-top: 0.5vh;
}

.tool-client-share-warning-name-item {
  position: relative;
  padding: 5px;
  background-color: var(--light-blue, #3cc3f2);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 5px;
  font-size: var(--s-font);
  width: fit-content;
  flex-wrap: wrap;
  /* flex-direction: column; */
}
.tool-client-share-warning-name-item-err {
  background: var(--orange, #ea7b2c);
}

.tool-client-share-warning-remove-btn {
  background: none;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
}

.tool-client-share-warning-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 1vh;
}

.tool-client-share-warning-btn-container button {
  background-color: var(--light-blue, #3cc3f2);
}

#tool-client-share-warning-modal-cancel-btn {
  background-color: transparent;
  color: var(--light-blue, #3cc3f2);
  padding: 0px;
  margin: 0px;
  min-width: fit-content;
}

.tool-client-share-warning-modal-err-msg {
  position: relative;
  top: 50%;
  font-size: var(--xs-font);
  width: -moz-fit-content;
  width: fit-content;
  color: var(--orange, #ea7b2c);
}

/* ==============IPAD - TABLET=================== */

@include media-tab-potrait-device() {
  .tool-client-share-warning-modal-container {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: calc(100% - 20px);
    top: 50%;
    left: 0%;
    margin: 10px;
    transform: translate(0%, -50%);
  }
}

@media only screen and (max-width: 500px) {
  /* ============ SHARING WARNING MODAL ================= */
  .tool-client-share-warning-modal-container {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: calc(100% - 20px);
    top: 50%;
    left: 0%;
    margin: 10px;
    font-size: var(--s-font);
    transform: translate(0%, -50%);
  }
}

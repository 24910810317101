@import "../../../general";

.creatable-select-from-group {
  @include lib-input-group();

  .creatable-select-from-label {
    @include lib-input-label();
  }

  .creatable-select-from-input {
    @include lib-input-input();
  }

  .select__control {
    min-height: 38px;
    background-color: rgba(10, 24, 42, 1);
    width: 100%;
  }
}

.creatable-select-from-group-select {
  @include verticalFlex();
  width: 100%;
}
// .select__multi-value--is-disabled {
//   background-color: rgba(10, 24, 42, 1) !important;
// }
.css-ros2d1-control {
  // padding: 10px !important;
  // background-color: red !important;
}

.css-1lr4gxb-ValueContainer {
  // color: green !important;
}
.css-1jqq78o-placeholder {
  color: grey !important;
  font-style: italic;
  font-size: 14px !important;
}
.css-166bipr-Input {
  color: white !important;
}
.select__input-container {
  color: white !important;
}
.css-1aek7lh-control:hover {
  border-color: #394d67 !important;
}
.css-1qg01vj-control {
  &:focus,
  &:hover {
    border-color: #394d67 !important;
    box-shadow: none !important;
  }
}

@import "./../../../general";

.flipAi_MainContainer {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.6);
}

.flipAi_MainWrapper {
  width: 310px;
  height: 215px;
  background-color: #fff;
  border-radius: 8px;
  position: relative;
  @include media-small-device() {
    width: 80vw;
    height: 40vh;
  }
  @include media-large-device() {
    width: 70vw;
    height: 70vh;
  }
  @include media-tab-potrait-device() {
    width: 90%;
  }
  @include media-extrasmall-device() {
    width: 90%;
  }
}
.flipVideoClose_btn {
  position: absolute;
  top: -38px;
  right: 10px;
  z-index: 10;
  cursor: pointer;
  font-size: 34px;
  color: #000000;
  // color: black;
  // background-color: var(--white);
  border-radius: 15px;
}
.flipAiIframeSection {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

/* ========= CLIENT SIDEBAR CSS ========== */

.client-sidebar {
  position: relative;
  grid-area: sidebar;
  height: 100vh;
  display: flex;
  flex-direction: column;
  /* background: var(--vertical-blue-black-gradient); */
  background: transparent;
  /* border-right: 1px solid var(--light-grey); */
}

.client-account-sidebar {
  -webkit-box-shadow: 0px 4px 4px 0px var(--shadow-color);
  -moz-box-shadow: 0px 4px 4px 0px var(--shadow-color);
  box-shadow: 0px 4px 4px 0px var(--shadow-color);
  z-index: 2;
}

.client-file-history-head {
  position: relative;
  display: block;
  height: fit-content;
  -webkit-box-shadow: 2px 4px 4px 0px var(--shadow-color);
  -moz-box-shadow: 2px 4px 4px 0px var(--shadow-color);
  box-shadow: 2px 4px 4px 0px var(--shadow-color);
  z-index: 4;
  padding: 0px 10px 10px 10px;
  background: var(--dark-blue, #112949);
  /* background: white; */
  /* border-bottom: 1px solid var(--light-grey); */
}

.client-file-history-head-top {
  position: relative;
  display: flex;
  align-items: center;
  height: 60px;
  justify-content: space-between;
  /* padding-left: 10px; */
  /* padding: 10px;
    padding-bottom: 0px;
    margin-bottom: 12px; */
}

.client-sidebar-head {
  position: relative;
  display: inline-flex;
  align-items: center;
  column-gap: 10px;
  margin-right: 10px;
  color: var(--white, #ffffff);
}

.client-sidebar-head-text {
  font-size: var(--xl-font);
}

.client-sort-btn {
  position: relative;
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  font-size: var(--m-font);
  width: fit-content;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: var(--white, #ffffff);
}

.client-new-file-btn {
  position: relative;
  float: right;
  color: white;
  background: var(--light-blue, #3cc3f2);
  transition: 150ms;
  padding: 5px;
  min-width: fit-content;
}

.client-add-btn {
  height: 30px;
  width: 30px;
}

/* .client-new-file-btn:hover{
    transform: scale(1.05);
  } */

.client-search-sort-form {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  /* padding: 10px;
    padding-top: 5px; */
}

.client-search-input {
  background: transparent;
  /* position: relative;
    width: 100%;
    font-size: var(--s-font);
    outline: none;
    padding: 6px 10px;
    border-radius: 6px;
    color: var(--white,#ffffff);
    border: 0.5px solid rgba( 255, 255, 255, 0.4 ); */
}

.client-search-btn {
  position: absolute;
  display: inline-flex;
  align-items: center;
  /* bottom: 6.5px; */
  right: 10px;
  /* width: 35px; */
  font-size: var(--m-font);
  background: transparent;
  border: none;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
  color: var(--dark-grey);
  transition: 150ms;
}

.client-search-btn:hover {
  transform: scale(1.05);
}

/* ============ SIDEBAR FILES ==============*/

.client-files-history {
  position: relative;
  flex: 1;
  width: 100%;
  /* border-right: 1px solid rgba( 255, 255, 255, 0.4 ); */
  overflow-y: scroll;
  overflow-x: hidden;
  transition: 300ms;
  -webkit-box-shadow: 0px 4px 4px 0px var(--shadow-color);
  -moz-box-shadow: 0px 4px 4px 0px var(--shadow-color);
  box-shadow: 0px 4px 4px 0px var(--shadow-color);
  z-index: 2;
}

/* to keep the scrollbar hidden when not hovered */
/* .client-files-history::-webkit-scrollbar {
      width: 1px;
  }

  .client-files-history:hover::-webkit-scrollbar {
      width: 3px;
      transition: 1s;
  } */

/* .client-files-history::-webkit-scrollbar {
    position: absolute;
    overflow-y: overlay;
    display: block;
    width: 0.6vh;
    transition: width 300ms;
  }

  .client-files-history::-webkit-scrollbar-track {
    background-color: var(--light-grey);
    transition: 300ms;
  }

  .client-files-history::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 10px;
    transition: 300ms;
  }

  .client-files-history:hover::-webkit-scrollbar-thumb {
    background-color: var(--dark-blue,#112949);
  }*/

/* .client-files-history:hover::-webkit-scrollbar {
    width: 3px;
  } */

.client-month-container {
  position: relative;
  display: block;
  cursor: pointer;
  overflow: hidden;
  height: auto;
  transition: height 500ms;
  box-sizing: border-box;
  /* -webkit-box-shadow: 0px 0px 4px 1px var(--shadow-color);
    -moz-box-shadow: 0px 0px 4px 1px var(--shadow-color);
    box-shadow: 0px 0px 4px 1px var(--shadow-color); */
}

.client-month-container-hidden {
  height: 22px;
}

.client-sidebar-month-head {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  vertical-align: middle;
  padding: 10px;
}

.client-month {
  position: relative;
  font-size: var(--s-font);
  color: var(--label-blue, #9cbdeb);
}

.client-month-arrow {
  position: relative;
  font-size: var(--m-font);
  color: var(--dark-grey);
  transition: 0.3s;
}

.client-month-arrow-active {
  transform: rotateZ(-90deg);
}

.client-file-list {
  position: relative;
  overflow-y: hidden;
  height: fit-content;
  width: 100%;
  transition: 0.3s;
  display: flex;
  flex-direction: column;
}

/* .client-sidebar-file {
    position: relative;
    height: 0px;
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    color: var(--white,#ffffff);
    cursor: pointer;
    border: none;
    border-radius: 5px;
    transition: 100ms;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 10px;
  } */

.client-sidebar-file {
  position: relative;
  height: 0px;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  color: var(--white, #ffffff);
  cursor: pointer;
  border: none;
  border-radius: 5px;
  transition: 100ms;
  box-sizing: border-box;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-template-rows: repeat(3, max-content);
  grid-template-areas:
    "sidebar-file-services sidebar-file-feedback"
    "sidebar-file-names sidebar-file-names"
    "sidebar-file-subservices sidebar-file-date";
  justify-content: space-between;
  align-items: center;
  row-gap: 10px;
}

.client-sidebar-file:hover {
  border-left: none;
  border-right: none;
}

.client-sidebar-file-active {
  background: linear-gradient(
    90deg,
    rgba(17, 41, 73, 0.25) 0%,
    rgba(0, 0, 0, 0.25) 100%
  );
  backdrop-filter: 0.8;
}

#client-dashboard-archive-month {
  display: block;
  width: 100%;
  text-align: center;
  cursor: pointer;
}

.client-sidebar-archive-file {
  padding: 0px;
}
.client-sidebar-archive-file-show {
  height: fit-content;
  margin: 2px 10px;
  padding: 10px;
}

.client-sidebar-file-show {
  height: fit-content;
  margin: 2px 10px;
  padding: 0px 10px 5px 10px;
}

.client-sidebar-file-hide {
  height: 0px;
  margin: 0px;
  padding: 0px;
}

.client-sidebar-file-locked {
  color: var(--light-grey);
  opacity: 0.9;
}

.client-sidebar-file-feedback {
  grid-area: sidebar-file-feedback;
  justify-self: flex-end;
}

.client-sidebar-file-feedback .rating-star {
  height: 2vh;
  width: 2vh;
  color: var(--light-grey);
}

.client-sidebar-file-lock {
  grid-area: sidebar-file-lock;
  position: relative;
  right: 0px;
  color: var(--light-blue);
}

.client-sidebar-file-lock-download {
  color: var(--orange, #ea7b2c);
}

.client-sidebar-file-info {
  position: relative;
  display: inline-block;
  font-size: var(--s-font);
}

.client-sidebar-file-subservice-details {
  grid-area: sidebar-file-subservices;
  display: flex;
  gap: 0.5vh;
}

.client-sidebar-file-date {
  grid-area: sidebar-file-date;
  justify-self: flex-end;
}

.client-sidebar-file-icon {
  position: relative;
  display: inline-block;
  height: 40px;
  width: 40px;
}

.client-sidebar-file-icon .client-file-icon {
  width: 100%;
  height: 100%;
}

.client-sidebar-file-filename {
  grid-area: sidebar-file-names;
  display: flex;
  gap: 10px;
  font-size: var(--s-font);
  align-items: center;
  width: 100%;
}

.client-file-stages {
  grid-area: sidebar-file-services;
  position: relative;
  display: flex;
  flex-direction: row;
  column-gap: 5px;
}

.client-file-stage {
  position: relative;
  border-radius: 0px 0px 5px 5px;
  font-size: var(--xs-font);
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(241, 247, 249, 0.1);
  /* -webkit-box-shadow: 0px 0px 4px 1px var(--shadow-color);
    -moz-box-shadow: 0px 0px 4px 1px var(--shadow-color);
    box-shadow: 0px 0px 4px 1px var(--shadow-color); */
}

.file-type-svg {
  width: 80%;
}

.client-file-stage-active {
  /* background: rgba(17, 41, 73, 0.4); */
  background: rgba(0, 0, 0, 0.4);
}

.client-file-stage-active .file-type-svg {
  color: var(--light-blue, #3cc3f2);
}

.client-file-history-empty {
  position: relative;
  display: block;
  width: 100%;
  text-align: center;
  font-style: italic;
  font-size: var(--s-font);
  color: var(--white, #ffffff);
  margin: 10px auto;
}

/* =============== 720p ==================== */

@media only screen and (max-width: 1460px) {
  /* =========== Sidebar ======================= */
  .client-file-history-head-top {
    height: 50px;
  }

  .client-new-file-btn {
    padding: 2px;
    min-width: fit-content;
  }

  .client-new-file-btn {
    border-radius: 5px;
  }

  /* .client-search-btn{
        right: 5px;
        height: 28px;
        width: 28px;
    } */

  .client-sidebar-file-icon {
    height: 28px;
    width: 28px;
  }

  /* .client-sidebar-file{
        row-gap: 5px;
    } */

  /* .client-sidebar-file-details{
        margin-top: 10px;
    } */

  .client-file-stage {
    padding: 2px 3px;
    border-radius: 0px 0px 3px 3px;
    height: 30px;
    width: 30px;
  }

  /* .client-files-history::-webkit-scrollbar {
        width: 1px;
    }

    .client-files-history:hover::-webkit-scrollbar {
        width: 3px;
    } */
}

/* ===================== IPAD =============== */

@media only screen and (min-width: 700px) and (max-width: 780px) and (min-height: 900px) and (max-height: 1100px),
  (min-width: 700px) and (max-width: 1000px) and (min-height: 700px) and (max-height: 1000px),
  (min-width: 1000px) and (max-width: 1100px) and (min-height: 1300px) and (max-height: 1400px),
  (min-width: 500px) and (max-width: 550px) and (min-height: 700px) and (max-height: 730px),
  (min-width: 750px) and (max-width: 880px) and (min-height: 900px) and (max-height: 1200px),
  (min-width: 800px) and (max-width: 1000px) and (min-height: 1300px) and (max-height: 1400px),
  (min-width: 500px) and (max-width: 580px) and (min-height: 700px) and (max-height: 750px) {
  /* ========= CLIENT SIDEBAR CSS ========== */

  .client-sidebar {
    position: absolute;
    grid-area: none;
    top: 50%;
    transform: translateY(-50%);
    left: -100vw;
    width: 100vw;
    display: inline-block;
    /* z-index: 1; */
    height: calc(100vh - 12vh);
    overflow-y: auto;
    transition: 300ms;
    background: var(--vertical-blue-black-gradient);
  }

  .client-new-file-btn {
    padding: 2px;
    min-width: fit-content;
  }

  .client-file-history-head-top {
    margin: 10px auto;
  }

  .client-add-btn {
    height: 30px;
    width: 30px;
  }

  .client-sidebar-file {
    row-gap: 15px;
  }

  .client-file-stage {
    height: 40px;
    width: 40px;
  }

  .client-sidebar-file-lock svg {
    height: 20px;
    width: 20px;
  }

  .client-sidebar-file-lock-revoked {
    color: var(--orange);
  }

  .client-sidebar-show {
    left: 0px;
  }

  /* #client-file-sidebar{
        z-index: 3;
      } */

  .client-sidebar-mobile {
    z-index: 3;
  }

  .client-files-history {
    overflow-y: overlay;
  }

  .client-sidebar-file-feedback .rating-star {
    height: 2.5vh;
    width: 2.5vh;
    color: var(--light-grey);
  }
}

/* ============ Mobile ================= */

@media only screen and (max-width: 500px) {
  /* ========= CLIENT SIDEBAR CSS ========== */

  .client-sidebar {
    position: absolute;
    grid-area: none;
    top: 50px;
    left: -100vw;
    width: 100vw;
    display: inline-block;
    /* z-index: 1; */
    height: calc(100vh - 100px);
    overflow-y: auto;
    transition: 300ms;
    background: var(--vertical-blue-black-gradient);
  }

  .client-new-file-btn {
    padding: 2px;
    min-width: fit-content;
  }

  /* .client-add-btn{
        height: 20px;
        width: 20px;
    } */

  .client-sidebar-show {
    left: 0px;
  }

  /* #client-file-sidebar{
        z-index: 3;
      } */
  .client-sidebar-mobile {
    z-index: 3;
  }

  .client-files-history {
    overflow-y: overlay;
  }

  .client-sidebar-file-feedback .rating-star {
    height: 2.5vh;
    width: 2.5vh;
    color: var(--light-grey);
  }
}

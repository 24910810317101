@import "../../../general";

.text-area-input-container {
  @include lib-input-group();
  color: var(--white);

  .text-area-input-label {
    @include lib-input-label();
  }

  .text-area-input {
    outline: none !important;
    @include lib-input-input();
    resize: none;
    flex: 0 0 110px;
    border: 1px solid rgba(57, 77, 103, 1);
  }
  .text-area-input:focus {
    border: 1px solid var(--white);
  }
}
.text-area-input::placeholder {
  color: grey !important;
  font-size: 14px !important;
}

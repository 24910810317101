@import "../common";

.footer_mainContainer {
  width: 100%;
  height: 100%;
}

.footer_primaryContainer {
  @include verticalFlex($align: flex-start, $gap: 4rem);
  height: auto;
  background: #212121;
  padding: 3rem 1.7rem;
  overflow: hidden;
  @include media-350p() {
    padding: var(--rem-5xl-font);
  }
  @include media-small-device() {
    padding: 30px;
    flex-direction: column;
    gap: var(--rem-5xl-font);
  }
  @include media-mid-device() {
    padding: var(--rem-5xl-font);
    flex-direction: column;
    gap: var(--rem-5xl-font);
  }
  @include media-large-device() {
    padding: var(--rem-5xl-font);
    flex-direction: column;
  }
  @include media-small-desktop() {
    padding: var(--rem-5xl-font);
    flex-direction: row;
  }
  @include media-xl() {
    padding: 3rem 12rem;
    flex-direction: row;
    gap: var(--rem-5xl-font);
  }
}

.top_gradient {
  position: absolute;
  right: 0;
  top: 0;
  height: 50%;

  @include media-large-device() {
    height: 100%;
  }

  svg {
    height: 100%;
  }
}
.bottom_gradient {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 50%;
  transform: rotate(180deg);

  @include media-large-device() {
    height: 100%;
  }

  svg {
    height: 100%;
  }
}

.footer_logotext_mainContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.footer_logoinsideTextCont {
  color: white;
  font-size: var(--rem-base-font);
  width: 13rem;
  @include media-large-device() {
    width: 22rem;
  }
  @include media-xxl() {
    font-size: var(--rem-2xl-font);
  }
}
.footer_secondaryContainer {
  @include verticalFlex($align: flex-start, $gap: 10px, $fontSize: 18px);
  height: auto;
  background: #000000;
  padding: 2rem 1.7rem;
  color: #ffffffb3;
  font-family: "EXO2.0-REGULAR";
  @include media-350p() {
    padding: 2rem 3rem;
  }
  @include media-small-device() {
    padding: 2rem 1rem;
    flex-direction: row;
  }
  @include media-mid-device() {
    padding: 2rem 1rem;
    flex-direction: row;
  }
  @include media-large-device() {
    padding: 2rem 3rem;
    flex-direction: row;
  }
  @include media-xl() {
    padding: 2rem 11rem;
    flex-direction: row;
    align-items: center;
    gap: 0;
  }
  @include media-xxl() {
    font-size: var(--rem-3xl-font);
    padding: 2rem 11rem;
  }
}
.footer_leftPrimary_container {
  @include verticalFlex($align: flex-start, $gap: 1.8rem, $width: auto);
  height: 100%;
  @include media-mid-device() {
    width: 100%;
  }
  @include media-large-device() {
    width: 100%;
  }
  @include media-small-desktop() {
    width: 30%;
  }
}
.rightPrimary_container {
  @include verticalFlex($justify: center, $gap: 3rem);
  color: white;
  @include media-mid-device() {
    width: 100%;
  }
  @include media-large-device() {
    width: 100%;
  }
  @include media-small-desktop() {
    width: 70%;
  }
}
.footer_HeadingSecondaryContainer {
  @include verticalFlex($align: flex-start, $gap: 2.5rem, $width: auto);
  height: 100%;
  font-size: 18px;
  @include media-small-device() {
    width: 80%;
  }

  @include media-large-device() {
    width: 48%;
  }
  @include media-small-desktop() {
    width: 30%;
  }
}
.footer_primaryLinksContainer {
  @include centerFlex($align: flex-start, $justify: space-between);
  flex-direction: column;
  gap: 1rem;
  font-size: 18px;
}
.footer_primaryTopLinksContainer {
  @include centerFlex($align: flex-start, $justify: space-between);
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;
}

.footer_linksupperparentContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  width: 100%;
  gap: 8px;
  font-size: 18px;
  @include media-small-device() {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    width: 100%;
    gap: 0;
  }
}
.footer_linksupperIndivparentContainer {
  gap: 10px;
}
.footer_linksDownparentContainer {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  gap: 1rem;
  @include media-small-device() {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0;
  }
}
.footer_linksparentContainer {
  @include verticalFlex($gap: 10px, $align: flex-start, $justify: center, $width: auto, $fontSize: 18px);
}
.footer_linksupperindivparentContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.footer_textallIconsContainer {
  display: flex;
  flex-direction: column;
  gap: 1.28rem;
}
.footer_contactus_Container {
  font-size: var(--rem-lg-font);
  font-family: "EXO2.0-REGULAR";
  @include media-xxl() {
    font-size: var(--rem-3xl-font);
  }
}
.footer_termsPolicyContainer {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  gap: 10px;
  @include media-small-device() {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  @include media-large-device() {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    gap: 0;
    height: 1.2rem;
  }
  @include media-small-desktop() {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 68%;
    place-items: start;
  }

  @include media-sm() {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: fit-content;
  }
}
.footer_termspolicylinkContainer {
  width: auto;
  height: fit-content;
  font-size: 1.2rem;
}
.footer_LinkTagsText {
  color: #ffffffb3;
  font-size: var(--rem-lg-font);
  @include media-xxl() {
    font-size: var(--rem-3xl-font);
  }
}
.footer_LinkTagsText:hover {
  color: #ffffffb3;
}
.footer_termsemptylinkContainer {
  display: none;
  @include media-small-desktop() {
    display: block;
  }
}
.footer_allSocialIcons {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.footer_followusText {
  font-size: var(--rem-lg-font);
  color: white;
  font-family: "EXO2.0-REGULAR";
  @include media-xxl() {
    font-size: var(--rem-3xl-font);
  }
}
.footer_container_SocialBlock {
  display: flex;
  gap: 5px;
  font-size: 18px;
  @include media-xxl() {
    font-size: var(--rem-3xl-font);
    gap: 1rem;
  }
}
.footer_Container_Indiv_Icons {
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 5px;
  color: #112949;
  padding: 8px;
  cursor: pointer;
}
.footer_Container_Indiv_Icons:hover {
  color: #112949;
}
.skyblue_color {
  color: var(--light-blue) !important;
  font-size: var(--rem-lg-font) !important;
  cursor: pointer;
}
.skyblue_color:hover {
  color: var(--light-blue);
}

.footer_links_text {
  font-size: var(--rem-lg-font) !important;
}

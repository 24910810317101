/* ========== CLIENT FETCH PAGE ========================== */
.client-fetch-page {
  position: relative;
  grid-area: dashboard;
  display: flex;
  flex-direction: column;
  text-align: center;
  /* padding: 10px; */
  /* background: var(--vertical-blue-black-gradient); */
  background: transparent;
}

.client-fetch-head {
  position: relative;
  display: block;
  text-align: center;
  width: 70%;
  margin: 20px auto;
  font-size: var(--xxxl-font);
  word-wrap: break-word;
  color: var(--white, #ffffff);
}
@media only screen and (max-width: 1300px) {
  .client-fetch-head {
    font-size: var(--l-font);
  }
}

.client-fetch-page-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90px;
  margin-bottom: 50px;
}

.client-fetch-page-logo img {
  height: 100%;
}

.client-fetch-form {
  position: relative;
  width: 80%;
  left: 50%;
  margin-bottom: 50px;
  transform: translateX(-50%);
}
@media only screen and (max-width: 1300px) {
  .client-fetch-form {
    margin-bottom: 20px;
  }
}

.client-fetch-input-error-container {
  position: relative;
  width: 60%;
  display: inline-block;
}

/* .client-fetch-input{
    width: 100%;
    padding: 7px 10px;
    border-radius: 6px;
    font-size: var(--s-font);
    outline: none;
    background: rgba(17, 41, 73, 0.4);
    color: var(--white,#ffffff);
    border: 0.5px solid rgba( 255, 255, 255, 0.4 );
  }  */

.client-fetch-error-msg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  font-size: var(--xs-font);
  width: fit-content;
  color: var(--orange, #ea7b2c);
}

.client-fetch-form > button {
  position: relative;
  display: inline-block;
  margin-left: 10px;
  background: var(--light-blue, #3cc3f2);
}
.client-fetch-form > button:hover {
  opacity: 0.9;
}

.client-error-msg {
  position: absolute;
  display: inline-block;
  right: 35%;
  top: 50%;
  transform: translateY(-50%);
  font-size: var(--xs-font);
  color: red;
}
@media only screen and (max-width: 1300px) {
  .client-error-msg {
    right: 35%;
  }
}

/* .client-fetch-background{
    position: relative;
    display: none;
    width: 80%;
    left: 50%;
    transform: translateX(-50%);
  }
  @media only screen and (max-width: 1300px) {
    .client-fetch-background{
      width: 85%;
    }
  } */

.fetch-animation-container {
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: block;
  /* position: relative;
    display: block;
    width: 100%;
    left: 50%;
    transform: translateX(-50%); */
}

#fetch-animation {
  position: relative;
  width: 100%;
  /* height: 100%; */
}

@media only screen and (max-width: 1460px) {
  /* =========== Fetch Page ==================== */

  .client-fetch-head {
    width: 80%;
    font-size: var(--xxl-font);
  }
}

@media only screen and (max-width: 850px) and (min-width: 500px) {
  /* ========== FETCH page ========== */
  .client-fetch-page-logo {
    height: 60px;
    margin-bottom: 20px;
  }
}

/* @media only screen and (min-width: 700px) and (max-width: 780px) and (min-height: 1000px) and (max-height: 1050px),
  (min-width: 1020px) and (max-width: 1040px) and (min-height: 1350px) and (max-height: 1370px),
  (min-width: 500px) and (max-width: 550px) and (min-height: 700px) and (max-height: 730px) { */
@media only screen and (min-width: 700px) and (max-width: 780px) and (min-height: 900px) and (max-height: 1100px),
  (min-width: 700px) and (max-width: 1000px) and (min-height: 700px) and (max-height: 1000px),
  (min-width: 1000px) and (max-width: 1100px) and (min-height: 1300px) and (max-height: 1400px),
  (min-width: 500px) and (max-width: 550px) and (min-height: 700px) and (max-height: 730px),
  (min-width: 750px) and (max-width: 880px) and (min-height: 900px) and (max-height: 1200px),
  (min-width: 800px) and (max-width: 1000px) and (min-height: 1100px) and (max-height: 1400px),
  (min-width: 500px) and (max-width: 580px) and (min-height: 700px) and (max-height: 750px) {
  /* ========== CLIENT FETCH PAGE ========================== */

  .client-fetch-page {
    position: relative;
    grid-area: dashboard;
    overflow-y: auto;
  }

  .client-fetch-head {
    width: 70%;
    font-size: var(--ml-font);
  }

  .client-fetch-page-logo {
    margin-bottom: 30px;
  }

  .client-fetch-form {
    width: 70%;
  }

  .client-fetch-input-error-container {
    width: 100%;
  }

  /* .client-fetch-input-error-container > input{
          border-radius: 4px;
      } */

  .client-fetch-form > button {
    /* border-radius: 4px; */
    margin-top: 10px;
    margin-left: 0px;
  }

  /* .fetch-animation-container{
          width: 70%;
      } */

  .fetch-animation-container {
    position: absolute;
    width: 100vw;
    height: 100vh;
    /* display: block; */
    display: none;
    /* position: relative;
          display: block;
          width: 100%;
          left: 50%;
          transform: translateX(-50%); */
  }

  #fetch-animation {
    position: relative;
    width: 100%;
    /* height: 100%; */
  }
}

@media only screen and (max-width: 500px) {
  /* ========== CLIENT FETCH PAGE ========================== */

  .client-fetch-page {
    position: relative;
    grid-area: dashboard;
    overflow-y: auto;
  }

  .client-fetch-head {
    width: 90%;
    font-size: var(--ml-font);
  }

  .client-fetch-page-logo {
    margin-bottom: 30px;
  }

  .client-fetch-form {
    width: 90%;
  }

  .client-fetch-input-error-container {
    width: 100%;
  }

  .client-fetch-input-error-container > input {
    border-radius: 4px;
  }

  .client-fetch-form > button {
    border-radius: 4px;
    margin-top: 10px;
    margin-left: 0px;
  }

  /* ================= FETCH ANIMATION VIDEO ============ */

  .fetch-animation-container {
    position: absolute;
    width: 100vw;
    height: 100vh;
    /* display: block; */
    display: none;
    /* position: relative;
        display: block;
        width: 100%;
        left: 50%;
        transform: translateX(-50%); */
  }

  #fetch-animation {
    position: relative;
    width: 100%;
  }
}

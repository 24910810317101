@import "../../../../general";

.tools_consult_files_preview_section {
  // @include stc-glass-card();
  @include horizontalFlex($gap-size: 0vh);
  @include stc-normal-font();
  margin: 0;
  height: 100%;
  background-color: none;
  border: none;

  @include media-potrait() {
    @include verticalFlex();
    overflow-y: auto;
  }

  .file_preview_container {
    @include verticalFlex($justify: flex-start);
    height: 100%;
    width: 100%;
    padding: 0px 1vh;

    @include media-potrait() {
      height: 50%;
      width: 100%;
    }

    .file_preview_head {
      @include horizontalFlex();
      height: 3.5vh;

      .file_preview_heading {
        width: 100%;
        @include stc-sub-heading-font();
        @include media-extrasmall-device() {
          @include stc-heading-font();
        }
      }

      .source_download_btn {
        @include media-large-device() {
          display: none;
        }
        @include media-potrait() {
          display: inline-block;
        }
      }

      .target_download_btn {
        @include media-large-device() {
          display: inline-block;
        }
        @include media-potrait() {
          display: none;
        }
      }
    }

    .file_preveiw_error {
      @include horizontalFlex();
      @include stc-normal-font();
      @include stc-badge($color: var(--white));
      background-color: var(--orange);
      color: var(--white);
      border-radius: 0.3vh;
    }

    .file_preview {
      @include verticalFlex();
      height: 100%;
      overflow: auto;
    }

    .file_preview_downloading {
      @include verticalFlex($justify: center);
      position: absolute;
      width: 100%;
      height: 100%;
      background: var(--tool-bg-blue);
      color: var(--whtie);
      z-index: 1;
    }

    .preview-loader-msg {
      @extend .file_preview_downloading;
    }
  }

  .file_source_preview_container {
    @include media-potrait() {
      height: 100%;
      padding-bottom: 1vh;
      border-bottom: 1px solid #394d67;
    }
  }

  .file_target_preview_container {
    @include media-potrait() {
      height: calc(50% - 1vh - 1px);
    }
  }
}

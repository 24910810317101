@import "../../common";

.mainservice_heading {
  color: var(--light-blue);
  font-size: 2.2vh;
  @include media-sm() {
    font-size: 2.4vh;
    width: 100%;
  }
  @include media-small-device() {
    font-size: 2.4vh;
  }

  @include media-mobile-landscape() {
    font-size: var(--rem-xl-font);
  }
}
.mainservice_DropdownHide {
  position: absolute;
  right: 0.5rem;
  top: 6px;
  color: var(--white);
  font-size: 1.5rem;
  @include media-large-device() {
    display: none;
  }
  @media screen and (min-width: 1023px) and (max-width: 1025px) and (min-height: 1365px) and (max-height: 1367px) and (orientation: portrait) {
    display: none;
  }
}
.serviceIndivisual_IconsContainer {
  @include centerFlex($justify: flex-start);
  flex-direction: column;
  color: var(--white);
}

.serviceIndivisual_IconsTextContainer {
  text-align: center;
  font-size: 1.2vh;
  padding: 0 20px;
  padding-top: 1vh !important;
  @include media-sm() {
    padding: 0;
    font-size: 1.6vh;
  }
  @include media-small-device() {
    font-size: 1.6vh;
  }

  @media screen and (min-width: 801px) {
    font-size: 1.4vh;
  }
  @include media-mobile-landscape() {
    padding: 0 15px !important;
    font-size: 12px;
  }
}

.mainservices_container {
  display: flex;
  flex-direction: column;
  padding: 0rem 0.5rem;
  padding-top: 0.7rem;
  color: var(--white);

  @media screen and (min-width: 600px) and (max-width: 800px) {
    padding-top: 1rem;
  }
  @media (width: 1024px) and (height: 1366px) and (orientation: portrait) {
    padding-top: 2rem;
  }
}
.mainservice_icons_container {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.single_container {
  width: 25%;
}

.subservice_single_iconcontainer {
  margin-left: 2px;
  width: 20%;
  flex-wrap: wrap;
}

.subservice_main_container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 2vh;
  padding: 1.2rem;
  background-color: rgba(0, 0, 0, 0.4);
  padding-top: 2vh;
  padding-bottom: 2vh;
  transition: 0.5s all ease-in-out;

  @include media-smaller-device() {
    gap: 1.5vh;
  }

  @media screen and (max-height: 300px) {
    gap: 11vh !important ;
    padding-left: 12vh !important;
  }

  @media screen and (min-height: 301px) and (max-height: 325px) {
    gap: 10vh !important ;
    padding-left: 10vh !important;
  }

  @media screen and (min-height: 321px) and (max-height: 370px) {
    gap: 8vh !important ;
    padding-left: 9.5vh !important;
  }
  @media screen and (min-height: 321px) and (max-height: 370px) and (min-width: 730px) {
    gap: 10vh !important ;
    padding-left: 9.5vh !important;
  }
  @media screen and (min-height: 371px) and (max-height: 377px) {
    gap: 8vh !important ;
    padding-left: 9.5vh !important;
  }
  @media screen and (min-height: 371px) and (max-height: 377px) and (min-width: 805px) {
    gap: 10vh !important ;
    padding-left: 9.5vh !important;
  }
  @media screen and (min-height: 408px) and (max-height: 415px) and (min-width: 725px) {
    gap: 10.5vh !important ;
    padding-left: 9.5vh !important;
  }
  @include media-small-device() {
    gap: 3.5vh;
    padding-left: 3vh;
  }
  @media screen and (min-width: 700px) and (max-width: 725px) {
    gap: 7vh !important ;
    padding-left: 5vh !important;
  }

  @media screen and (min-width: 750px) {
    gap: 3.5vh;
    padding-left: 3vh;
  }
  @media screen and (min-width: 801px) {
    gap: 3.2vh;
    padding-left: 3vh;
  }
  @media screen and (min-width: 901px) and (orientation: portrait) {
    gap: 3.2vh !important;
    padding-left: 2.5vh !important;
  }

  @include media-mobile-landscape() {
    padding-left: 8vh;
    gap: 11vh;
  }

  @media (min-width: 1020px) and (min-height: 1170px) and (max-height: 1380px) and (orientation: portrait) {
    //ipadpro-portrait
    gap: 3.5vh !important;
    padding-left: 3.5vh !important;
  }
}

.mainservice_heading_container {
  height: 100%;
  @include centerFlex($justify: flex-start);
  margin-bottom: 1vh;
  padding-left: 1vh;
  padding-bottom: 0.8vh;

  @include media-small-device() {
    margin-bottom: 0.5vh;
    padding-left: 2.5vh;
  }

  @include media-mobile-landscape() {
    padding-left: 10vh;
  }

  @media screen and (min-width: 700px) and (max-width: 725px) {
    padding-left: 3vh;
  }
}

.serviceContainer_Iconimage {
  width: 50px;
  height: 50px;
  border-radius: 10px;

  @include media-mobile-landscape() {
    margin-bottom: 1.5vh;
  }
}
.img_svg {
  width: 100%;
  height: 100%;
}

.svg {
  margin-bottom: -0.9vh;
  @include media-mid-device() {
    margin-bottom: -0.6vh;
  }
  @media screen and (min-width: 318px) and (max-width: 322px) and (min-height: 565px) and (max-height: 570px) {
    margin-bottom: -1.1vh;
  }
  @media screen and (min-width: 413px) and (max-width: 415px) and (min-height: 895px) and (max-height: 897px) {
    margin-bottom: -0.75vh;
  }
  @media screen and (min-width: 410px) and (max-width: 500px) and (min-height: 910px) and (max-height: 935px) and (orientation: portrait) {
    margin-bottom: -0.65vh;
  }
}

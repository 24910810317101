@import "../../../general";

.custom_media_input_wrapper {
  @include lib-input-input();
  border-radius: 4px;
  border: 1px dashed #394d67;
  .custom_media_input_label {
    padding: 1vh;
    font-style: italic;
    color: var(--white);
    font-weight: 100;
    width: 90%;
    margin-left: auto;

    // @media screen and (min-width: 1500px) and (max-width: 1700px) {
    //   padding-left: 28vh;
    // }

    // @include media-extrasmall-device() {
    //   padding-left: 10vh;
    // }
  }

  .custom_media_input_files {
    border: none;
  }

  .custom_media_input_input_btn {
    @include verticalFlex($width: auto);
    color: var(--white);
    padding: 0vh 0.5vh;
  }
  .input_logo_upload {
    width: 15px;
    height: 15px;
    @include media-large-device() {
      width: 15px;
      height: 15px;
    }
  }
}

.files_input_name_list {
  @include horizontalFlex($justify: center);
  overflow-y: hidden;
  overflow-x: auto;
  max-height: 38px;
  white-space: nowrap;
  // @include scrollbars(
  //   $size: 2px,
  //   $thumb-color: rgba(255, 255, 255, 0.1),
  //   $track-color: rgba(6, 14, 24, 1)
  // );
}

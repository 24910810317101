@import "../../general";

.flip_upload_container_box {
  padding: 4vh 1vh;
  border-radius: 12px;
  box-shadow: 0px 0px 20px 0px #11294940;
  border: 2px dashed var(--white);
  stroke: var(--white);
  background: #344155;
  @include horizontalFlex($width: 60%, $align: flex-start, $justify: center);
  @include media-large-device() {
    padding: 2vh 1vh;
    border-radius: 8px;
    width: 70%;
  }
  @include media-small-desktop() {
    border-radius: 10px;
    padding: 3vh 1vh;
  }
  @include media-tab-potrait-device() {
    width: 85%;
    border-radius: 12px;
    padding: 2.5vh 1vh;
  }
  @include media-extrasmall-device() {
    width: 85%;
    padding: 2.5vh 1vh;
  }
  @include media-1720p() {
    width: 70%;
    padding: 2vh 1vh;
  }
  @include media-xl() {
    width: 60%;
    padding: 2.8vh 1vh;
  }
  @media screen and (min-width: 300px) and (max-width: 950px) and (orientation: landscape) {
    padding: 2vh 1vh;
  }
}
.flip_upload_container {
  @include verticalFlex($gap-size: 5vh);
  @include stc-heading-font($fontColor: var(--white));

  @include media-large-device() {
    gap: 2vh;
  }
  @include media-tab-potrait-device() {
    gap: 6vh;
  }
  @include media-small-desktop() {
    gap: 4vh;
  }

  @include media-xl() {
    gap: 4.5vh;
  }
  @media screen and (min-width: 1500px) and (max-width: 1750px) {
    gap: 4vh;
  }
  @media screen and (min-width: 300px) and (max-width: 950px) and (orientation: landscape) {
    gap: 1.5vh;
  }

  .flip_upload_heading {
    text-align: center;
    @include verticalFlex($align: center, $justify: center);

    @include media-large-device() {
      @include stc-heading-font($fontColor: var(--white));
      width: 80%;
    }
    @include media-small-desktop() {
      @include stc-heading-font();
    }
    @include media-tab-potrait-device() {
      @include stc-heading-font();
    }
    .flip_upload_heading_text {
      @include media-large-device() {
        @include stc-heading-font($fontColor: var(--white));
      }
      @include media-small-desktop() {
        @include stc-heading-font();
      }
      @include media-tab-potrait-device() {
        @include stc-heading-font($fontColor: var(--white));
      }
      .flip_text_2 {
        @include media-tab-potrait-device() {
          @include stc-heading-font($fontColor: var(--white));
        }
      }
    }
    .file_upload_language_box {
      @include horizontalFlex($align: center, $justify: center, $gap-size: 1vh);
      @include stc-normal-font($fontColor: var(--white));

      @include media-large-device() {
        // @include stc-heading-font();
      }
      .english_box,
      .arabic_box {
        cursor: pointer;
        @include stc-btn($btnBgColor: transparent);
        @include stc-heading-font($fontColor: var(--white));
        border: 1px solid transparent;
      }

      .active_lang_box {
        @include stc-btn($btnBgColor: transparent);
        @include stc-heading-font($fontColor: var(--white));
        cursor: pointer;
        border-radius: 5px;
        border: 1px solid var(--white);
      }
      .english_box,
      .arabic_box,
      .active_lang_box:hover {
        opacity: 1;
      }
    }
  }
  .flip_upload_upload_box {
    // min-height: 10vh;
    @include verticalFlex();
    .upload_file_name_text {
      // min-height: 30px;
    }
  }
  .flip_upload_size {
  }
  .flip_ppt_text {
    @include media-large-device() {
      @include stc-mega-heading-font($fontColor: var(--white));
    }
    @include media-small-desktop() {
      @include stc-heading-font($fontColor: var(--white));
    }
  }
  .file_swap_languages {
    @include horizontalFlex($width: 50%, $align: flex-start, $justify: center);
    @include media-tab-potrait-device() {
      width: 75%;
    }
    @include media-extrasmall-device() {
      width: 75%;
    }
    @include media-large-device() {
      width: 50%;
    }
    .source_lang {
      @include verticalFlex($width: 30%, $gap-size: 0.5vh);
      .english_arabic_text_lang {
      }
      .source_target_text_lang {
        @include stc-normal-font();

        @include media-tab-potrait-device() {
          @include stc-heading-font();
        }
      }
    }
    .swap_icons_logo {
      cursor: pointer;
    }
    .target_lang {
      @include verticalFlex($width: 30%, $gap-size: 0.5vh);

      .english_arabic_text_lang {
      }
      .source_target_text_lang {
        @include stc-normal-font();

        @include media-tab-potrait-device() {
          @include stc-heading-font();
        }
      }
    }
  }
  .flip_or_section {
    @include horizontalFlex($width: 26%);
    @include media-large-device() {
      width: 33%;
    }
    @include media-small-desktop() {
      width: 28%;
    }
    @include media-xl() {
      width: 26%;
    }
    @include media-tab-potrait-device() {
      width: 45%;
    }
    @include media-extrasmall-device() {
      width: 70%;
    }
    .or_text {
      padding: 0vh 1.5vh;
      @include media-large-device() {
        padding: 0vh 0.7vh;
        @include stc-mega-heading-font($fontColor: var(--white));
      }
      @include media-small-desktop() {
        @include stc-heading-font($fontColor: var(--white));
      }
      @include media-xl() {
        padding: 0vh 1.2vh;
      }
      @include media-tab-potrait-device() {
        padding: 0vh 0.8vh;
      }
      @include media-extrasmall-device() {
        padding: 0vh 0.8vh;
      }
    }
    .or_section_underline {
      width: 90%;
    }
  }
}

.file_upload_middle_container {
  @include verticalFlex($gap-size: 1.5vh);

  @include media-large-device() {
    gap: 1vh;
  }
  @include media-small-desktop() {
    gap: 1vh;
  }
  @include media-xl() {
    gap: 1.5vh;
  }
  @include media-tab-potrait-device() {
    gap: 1.5vh;
  }
  @include media-small-desktop() {
    gap: 1.5vh;
  }
}

.upload_support_text_main {
  @include stc-normal-font($fontColor: #ffffff80);
  font-style: italic;
  @include verticalFlex($gap-size: 0vh);
  @include media-large-device() {
    font-style: italic;
    @include stc-normal-font($fontColor: #ffffff80);
  }
  @include media-small-desktop() {
    font-style: italic;
    @include stc-normal-font($fontColor: #ffffff80);
  }
  @include media-xl() {
    @include stc-small-font($fontColor: #ffffff80);
    font-style: italic;
    gap: 0.5vh;
  }
  @include media-tab-potrait-device() {
    text-align: center;
    font-style: italic;

    @include stc-small-font($fontColor: #ffffff80);
  }
  @include media-extrasmall-device() {
    text-align: center;
    font-style: italic;

    @include stc-small-font($fontColor: #ffffff80);
  }
  .upload_support_text {
  }
  .upload_file_size_text {
  }
  .upload_language_support {
  }
  .file_upload_note_text {
    padding-top: 2vh;
    .file_upload_note_support_mail_id {
      cursor: pointer;
      text-decoration: underline;
      color: #ffffff80;
    }
  }
}

.file_select_upload_heading_text {
  @include stc-normal-font($fontColor: #ffffff80);

  @include media-tab-potrait-device() {
    @include stc-heading-font($fontColor: #ffffff80);
  }
}

.tooltip_container {
  cursor: pointer;
  position: absolute;
  right: -260px;
  top: -10px;
  @include media-large-device() {
    right: -100px;
  }
  @include media-small-desktop() {
    right: -130px;
  }
  @media screen and (min-width: 1500px) and (max-width: 1750px) {
    right: -220px;
  }
  @include media-xl() {
    right: -25vh;
  }
  @include media-xxl() {
    right: -22vh;
  }
  @include media-tab-potrait-device() {
    right: -5vh;
    top: -3vh;
  }
  @include surface-duo() {
    right: -6vh;
    top: -3vh;
  }
  @include media-extrasmall-device() {
    right: -4vh;
    top: -2.5vh;
  }
  @media screen and (min-width: 0px) and (max-width: 330px) {
    right: -26px;
    top: -25px;
  }
}

// .tooltip_container {
//   position: relative;
// }

.file_info_tooltip {
  @include stc-normal-font($fontColor: var(--white));
  position: absolute;
  // top: 100%;
  // top: 30px;
  // left: 10%;
  right: 0px;
  // transform: translateX(-94%);
  // background-color: black;
  // border: 1px solid #fff;
  border: 1px solid #394d67;
  background: #0a182a;

  padding: 1vh;
  width: 45vh;
  max-height: 45vh;
  overflow-y: auto;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 999;

  @include media-small-desktop() {
    width: 60vh;
  }

  @include media-xl() {
    width: 70vh;
  }

  @include media-tab-potrait-device() {
    width: 30vh;
    height: 40vh;
    overflow-y: auto;
  }

  @include media-extrasmall-device() {
    width: 30vh;
    height: 40vh;
    overflow-y: auto;
  }

  .file_info_tooltip_ordered_container {
    padding: 0;
    margin: 0;

    .file_info_tooltip_list {
      padding-left: 0.9rem;
      text-indent: -0.9rem;
    }

    .file_info_tooltip_non_ordered_item {
      margin: 0;
    }
  }
  animation: fadeIn 0.3s ease-in;
}

.tooltip::after {
  content: "";
  position: absolute;
  top: -5px;
  left: 92%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
}
.cloud_upload_img {
  @media screen and (min-width: 1025px) and (max-width: 1280px) {
    aspect-ratio: 1/1;
    height: 35px;
  }
  @media screen and (min-width: 300px) and (max-width: 950px) and (orientation: landscape) {
    height: 6vh;
  }
}
.stc_upload_loader {
  @include horizontalFlex($justify: center);
  .spin_upload_loader {
    height: 55px;
    width: 55px;
    border: 3px solid;
    border-color: white transparent white transparent;
    border-radius: 50%;
    // place-self: center;
    animation: spin 1.3s linear infinite;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

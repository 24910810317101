@import "./general";

.tools_module {
  grid-area: stdashboard;
  background: var(--tool-bg-blue);

  //add if the content height gets longer than the screen size
  overflow: auto;
  // z-index: 1;

  @include media-small-device() {
    overflow: hidden;
  }

  @include media-potrait($tablet: true) {
    overflow: auto;
  }
}

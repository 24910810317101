@import "./../../general";

.files_preview_section {
  @include stc-glass-card();
  @include horizontalFlex();
  @include stc-normal-font();
  margin: 0;
  height: calc(100% - 25vh - 1vh);
  padding-bottom: 0;
  border-bottom: 0px;
  border-radius: $genBorderRadius $genBorderRadius 0 0;

  @include media-potrait() {
    @include verticalFlex();
    overflow-y: auto;
    // height: 58vh;
  }

  .file_preview_container {
    @include verticalFlex($justify: flex-start);
    height: 100%;
    width: 50%;
    // height: 63vh;

    @include media-potrait() {
      height: 50%;
      width: 100%;
    }

    .file_preview_head {
      @include horizontalFlex();
      height: 3.5vh;

      .file_preview_heading {
        @include stc-sub-heading-font();
        @include media-extrasmall-device() {
          @include stc-heading-font();
        }
      }

      .source_download_btn {
        @include media-large-device() {
          display: none;
        }
        @include media-potrait() {
          display: inline-block;
        }
      }

      .target_download_btn {
        @include media-large-device() {
          display: inline-block;
        }
        @include media-potrait() {
          display: none;
        }
      }
    }

    .file_preveiw_error {
      @include horizontalFlex($justify: center, $width: auto);
      @include stc-normal-font();
      @include stc-badge($color: var(--white));
      background-color: var(--orange);
      color: var(--white);
      border-radius: 0.3vh;

      max-width: 18rem;
      @include media-350p() {
        max-width: 22rem;
      }

      @include media-tab-potrait-device() {
        max-width: 24rem;
      }

      @include media-xl() {
        max-width: 24rem;
      }

      .file_size_limit_exceed_error_anchor {
        color: var(--white);
      }
    }

    .file_preview_partial_error {
      @include horizontalFlex($justify: center, $width: auto);

      @include stc-normal-font();
      @include stc-badge($color: var(--white));
      background-color: var(--orange);
      color: var(--white);
      border-radius: 0.3vh;
      padding: 0.5rem;

      max-width: 18rem;
      @include media-350p() {
        max-width: 22rem;
      }

      @include media-tab-potrait-device() {
        max-width: 24rem;
      }

      @include media-xl() {
        max-width: 24rem;
      }

      .file_size_limit_exceed_error_anchor {
        color: var(--white);
      }
      position: absolute;
      z-index: 1;
      top: 4px;
    }

    .file_preview {
      @include verticalFlex();
      height: 100%;
      overflow: auto;
    }

    .file_preview_downloading {
      @include verticalFlex($justify: center);
      position: absolute;
      width: 100%;
      height: 100%;
      background: var(--tool-bg-blue);
      color: var(--whtie);
      z-index: 1;
    }

    .preview-loader-msg {
      @extend .file_preview_downloading;
    }
  }

  .file_source_preview_container {
    @include media-potrait() {
      height: 50%;
      padding-bottom: 1vh;
      border-bottom: 1px solid #394d67;
    }
  }

  .file_target_preview_container {
    @include media-potrait() {
      height: calc(50% - 1vh - 1px);
    }
  }
}

.output_progress_section {
  @include horizontalFlex();
  .output_progress_bar {
    position: relative;
    height: 1vh;
    width: 100%;
    border-radius: 1vh;
    overflow: hidden;
    z-index: 2;

    .output_progress_bar_complete {
      position: absolute;
      width: 0%;
      height: 100%;
      background-color: var(--light-blue);
    }
  }
}

.file_download_btn {
  @include horizontalFlex($justify: center, $align: center);
  width: auto;
  height: 3.2vh;
  background: var(--white);
  border-radius: $genSmBorderRadius;
  cursor: pointer;
  color: var(--dark-blue);

  .file_download_txt_wrapper {
    @include horizontalFlex($gap-size: 0.5vh, $align: center);
    width: auto;
    height: 3.2vh;
    padding: 0 0.5vh;
  }

  svg {
    @include horizontalFlex($justify: center, $align: center, $width: auto);
    height: 100%;
    aspect-ratio: 1/1;
    path {
      @include horizontalFlex($justify: center, $align: center, $width: auto);
    }
  }
}

.download_file_box {
  @include verticalFlex();
  position: absolute;
  background: var(--dark-blue);
  gap: 1px;
  min-height: 3.2vh;
  border-radius: $genSmBorderRadius;
  z-index: 3;
  top: calc(100% + 0.5vh);
  overflow: hidden;
  box-shadow: 0vh 0vh 1vh 0.1vh var(--shadow-color);

  .download_file_link_btn {
    @include verticalFlex($justify: center);
    background: var(--white);
    height: 3.2vh;
    text-transform: capitalize;
  }
}

.rpv-core__doc-loading {
  background-color: transparent !important;
}

.rpv-core__inner-page {
  // background-color: transparent !important;
}
.file_preveiw_not_download {
  @include horizontalFlex($align: center, $justify: center);
  @include stc-normal-font();
  color: var(--white);
  height: 100%;
  .encrypt_message {
    border: 1px solid #394d67;
    width: fit-content;
    padding: 1.8vh;
    border-radius: 8px;
    background: var(--tool-dark-blue);
    box-shadow: 0px 0px 20px 0px #11294940;
  }
}

.pdf_show_more {
  @include stc-btn($btnBgColor: var(--black));
  box-shadow: 0vh 0vh 1vh 0.2vh var(--shadow-color);
  margin: 0.5vh;
  position: absolute;
  bottom: 1vh;
  right: 0.5vh;
}

.rpv-core__doc-error-text {
  word-break: break-all;
}

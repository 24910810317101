@import "../../../general";

.tools_consult_file_upload_section {
  @include verticalFlex($justify: center);
  box-shadow: 0px 0px 20px 0px rgba(17, 41, 73, 0.2509803922);
  border: 2px dashed var(--white);
  stroke: var(--white);
  height: 100%;
  width: 100%;
  background: #344155;
  border-radius: 0.8vh;
  padding: 2vh;
  @include media-large-device() {
    padding: 15vh 3vh 3vh 3vh;
  }
}

.tools_consult_file_upload_main {
  @include verticalFlex($gap-size: 1vh);
}

.tools_consult_heading_text {
  @include verticalFlex($gap-size: 0vh);
  @include stc-heading-font($fontColor: var(--white));
  text-align: center;
}

.tools_consult_file_cretia {
  @include verticalFlex($gap-size: 0vh);
  @include stc-normal-font($fontColor: rgba(255, 255, 255, 0.5));
  font-style: italic;
}

.tools_consult_upload_btn {
  @include verticalFlex($gap-size: 3vh);
  @include stc-heading-font($fontColor: var(--white));
}

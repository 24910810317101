@import "../../../general";

.general-form-text-group {
  @include lib-input-group();
  color: var(--white);

  .general-form-text-label {
    @include lib-input-label();
    flex-direction: row;
  }

  .general-form-text-label-validation {
    color: var(--orange);
  }

  .general-form-text-input {
    border-radius: 4px !important;

    @include media-xl() {
      font-size: 16px;
    }

    @include lib-input-input();
    width: 100%;
    @include media-tab-landscape-device() {
      height: 35px;
    }
    @include media-small-desktop {
      height: 35px;
    }
  }
}
.general-form-text-input::placeholder {
  color: grey !important;
  font-size: 14px !important;
}

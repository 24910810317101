@import "../../../common";
.service {
  @include verticalFlex($justify: flex-start, $gap: 4vh);
  border-right: 1px solid #9c9c9c;
  height: 80vh;
  // padding-top: 1vh;
  margin: 3vh 0;
  padding: 1rem;
  width: auto;
}

.service_name {
  color: var(--light-blue);
  font-size: 2.2vh;
  text-align: center;
  font-family: "Exo2.0-SemiBold";
  align-self: center;
}

.list_of_services {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  color: var(--white);
}
.list_of_services_gap {
  gap: 2vh;
}
.border_right_none {
  border-right: none;
}
.single_service_top_padding {
  padding-top: 2vh;
  padding-left: 4vh;
  padding-right: 7vh;
}

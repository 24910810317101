@import "../../general";

.home_recent_assg {
  grid-area: stc-recent-assgn;
  @include verticalFlex($justify: space-around);
  background: #060e18;
  padding: 1vh 0;
  // margin-top: -12vh;

  // @include media-large-device() {
  //   padding: 1vh;
  // }
  @include media-small-desktop() {
    // margin-top: -14vh;
    // padding: 1.5vh 0;
  }
  @include media-tab-potrait-device() {
    display: none;
  }
  @include media-extrasmall-device() {
    display: none;
  }

  .home_recent_heading_container {
    @include horizontalFlex();

    .home_recent_heading {
      @include stc-mega-heading-font($fontColor: var(--white));
    }
    .home_new_asgn_req_btn {
      @include stc-heading-font();
      @include stc-btn($btnBgColor: var(--white), $btnTextColor: #112949);
      @include horizontalFlex();
      justify-content: center;
      border-radius: 4px;
      width: auto;
      gap: 0.5vh;

      svg {
        @include stc-normal-font();
        color: var(--dark-blue);
      }

      @include media-tab-landscape-device() {
        // width: 12vh;
      }
    }
  }

  .home_assignment_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    column-gap: 1vh;
    row-gap: 1vh;
    // column-gap: 20p
    // row-gap: 20px;x;
    // @include media-large-device() {
    //   column-gap: 10px;
    //   row-gap: 10px;
    // }
    // @include media-small-desktop() {
    //   column-gap: 15px;
    //   row-gap: 15px;
    // }
    // @include media-xl() {
    //   column-gap: 1vh;
    //   row-gap: 1vh;
    // }
    @include media-tab-potrait-device() {
      grid-template-columns: 1fr;
    }
    @include media-extrasmall-device() {
      grid-template-columns: 1fr;
    }

    .home_view_more {
      @include horizontalFlex();
      @include stc-normal-font();
      width: auto;
      color: var(--white);
      text-decoration: underline;
      cursor: pointer;
      margin-left: auto;
    }
  }

  .no_assignment_container {
    min-height: 240px;
    @include horizontalFlex($align: center, $justify: center);
    @include stc-heading-font($fontColor: rgba(255, 255, 255, 0.5));

    @include media-large-device() {
      min-height: 18vh;
    }
    @include media-small-desktop() {
      min-height: 22vh;
    }
    @include media-xl() {
      min-height: 20vh;
    }
  }
}

// @media screen and (max-width: 1280px) and (max-height: 720px) {
//   .home_recent_assg {
//     margin-top: -20vh;
//   }
// }

// @media only screen and (max-width: 1024px) {
//   .home_recent_assg {
//     margin-top: 0vh;
//   }
// }

// @media screen and (min-width: 1920px) and (min-height: 1080px) {
//   .home_recent_assg {
//     margin-top: -2vh;
//   }
// }

// @media screen and (max-width: 1180px) and (min-height: 820px) {
//   .home_recent_assg {
//     margin-top: 2vh;
//   }
// }

@import "../../general";

.tools_consult_request_file_upload_main {
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 4vh;
  min-height: 100%;
  padding: 1vh;
  overflow: auto;

  @include media-large-device {
    height: 100%;
  }

  @include media-tablet-1000p() {
    height: auto;
  }
}

.tools_file_upload_main {
  padding: 1vh;
  height: 100%;
  @include verticalFlex($justify: flex-start, $align: center, $gap-size: 4vh);
  @include media-tab-potrait-device() {
  }
  @include media-extrasmall-device() {
  }
  @media screen and (min-width: 200px) and (max-width: 370px) {
    max-height: calc(100vh - 100px);
    overflow-x: hidden;
    overflow-y: auto;
  }
  @include media-large-device() {
    padding: 1vh;
  }
  @include media-tab-potrait-device() {
    padding: 1vh;
    max-height: calc(100vh - 100px);
    overflow-y: auto;
  }
  @include media-extrasmall-device() {
    padding: 1vh;
    max-height: calc(100vh - 100px);
    overflow-y: auto;
  }
}

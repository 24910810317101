/* ============ ENCRYPTED ASSIGNMENT MODAL ============== */

.client-encrypted-modal-container {
  border-radius: 10px;
  transform: translate(-15%, 60%);
}

.client-encrypted-modal-container p {
  position: relative;
  margin: 15px auto;
}

.client-encrypted-modal-timer-btn {
  position: relative;
  display: inline-block;
  min-width: 150px;
  padding: 4px 10px;
  font-size: var(--s-font);
  border-radius: 5px;
  background: white;
  border: 1px solid var(--orange, #ea7b2c);
  color: var(--orange, #ea7b2c);
  cursor: not-allowed;
}

.client-encrypted-modal-okay-btn {
  float: right;
}

.client-encrypted-btn-container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (min-width: 700px) and (max-width: 780px) and (min-height: 1000px) and (max-height: 1050px),
  (min-width: 1020px) and (max-width: 1040px) and (min-height: 1350px) and (max-height: 1370px),
  (min-width: 500px) and (max-width: 550px) and (min-height: 700px) and (max-height: 730px) {
  /* ================= MODAL ========================== */
  .client-encrypted-modal-container {
    width: 80vw;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .client-encrypted-modal-container p {
    margin: 10px auto;
  }
  .client-encrypted-btn-container {
    row-gap: 10px;
    flex-direction: column;
  }
}

@media only screen and (max-width: 500px) {
  .client-encrypted-modal-container {
    width: 80vw;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .client-encrypted-modal-container p {
    margin: 10px auto;
  }
  .client-encrypted-btn-container {
    row-gap: 10px;
    flex-direction: column;
  }
}

.client-revoke-request-error-modal-container {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 30vw;
  border-radius: 10px;
  top: 50%;
  transform: translate(-10%, -50%);
  background: var(--black, #03080f);
}

#client-revoke-reuqest-error-heading {
  color: var(--label-blue, #9cbdeb);
}

#client-revoke-request-error-heading-close-btn {
  color: var(--light-blue, #3cc3f2);
}

.client-revoke-request-error-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.client-revoke-request-error-btn-container button {
  background-color: var(--light-blue, #3cc3f2);
}

#client-revoke-request-error-modal-cancel-btn {
  background-color: transparent;
  color: var(--light-blue, #3cc3f2);
  padding: 0px;
  margin: 0px;
  min-width: fit-content;
}

/* ###################### Tablet ############################*/

@media only screen and (min-width: 700px) and (max-width: 780px) and (min-height: 1000px) and (max-height: 1050px),
  (min-width: 1020px) and (max-width: 1040px) and (min-height: 1350px) and (max-height: 1370px),
  (min-width: 500px) and (max-width: 550px) and (min-height: 700px) and (max-height: 730px) {
  .client-revoke-request-error-modal-container {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: calc(100% - 20px);
    top: 50%;
    left: 0%;
    margin: 10px;
    transform: translate(0%, -50%);
  }
}

@media only screen and (max-width: 500px) {
  .client-revoke-request-error-modal-container {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: calc(100% - 20px);
    top: 50%;
    left: 0%;
    margin: 10px;
    transform: translate(0%, -50%);
  }
}

.login-input {
  position: relative;
  height: fit-content;
}

.client-login-input-error {
  font-size: var(--xs-font);
  color: red;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  right: 28px;
}

.client-login-signup {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  box-sizing: border-box;
  font-size: var(--s-font);
  background-color: #d2d4d3;
}

.client-lg-sn-img-container {
  position: relative;
  float: right;
  width: 100vw;
  height: 100vh;
  background: transparent;
  box-sizing: border-box;
  z-index: 1;
}

.client-login-text {
  position: absolute;
  left: 32vw;
  top: 45vh;
  z-index: 1;
  font-size: 48px;
  width: 300px;
  display: none;
}

@media only screen and (max-width: 1300px) {
  .client-login-text {
    font-size: 36px;
    width: 200px;
  }
}

.client-login-background {
  /* background-image: url("./background/login-bg.jpg"); */
  background-size: cover;
  position: relative;
  height: 100%;
  width: 100%;
}

.client-login-background-img {
  background-size: cover;
  position: relative;
  height: auto;
  width: 100vw;
  display: none;
}

#client-login-background-mobile {
  background-size: cover;
  position: relative;
  top: 9vh;
  width: 100vw;
  height: 80vw;
  display: none;
}

#client-login-background-tablet {
  background-size: cover;
  position: relative;
  width: 100vw;
  height: 80vw;
  display: none;
}

#client-login-background-desktop {
  display: block;
}

.client-lg-sn-form-container {
  position: absolute;
  left: 5%;
  bottom: 0;
  display: block;
  width: 25%;
  color: white;
  height: 80vh;
  background: linear-gradient(var(--dark-blue, #112949), var(--black, #03080f));
  box-sizing: border-box;
  border-radius: 10px 10px 0px 0px;
  z-index: 2;
}

.client-lg-sn-animate-container {
  position: absolute;
  left: 5%;
  bottom: 0;
  display: block;
  width: 25%;
  color: white;
  height: 80vh;
  background: linear-gradient(var(--dark-blue, #112949), var(--black, #03080f));
  box-sizing: border-box;
  border-radius: 10px 10px 0px 0px;
  transition: 0.5s;
  z-index: 2;
}

.client-lg-sn-animate-container-active {
  left: 0%;
  height: 100vh;
  width: 100vw;
  border-radius: 0px;
  z-index: 2;
  /* animation: login-animation 0.5s 1; */
}
@keyframes login-animation {
  /* 0% {opacity : 0; left: 0%; height: 100vh; width: 100vw;}
    100% {opacity : 1; left: 0%; height: 100vh; width: 100vw;} */
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.client-solo-component {
  display: none;
}
.client-brand-access {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.client-access-head {
  margin-bottom: 0px !important;
}
.client-brand {
  position: absolute;
  top: 23%;
  left: 6.3%;
  font-size: var(--xxs-font);
  height: auto;
  width: 10%;
  background: transparent;
  z-index: 3;
}

.client-brand img {
  position: relative;
  height: 34%;
  width: 34%;
  background: transparent;
}

/* ======== LOGIN FORM CSS=========*/

.client-login-form {
  position: absolute;
  display: block;
  bottom: 55px;
  margin: 10px auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -30%);
  width: 90%;
  z-index: 2;
}

.client-form-head {
  font-size: var(--xxl-font);
  color: white;
  margin-bottom: 20px;
}

.client-form-input {
  width: 100%;
  color: var(--dark-blue, #112949);
  margin: 5px auto 15px auto;
  background-color: #d2d4d3;
}
.client-form-input::-webkit-input-placeholder {
  color: var(--light-black, #3d3d3d);
  font-style: italic;
}
.client-form-input::-moz-placeholder {
  color: var(--light-black, #3d3d3d);
  font-style: italic;
}
.client-form-input:-ms-input-placeholder {
  color: var(--light-black, #3d3d3d);
  font-style: italic;
}
.client-form-input:-moz-placeholder {
  color: var(--light-black, #3d3d3d);
  font-style: italic;
}

.client-password-input {
  position: relative;
  display: flex;
  align-items: center;
  margin: 5px auto 15px auto;
}

.client-password-input input {
  margin: 0px;
}

.client-pswd-show-btn {
  position: absolute;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  transform: translateY(-50%);
  right: 8px;
  font-size: var(--s-font);
  background: transparent;
  outline: none;
  color: var(--light-grey, #bdbdbd);
}

.client-pswd-show-btn-active {
  color: var(--black, #03080f);
}

.client-form-remember-check {
  position: relative;
  display: block;
  text-align: right;
  /* margin: 5px auto; */
}

/* .client-form-check {
    margin-right: 10px;
  } */

.client-login-btn {
  position: relative;
  float: right;
  display: flex;
  min-width: 105px;
  padding: 0px 5px;
  height: 28px;
  font-size: var(--s-font);
  background: var(--light-blue, #3cc3f2);
  color: white;
  outline: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}
.client-login-btn:hover {
  opacity: 0.95;
}

.client-login-bottom-form-btns-table {
  width: 100%;
}

.client-login-btns-section {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.client-login-btn-part {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.client-login-form-btns {
  position: relative;
  display: inline-flex;
  align-items: center;
  font-size: var(--s-font);
  text-decoration: none;
  column-gap: 5px;
}

.client-form-left-btns {
  position: relative;
  float: left;
}

.client-anchor {
  position: relative;
  display: inline-block;
  width: fit-content;
  font-size: var(--s-font);
  color: var(--light-blue, #3cc3f2);
  position: relative;
  display: block;
  margin: 2px 0px;
  cursor: pointer;
}

.client-form-right-btns {
  position: relative;
  float: right;
  width: 150px;
}

.client-form-botttom {
  position: relative;
  height: fit-content;
}

.client-verify-container {
  position: absolute;
  display: block;
  margin: 10px auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -30%);
  width: 90%;
  z-index: 2;
  /* position: absolute;
    top: 40%;
    margin: 10px 35px; */
}

.client-login-footer {
  position: absolute;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  font-size: var(--s-font);
  align-items: center;
  text-align: center;
  bottom: 20px;
  row-gap: 10px;
  column-gap: 10px;
  justify-content: center;
  z-index: 2;
}

.client-login-footer-text {
  text-decoration: none;
  color: var(--white);
}

.client-login-footer-item {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  column-gap: 10px;
  align-items: center;
  font-size: var(--xs-font);
  color: #d2d4d3;
}

.client-login-footer-separator {
  position: relative;
  display: inline-block;
  background: #d2d4d3;
  height: 5px;
  width: 5px;
  border-radius: 10px;
}

/* .iso-logo{
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 1;
    height: 90px;
    opacity: 0.75;
  } */

.rights-reserved {
  position: absolute;
  font-size: var(--xs-font);
  color: var(--white, #ffffff);
  bottom: 10px;
  right: 10px;
  z-index: 1;
}
@media only screen and (max-width: 849px) and (min-width: 500px) {
  .company_icon_container img {
    height: 10vh !important;
  }
  .discount_text {
    font-size: 12px !important;
  }
}
@media only screen and (max-width: 1025px) and (min-width: 850px) {
  /* ========= CLIENT ERROR ================================ */

  .client-maintenance-error-msg {
    width: 80%;
    transform: translateY(50px);
    font-size: 36px;
  }

  /* .client-lg-sn-img-container img{
        top: 10px;
        left: 25px;
    } */

  /* .client-login-background {
        background-image: url("./background/login-bg.jpg");
        background-size: cover;
        position: relative;
        width: 100vw;
        height: 100vh;
    } */

  .client-login-background-img {
    height: 100vh;
    width: auto;
    left: 40vh;
  }
  .company_icon_container img {
    height: 15vh !important;
  }
  .discount_text {
    font-size: 20px !important;
  }
  #client-login-background-desktop {
    display: block;
  }

  .client-login-text {
    font-size: 26px;
  }

  .client-login-form {
    top: 40%;
    height: fit-content;
  }

  .client-form-head {
    margin-bottom: 10px;
  }

  .client-input {
    padding: 5px;
  }

  .client-form-input {
    margin: 5px auto 10px auto;
  }

  .client-login-btns-section {
    row-gap: 5px;
  }

  .client-login-btn {
    padding: 5px 30px;
    height: fit-content;
    min-width: 24px;
  }

  .client-login-input-error {
    right: 15px;
  }
}
@media screen and (min-width: 900px) and (max-width: 924px) and (min-height: 1350px) and (max-height: 1380px) {
  #client-login-background-tablet {
    height: 84vw;
  }
}
@media only screen and (min-width: 700px) and (max-width: 780px) and (min-height: 900px) and (max-height: 1100px),
  (min-width: 900px) and (max-width: 1000px) and (min-height: 800px) and (max-height: 1000px),
  (min-width: 1000px) and (max-width: 1100px) and (min-height: 1300px) and (max-height: 1400px),
  (min-width: 500px) and (max-width: 550px) and (min-height: 700px) and (max-height: 730px),
  (min-width: 750px) and (max-width: 880px) and (min-height: 900px) and (max-height: 1200px),
  (min-width: 800px) and (max-width: 1000px) and (min-height: 1300px) and (max-height: 1400px) {
  /* .client-login-background {
        background-image: url("./background/login-bg-500p.jpg");
        background-size: cover;
        position: relative;
        width: 100vw;
        height: 80vw;
    } */
  .client-login-background-img {
    left: 0vh;
  }
  #client-login-background-tablet {
    display: block;
  }
  .company_icon_container {
    left: 20% !important;
    bottom: 47vh !important;
  }
  #client-login-background-desktop {
    display: none !important;
  }
  .company_icon_container img {
    height: 11vh !important;
  }
  .discount_text {
    font-size: var(--l-font) !important;
  }
  #client-login-background-mobile {
    display: none;
  }
  .client-login-form {
    top: 8vh !important;
    /* transform: translate(-50%, 0%); */
  }
  .client-brand {
    display: none;
  }
  .client-brand img {
    height: 40%;
    width: 40%;
  }
  /* .client-brand {
    position: absolute;
    top: 2%;
    left: 4%;
    width: 40%;
  } */
  .client-solo-component {
    top: 4%;
    left: 6%;
    gap: 0.6vh;
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
  }

  .client-solo-component img {
    height: 4.5vh;
  }
  .client-form-input {
    padding: 7px;
    margin: 15px auto;
    border-radius: 7px;
  }

  .client-login-btn {
    min-width: 140px;
    padding: 7px 15px;
    border-radius: 7px;
  }

  .client-lg-sn-form-container {
    position: absolute;
    align-self: center;
    left: 23%;
    width: 50%;
    margin: 0px 2.5%;
    color: white;
    height: 42vh;
  }

  .client-login-text {
    position: absolute;
    left: 10px;
    top: 20vh;
    z-index: 1;
    font-size: 28px;
    width: 50vw;
  }
  .login_redirect_container {
    top: 1rem !important;
    /* position: fixed; */
    position: absolute;
    right: 2.5rem !important;
    /* width: 43vh !important; */
    gap: 2.5rem !important;
    display: flex;
    justify-content: center;
    z-index: 2;
  }
  .client-lg-sn-img-container img {
    /* position: absolute; */
    width: auto;
    height: 100vh;
    /* top: 10vh; */
  }
  /* .login_icon_new {
    height: 1.8vh !important;
  } */

  .client-lg-sn-animate-container {
    left: 50%;
    width: 50%;
    transform: translateX(-50%);
    color: white;
    height: 42vh;
  }

  .client-lg-sn-animate-container-active {
    height: 100vh;
    width: 100vw;
    border-radius: 0px;
  }

  .client-form-head {
    font-size: var(--xl-font);
  }

  .client-login-form {
    top: 30px;
    transform: translate(-50%, 0%);
  }

  .client-verify-container {
    top: 5%;
    margin: 0px;
    width: 95%;
    left: 50%;
    transform: translateX(-50%);
  }

  .client-login-btns-section {
    row-gap: 15px;
  }

  .client-login-btn-part {
    align-items: flex-start;
  }

  .client-login-form-btns {
    font-size: var(--m-font);
  }

  /* .client-login-footer{
        width: 100%;
    }

    .client-login-footer-item{
        width: 100%;
        justify-content: center;
    }

    .client-login-footer-text{
        width: 120px;
    }

    .client-login-footer-text-left{
        text-align: right;
    }

    .client-login-footer-text-right{
        text-align: left;
    }

    #hidden-dot{
        display: none;
    } */

  .client-login-footer {
    position: absolute;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    font-size: var(--s-font);
    align-items: center;
    text-align: center;
    bottom: 20px;
    row-gap: 10px;
    column-gap: 10px;
    justify-content: center;
    z-index: 2;
  }

  .client-login-footer-item {
    position: relative;
    display: flex;
    width: fit-content;
    flex-wrap: nowrap;
    column-gap: 10px;
    align-items: center;
    font-size: var(--xs-font);
    color: #d2d4d3;
  }

  .client-login-footer-text {
    width: fit-content;
  }

  .client-login-footer-separator {
    position: relative;
    display: inline-block;
    background: #d2d4d3;
    height: 5px;
    width: 5px;
    border-radius: 10px;
  }

  #hidden-dot {
    display: inline-block;
  }

  .rights-reserved {
    display: none;
  }
}

@media only screen and (max-width: 850px) and (min-width: 500px) {
  /* ========= CLIENT ERROR ================================ */

  .client-maintenance-error-msg {
    width: 80%;
    transform: translateY(50px);
    font-size: 36px;
  }

  /* .client-lg-sn-img-container img{
        width: 100%;
        height: auto;
        top: 10px;
        left: 25px;
    } */

  /* .client-login-background {
        background-image: url("./background/login-bg.jpg");
        background-size: cover;
        position: relative;
        width: 100vw;
        height: 100vh;
    } */

  .client-login-background-img {
    height: auto;
    width: 100vw;
  }

  #client-login-background-desktop {
    display: block;
  }

  .client-login-text {
    font-size: 26px;
  }

  .client-login-form {
    top: 40%;
    height: fit-content;
  }

  .client-form-head {
    margin-bottom: 10px;
  }

  .client-input {
    padding: 5px;
  }

  .client-form-input {
    margin: 5px auto 10px auto;
  }

  .client-login-btns-section {
    row-gap: 5px;
  }

  .client-login-btn {
    padding: 3px 14px;
    height: fit-content;
    min-width: 40px;
    border-radius: 2px;
  }

  .client-login-input-error {
    right: 15px;
  }

  .client-login-form-btns {
    column-gap: 5px;
  }

  .client-login-footer-item {
    width: 100%;
    justify-content: center;
  }

  .client-login-footer-text {
    width: 79px;
  }

  .client-login-footer-text-left {
    text-align: right;
  }

  .client-login-footer-text-right {
    text-align: left;
  }

  #hidden-dot {
    display: none;
  }
}

@media only screen and (max-width: 1460px) {
  .client-login-background {
    background-image: url("../../assets/login-bg.jpg");
    background-size: cover;
    position: relative;
    height: 100vh;
    width: 100vw;
  }
  .client-login-footer-item {
    width: 100%;
    justify-content: center;
  }

  .client-login-footer-text {
    /* width: 79px; */
    width: 40%;
  }

  .client-login-footer-text-left {
    text-align: right;
  }

  .client-login-footer-text-right {
    text-align: left;
  }

  #hidden-dot {
    display: none;
  }
}

@media only screen and (min-height: 500px) and (max-height: 550px) and (min-width: 700px) and (max-width: 730px),
  (min-height: 1020px) and (max-height: 1040px) and (min-width: 1350px) and (max-width: 1370px) {
  /* Landscape mode of surface duo */

  .client-login-background-img {
    height: 100vh;
    width: auto;
  }
}

@media only screen and (max-width: 500px) {
  /* .client-login-background {
        background-image: url("./background/login-bg-500p.jpg");
        background-size: cover;
        position: relative;
        top: 9vh;
        width: 100vw;
        height: 80vw;
    } */

  #client-login-background-desktop {
    display: none;
  }

  #client-login-background-tablet {
    display: none;
  }

  #client-login-background-mobile {
    display: block;
    top: 5vh;
  }

  .client-brand {
    display: none;
  }

  .client-login-text {
    position: absolute;
    left: 10px;
    top: 20vh;
    z-index: 1;
    font-size: 28px;
    width: 50vw;
  }
  .client-solo-component img {
    height: 5vh;
    width: 5vh;
    aspect-ratio: 1/1;
  }

  .client-lg-sn-img-container img {
    width: auto;
    height: 240px;
    top: 10vh;
  }
  .client-solo-component {
    top: 4%;
    left: 6%;
    gap: 0.6vh;
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
  }

  .client-lg-sn-animate-container {
    left: 50%;
    width: 95%;
    transform: translateX(-50%);
    color: white;
    height: 50vh;
  }

  .client-lg-sn-animate-container-active {
    height: 100vh;
    width: 100vw;
    border-radius: 0px;
  }

  .client-form-head {
    font-size: var(--xl-font);
  }

  .client-login-form {
    top: 0%;
    transform: translate(-50%, 18%);
  }

  /* .client-form-input{
        padding: 5px;
        border-radius: 5px;
    } */

  .client-login-btn {
    border-radius: 4px;
  }

  .client-verify-container {
    top: 5%;
    margin: 0px;
    width: 95%;
    left: 50%;
    transform: translateX(-50%);
  }

  .client-login-footer {
    width: 100%;
  }

  .client-login-footer-item {
    width: 100%;
    justify-content: center;
  }

  /* .client-login-footer-text{
        width: 79px;
    } */

  .client-login-footer-text-left {
    text-align: right;
  }

  .client-login-footer-text-right {
    text-align: left;
  }

  #hidden-dot {
    display: none;
  }

  .rights-reserved {
    display: none;
  }
}

/* new login buttons css */
.login_redirect_container {
  top: 1rem;
  /* position: fixed; */
  position: absolute;
  right: 2.5rem;
  /* width: 52vh; */
  gap: 2.5rem;
  display: flex;
  justify-content: center;
  z-index: 2;
}
/* .login_logo_new {
  height: 5.5vh;
} */
.login_icon_new {
  height: 2.1vh;
}
.new_login_btn {
  display: flex;
  align-items: center;
  gap: 0.5vh;
  cursor: pointer;
}

.company_icon_container {
  position: absolute;
  left: 34%;
  z-index: 2;
  bottom: 8vh;
  display: flex;
  flex-direction: column;
  align-items: start;
  /* justify-content: flex-start; */
  /* background-color: red; */
}
.company_icon_container img {
  height: 100px;
  text-align: start;
}

.discount_text {
  color: #112949;
  font-size: 34px;
}
@media screen and (min-width: 2000px) and (max-width: 3000px) {
  .client-login-background-img {
    height: 100%;
  }

  .company_icon_container {
    bottom: 14vh;
    gap: 1vh;
  }
  .company_icon_container img {
    height: 150px;
  }
  .discount_text {
    font-size: 48px;
  }
}

@media screen and (min-width: 1700px) and (max-width: 1750px) {
  .client-login-background-img {
    height: 100vh;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1499px) {
  .discount_text {
    font-size: 24px;
  }
  .company_icon_container {
    bottom: 13vh;
    left: 34%;
  }
  .company_icon_container img {
    height: 12vh;
  }
  .client-login-background-img {
    left: 0;
    height: 100%;
  }
}
.new-form-label {
  margin-bottom: 0px;
}
/* @media screen and (min-width: 0px) and (max-width: 380px) and(orientation: landscape) {
  .company_icon_container img {
    height: 15vh !important;
  }
  .discount_text {
    font-size: 20px !important;
  }
} */

@media screen and (min-width: 0px) and (max-width: 380px) {
  .discount_text {
    font-size: 16px;
    white-space: nowrap;
  }
  .company_icon_container {
    position: absolute;
    top: 28vh;
    left: 50%;
    transform: translateX(-50%);
    width: fit-content;
  }
  #client-login-background-mobile {
    top: 0vh;
  }
  .company_icon_container img {
    height: 7vh !important;
  }
  /* .login_logo_new {
    height: 30px !important;
  } */
  .login_icon_new {
    height: 12px !important;
  }
  .login_redirect_container {
    /* position: fixed; */
    position: absolute;
    top: 1rem;
    right: 0px;
    width: 100vw;
    padding: 0vh 3vh;
    gap: 0.5rem;
  }
  .client-lg-sn-form-container {
    position: absolute;
    align-self: center;
    left: 0%;
    width: 95%;
    margin: 0px 2.5%;
    color: white;
    height: 60vh;
  }
}

@media screen and (min-width: 381px) and (max-width: 500px) {
  .discount_text {
    font-size: 22px;
    white-space: nowrap;
  }
  .company_icon_container {
    position: absolute;
    top: 32vh;
    left: 50%;
    transform: translateX(-50%);
    width: fit-content;
    /* left: 3.5vh !important; */
  }
  .client-mobile-head {
    font-size: 26px;
  }
  .company_icon_container img {
    height: 7vh !important;
  }
  .login_redirect_container {
    /* position: fixed; */
    position: absolute;
    top: 1rem !important;
    right: 0px !important;
    width: 100vw !important;
    padding: 0vh 3vh;
    gap: 1.5rem;
  }
  /* .login_logo_new {
    height: 35px !important;
  } */
  .login_icon_new {
    height: 15px !important;
  }
  .client-lg-sn-form-container {
    position: absolute;
    align-self: center;
    left: 0%;
    width: 95%;
    margin: 0px 2.5%;
    color: white;
    height: 54vh;
  }
  .client-mobile-head {
    font-size: 33px;
  }
}

@media screen and (min-width: 0px) and (max-width: 1299px) {
  .login_logo_new {
    /* height: 100%; */
    height: 31px !important;
  }
}

@media screen and (min-width: 1300px) and (max-width: 1499px) {
  .login_logo_new {
    height: 38px;
  }
}

@media screen and (min-width: 1500px) {
  .login_logo_new {
    height: 45px;
  }
}
@media screen and (min-width: 0px) and (max-width: 349px) {
  .login_redirect_container {
    gap: 0.5rem !important;
  }
}

@media screen and (min-width: 350px) and (max-width: 499px) {
  .login_redirect_container {
    gap: 1.5rem !important;
  }
}

@media screen and (min-width: 500px) and (max-width: 599px) {
  .login_redirect_container {
    width: 100%;
    justify-content: center;
    align-items: center;
    right: 0vh !important;
    gap: 1.5rem !important;
  }
}

/* ================== Top Dashboard Parts =============== */
.top-client-dashboard {
  position: relative;
  background: linear-gradient(
    78deg,
    rgba(16, 39, 69, 1) 0%,
    rgba(0, 1, 1, 0.7) 100%
  );
  padding: 10px;
  padding-bottom: 0px;
  display: grid;
  grid-template-columns: max-content max-content auto min-content;
  grid-template-rows: repeat(3, min-content);
  row-gap: 20px;
  column-gap: 20px;
  grid-template-areas:
    "top-dashboard-filename top-dashboard-version top-dashboard-key-section top-dashboard-lock"
    "top-dashboard-info-section top-dashboard-info-section top-dashboard-info-section top-dashboard-info-section"
    "top-dashboard-tab-buttons top-dashboard-tab-buttons top-dashboard-tab-buttons top-dashboard-tab-buttons";
  /* -webkit-box-shadow: 0px 0px 4px 1px var(--shadow-color);
    -moz-box-shadow: 0px 0px 4px 1px var(--shadow-color);
    box-shadow: 0px 0px 4px 1px var(--shadow-color); */
  /* z-index: 1; */
}

.client-top-dash-head {
  height: fit-content;
}

/* ==================== Client Top Head Filename ========================== */
.client-file-logo {
  grid-area: top-dashboard-filename;
  position: relative;
  display: inline-flex;
  align-items: center;
  height: fit-content;
  gap: 1vh;
  height: 50px;
  width: 100%;
  /* margin-bottom: 20px; */
  font-size: var(--l-font);
}

.client-file-logo .client-feedback-rating-container {
  height: 40px;
}

.client-dashboard-back-btn {
  display: none;
  justify-content: center;
  vertical-align: middle;
  text-align: center;
  font-size: 20px;
  background: transparent;
  border: none;
  color: var(--white, #ffffff);
}

.client-file-logo img {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
}

.client-filename {
  position: relative;
  display: inline-block;
  word-wrap: break-word;
  word-break: break-all;
  /* width: 85%; */
  width: fit-content;
  max-width: 30vw;
  font-size: var(--ml-font);
  font-weight: bold;
  vertical-align: top;
  color: var(--white, #ffffff);
}

.client-file-version-name {
  grid-area: top-dashboard-version;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  max-height: 50px;
  color: var(--label-blue);
  font-size: var(--s-font);
}

.client-file-lock {
  color: var(--light-blue);
  font-size: var(--l-font);
  display: flex;
  padding-top: 0.5vh;
  justify-content: flex-end;
}

#client-file-lock-encrypted {
  color: var(--light-blue);
}

#client-file-lock-decrypted {
  color: var(--white);
}

#client-file-lock-download {
  color: var(--orange);
}

.client-key-button-section {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-area: top-dashboard-key-section;
  justify-content: flex-end;
}

.client-top-dashboard-section {
  font-size: var(--s-font);
  color: var(--white);
  display: grid;
  grid-area: top-dashboard-info-section;
  column-gap: 10px;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fit, minmax(100px, max-content));
  grid-template-rows: 1fr;
}

.client-file-info {
  position: relative;
  display: grid;
  grid-template-rows: repeat(2, max-content);
  align-items: center;
  row-gap: 10px;
}

.client-file-info-head {
  position: relative;
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: center;
  column-gap: 10px;
}

.client-file-info-head-services-count {
  display: none;
}

.client-file-info-head-logo {
  position: relative;
  border-radius: 0px 0px 5px 5px;
  font-size: var(--xs-font);
  display: grid;
  justify-content: center;
  align-items: center;
}

.client-file-info-head-logo img {
  height: 2.5vh;
  width: 2.5vh;
}

.client-file-info-value-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 0.5vh;
}

.client-file-info-value-item {
  display: grid;
  grid-template-columns: max-content max-content;
  justify-content: flex-start;
  column-gap: 3px;
  align-items: center;
  overflow-y: hidden;
  transition: 200ms;
}

.client-file-info-value-item-text {
  display: flex;
  align-items: center;
  word-wrap: break-word;
}

.client-file-info-value-item-tooltip {
  display: flex;
  align-items: center;
}

.client-file-info-head-text {
  font-weight: bold;
  display: flex;
  flex-direction: row;
  gap: 0.5vh;
}

.client-top-details {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-template-rows: 1fr;
  column-gap: 10px;
  font-size: var(--s-font);
}

@media only screen and (min-width: 700px) and (max-width: 780px) and (min-height: 1000px) and (max-height: 1050px),
  (min-width: 1020px) and (max-width: 1040px) and (min-height: 1350px) and (max-height: 1370px),
  (min-width: 500px) and (max-width: 550px) and (min-height: 700px) and (max-height: 730px) {
  /* ================== Top Dashboard Parts =============== */

  .top-client-dashboard {
    width: 100%;
    padding: 10px;
    padding-bottom: 0px;
    grid-template-columns: 2fr 0.5fr;
    grid-template-rows: repeat(5, min-content);
    row-gap: 10px;
    column-gap: 10px;
    grid-template-areas:
      "top-dashboard-filename top-dashboard-version"
      "top-dashboard-filename top-dashboard-lock"
      "top-dashboard-info-section top-dashboard-info-section"
      "top-dashboard-key-section top-dashboard-key-section"
      "top-dashboard-tab-buttons top-dashboard-tab-buttons";
  }

  .client-file-logo {
    margin-bottom: 0px;
  }

  .client-filename {
    max-width: fit-content;
    font-size: var(--sm-font);
  }

  .client-dashboard-back-btn {
    display: flex;
    justify-content: center;
    vertical-align: middle;
    text-align: center;
    font-size: 20px;
    background: transparent;
    border: none;
    width: fit-content;
    height: fit-content;
    margin-right: 5px;
  }

  /* .client-file-version-name{
        max-height: 45px;
    } */

  .client-file-info-head-services-count-show {
    display: flex;
  }

  .client-file-info-value-item {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    justify-content: flex-start;
    column-gap: 3px;
  }

  .client-file-info-value-item-collapse {
    height: 0px;
  }

  .client-file-lock {
    font-size: var(--sm-font);
    padding: 0px;
  }

  .client-key-button-section {
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }

  .client-top-dashboard-section {
    font-size: var(--s-font);
    color: var(--white);
    display: grid;
    column-gap: 10px;
    row-gap: 10px;
    justify-content: start;
    grid-template-columns: 1fr;
  }

  .client-file-info {
    row-gap: 4px;
  }

  .client-file-info-head-logo img {
    height: 3.5vh;
    width: 3.5vh;
  }
}

@media only screen and (max-width: 500px) {
  /* ================== Top Dashboard Parts =============== */

  .top-client-dashboard {
    width: 100%;
    padding: 10px;
    padding-bottom: 0px;
    grid-template-columns: 2fr 0.5fr;
    grid-template-rows: repeat(5, min-content);
    row-gap: 10px;
    column-gap: 10px;
    grid-template-areas:
      "top-dashboard-filename top-dashboard-version"
      "top-dashboard-filename top-dashboard-lock"
      "top-dashboard-info-section top-dashboard-info-section"
      "top-dashboard-key-section top-dashboard-key-section"
      "top-dashboard-tab-buttons top-dashboard-tab-buttons";
  }

  .client-file-logo {
    margin-bottom: 0px;
  }

  .client-filename {
    max-width: fit-content;
    font-size: var(--sm-font);
  }

  .client-dashboard-back-btn {
    display: flex;
    justify-content: center;
    vertical-align: middle;
    text-align: center;
    font-size: 20px;
    background: transparent;
    border: none;
    width: fit-content;
    height: fit-content;
    margin-right: 5px;
  }

  /* .client-file-version-name{
        max-height: 45px;
    } */

  .client-file-info-head-services-count-show {
    display: flex;
  }

  .client-file-info-value-item {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    justify-content: flex-start;
    column-gap: 3px;
  }

  .client-file-info-value-item-collapse {
    height: 0px;
  }

  .client-file-lock {
    font-size: var(--sm-font);
    padding: 0px;
  }

  .client-key-button-section {
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }

  .client-top-dashboard-section {
    font-size: var(--s-font);
    color: var(--white);
    display: grid;
    column-gap: 10px;
    row-gap: 10px;
    justify-content: start;
    grid-template-columns: 1fr;
  }

  .client-file-info {
    row-gap: 4px;
  }

  .client-file-info-head-logo img {
    height: 3.5vh;
    width: 3.5vh;
  }
}

/* ===================== Delivery Tab ================== */

.delivery-tab-section {
  position: relative;
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: max-content;
  grid-template-columns: 1fr;
  row-gap: 1.5vh;
  margin: 10px;
  padding: 1vh;
  overflow-y: auto;
  font-size: var(--s-font);
  border-radius: 10px;
}

/* ======================== FILE INFO SECTION =================== */
.file-info-section {
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: max-content;
  grid-template-columns: 1fr;
  row-gap: 1vh;
}

.file-info-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: var(--m-font);
  color: var(--white);
}

.file-info-item {
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: max-content;
  grid-template-columns: 1fr;
  row-gap: 0.8vh;
}

/* .file-info-item-head{
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content; */
/* grid-template-columns: 1fr; */
/* grid-template-columns: repeat(2,max-content); */
/* justify-content: space-between;
    row-gap: 1vh;
    font-size: var(--sm-font);
    color: var(--label-blue);
} */

.file-info-item-head {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1vh;
  justify-content: space-between;
  align-items: center;
  font-size: var(--s-font);
  color: var(--label-blue);
}

#file-info-delivery-notes .file-info-item-value {
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: auto;
  gap: 0.5vh;
}

/* .delivery-notes{
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: minmax(max-content, 50vw);
    justify-content: flex-start;
    gap: 0.5vh;
} */

.delivery-notes {
  /* display: flex;
    flex-direction: row;
    flex-wrap: wrap; */
  gap: 0.5vh;
  min-width: 50%;
  text-align: justify;
}

.delivery-notes-file-name {
  color: var(--label-blue);
  word-break: break-all;
  max-width: 50%;
  margin-right: 0.5vh;
}

.delivery-note {
  word-break: break-word;
}

#file-info-files-list {
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: max-content;
  grid-template-columns: 1fr;
  row-gap: 0.8vh;
}

#file-info-files-container {
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: max-content;
  grid-template-columns: 1fr;
  row-gap: 0.8vh;
  max-height: 15vh;
  overflow-y: auto;
}

/* .file-info-file{
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    grid-template-columns: 1fr;
    row-gap: 1vh;
} */
.file-info-file {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 1vh;
  justify-content: space-between;
  align-items: center;
}

.file-info-file-name {
  display: flex;
  flex-direction: row;
  gap: 0.5vh;
  align-items: center;
  max-width: 60%;
  word-break: break-all;
  /* padding: 0.3vh 0.5vh;
    margin: 1px 0px; */
}

.file-info-file-download {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-template-columns: 1fr;
  align-items: center;
  column-gap: 0.8vh;
}
.file-info-file-download .file-info-file-download-btn {
  min-width: 5vh;
}

.file-info-file-download-btn {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  gap: 0.5vh;
  align-items: center;
  justify-content: center;
  font-size: var(--s-font);
  cursor: pointer;
  color: var(--light-blue);
}

.ext-link-btns {
  outline: none;
  border: none;
  background: none;
}

.file-info-file-ext-text-btn-active {
  color: #e98742;
}

/* =========New download btns css========= */
.file-info-file-download-btn-group {
  display: flex;
  /* min-width: 23vh;
    max-width: 25vh; */
  gap: 1vh;
}

.ext-btn-box-link {
  text-decoration: none;
}

.external-file-download-btn {
  display: flex;
  /* min-width: 9vh;
    max-width: 12vh; */
}

.default-file-download-btns {
  display: flex;
}

.file-info-file-download-btn svg {
  font-size: 1.55vh;
}

.file-info-file-download-btn-ext-text {
  text-decoration: underline;
}

#file-info-download-all-btn {
  background: var(--light-blue);
  color: var(--white);
}

/* .file-info-file-download-btn{
    padding: 0.3vh 0.5vh;
    border-radius: 0.5vh;
    color: var(--light-blue);
    display: flex;
    gap: 0.6vh;
    align-items: center;
    justify-content: center;
    font-size: var(--s-font);
    font-weight: bold;
    cursor: pointer;
    transition: 300ms;
    text-align: center;
    animation: download-btn-animation 3s linear 0s;
}

@keyframes download-btn-animation {
    0%   {opacity: 0;}
    15%  {opacity: 0.5;}
    40%  {
        opacity: 1;
        background: var(--light-blue);
        color: white;
    }
    100% {
        background: transparent;
        color: var(--light-blue);
    }
  }

.file-info-file-download-btn svg{
    font-size: 1.2vh;
}

.file-info-file-download-btn span{
    display: inline-flex;
    align-items: center;
} */

/* .file-info-file-download-btn:hover{
    background: var(--light-blue);
    color: white;
} */

/* .file-info-file-download-btn-activate{
    background: var(--light-blue);
    color: white;
} */

/* ======================= FEEDBACK SECTION ======================= */

/* .client-feedback-input{
    position: relative;
    width: 100%;
    height: fit-content;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-row-gap: 10px;
    grid-template-areas:
          "client-quality-feedback client-comment blank"
          "client-service-feedback client-comment client-submit-btn"
    ;
  } */

.client-feedback-input {
  position: relative;
  width: 100%;
  height: fit-content;
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  grid-template-areas:
    "client-quality-feedback client-service-feedback"
    "client-message-feedback client-message-feedback";
}

.client-feedback-question {
  /* width: 500px; */
  display: flex;
  row-gap: 10px;
  flex-direction: column;
}

#client-quality-feeback {
  grid-area: client-quality-feedback;
}

#client-service-feeback {
  grid-area: client-service-feedback;
}

.client-message-feedback {
  grid-area: client-message-feedback;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

#client-feedback-comment {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.client-feedback-submit-btn {
  position: relative;
  color: var(--light-blue, #3cc3f2);
  background-color: transparent;
  font-size: var(--l-font);
  cursor: pointer;
}
.client-feedback-submit-btn:hover {
  opacity: 0.9;
}

.client-feedback-label {
  position: relative;
  display: block;
  min-width: 180px;
  font-size: var(--s-font);
  color: var(--label-blue, #9cbdeb);
  width: fit-content;
  /* margin-bottom: 10px; */
}

.client-feedback-rating {
  position: relative;
  display: flex;
  column-gap: 30px;
  height: fit-content;
}

.client-feedback-emoji {
  height: 25px;
  width: 25px;
}

.client-feedback-rating-container {
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 30px;
}
.client-feedback-rating-container span {
  display: flex;
}

.client-feedback-text {
  position: relative;
  display: inline;
  margin: auto 0px;
  font-size: var(--s-font);
}

.feedbcak-small-label {
  width: 110px;
}

#commentsFeedback {
  position: relative;
  font-size: var(--s-font);
  background: rgba(17, 41, 73, 0.4);
  width: 100%;
  height: 50px;
  padding: 5px;
  border-radius: 5px;
  outline: none;
  color: var(--white, #ffffff);
  border: 0.5px solid rgba(255, 255, 255, 0.4);
}

.client-profile-form-input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--input-grey, #e98742);
}
.client-profile-form-input::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--input-grey, #e98742);
}
.client-profile-form-input:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--input-grey, #e98742);
}
.client-profile-form-input:-moz-placeholder {
  /* Firefox 18- */
  color: var(--input-grey, #e98742);
}

#comments-client-feedback-label {
  vertical-align: top;
}

#client-feedback-sbmt-td {
  vertical-align: top;
}

.rating-container {
  position: relative;
  display: inline-flex;
  flex-direction: row;
  column-gap: 10px;
}

.rating-label {
  display: inline-block;
  height: 25px;
  width: 25px;
}

.rating-label input[type="radio"] {
  display: none;
}

.client-feedback-rating-error {
  position: relative;
  width: fit-content;
  font-size: var(--s-font);
  height: fit-content;
  text-align: start;
  color: red;
  word-wrap: break-word;
}

.rating-star {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  color: #ebebeb;
}

#overallFeedback {
  position: relative;
  display: inline-block;
  height: 50px;
  width: 100px;
  font-size: 1.8rem;
  font-weight: bold;
  color: #1a9356;
}

.client-feedback-comment-div {
  position: relative;
  display: inline-block;
  width: 100%;
  text-align: justify;
  font-size: var(--s-font);
  justify-content: center;
  margin-right: 10px;
}

/* ============== COMMENT SECTION ================= */
.comments-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1vh;
  padding: 10px;
  font-size: var(--s-font);
  border-radius: 10px;
  height: 30vh;
  overflow-y: auto;
}

.comment-msg {
  display: grid;
  gap: 0.4vh;
  justify-content: space-between;
  align-items: center;
  max-width: 60%;
  min-width: 40%;
  padding: 1vh;
  border-radius: 0.5vh;
}

.comment-msg-user {
  align-self: flex-start;
  grid-area: comment-msg-user;
  color: var(--label-blue);
}

.comment-msg-date {
  grid-area: comment-msg-date;
  color: var(--label-blue);
  justify-self: flex-end;
  align-self: flex-start;
}

.comment-msg-feedback-file {
  display: flex;
  flex-direction: column;
  grid-area: comment-msg-feedback-file;
  color: var(--label-blue);
  word-wrap: break-word;
  word-break: break-all;
}

.comment-msg-chat-text {
  word-break: break-word;
  grid-area: comment-msg-chat-text;
}

/* ====== Unknown Message Type ==== */
/* .comment-msg-unknown{

} */

/* ==== Feedback Comment ========= */
.comment-msg-feedback {
  align-self: flex-start;
  grid-template-columns: repeat(2, minmax(auto, 1fr));
  grid-template-rows: repeat(2, max-content);
  grid-template-areas:
    "comment-msg-user comment-msg-date"
    "comment-msg-feedback-file comment-msg-feedback-container";
}

.comment-msg-feedback .comment-msg-feedback-container {
  grid-area: comment-msg-feedback-container;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  color: var(--label-blue);
}

.comment-feedback-quality {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1vh;
}

.comment-feedback-quality-label {
  display: inline-block;
  height: fit-content;
}

/* ===== Chat Messages ==== */
.comment-msg-chat {
  grid-template-columns: repeat(2, minmax(auto, 1fr));
  grid-template-rows: repeat(3, max-content);
  grid-template-areas:
    "comment-msg-user comment-msg-date"
    "comment-msg-feedback-file comment-msg-feedback-file"
    "comment-msg-chat-text comment-msg-chat-text";
}

/* ===== Sent Comment ===== */
.comment-msg-sent {
  align-self: flex-start;
}

/* ===== Receive Comment ===== */
.comment-msg-receive {
  align-self: flex-end;
}

/* ===== Notification Comment ===== */
.comment-msg-notification {
  grid-template-columns: repeat(2, minmax(auto, 1fr));
  grid-template-areas: "comment-msg-chat-text comment-msg-date";
}

/* ================ 720p screen =================== */

@media only screen and (max-width: 1460px) {
  /* ================= Feedback Form ===============*/
  .client-feedback-rating-container {
    column-gap: 10px;
  }

  .client-feedback-question {
    width: 300px;
  }

  #client-feedback-comment {
    grid-area: client-comment;
  }

  #commentsFeedback {
    height: 80%;
  }

  .rating-star {
    width: 20px;
    height: 20px;
  }
}

/* ==================== Landscape mobile ====================== */
@media only screen and (max-width: 850px) and (min-width: 500px) {
  /* ===============  FEEDBACK SECTION ==================== */
  .client-feedback-rating-container {
    column-gap: 5px;
  }

  .client-feedback-rating {
    column-gap: 20px;
  }

  .client-feedback-label {
    min-width: 120px;
    max-width: fit-content;
  }

  .client-feedback-emoji {
    height: 15px;
    width: 15px;
  }

  .rating-label {
    height: 15px;
    width: 15px;
  }

  .rating-star {
    width: 15px;
    height: 15px;
    margin-right: 0px;
  }

  .client-feedback-input {
    column-gap: 10px;
  }

  #client-feedback-comment {
    width: 100%;
  }

  #commentsFeedback {
    height: 80%;
  }

  .client-feedback-question {
    width: 100%;
  }
}

@media only screen and (max-width: 1025px) and (min-width: 850px) {
  /* ===============  FEEDBACK SECTION ==================== */
  .client-feedback-rating-container {
    column-gap: 5px;
  }

  .client-feedback-rating {
    column-gap: 20px;
  }

  .client-feedback-label {
    min-width: 120px;
    max-width: fit-content;
  }

  .rating-label {
    height: fit-content;
    width: fit-content;
  }

  .rating-star {
    width: 15px;
    height: 15px;
    margin-right: 0px;
  }

  .client-feedback-input {
    column-gap: 10px;
  }

  #client-feedback-comment {
    width: 100%;
  }

  #commentsFeedback {
    height: 80%;
  }

  .client-feedback-question {
    width: 100%;
  }
}

/* ####################### Tablet ################################## */

@media only screen and (min-width: 700px) and (max-width: 780px) and (min-height: 1000px) and (max-height: 1050px),
  (min-width: 1020px) and (max-width: 1040px) and (min-height: 1350px) and (max-height: 1370px),
  (min-width: 500px) and (max-width: 550px) and (min-height: 700px) and (max-height: 730px) {
  /* ======================== FILE INFO SECTION =================== */
  .file-info-head {
    font-size: var(--sm-font);
  }

  .file-info-item-head {
    font-size: var(--s-font);
    font-weight: bold;
  }

  .rating-star {
    height: 3vh;
    width: 3vh;
  }

  .file-info-file-download-btn svg {
    font-size: 1.8vh;
  }

  .file-info-file-download-btn {
    gap: 0.6vh;
    font-size: var(--s-font);
  }

  /* ======================= FEEDBACK SECTION ======================= */
  /* ================== Feedback Form =================== */
  .client-feedback-input {
    height: auto;
    grid-template-rows: auto auto auto auto;
    grid-template-columns: 100%;
    grid-row-gap: 1.5vh;
    grid-template-areas:
      "client-quality-feedback"
      "client-service-feedback"
      "client-message-feedback";
  }

  .client-feedback-emoji {
    height: fit-content;
    width: fit-content;
  }

  .client-dashboard-delivery-details p {
    width: 100%;
  }

  .rating-label {
    height: fit-content;
    width: fit-content;
  }

  /* .client-feedback-label{
        margin-bottom: 5px;
    }   */

  .client-feedback-question {
    row-gap: 0.5vh;
    width: auto;
  }

  #client-feedback-comment {
    grid-area: client-comment;
    row-gap: 10px;
  }

  #commentsFeedback {
    height: 50%;
  }

  .client-feedback-submit-btn {
    font-size: var(--m-font);
  }

  #client-feedback-submit-btn {
    height: auto;
  }

  /* ============ Comments Messages ================ */
  .comments-section {
    background: rgba(17, 41, 73, 0.4);
    font-size: var(--s-font);
    padding: 0.8vh;
  }

  .comment-msg {
    background: rgba(17, 41, 73, 0.4);
    min-width: 50%;
    max-width: 80%;
  }

  /* ======= Comment Feedback Msg ============*/
  .comment-msg-feedback {
    grid-template-columns: max-content 1fr;
  }

  .comment-feedback-quality {
    gap: 0.5vh;
  }

  .comment-feedback-quality .rating-star {
    height: 2vh;
    width: 2vh;
  }

  .comment-msg-feedback-file {
    width: 40vw;
  }

  /* ======= Comment Chat Msg ============ */
  .comment-msg-chat {
    grid-template-columns: max-content 1fr;
  }
}

/* ####################### Mobile ################################## */

@media only screen and (max-width: 500px) {
  .delivery-tab-section {
    font-size: var(--xs-font);
  }

  /* ======================== FILE INFO SECTION =================== */
  .file-info-head {
    font-size: var(--s-font);
  }

  .file-info-item-head {
    font-size: var(--xs-font);
    font-weight: bold;
  }

  .rating-star {
    height: 2vh;
    width: 2vh;
  }

  .client-feedback-text {
    font-size: var(--xs-font);
  }

  .client-feedback-rating-error {
    font-size: var(--xs-font);
  }

  .file-info-file-download-btn svg {
    font-size: 1.7vh;
  }

  .file-info-file-download-btn {
    gap: 0.6vh;
    font-size: var(--xs-font);
  }

  #file-info-files-container {
    max-height: 20vh;
  }

  /* ======================= FEEDBACK SECTION ======================= */
  /* ================== Feedback Form =================== */
  .client-feedback-label {
    font-size: var(--xs-font);
  }

  .client-feedback-input {
    height: auto;
    grid-template-rows: auto auto auto auto;
    grid-template-columns: 100%;
    grid-row-gap: 0.8vh;
    grid-template-areas:
      "client-quality-feedback"
      "client-service-feedback"
      "client-message-feedback";
  }

  .client-feedback-emoji {
    height: fit-content;
    width: fit-content;
  }

  .client-dashboard-delivery-details p {
    width: 100%;
  }

  .rating-label {
    height: fit-content;
    width: fit-content;
  }

  /* .client-feedback-label{
        margin-bottom: 5px;
    }   */

  .client-feedback-question {
    row-gap: 0.5vh;
    width: auto;
  }

  #client-feedback-comment {
    grid-area: client-comment;
    row-gap: 10px;
  }

  #commentsFeedback {
    font-size: var(--xs-font);
    height: 50%;
  }

  .client-feedback-submit-btn {
    font-size: var(--m-font);
  }

  /* ============ Comments Messages ================ */
  .comments-section {
    background: rgba(17, 41, 73, 0.4);
    font-size: var(--xs-font);
    padding: 0.8vh;
  }

  .comment-msg {
    background: rgba(17, 41, 73, 0.4);
    min-width: 50%;
    max-width: 90%;
  }

  /* ======= Comment Feedback Msg ============*/
  .comment-msg-feedback {
    grid-template-columns: max-content 1fr;
  }

  .comment-feedback-quality {
    gap: 0.5vh;
  }

  .comment-feedback-quality .rating-star {
    height: 2vh;
    width: 2vh;
  }

  .comment-msg-feedback-file {
    width: 40vw;
  }

  /* ======= Comment Chat Msg ============ */
  .comment-msg-chat {
    column-gap: 1vh;
    grid-template-columns: max-content 1fr;
  }
  .comment-msg-user {
    max-width: 20vh;
    word-break: break-word;
  }
}

@import "../../../general";

.chat_query_limit_warning_modal {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.6);
}

.chat_query_limit_container {
  height: fit-content;
  width: fit-content;
  // background: var(--vertical-blue-black-gradient);
  text-align: left;
  padding: 10px;
  left: 50%;
  font-size: var(--s-font);
}

.chat_query_limit_modal_container {
  @include centerFlex();
  justify-content: center;
  width: 100%;
  height: 100%;
}
.chat_query_limit_modal_wrapper {
  width: 100%;
  height: 50%;
  background-color: #0a182a;
  color: var(--white);
  border-radius: 12px;
  z-index: 10;

  @include media-mid-device() {
    width: 60%;
  }
}
.chat_query_limit_modal_section {
  @include verticalFlex($align: flex-start, $gap-size: 8vh);
  height: 100%;
  width: 100%;
  padding: 2vh;

  @include media-mid-device() {
    padding: 4vh;
  }
}
.chat_query_limit_modal_heading_text {
  @include verticalFlex($align: flex-start, $gap-size: 1vh);
}
.chat_query_limit_modal_button_section {
  @include centerFlex($gap-size: 1vh);
  justify-content: space-between;
  width: 100%;
}
.chat_query_limit_modal_left_button,
.chat_query_limit_modal_right_button {
  width: 100%;
  padding: 1vh 0;
  border: 2px solid white;
  // font-size: var(--sm-font);
  border-radius: 5px;
  @include stc-normal-font();
}
.chat_query_limit_modal_left_button {
  background-color: var(--white);
  color: black;
}
.chat_query_limit_modal_right_button {
  background: transparent;
  color: var(--white);
}
.chat_query_limit_modal_main_text {
  @include stc-heading-font();
}
.chat_query_limit_modal_para_text {
  @include stc-normal-font($fontColor: rgb(184, 184, 184));
}

.chat_query_limit_warning-container {
}

@import "../../../general";

.tool_media_input_wrapper {
  @include stc-btn($btnBgColor: var(--white));
  //   @include lib-input-input();
  //   border-radius: 4px;
  //   border: 1px solid #394d67;
  .tool_media_input_label {
    padding: 1vh;
    font-style: italic;
    color: var(--black);
  }

  .tool_media_input_files {
    border: none;
  }

  .tool_media_input_input_btn {
    @include stc-normal-font($fontColor: #112949);
  }
}
.cursor_disallowed {
  cursor: not-allowed;
}
.disabled_container_flip {
  min-height: 23.5vh;
  @include verticalFlex($align: center, $justify: center);
}
//file upload
// .flip_upload_btn {
//   .flip_file_upload_class {
//     color: black;
//   }
// }
// .flip_test {
//   background-color: red;
// }
.loader_line {
  width: 100vw;
  height: 3.5px;
  position: fixed;
  top: 0;
  overflow: hidden;
  background-color: #ddd;
  // margin: 100px auto;
  // -webkit-border-radius: 20px;
  // -moz-border-radius: 20px;
  // border-radius: 20px;
}

.loader_line:before {
  content: "";
  position: absolute;
  left: -50%;
  height: 3px;
  width: 40%;
  background-color: #ce6f2c;
  -webkit-animation: lineAnim 1.6s linear infinite;
  -moz-animation: lineAnim 1.6s linear infinite;
  animation: lineAnim 1.6s linear infinite;
}

@keyframes lineAnim {
  0% {
    left: -40%;
  }
  50% {
    left: 20%;
    width: 80%;
  }
  100% {
    left: 100%;
    width: 100%;
  }
}

@import "../../../../../general";

.tools_consult_file_upload_middle_container {
  @include verticalFlex($gap-size: 4vh);
  @include media-large-device() {
    gap: 3vh;
  }
}
.tools_consult_upload_img {
  padding-bottom: 1vh;
}

.tools_consult_upload_file_btn {
  @include verticalFlex($gap-size: 1vh);
  @include stc-heading-font($fontColor: var(--white));
}
.tools_consult_span_texts {
  font-size: var(--sm-font);
  display: none;
  @include media-large-device() {
    display: block;
  }
}
.tools_consult_uplaodfile_wrapper {
  @include verticalFlex($gap-size: 2vh);
}
.tools_consult_flip_or_section {
  @include horizontalFlex($width: 50%);

  @include media-mid-device() {
    width: 40%;
  }
  @include media-large-device() {
    width: 60%;
  }
}
.tools_consult_or_section_underline {
  width: 100%;
  height: 1px;
  background-color: white;

  @include media-large-device() {
    width: 100%;
  }
}
.tools_consult_upload_img {
  @include media-large-device() {
    width: 3rem;
  }

  @include media-small-desktop() {
    width: 4rem;
  }

  @include media-xl() {
    width: 5rem;
  }
}

@import "../../../common";

.carousel_wrapper {
  position: relative;
  height: 125px;
  width: 100%;

  @include media-350p() {
    height: 155px;
  }

  @include media-mid-device() {
    height: 100px;
  }

  @include media-large-device() {
    width: 100%;
  }

  @include media-small-desktop() {
    width: 80%;
  }
  @include media-xl() {
    width: 70%;
  }
  @media screen and (min-width: 1800px) {
    width: 60%;
  }

  @include media-xxl() {
    height: 200px;
  }
  @include media-xxxl() {
    height: 300px;
  }
}

.carousel_btns_wrapper {
  @include horizontalFlex($position: absolute);
  top: 0%;

  @include media-small-device() {
    width: 80%;
    left: 0%;
  }

  @media screen and (min-width: 700px) {
    width: 70%;
    left: 15%;
  }

  @include media-mid-device() {
    top: 10%;
    left: 10%;
    width: 80%;
  }

  @include media-large-device() {
    display: none;
  }

  @include media-sm-landscape-500p() {
    transform: translate(55px, 20px);
    // width: 80%;
  }

  @media screen and (min-width: 501px) and (max-width: 699px) and (min-height: 700px) and (max-height: 730px) and (orientation: portrait) {
    width: 70%;
    top: 20%;
    left: 15%;
  }

  @include media-tablet-1000p() {
    display: flex;
    width: 60%;
    left: 20%;
  }
}

.carousel_btn {
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.5);
  font-size: var(--rem-2xl-font);
  padding: 0;
}

.carousel_btn_left {
  @extend .carousel_btn;
}

.carousel_btn_right {
  @extend .carousel_btn;
}

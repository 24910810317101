@import "../../general";

.st_tools_main {
  box-sizing: border-box;
  grid-area: sttools;
  background: rgba(6, 14, 24, 1);
  @include verticalFlex($justify: flex-start, $gap-size: 1vh);

  @include media-extrasmall-device() {
    padding: 0vh 1vh;
    // height: calc(100vh - 100px);
  }
  @include media-tab-potrait-device() {
    padding: 0vh 1vh;
  }
  .st_tools_topnav_area {
    height: 60px;
    width: 100%;
    background: var(--tool-dark-blue);

    @include media-tab-potrait-device() {
      display: none;
    }
    @include media-extrasmall-device() {
      display: none;
    }
  }
  .st_tools_main_heading_container {
    @include horizontalFlex($justify: flex-start);
    padding: 1vh 1vh 0 3vh;

    @include media-extrasmall-device() {
      padding: 1vh 1vh 0 0vh;
    }
    @include media-tab-potrait-device() {
      padding: 1vh 1vh 0 0vh;
    }
    // width: 85%;

    // @include media-extrasmall-device() {
    //   width: 98%;
    //   padding: 1vh;
    // }

    // @include media-large-device() {
    //   padding: 2vh 1vh 0vh 1vh;
    // }
    // @include media-tab-potrait-device() {
    //   width: 98%;
    //   padding: 2vh 1vh 1vh 1vh;
    // }
    // @include media-small-desktop() {
    //   padding: 2vh 1vh 0vh 1vh;
    // }
    // @include media-xl() {
    //   padding: 2vh 1vh 0vh 1vh;
    // }
    .st_tools_heading {
      @include stc-mega-heading-font($fontColor: var(--white));
      width: min-content;

      @include media-extrasmall-device() {
        @include stc-heading-font($fontColor: var(--white));
      }
    }
    .st_tools_heading_icon {
      .ai_tools_icon_img {
        aspect-ratio: 1/1;
        @include media-large-device() {
          height: 20px;
        }
        @include media-small-desktop() {
          height: 28px;
        }
        @include media-xl() {
          height: 34px;
        }
        @include media-tab-potrait-device() {
          height: 23px;
        }
        @include surface-duo() {
          height: 32px;
        }
        @include media-extrasmall-device() {
          height: 26px;
        }
      }
    }
  }
}

.st_tools_card_main {
  // height: 85vh;
  width: 100%;
  overflow-y: auto;
  padding: 0vh 1vh;

  @include media-tab-potrait-device() {
    padding: 0vh;
  }
  @include media-extrasmall-device() {
    padding: 0vh;
  }
}

.st_tools_card_wrapper {
  height: 100%;
  padding-right: 1vh;
  @include horizontalFlex();

  @include media-large-device() {
    @include verticalFlex($gap-size: 2vh, $justify: flex-start);
  }

  @include media-potrait() {
    @include scrollbars($size: 2px, $thumb-color: rgba(255, 255, 255, 0.1), $track-color: rgba(6, 14, 24, 1));
    padding-right: 0vh;
  }

  @include media-large-device() {
    // overflow: auto;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 80vh;
  }
  @include media-small-desktop() {
    height: 83vh;
  }
  @include media-xl() {
    height: 85vh;
  }
  @include media-tab-potrait-device() {
    overflow-x: auto;
    height: auto;
    @include horizontalFlex();
    align-items: stretch;
    gap: 1vh;
  }
  @include media-extrasmall-device() {
    align-items: stretch;
    gap: 1vh;
    margin-bottom: 1vh;
  }

  // @media screen and (min-width: 100px) and (max-width: 1023px) and (orientation: landscape) {
  //   flex-direction: column;
  // }
  @include media-sm-landscape-500p() {
    @include regScrollbars();
    flex-direction: column;
  }
  @include media-sm-landscape-700p() {
    @include regScrollbars();

    flex-direction: column;
  }
  @include media-sm-landscape-900p() {
    @include regScrollbars();
    flex-direction: column;
  }

  @include media-tab-landscape-device() {
    @include regScrollbars();
  }
}

.st_tools_mobile_left {
  .st_tools_card_wrapper {
    @include verticalFlex($gap-size: 2vh, $justify: flex-start);

    @include media-extrasmall-device() {
      gap: 1.5vh;
    }
    @include media-tab-potrait-device() {
      gap: 1.5vh;
    }
  }
  @include media-tab-potrait-device() {
    @include verticalFlex($justify: flex-start, $gap-size: 2vh);
    overflow-x: hidden;
    padding-top: 2vh;
    height: 82vh;
  }
  @include media-extrasmall-device() {
    @include verticalFlex($justify: flex-start, $gap-size: 1.5vh);
    overflow-x: hidden;
    padding-top: 1vh;
    height: 82vh;
  }
  @media screen and (min-width: 0px) and (max-width: 350px) {
    height: 75vh;
  }
}
.st_tools_left_content {
  @include media-tab-potrait-device() {
    padding: 1vh 0vh;
    min-height: 6vh;
  }
  @include media-extrasmall-device() {
    padding: 1vh 0vh;
  }
}
.st_tools_card_left {
  @include media-tab-potrait-device() {
    // padding: 2vh 0vh 0vh 0vh;
    padding: 3.5vh 2.5vh !important;
    overflow: hidden;
    min-height: 22vh !important;
  }
  @include surface-duo() {
    min-height: 22vh !important;
    padding: 3.5vh 2.5vh !important;
  }
  @include media-extrasmall-device() {
    padding: 2vh 0vh 0vh 0vh;
    overflow: hidden;
    min-height: 210px;
  }
  @media screen and (min-width: 0px) and (max-width: 350px) {
    min-height: 160px;
  }
}
.st_tools_card {
  cursor: pointer;
  @include stc-glass-card();
  padding: 2vh;
  margin: 0;
  min-width: 340px;
  // border-radius: 12px;
  cursor: pointer;

  @include media-large-device() {
    overflow: hidden;
    @include verticalFlex();
    min-height: 140px;
    padding: 2vh;
    min-width: auto;
    // border-radius: 8px;
  }
  @include media-small-desktop() {
    // min-height: 180px;
  }
  @include media-xl() {
    min-height: 180px;
    gap: 0vh;
    // border-radius: 12px;
  }
  @include media-tab-potrait-device() {
    border-radius: 12px;
    @include verticalFlex($justify: flex-start);
    min-width: 30vh;
    gap: 2vh;
    padding: 2vh;
    margin: 0;
    // min-height: 22vh;
  }
  @include surface-duo() {
    min-height: 20vh;
  }
  @include media-extrasmall-device() {
    @include verticalFlex($justify: flex-start);
    gap: 1vh;
    margin: 0;
  }
  @media screen and (min-width: 0px) and (max-width: 370px) {
    min-width: 250px;
  }
  @include media-sm-landscape-500p() {
    min-width: 195px;
  }
  @include media-sm-landscape-700p() {
    min-width: 195px;
  }
  @include media-sm-landscape-900p() {
    min-width: 195px;
  }
  .st_tool_name_main {
    @include horizontalFlex();
    .st_tool_name {
      @include horizontalFlex($gap-size: 0.5vh, $align: flex-start);
      @include media-tab-potrait-device() {
        align-items: center;
      }
      @include media-extrasmall-device() {
        align-items: center;
      }
      .st_tool_logo {
      }
      .st_tool_name {
        @include stc-mega-heading-font();
        color: var(--white);
      }
    }
    .st_tool_arrow {
      cursor: pointer;
      .ai_tools_icon_img {
        aspect-ratio: 1/1;
        height: 25px;

        @include media-large-device() {
          height: 20px;
        }
        @include media-tab-potrait-device() {
          height: 40px;
        }
        @include surface-duo() {
          height: 27px;
        }
        @include media-small-desktop() {
          height: 25px;
        }
        @include media-xl() {
          height: 40px;
        }
      }
    }
  }
  .st_arrow_back_btn {
    @include media-sm-landscape-500p() {
      height: 4vh;
    }
    @include media-sm-landscape-700p() {
      height: 4vh;
    }
    @include media-sm-landscape-900p() {
      height: 4vh;
    }
  }
  .st_tool_content_main {
    @include horizontalFlex($justify: center, $align: center);
    margin: 2.5vh auto;
    text-align: center;
    @include media-large-device() {
      min-height: 9vh;
      margin: 0vh auto;
    }
    @include media-small-desktop() {
      // margin: 1vh auto;
      min-height: 9vh;
    }
    @include media-xl() {
      margin: 0vh auto;
      min-height: 9vh;
    }
    @include media-tab-potrait-device() {
      margin: 0vh;
    }
    @include media-extrasmall-device() {
      margin: 0vh;
    }
    .st_tool_content {
      @include verticalFlex($gap-size: 1vh);
      width: 85%;
      color: var(--white);
      @include stc-normal-font($fontColor: var(--white));

      @include media-tab-potrait-device() {
        @include stc-sub-heading-font($fontColor: var(--white));
      }
    }
  }
}

.search_form_home_mobile {
  @include media-tab-potrait-device() {
    padding: 1vh 0vh;
    @include horizontalFlex();
    width: 95vw;
  }
  @include media-tab-potrait-device() {
    padding: 1vh 0vh;
    @include horizontalFlex();
    width: 95vw;
  }
  @include media-extrasmall-device() {
    padding: 1vh;
    @include horizontalFlex();
    width: 95vw;
  }
  @include media-large-device() {
    display: none;
  }
  .search_form_home_mobile_input {
    // display: none;
    @include media-tab-potrait-device() {
      @include stc-input();
      background: transparent;
      width: 100%;
    }
    @include media-extrasmall-device() {
      @include stc-input();
      background: transparent;
      width: 100%;
    }
    @include media-large-device() {
      display: none;
    }
  }
  .search_form_home_mobile_input::placeholder {
    color: grey;
  }
  .search_form_mobile_btn {
    // display: none;
    @include media-tab-potrait-device() {
      @include stc-btn($btnBgColor: transparent, $minWidth: fit-content);
      position: absolute;
      right: 1vh;
      padding: 0.5vh;
    }
    @include media-extrasmall-device() {
      @include stc-btn($btnBgColor: transparent, $minWidth: fit-content);
      position: absolute;
      right: 1vh;
      padding: 0.5vh;
    }
    @include media-large-device() {
      display: none;
    }
  }
}

.st_tool_img {
  aspect-ratio: 1/1;
  height: 25px;
  @include media-large-device() {
    height: 20px;
  }
  @include media-small-desktop() {
    height: 25px;
  }
  @include media-xl() {
    height: 40px;
  }
}
.tools_ai_logo {
  @include media-large-device() {
    height: 15px;
  }
  @include media-small-desktop() {
    height: 20px;
  }
  @media screen and (min-width: 1500px) and (max-width: 1750px) {
    height: 23px;
  }
  @include media-xl() {
    height: 26px;
  }
  @include media-xxl() {
    height: 35px;
  }
  @include media-tab-potrait-device() {
    height: 21px;
  }
  @include media-extrasmall-device() {
    height: 21px;
  }
  @media screen and (min-width: 0px) and (max-width: 370px) {
    height: 17px;
  }
}
.st_tool_new_img {
  @include media-large-device() {
    height: 24px;
  }
  @include media-small-desktop() {
    height: 32px;
  }
  @include media-xl() {
    height: 40px;
  }
  @media screen and (min-width: 1500px) and (max-width: 1750px) {
    height: 40px;
  }
  @include media-xxl() {
    height: 55px;
  }

  @include media-tab-potrait-device() {
    height: 44px;
  }

  @include media-half-screen-desktop-device() {
    height: 3vh;
  }
  @include surface-duo() {
    height: 30px;
  }
  @include media-extrasmall-device() {
    height: 36px;
  }
  @include media-sm-landscape-500p() {
    height: 5vh;
  }
  @include media-sm-landscape-700p() {
    height: 5vh;
  }
  @include media-sm-landscape-900p() {
    height: 5vh;
  }
}
.st_tools_main_left {
  @include media-extrasmall-device() {
    height: calc(100vh - 120px);
  }
}

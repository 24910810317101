@import "../../../../common";

.category_text_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  flex-direction: column;
  gap: 0.5rem;
  width: 17rem;
  cursor: pointer;
  height: 100%;

  @media screen and (min-width: 360px) {
    width: 20rem;
    // height: auto;
  }

  @include media-small-device() {
    width: 20rem;
  }

  @include media-mid-device() {
    width: 30rem;
  }
  @include media-xxl() {
    width: 35rem;
  }

  @include media-xxxl() {
    width: 45rem;
  }
}

.category_text {
  color: var(--white);
  font-size: 20px;
  text-transform: uppercase;

  @media screen and (min-width: 350px) {
    font-size: 24px;
  }

  @include media-small-device() {
    font-size: 28px;
  }

  @include media-small-desktop() {
    font-size: 32px;
  }

  @include media-xxl() {
    font-size: var(--rem-5xl-font);
  }

  @include media-xxxl() {
    font-size: var(--rem-6xl-font);
  }
}

.category_desc_text {
  color: var(--white);
  font-size: var(--rem-sm-font);

  @include media-350p() {
    font-size: var(--rem-base-font);
  }
  @include media-large-device() {
    display: block;
  }

  @include media-xxl() {
    font-size: var(--rem-2xl-font);
  }

  @include media-xxxl() {
    font-size: var(--rem-3xl-font);
  }
}

@import "../../../general";

.consult_pdf_preview_pop_up_modal {
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 1vh;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.9);

  .consult_pdf_preview_modal_wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    padding: 4vh 0px;
    width: 60%;
    height: 100%;
    gap: 1vh;
    @include stc-normal-font();

    .consult_pdf_preview_modal_header_wrapper {
      .file_preview_head {
        @include horizontalFlex();
        height: 3.5vh;

        .file_preview_heading {
          @include horizontalFlex($justify: flex-start);
          @include stc-sub-heading-font();
          @include media-extrasmall-device() {
            @include stc-heading-font();
          }
          .consult_pdf_preview_modal_file_icon {
            width: 2rem;
            height: auto;
          }
        }

        .source_download_btn {
          @include media-large-device() {
            display: none;
          }
          @include media-potrait() {
            display: inline-block;
          }
        }

        .target_download_btn {
          @include media-large-device() {
            display: inline-block;
          }
          @include media-potrait() {
            display: none;
          }
        }
      }
    }

    .consult_pdf_preview_modal_pdf_preview_wrapper {
      background-color: var(--light-grey);
      overflow: auto;
    }
  }

  .consult_pdf_preview_pop_up_modal_close {
    @include horizontalFlex($width: auto, $align: flex-start);
    padding: 4vh 0px;
    color: white;
    height: 100%;
    svg {
      cursor: pointer;
    }
  }
}

@import "../../general";

.home_file_search_main {
  grid-area: stc-file-search;
  @include verticalFlex($gap-size: 1vh, $justify: space-evenly);
  background: #060e18;
  padding: 2vh;

  @include media-large-device() {
    gap: 1vh;
  }
  @include media-small-desktop() {
    gap: 1vh;
  }
  @include media-tab-potrait-device() {
    padding: 1vh;
    gap: 1vh;
    // margin-top: -11vh;
  }
  @include media-extrasmall-device() {
    padding: 1vh;
    gap: 3vh;
  }

  @include media-xl() {
    padding-top: 0;
  }

  .home_file_search_heading {
    .home_file_search_text {
      @include stc-mega-heading-font($fontColor: var(--white));

      @include media-tab-potrait-device() {
      }
    }
  }

  // @include media-mid-device() {
  //   margin-top: -7vh;
  // }
}
.home_search_form {
  @include horizontalFlex($width: 72%, $align: stretch);
  @include media-tab-potrait-device() {
    width: 100%;
  }
  @include media-extrasmall-device() {
    width: 100%;
  }
  @include media-large-device() {
    padding-top: 2vh;
  }
}

.home_file_content {
  @include horizontalFlex($justify: center, $align: center);
  .home_file_content_text {
    width: 48%;
    text-align: center;
    font-size: 15px;
    color: var(--white);

    @include media-large-device() {
      width: 60%;
      @include stc-normal-font();
    }
    @include media-small-desktop() {
      width: 45%;
    }
    @include media-tab-potrait-device() {
      width: 80%;
      @include stc-heading-font($fontColor: var(--white));
    }
    @include media-extrasmall-device() {
      width: 80%;
      @include stc-small-font($fontColor: var(--white));
    }
  }
}

.home_search_dashboard_input {
  @include horizontalFlex();
  @include stc-input($inputBgColor: transparent);
  border: 1px solid #394d67;
  // padding: 11px 10px;
  // border-radius: 4px;
  // border: none;
  // outline: none;
  // background: #060e18;
  // color: var(--white, #ffffff);
  // border: 1px solid #394d67;
}
.home_search_dashboard_input::placeholder {
  color: #ffffff80;

  @include media-tab-potrait-device() {
    // @include stc-heading-font($fontColor: #ffffff80);
  }
}

@media only screen and (max-width: 1300px) {
  .home_search_dashboard_input {
    padding: 5px;
    border-radius: 5px;
  }
}
@include media-extrasmall-device() {
  .home_search_dashboard_input {
    padding: 8px;
    border-radius: 5px;
  }
}

.home_search_btns {
  @include stc-btn($btnBgColor: var(--white), $btnTextColor: #112949, $minWidth: 6vw);
  width: 14vh !important;
  border-radius: 4px;

  @include media-tab-potrait-device() {
    // @include stc-mega-heading-font($fontColor: 112949);
  }
}

/* ================== Source File Download Modal ============ */
.client-source-download-file-modal {
  position: relative;
  display: flex;
  flex-direction: column;
  /* row-gap: 10px; */
  width: 30vw;
  border-radius: 10px;
  top: 50%;
  transform: translate(-10%, -50%);
  background: var(--black, #03080f);
}

.client-source-download-file-modal-head {
  color: var(--label-blue);
}

.client-source-file-download-section {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: row;
  grid-auto-rows: max-content;
  row-gap: 0.5vh;
  column-gap: 100%;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: var(--s-font);
}

.client-source-file {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.client-source-file-download {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-template-columns: 1fr;
  column-gap: 0.8vh;
}
/* .client-source-file-download .client-source-file-download-btn{
    min-width: 60px;
} */

.client-source-file-download-btn {
  color: var(--light-blue);
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-gap: 0.2vh;
  align-items: center;
  justify-content: center;
  font-size: var(--s-font);
  font-weight: normal;
  cursor: pointer;
}

.client-source-file-download-btn svg {
  font-size: 1.5vh;
}

.client-source-file-download-all {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  column-gap: 0.5vh;
  align-items: center;
  justify-self: flex-end;
}

.client-source-file-name {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  column-gap: 0.5vh;
  align-items: center;
  justify-self: flex-end;
  width: fit-content;
  max-width: 70%;
}

.client-source-file-name-modal-text {
  max-width: 15vw;
  word-wrap: break-word;
  word-break: break-all;
}

.client-source-file-name img {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 30px;
}

/* ======== TABLET ========= */
@media only screen and (min-width: 700px) and (max-width: 780px) and (min-height: 1000px) and (max-height: 1050px),
  (min-width: 1020px) and (max-width: 1040px) and (min-height: 1350px) and (max-height: 1370px),
  (min-width: 500px) and (max-width: 550px) and (min-height: 700px) and (max-height: 730px) {
  .client-source-download-file-modal {
    width: calc(100% - 20px);
    top: 50%;
    left: 0%;
    margin: 10px;
    transform: translate(0%, -50%);
  }

  .client-source-file-download-btn svg {
    font-size: 1.8vh;
  }

  .client-source-file-download-all {
    column-gap: 1vh;
  }

  .client-source-file-download {
    column-gap: 1vh;
  }

  .client-source-file-name-modal-text {
    max-width: 50vw;
  }
}

/* ============== MOBILE ================ */

@media only screen and (max-width: 500px) {
  .client-source-download-file-modal {
    width: calc(100% - 20px);
    top: 50%;
    left: 0%;
    margin: 10px;
    transform: translate(0%, -50%);
  }

  .client-source-file-download-btn svg {
    font-size: 1.8vh;
  }

  .client-source-file-download-all {
    column-gap: 1vh;
  }

  .client-source-file-download {
    column-gap: 1vh;
  }

  .client-source-file-name-modal-text {
    max-width: 50vw;
  }
}
